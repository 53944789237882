import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, timeout } from 'rxjs/operators';
import { ErrorHandlerService } from './error-handler.service';
import { environment } from "../../environments/environment";
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import * as moment from 'moment-timezone';
import { isEmpty, get, has } from 'lodash';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(private httpClient: HttpClient,
    private handleError: ErrorHandlerService) { }

  getCommonHttpClientParams(urlAppendedString) {
    return this.httpClient.get(urlAppendedString).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.handleError.handleError)
    );
  }

  getCommonFileDownload(urlAppendedString, params = {}){
    let httpParams = new HttpParams();
    Object.keys(params).forEach(function (key) {
      httpParams = httpParams.append(key, params[key]);
    });
    return this.httpClient.get(urlAppendedString,{responseType:"blob",params: httpParams}).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.handleError.handleError)
    );
  }

  getCommonHttpClientQueryParams(url, params) {
    //console.log(url, params);
    let httpParams = new HttpParams();
    Object.keys(params).forEach(function (key) {
      httpParams = httpParams.append(key, params[key]);
    });
    return this.httpClient.get(url, {params: httpParams}).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.handleError.handleError)
    );
  }

  getCommonHttpTimeout(url, params, time) {
    console.log(url, params);
    let httpParams = new HttpParams();
    Object.keys(params).forEach(function (key) {
      httpParams = httpParams.append(key, params[key]);
    });
    return this.httpClient.get(url, {params: httpParams}).pipe(
      timeout(time*1000),
      map((res: any) => {
        return res;
      }),
      catchError((error) => {
       return throwError(error);
      })
    );
  }

  getResponseDataForFilter(url, params) {
    console.log("getResponseDataForFilter >>>>>>>>>", url, params);
    let httpParams = new HttpParams();
    Object.keys(params).forEach(function (key) {
      httpParams = httpParams.append(key, params[key]);
    });
    return this.httpClient.get(url, {params: httpParams}).pipe(
      map((res: any) => {
        if(res.data && res.data.items){
          return res.data.items;
        }
        return res.data;
      }),
      catchError(this.handleError.handleError)
    );
  }

  // getCommonHttpClientQueryParamsWithObservable(url, params): Observable<any> {
  //   console.log("I am called am common service", url, params);

  //   let httpParams = new HttpParams();
  //   Object.keys(params).forEach(function (key) {
  //     httpParams = httpParams.append(key, params[key]);
  //   });
  //   // console.log("Http Params: ", httpParams);

  //   return this.httpClient.get(url, {params: httpParams}).pipe(
  //     map((res: any) => {
  //       console.log("This is result from common service: ", res);
  //       return res;
  //     }),
  //     catchError(this.handleError.handleError)
  //   );
  // }

  postCommonHttpClient(url, data) {
    console.log(url, data);
    return this.httpClient.post(url, data).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.handleError.handleError)
    );
  }

  postCommonHttpClientWithParams(url, data, params) {
    console.log(url, data);
    let httpParams = new HttpParams();
    Object.keys(params).forEach(function (key) {
      httpParams = httpParams.append(key, params[key]);
    });
    return this.httpClient.post(url, data, {params: httpParams}).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.handleError.handleError)
    );
  }

  async doubleExtensionCheck(originalFileName) {
    let supportedExtensions = ['jpg', 'jpeg', 'png', 'svg', 'xls', 'xlsx','apk'];
    let doubleExtensionCheck = originalFileName.split(".");
    let extensionCount = 0;
    console.log(doubleExtensionCheck);
    if (doubleExtensionCheck.length > 2) {
        await doubleExtensionCheck.forEach(async data => {
            extensionCount = supportedExtensions.indexOf(data) > -1 ? extensionCount + 1 : extensionCount;
        });
    console.log(extensionCount);
        if (extensionCount > 1) {
            return true;
        } else {
            return false;
        }
    } else {
      return false;
    }
  }

  downloadAsExcel(workBokData){
    let workbook = new Workbook();
    let sheet;
    for(let i =0;i<workBokData.worksheet.length; i++){
      if(has(workBokData.worksheet[i], 'frozen') && get(workBokData.worksheet[i], 'frozen')) {
        sheet = workbook.addWorksheet(workBokData.worksheet[i].name, {
          views: [{ state: "frozen", ySplit: 1 }],
        });
      } else {
        sheet = workbook.addWorksheet(workBokData.worksheet[i].name);
      }

       sheet.columns = workBokData.worksheet[i].columns ;
       if(workBokData.worksheet[i].isRow == 1){
         sheet.addRow(workBokData.worksheet[i].rows , "n");
       }else{
         sheet.addRows(workBokData.worksheet[i].rows);
       }
    }
    return workbook.xlsx.writeBuffer().then(function (data) {
      var blob = new Blob([data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
      fs.saveAs(blob, workBokData.fileName);
    })
  }

  estTimeConvert(date){
    return moment.tz(date,'America/New_York').format('MM-DD-YYYY HH:mm');
  }

  getAccountPersonalData(keyName) {
    let temp = {
      druidName: "druid_system",
      druidKeyName: "fnkU2ck$M!V4m",
      apiPersonalKey: "a758e45a88e9ce3a9d6ab9509a4c2f09",
      pgPersonalKey: "portal-pg=Token-phase-1",
      secretKeyCryptoToken: "portal-Temp=Token-phase-1",
    }
    return has(temp, keyName) ? temp[keyName] : '';
  }

  formatDateInTimeZone(date, timeZone) {
    const formattedDate = moment.tz(date, timeZone).format(environment.dateTimeFormat);
    const timeZoneAbbr = moment.tz.zone(timeZone)?.abbr(Date.now());
  
    return `${formattedDate} (${timeZoneAbbr})`;
  }

  putCommonHttpClient(url, data) {
    console.log(url, data);
    return this.httpClient.put(url, data).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.handleError.handleError)
    );
  }

  deleteCommonHttpClient(url) {
    console.log(url);
    return this.httpClient.delete(url).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.handleError.handleError)
    );
  }
  
}
