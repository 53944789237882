<p-toast position="top-right"></p-toast>

<div class="shadow  p-3 mb-5 bg-white rounded">
<div class="d-flex justify-content-between align-items-center flex-wrap grid-margin px-0 bg-white rounded mr-2 ml-2 mb-0">

    <div class="d-flex align-items-center flex-wrap text-nowrap">
      <!-- Action button -->
      <div ngbDropdown>
        <button class="btn btn-outline-primary btn-xs d-none d-md-block" id="dropdownBasic1" ngbDropdownToggle><span
            class="feather link-icon icon-sm icon-list"></span></button>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="shadow p-3 mb-2 bg-white rounded" style="border: 1px solid #e9ecef;">

          <a ngbDropdownItem class="d-flex align-items-center" triggers="mouseenter:mouseleave" ngbPopover="Add HostInventory"
          [routerLink]="'/add-host-inventory'" [disabled]="!isPermission(privileges.ADD_HOST_INVENTORY)">
          <i data-feather="plus"
            appFeatherIcon class="icon-sm mr-2"></i> <span class="">{{'hostInventory.add_hostInventory' | translate}}</span></a>

          <a ngbDropdownItem class="d-flex align-items-center" href="" (click)="allData();false"><i data-feather="download"
            appFeatherIcon class="icon-sm mr-2"></i> <span class="">{{'hostInventory.download' | translate}}</span></a>

          <a [disabled]="!isPermission(privileges.ADD_HOST_INVENTORY)" ngbDropdownItem class="d-flex align-items-center" href="" (click)="openUploadModal();false" ><i data-feather="upload"
              appFeatherIcon class="icon-sm mr-2"></i> <span class="">{{'hostInventory.upload_hostInventory' | translate}}</span></a>

          <a [disabled]="!isEnableBulkUpdateTid || !isPermission(privileges.EDIT_HOST_INVENTORY)" ngbDropdownItem class="d-flex align-items-center" href="" (click)="isFilterSelected();false" ><i data-feather="upload"
              appFeatherIcon class="icon-sm mr-2"></i><span class="">{{'hostInventory.bulkTidUpdate' | translate}}</span></a>
              <!-- <button >Click me</button> -->
        </div>
      </div>
    </div>

  <app-search-box-filter class="col-5 px-0" placeholder="Search by TID, Processor & OwnerID" (filteredData)="updateFilter($event)"
  [queryParam]="filterQuery" [apiNameProperty]="apiName" (hostFilterLoader)="hostFilterLoader($event)" [events]="eventsSubject.asObservable()"></app-search-box-filter>

    <div class="d-flex align-items-center flex-wrap text-nowrap">
      <!-- quirk search -->
      <div class="btn-group mb-3 mb-md-0 ml-1" role="group" aria-label="Basic example">
        <button type="button" (click)=toggleDisplay() placement="left"
        triggers="mouseenter:mouseleave" ngbPopover="Filter" class="btn btn-outline-primary btn-xs d-none d-md-block mr-4">
          <i class="link-icon feather icon-filter"></i>
        </button>
        <!-- Button trigger modal -->
      </div>
      <div>
        <button type="button" (click)="refreshPage()" placement="left"
        triggers="mouseenter:mouseleave" ngbPopover="Refresh" class="btn btn-outline-primary btn-circle"><i class="link-icon feather icon-refresh-cw"></i>
        </button>
      </div>
    </div>
  </div>

  <div *ngIf="isShow">
  <div class="row my-3 pt-3 border-top ">

    <div class="col-md-3">
      <ng-select [multiple]="true" [items]="processorList" bindLabel="name" bindValue="name" placeholder="Processor" clearAllText="Clear"
        (change)="selectedFilter($event,'processorId')" [(ngModel)]="selectedProcessor">
      </ng-select>
    </div>

    <div class="col-md-3">
      <div class="input-group">
        <input type="text" class="form-control floating-input" [(ngModel)]="selectedDate" placeholder="Date selection"
          [readonly]="true">
        <div class="input-group-append">
          <button class="d-flex align-items-center btn btn-primary mdi  mdi-calendar-month-outline"
            (click)='openDateRangeModel()'>
          </button>
        </div>
        </div>
    </div>

    <div class="col-md-3">
      <ng-select [items]="orderBy" bindLabel="order" bindValue="order" placeholder="orderBy" clearAllText="Clear"
        (change)="selectedFilter($event,'sort')" [(ngModel)]="selectedOrder">
      </ng-select>
    </div>

    <div class="col-md-3">
      <ng-select [items]="hostInventoryStatus" bindLabel="status" bindValue="Status" placeholder="status" clearAllText="Clear"
        (change)="selectedFilter($event,'status')" [(ngModel)]="selectedstatus">
      </ng-select>
    </div>
  </div>
  <div class="row my-3 pt-3 border-top ">
    <div class="col-md-3">
      <div class="input-group">
        <input type="text" class="form-control floating-input" placeholder="Search Agent Info (Min 3 Chars)"
          (ngModelChange)="selectedFilter($event,'hostInventoryAgentInfo')" [(ngModel)]="searchedAgent">
      </div>
    </div>
    <div class="col-md-3">
      <div class="input-group">
        <input type="text" class="form-control floating-input" placeholder="Search Owner Info (Min 3 Chars)"
          (ngModelChange)="selectedFilter($event,'hostInventoryOwnerNotes')" [(ngModel)]="searchedOwner">
      </div>
    </div>
    <div class="col-md-3" *ngIf="isShow && userType == 'SYSTEM'">
      <ng-select [items]="customersDba$ | async" bindLabel="dba" bindValue="id" placeholder="Search DBA or Email"
        [(ngModel)]="selectedCustomerId" [closeOnSelect]="false" [clearable]="true" [loading]="isCustomersLoading"
        notFoundText="No customer found" typeToSearchText="Please enter 3 or more characters" [typeahead]="inputDba$"
        (change)="selectedFilter($event,'customerId')" [multiple]="true">
        <ng-template ng-option-tmp let-item="item">
          {{item.dba}} <br />
          <small>{{item.email}}</small>
        </ng-template>
      </ng-select>
    </div>
  </div>
  </div>
  
  <div *ngIf="isShow" class="row p-2 border-top mt-1">
    <div class="col-md-12 text-right">
      <button class="btn btn-outline-primary mr-2" (click)="search()" type="button" placement="top" triggers="mouseenter:mouseleave" [ngbPopover]="disableButtonContent" [disabled]="isDisableSearchButton">
        <i class="link-icon feather icon-search align-bottom"></i>
        Search
      </button>
      <button class="btn btn-outline-primary " (click)="clear()" type="button">
        <i class="link-icon feather icon-x-circle align-bottom"></i>
        {{'parameter.Clear' | translate}}
      </button>
    </div>
  </div>

</div>

<ng-template #OpenLoader class="text-center m-4" let-modal>
  <div class="modal-header d-flex justify-content-between">
    <h5>{{'htmlText.isFilterAlertTitle'| translate}}</h5>
  </div>
  <p class="text-center mt-2 mb-2">
    {{'htmlText.isFilterAlert'| translate}}</p>
  <div class="text-right m-1">
    <button (click)="modal.close()"
      class="mt-2 text-right btn btn-danger mr-2">{{'htmlText.cancel'| translate}}</button>
  </div>
</ng-template>

