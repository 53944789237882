import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { apiLinks } from '../core/api-links/api-links';
import { HttpClient, HttpParams } from "@angular/common/http";
import {ErrorHandlerService} from './error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class BatchService {

  constructor(private httpClient: HttpClient, private handleError: ErrorHandlerService) { }

  getBatchDetails(params) {
    // return this.httpClient.get(apiLinks.batches).pipe(
    //   map((res: any) => {
    //     return res;
    //   }),
    //   catchError(this.handleError.handleError)
    // );
    let httpParams = new HttpParams();
    //httpParams = httpParams.append('sort', '{"field": "updatedAt", "order": "DESC"}');
    httpParams = httpParams.append('size', '10');
    Object.keys(params).forEach(function (key) {
      httpParams = httpParams.append(key, params[key]);
    });
    return this.httpClient.get(apiLinks.batches, {params: httpParams}).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.handleError.handleError)
    );
  }

  getAllTpnIds() {
    // return this.httpClient.get(apiLinks.batches).pipe(
    //   map((res: any) => {
    //     return res;
    //   }),
    //   catchError(this.handleError.handleError)
    // );
    let httpParams = new HttpParams();
    //httpParams = httpParams.append('sort', '{"field": "updatedAt", "order": "DESC"}');
    // httpParams = httpParams.append('size', '10');
    // Object.keys(params).forEach(function (key) {
    //   httpParams = httpParams.append(key, params[key]);
    // });
    return this.httpClient.get(apiLinks.tpnIds).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.handleError.handleError)
    );
  }

  getAllDBAs() {
    // return this.httpClient.get(apiLinks.batches).pipe(
    //   map((res: any) => {
    //     return res;
    //   }),
    //   catchError(this.handleError.handleError)
    // );
    let httpParams = new HttpParams();
    //httpParams = httpParams.append('sort', '{"field": "updatedAt", "order": "DESC"}');
    // httpParams = httpParams.append('size', '10');
    // Object.keys(params).forEach(function (key) {
    //   httpParams = httpParams.append(key, params[key]);
    // });
    return this.httpClient.get(apiLinks.allDBAsByUser).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.handleError.handleError)
    );
  }

  getAllStores(params){
    let httpParams = new HttpParams();
    httpParams = httpParams.append('size', '10');
    Object.keys(params).forEach(function (key) {
      httpParams = httpParams.append(key, params[key]);
    });
    return this.httpClient.get(apiLinks.dbaBatches, {params: httpParams}).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.handleError.handleError)
    );
  }

  getAllTotalStores(){
    return this.httpClient.get(apiLinks.dbaTotalBatches).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.handleError.handleError)
    );
  }

}
