import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { map, catchError, timeout } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { AuthService } from "./auth.service";
import {ErrorHandlerService} from './error-handler.service';
import { Application } from '../core/model/application';
import * as _ from 'lodash';
import {apiLinks} from "../core/api-links/api-links";

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {
  getApplicationsUrl = `${environment.steamApiUrl}/applications`;
  getApplicationSignUrl = `${environment.apiUrl}/applicationSignature`;
  getDeviceModalsUrl = `${environment.apiUrl}/device-models-by-manufacturer/`;
  getLatestBuildNumber = `${environment.steamApiUrl}/getLatestBuildISO/`;
  getManufacturerUrl = `${environment.apiUrl}/manufacturers`;
  postApplicationUrl = `${environment.steamApiUrl}/applications`;
  getCustomerUrl = `${environment.apiUrl}/getAllDenovoCustomers`;
  constructor(
    private handleError:ErrorHandlerService,
    private httpClient: HttpClient,
    private authService: AuthService
  ) { }

  getAllData(httpReqUrl): Observable<Application[]> {
    // let filter=page!=0?`?isFilter=false&page=${page}&sort={"field": "updatedAt", "order": "DESC"}&size=20`:'';
    // console.log(page)
    return this.httpClient.get<Application>(httpReqUrl).pipe(
      map((res: any) => {
        console.log("Result Data: ", res.data);
        return res.data;
      }),
      catchError(this.handleError.handleError)
    );
  }

  getLastBuildNum(httpReqUrl, parameter) {
    console.log(parameter)
    httpReqUrl = httpReqUrl+parameter.manufacturerId+"/"+parameter.deviceModelId+"/"+parameter.applicationSignatureId;
    return this.httpClient.get(httpReqUrl).pipe(
      map((res: any) => {
        return res.data;
      }),
      catchError(this.handleError.handleError)
    );
  }
  getLastBuildNumISO(httpReqUrl, parameter,data) {
    //console.log(parameter);
    httpReqUrl = httpReqUrl+parameter.manufacturerId+"/"+parameter.deviceModelId+"/"+parameter.applicationSignatureId;
    return this.httpClient.post(httpReqUrl,data).pipe(
      map((res: any) => {
        return res.data;
      }),
      catchError(this.handleError.handleError)
    );
  }
  getVirtualTerminalParams(params) {
    let httpParams = new HttpParams();
    Object.keys(params).forEach(function (key) {
      httpParams = httpParams.append(key, params[key]);
    });
    return this.httpClient.get(`${apiLinks.vtPosparam}`, { params: httpParams }).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.handleError.handleError)
    );
  }
  createApplication(applicationData) {
    return this.httpClient.post(this.postApplicationUrl, applicationData).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.handleError.handleError)
    );
  }

  getReadableBytes(bytes, sizeType) {
    let i = Math.floor(Math.log(bytes) / Math.log(1024)),
    sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let sizesIndex = sizes.indexOf(sizeType);
    if(sizesIndex == -1) {
      return 'Invalid size type';
    }
    return (bytes / Math.pow(1024, i)).toFixed(2) + ' ' + sizes[sizesIndex];
  }

  getAllApplicationData(params) {
    console.log('service', params);

    let httpParams = new HttpParams();
    httpParams = httpParams.append('sort', '{"field": "updatedAt", "order": "DESC"}');
    httpParams = httpParams.append('size','20');
    Object.keys(params).forEach(function (key) {
      httpParams = httpParams.append(key, params[key]);
    })
    return this.httpClient.get(`${environment.steamApiUrl}/applications`, {params: httpParams}).pipe(
      map((res: any) => {
        console.log("Result Data: ", res);
        return res;
      }),
      catchError(this.handleError.handleError)
    );
  }

  getPGAPI(defaultData, timer) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Authorization': `Bearer ${this.authService.getUser.accessToken}`, 'withCredentials':'false'})
    };

    if(timer == 0) {
      return this.httpClient.post(`${apiLinks.processTransaction}`, defaultData, httpOptions).pipe(
        map((res: any) => {
         return res;
        }),
        catchError(this.handleError.handleError)
      )
    } else {
      return this.httpClient.post(`${apiLinks.processTransaction}`, defaultData, httpOptions).pipe(
        timeout(timer * 1000),
        map((res: any) => {
          return res;
        }),
        catchError((error) => {
          return error.message;
        })
      )
    }
  }

  getPaymentForm(data) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Authorization': `Bearer ${this.authService.getUser.accessToken}`, 'withCredentials':'false' })
    };
    return this.httpClient.post(`${apiLinks.paymentForm}`, data, httpOptions).pipe(
      map((res: any) => {
       return res;
      }),
      catchError(this.handleError.handleError)
    )
  }

  sendEmailorSMS(data) {
    return this.httpClient.post(`${apiLinks.sendEmailorSMS}`, data).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.handleError.handleError)
    )
  }
}
