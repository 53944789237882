<p-toast position="top-right"></p-toast>

<div class="shadow  p-3 mb-5 bg-white rounded">
  <div
    class="d-flex justify-content-between align-items-center flex-wrap grid-margin px-0 bg-white rounded mr-2 ml-2 mb-0">
    <div class="d-flex align-items-center flex-wrap text-nowrap">
                    </div>
    <app-search-box-filter class="col-md-4 px-0" (filteredData)="applyFilters($event)" [apiNameProperty]="'invoiceList'"
      [queryParam]="filterQuery" placeholder="Search by ISO/Invoice#" [events]="eventsSubject.asObservable()"></app-search-box-filter>
    <div class="d-flex align-items-center flex-wrap text-nowrap">
      <div class="btn-group mb-3 mb-md-0 ml-1" role="group" aria-label="Basic example">
        <button (click)=toggleDisplay() type="button" placement="left" triggers="mouseenter:mouseleave"
          ngbPopover="Filter" class="btn btn-outline-primary btn-xs d-none d-md-block mr-4">
          <i class="link-icon feather icon-filter"></i>
        </button>
      </div>
      <div>
        <button type="button" (click)="refreshPage()" placement="left" triggers="mouseenter:mouseleave"
          ngbPopover="Refresh" class="btn btn-outline-primary btn-circle"><i
            class="link-icon feather icon-refresh-cw"></i>
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="isShow" class="row pt-3 border-top mt-3">
    <div class="col-md-3">
      <ng-select [items]="status" bindLabel="name" bindValue="id" placeholder="Invoice Status" clearAllText="Clear"
        [(ngModel)]="statusTypes" [multiple]="false" (change)="selectedFilter($event, 'status')" [clearable] = "false">
      </ng-select>
    </div>
    <div class="col-md-3">
      <ng-select id="monthBox" [items]="monthList" bindLabel="label" bindValue="value" placeholder="Date Selection"
          [(ngModel)]="selectedMonth" (change)="selectedFilter($event, 'month')" [clearable] = "false">
      </ng-select>
    </div>
    <div class="col-md-2">
      <ng-select id="year" [items]="yearList" bindLabel="label" bindValue="value"  [(ngModel)]="selectedYear" placeholder="Year Selection"
        (change)="selectedFilter($event, 'year')" [clearable] = "false">
      </ng-select>
    </div>
    <div class="col-md-2"></div>

    <div class="col-md-2 text-right">
      <button class="btn btn-outline-primary mt-1" (click)="clear();" type="button">Clear</button>
    </div>
  </div>
</div>

<app-loading *ngIf="isLoading"></app-loading>

<!-- <div *ngIf="isRefresh" class="container">
  <div class="loading" *ngIf="!isLoading">
      <p class="load_center">
          <span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
          <span>Refreshing...</span>
      </p>
  </div>
</div> -->
<div *ngIf="(billingInvoiceListData?.length == 0) && !isLoading" class="container text-center">
  <div>
    <p class="load_center">
      No Data
    </p>
  </div>
</div>

<div *ngIf="billingInvoiceListData ? billingInvoiceListData.length > 0 : '' && !isLoading" class="row chat-wrapper">
  <div class="col-md-12">
    <div class="card">
      <div class="card-body">
        <div class="row position-relative">

          <div class="col-lg-5 chat-aside border-lg-right">
            <div class="aside-content">
              <div class="card-header list-header width-heade-align">
                <p class="card-text mt-1 col-md-12 pl-0 text-left"><span class="font-weight-bold">
                    {{'Invoices' | translate}} ({{collectionSize}})</span></p>
              </div>
              <div class="aside-body">
                <ul ngbNav #defaultNav="ngbNav" class="mt-3">
                  <li [ngbNavItem]="1">
                    <ng-template ngbNavContent>
                      <div #container class="container merchant-list-view-design ps" [perfectScrollbar]>
                        <p class="text-muted mb-1"></p>
                        <div (click)="selected(index)" [class.active]="viewMode == index"
                          class="ist-unstyled chat-list px-1 mb-2 back-ground-color back-hover"
                          *ngFor="let invoice of billingInvoiceListData; let index = index">
                          <div class="chat-item pr-1">
                            <a (click)="getUserDetails(invoice)" class="d-flex align-items-center">
                              <div class="flex-grow">
                                <div>
                                  <div class="d-flex justify-content-between">
                                    <p class="text-body over-flow ml-1"><span
                                        class="large-fontsize-label"><strong>{{invoice?.splitUpJSON?.isoName ||
                                          'NA'}}</strong></span><span class="large-fontsize"></span>
                                    </p>
                                    <p class="text-body mr-1"><span
                                        class="large-fontsize-label"><strong>{{invoice?.invoiceStatus}}</strong></span>
                                    </p>
                                  </div>
                                  <hr>
                                  <div class="text-body row">
                                    <div class="col-md-4 text-left">
                                      <dt>{{'invoice.period' | translate}}</dt>
                                      <dd>{{getMonthName(invoice?.month)}} - {{invoice?.year}}</dd>
                                    </div>
                                    <div class="col-md-4 pl-4">
                                      <dt>{{'invoice.paid' | translate}}</dt>
                                      <dd *ngIf="invoice?.paidDate != null">{{invoice?.paidDate | datePipe}}</dd>
                                      <dd *ngIf="invoice?.paidDate == null">NA</dd>
                                    </div>
                                    <div class="col-md-4 mt-2 text-right">
                                      <span class="" style="font-size: 18px;">
                                        <strong>{{invoice?.totalBilledAmt | currency}}</strong>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </a>
                          </div>

                        </div>
                      </div>
                      <div class="mt-2">
                        <ngb-pagination
                          class="d-flex justify-content-end mob-media mt-1" [collectionSize]="collectionSize"
                          [(page)]="page" [maxSize]="3" [rotate]="true" [pageSize]="limit" (pageChange)="getPage(page)">
                        </ngb-pagination>
                      </div>

                    </ng-template>
                  </li>
                </ul>
                <div [ngbNavOutlet]="defaultNav" class=""></div>
              </div>
            </div>
          </div>

          <div *ngIf="billingInvoiceListData" class="col-lg-7 chat-content">
            <div class="chat-header pb-3">
              <div class="d-flex">
                <div class="align-items-center flex-grow-1" [ngClass]="viewInvoice?.invoiceStatus">
                  <i
                    class="feather icon-corner-up-left icon-md mr-2 ml-n2 text-muted d-lg-none"></i>
                  <div>
                    <h4 class="mt-1 ml-3">
                    {{viewInvoice?.invoiceStatus}}
                    </h4>
                  </div>
                </div>
                <div class="align-items-center ml-3 mt-1">
                  <div>
                    <h4>{{'invoice.invoiceNumber' | translate}} {{viewInvoice?.invoiceNumber}}</h4>
                  </div>
                </div>
              </div>
            </div>

            <div class="chat-header border-bottom pb-2">
              <!-- <div class="d-flex">
                <div class="align-items-center flex-grow-1">
                  <i class="feather icon-corner-up-left icon-md mr-2 ml-n2 text-muted d-lg-none"></i>
                  <div>
                    <h4 class="mt-1 ml-1">
                      {{'invoice.summary' | translate}}
                    </h4>
                  </div>
                </div>
              </div> -->
              <div class="d-flex">
                <div class="align-items-center flex-grow-1">
                  <i
                    class="feather icon-corner-up-left icon-md mr-2 ml-n2 text-muted d-lg-none"></i>
                  <div>
                    <h4 class="mt-1">
                      {{'invoice.summary' | translate}}
                    </h4>
                  </div>
                </div>
                <div class="align-items-center ml-3 mt-1">
                  <div>
                    <h5>{{'invoice.generated' | translate}} {{viewInvoice?.generatedDate | datePipe}}</h5>
                  </div>
                </div>
              </div>

            </div>

            <div class="d-flex justify-content-end mt-2 mb-2 back-ground-color p-2">
              <div ngbDropdown class="mt-1 flex-grow-1">
                <span style="font-size: large" class="ml-3 mt-1"><strong>Total: {{viewInvoice?.splitUpJSON?.total |
                    currency}}</strong></span>
              </div>
                <div class="ml-md-2">
                  <div class="d-inline-block" ngbDropdown #myDrop="ngbDropdown">
                    <button class="btn btn-primary" id="dropdownManual" ngbDropdownToggle>
                      <span *ngIf="isDownloadStatus" class="spinner-border spinner-border-sm mr-1" role="status"
                        aria-hidden="true"></span>
                      {{'invoice.more' | translate}}</button>
                    <div ngbDropdownMenu aria-labelledby="dropdownManual">
                      <button ngbDropdownItem (click)="downLoadISOInvoice()"> <i data-feather="file-text" appFeatherIcon
                        class="icon-sm mr-2"></i><span>{{'invoice.renderInvoice' | translate}}</span></button>
                      <button ngbDropdownItem [disabled]="isNull(viewInvoice?.pdfFileLocation)" (click)="downLoadISOInvoiceBill('pdf')"> <i data-feather="file-text" appFeatherIcon
                          class="icon-sm mr-2"></i><span>{{'invoice.renderInvoiceBill' | translate}}</span></button>
                      <button ngbDropdownItem [disabled]="viewInvoice?.invoiceStatus !='UNCOMMITTED' " (click)="commitInvoice('COMMIT')"> <i data-feather="file-text" appFeatherIcon
                        class="icon-sm mr-2"></i><span>{{'invoice.commitInvoice' | translate}}</span></button>
                      <button ngbDropdownItem [disabled]="enableApprovalButton(viewInvoice)" (click)="commitInvoice('APPROVE')"> <i data-feather="file-text" appFeatherIcon
                          class="icon-sm mr-2"></i><span>{{'invoice.approveInvoice' | translate}}</span></button>
                        <!-- <button ngbDropdownItem> <i data-feather="file-text" appFeatherIcon
                          class="icon-sm mr-2"></i><span>{{'invoice.generateInvoice' | translate}}</span></button>     -->
                    </div>
                  </div>
                </div>
              </div>

            <div class="chat-body">
              <div class="row">
                <div class="mt-2 col-md-4 col-lg-4 col-sm-6">
                  <dt><span class="mdi mdi-account pr-1" aria-hidden></span>{{'invoice.merchant' | translate}}#</dt>
                  <dd class="pl-4">{{viewInvoice?.splitUpJSON?.totalMerchant || 'NA'}}</dd>
                </div>
                <div class="mt-2 col-md-4 col-lg-4 col-sm-6">
                  <dt><span class="mdi mdi-point-of-sale pr-1" aria-hidden></span>{{'invoice.tpn' | translate}}#</dt>
                  <dd class="pl-4">{{viewInvoice?.splitUpJSON?.totalTpn || 'NA'}}</dd>
                </div>
                <div class="mt-2 col-md-4 col-lg-4 col-sm-6">
                  <dt><span class="mdi mdi-cash-multiple pr-1" aria-hidden></span>{{'invoice.fixedFee' | translate}}</dt>
                  <dd class="pl-4">{{viewInvoice?.splitUpJSON?.fixedFee | currency : 'USD' : 'symbol' : '1.1-4'}}</dd>
                </div>
                <div class="mt-2 col-md-4 col-lg-4 col-sm-6">
                  <dt><span class="mdi mdi-cash-multiple pr-1" aria-hidden></span>{{'invoice.spinFee' | translate}}</dt>
                  <dd class="pl-4">{{viewInvoice?.splitUpJSON?.spinFixedFee || 0 | currency : 'USD' : 'symbol' : '1.1-4' }}</dd>
                </div>
                <div class="mt-2 col-md-4 col-lg-4 col-sm-6">
                  <dt><span class="mdi mdi-cash-multiple pr-1" aria-hidden></span>{{'invoice.setUpFee' | translate}}</dt>
                  <dd class="pl-4">{{viewInvoice?.splitUpJSON?.setUpFixedFee || 0 | currency : 'USD' : 'symbol' : '1.1-4' }}</dd>
                </div>
                <div class="mt-2 col-md-4 col-lg-4 col-sm-6">
                  <dt><span class="mdi mdi-cash-multiple pr-1" aria-hidden></span>{{'invoice.variableFee' | translate}}</dt>
                  <dd class="pl-4">{{viewInvoice?.splitUpJSON?.variableFee | currency : 'USD' : 'symbol' : '1.1-4'}}</dd>
                </div>
                <div class="mt-2 col-md-4 col-lg-4 col-sm-6">
                  <dt><span class="mdi mdi-cash-multiple pr-1" aria-hidden></span>{{'invoice.txSMSFee' | translate}}</dt>
                  <dd class="pl-4">{{viewInvoice?.splitUpJSON?.smsFee | currency : 'USD' : 'symbol' : '1.1-4'}}</dd>
                </div>
                <!-- <div class="mt-2 col-md-4 col-lg-4 col-sm-6">
                  <dt><span class="mdi mdi-cash-multiple pr-1" aria-hidden></span>{{'invoice.promoSMSFee' | translate}}</dt>
                  <dd class="pl-4">{{viewInvoice?.splitUpJSON?.promotionalSmsFee | currency}}</dd>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
