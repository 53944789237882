import { NgModule } from '@angular/core';
import {CommonModule} from "@angular/common";

import {datePipe,dateTimePipe} from "./datePipe"; // <---

@NgModule({
  declarations:[datePipe,dateTimePipe], // <---
  imports:[CommonModule],
  exports:[datePipe,dateTimePipe] // <---
})

export class datePipeModule{
    static forRoot() {
        return {
            ngModule: datePipeModule,
            providers: [],
        };
     }
}