export const environment = {
  production: false,
  apiUrl: "/portalApi",
  steamApiUrl: "/steamApi",
  paymentApiUrl: "/paymentapi",
  druidApiUrl: "/druidApi",
  secretKey: "1995",
  isSwitchTPN: true,
  secretKeyCryptoToken: "portal-Temp=Token-phase-1",
  apiFullUrl: "https://api.onepaycloud.tech/v1",
  steamApiFullUrl: "https://steam.onepaycloud.tech/v1",
  portalImagePath: "assets/images",
  portalMerchantLogoPath: "https://onepaycloud.tech/portalApi/assets/images/merchantlogos",
  steamImagePath:"../../../data/steam/assets/images",
  druidUserName: "druid_system",
  druidPassword: "V4mU1ck$M!fnk",
  dateFormat: "MM-DD-yyyy",
  dateTimeFormat: "MM-DD-yyyy - HH:mm:ss",
  displayTimeZone: 'America/New_York',
  autoLogoutTime: 60,
  checkInterval: 1000,
  inspect: true,
  minorChannel : {
    0: "POS Terminal",
    1 : "SPIN-LOCAL",
    2 : "SPIN-CLOUD",
    3 : "PINPAD",
    4: "E-Commerce",
    5 : "RECURRING-PAYMENT",
    6 : "INVOICE-PAYMENT",
    7 : "CUSTOMER-PAYMENT",
    8: "MPOS",
    9 : "EXTERNAL-ECOM",
    10: "ACH"
  },
  spinServer:'https://test.spinpos.net/',
  enableClientError:true,
  CAPTCHA_SITE_KEY:'6LffCfwpAAAAADIJKWX26cbukteSFHR5qcUA7nNr'
};
