<p-toast position="top-right"></p-toast>
<div class="container-fluid">
  <form (ngSubmit)="onFormSubmit()" [formGroup]="processorForm" autocomplete="off">
    <div class="card mb-4">
    </div>
    <div>
      <div class="card">
        <div class="card-body">
          <p class="font-weight-bold mb-4">Basic Info</p>
          <div class="mt-4">
            <div class="col-md-12">
              <div>
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-3">
                      <div class="form-group required floating-label">
                        <input type="text" class="form-control floating-input" (ngModelChange)="nameCheck($event)" formControlName="name" placeholder=" "
                          trim="blur" maxlength="15">
                        <span class="spinner-border spinner-border-sm loader_right" role="status" aria-hidden="true"
                          *ngIf="isSpinnerNameCheck"></span>
                        <label for="tid">{{'processorList.processorName' | translate}}</label>
                        <div class="regsiter-form-fields"></div>
                        <div class="register-error-message"
                          *ngIf="!processorForm.get('name').valid && (processorForm.get('name').dirty || processorForm.get('name').touched)">
                          <ngb-alert [dismissible]="false" [type]="'danger'"
                            *ngIf="processorForm.get('name').errors.required">
                            {{'errorMsg.procesorNameRequired' | translate}}
                          </ngb-alert>
                          <ngb-alert [dismissible]="false" [type]="'danger'"
                            *ngIf="processorForm.get('name').errors.hasPattern || processorForm.get('name').errors.hasLengthName">
                            {{'errorMsg.processorInValid' | translate}}
                          </ngb-alert>
                        </div>
                        <div class="register-error-message">
                          <ngb-alert [dismissible]="false" [type]="'danger'"
                            *ngIf="processorForm.get('name').valid && errMsgs">
                            {{'errorMsg.processorNameExists' | translate}}
                          </ngb-alert>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-group floating-label">
                        <input type="text" class="form-control floating-input" formControlName="ownerId" placeholder=" "
                          trim="blur" maxlength="8">
                        <label for="ownerId">{{'processorList.ownerId' | translate}}</label>
                        <div class="regsiter-form-fields"></div>
                        <div class="register-error-message"
                          *ngIf="!processorForm.get('ownerId').valid && (processorForm.get('ownerId').dirty || processorForm.get('ownerId').touched)">
                          <ngb-alert [dismissible]="false" [type]="'danger'"
                            *ngIf="processorForm.get('ownerId').errors.hasCorrectOwnerId || processorForm.get('ownerId').errors.hasOwnerLength">
                            {{'errorMsg.processorOwnerIdInvalid' | translate}}
                          </ngb-alert>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card mt-4">
        <div class="card-body">
          <p class="font-weight-bold mb-4">Routing Info</p>
          <div class="row">
            <div class="col-md-3">
              <div class="form-group required floating-label">
                <input type="number" class="form-control floating-input" formControlName="frequency" placeholder=" "
                  trim="blur" maxlength="8" (keypress)='onkeypress($event.target)'>
                <span class="spinner-border spinner-border-sm loader_right" role="status" aria-hidden="true"
                  *ngIf="isSpinnerOwner"></span>
                <label for="frequency">{{'processorList.frequency' | translate}}</label>
                <div class="regsiter-form-fields"></div>
                <div class="register-error-message"
                  *ngIf="!processorForm.get('frequency').valid && (processorForm.get('frequency').dirty || processorForm.get('frequency').touched)">
                  <ngb-alert [dismissible]="false" [type]="'danger'"
                    *ngIf="processorForm.get('frequency').errors.required">
                    {{'errorMsg.processorFrequencyRequired' | translate}}
                  </ngb-alert>
                  <ngb-alert [dismissible]="false" [type]="'danger'"
                    *ngIf="processorForm.get('frequency').errors.hasNonZero">
                    {{'errorMsg.processorInvalidFrequency' | translate}}
                  </ngb-alert>
                </div>

              </div>
            </div>

            <div class="col-md-3">
              <div class="form-group required floating-label">
                <input type="number" class="form-control floating-input" formControlName="max"
                  (keypress)='onkeypressNear($event.target)' placeholder=" " trim="blur" maxlength="8">
                <label for="max">{{'processorList.max' | translate}}</label>
                <div></div>
                <div class="regsiter-form-fields"></div>
                <div class="register-error-message"
                  *ngIf="!processorForm.get('max').valid && (processorForm.get('max').dirty || processorForm.get('max').touched)">
                  <ngb-alert [dismissible]="false" [type]="'danger'"
                    *ngIf="processorForm.get('max').errors.required">
                    {{'errorMsg.processorMaxRequired' | translate}}
                  </ngb-alert>
                  <!-- <ngb-alert [dismissible]="false" [type]="'danger'"
                    *ngIf="processorForm.get('max').errors.hasNumber || processorForm.get('max').errors.haslengthNearDemonRegex">
                    {{'errorMsg.NearDemonNumRequired' | translate}}
                  </ngb-alert> -->
                </div>
              </div>
            </div>

            <div class="col-md-3" style="margin-top: -26px;/* margin-bottom: -14px; */">
              <div class="form-group required">
                <label for="labelStatus" style="margin-top: -8px; margin-left: 10px;">{{'processorList.status' | translate}}</label>
                <div style="margin-top: 18px;" >
                  <p-selectButton [options]="stateOptions" formControlName="status" >
                  </p-selectButton>
                </div>
                
                <div></div>
                <div class="regsiter-form-fields"></div>
                <div class="register-error-message" style="top: 26px;"
                  *ngIf="!processorForm.get('status').valid && (processorForm.get('status').dirty || processorForm.get('status').touched)">
                  <ngb-alert [dismissible]="false" [type]="'danger'"
                    *ngIf="processorForm.get('status').errors.required">
                    {{'errorMsg.processorStatusRequired' | translate}}
                  </ngb-alert>
                  <!-- <ngb-alert [dismissible]="false" [type]="'danger'"
                    *ngIf="processorForm.get('cashBox1Denomination').errors.hasNumber || processorForm.get('cashBox1Denomination').errors.haslengthCashBox1DemonRegex">
                    {{'errorMsg.CashBox1DemonNumRequired' | translate}}
                  </ngb-alert> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div class="card mt-4">
        <div class="card-body">
          <p class="font-weight-bold mb-4">Blocking Info</p>
          <div class="row">
            <div class="col-md-3">
              <div class="form-group floating-label">
                <div class="form-group floating-label">
                  <label for="blockingCode" style="margin-top: -28px">{{'processorList.blockingCode' | translate}}</label>
                  <p-chips [(ngModel)]="blockCode" formControlName="blockingCode">
                  </p-chips>
                <div></div>
                <div class="regsiter-form-fields"></div>
                <div class="register-error-message"
                  *ngIf="!processorForm.get('blockingCode').valid && (processorForm.get('blockingCode').dirty || processorForm.get('blockingCode').touched)">
                  <!-- <ngb-alert [dismissible]="false" [type]="'danger'"
                    *ngIf="processorForm.get('blockingCode').errors.required">
                    {{'errorMsg.processorBlockCodeRequired' | translate}}
                  </ngb-alert> -->
                  <!-- <ngb-alert [dismissible]="false" [type]="'danger'"
                    *ngIf="processorForm.get('cashBox1Denomination').errors.hasNumber || processorForm.get('cashBox1Denomination').errors.haslengthCashBox1DemonRegex">
                    {{'errorMsg.CashBox1DemonNumRequired' | translate}}
                  </ngb-alert> -->
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>



    </div>

    <p class="mt-2">Fields marked with an asterisk ( <span class="text_color"><strong>*</strong></span> ) are
      required.
    </p>
    <div class="d-flex justify-content-end mt-2">
      <button type="button" class="btn btn-primary mr-2" (click)="resetProcessorForm()" [disabled]="isEditHostInventory"><i
          class="feather icon-refresh-ccw"></i> Reset</button>
      <button class="btn btn-primary" type="submit" [disabled]="!processorForm.valid || isSaving || isSpinnerNameCheck || errMsgs"
        *ngIf="!isEditHostInventory">
        <span *ngIf="isSaving" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> <i
          class="feather icon-plus-square"></i> Submit</button>
      <button class="btn btn-primary" type="submit" [disabled]="!processorForm.valid || isSaving || isSpinnerNameCheck || errMsgs"
        *ngIf="isEditHostInventory">
        <span *ngIf="isSaving" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> <i
          class="feather icon-plus-square"></i> Save</button>
    </div>

</form>
</div>

<!-- <div class="modal-header d-flex justify-content-between">
  <div class="row thumbs_icon">
      <div class="ml-2">
          <h5 class="modal-title" id="exampleModalLongTitle"><span class="font-weight-bold text_baseline">Manage
                  Contract <small>any changes w.e.f {{monthToBeDisplayed}},{{year}}</small></span>
          </h5>
      </div>
  </div>
</div> -->

<!-- <form (ngSubmit)="onFormSubmit()" [formGroup]="processorForm" autocomplete="off">
  <div class="card mb-4">
  </div>
  <div>
    <div class="card">
      <div class="card-body">
        <div class="mt-4">
          <div class="col-md-12">
            <div>
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-3">
                    <div class="form-group required floating-label">
                      <input type="text" class="form-control floating-input" formControlName="tid" placeholder=" "
                        trim="blur" maxlength="15">
                        <span class="spinner-border spinner-border-sm loader_right" role="status" aria-hidden="true"
                        *ngIf="isSpinnerTid"></span>
                      <label for="tid">{{'htmlText.tid' | translate}}</label>
                      <div class="regsiter-form-fields"></div>
                      <div class="register-error-message"
                                          *ngIf="!processorForm.get('tid').valid && (processorForm.get('tid').dirty || processorForm.get('tid').touched)">
                                          <ngb-alert [dismissible]="false" [type]="'danger'"
                                            *ngIf="processorForm.get('tid').errors.required">
                                            {{'errorMsg.TIDRequired' | translate}}
                                          </ngb-alert>
                                          <ngb-alert [dismissible]="false" [type]="'danger'"
                                            *ngIf="processorForm.get('tid').errors.hasAlphaNum || processorForm.get('tid').errors.hasMidlength">
                                            {{'errorMsg.TIDAlphaNumrRequired' | translate}}
                                          </ngb-alert>
                                        </div>
                                      
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="form-group required floating-label">
                      <input type="text" class="form-control floating-input" formControlName="ownerId" placeholder=" "
                        trim="blur" maxlength="8" >
                        <span class="spinner-border spinner-border-sm loader_right" role="status" aria-hidden="true"
                        *ngIf="isSpinnerOwner"></span>
                      <label for="ownerId">{{'htmlText.ownerId' | translate}}</label>
                      <div class="regsiter-form-fields"></div>
                      <div class="register-error-message"
                                          *ngIf="!processorForm.get('ownerId').valid && (processorForm.get('ownerId').dirty || processorForm.get('ownerId').touched)">
                                          <ngb-alert [dismissible]="false" [type]="'danger'"
                                            *ngIf="processorForm.get('ownerId').errors.required">
                                            {{'errorMsg.OwnerRequired' | translate}}
                                          </ngb-alert>
                                          <ngb-alert [dismissible]="false" [type]="'danger'"
                                            *ngIf="processorForm.get('ownerId').errors.hasAlphaNum || processorForm.get('ownerId').errors.hasOwnerRegex">
                                            {{'errorMsg.OwnerAlphaNumRequired' | translate}}
                                          </ngb-alert>
                                        </div>
                                       
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="form-group required floating-label">
                      <input type="number" class="form-control floating-input" formControlName="nearestDenomination" (keypress)='onkeypressNear($event.target)'
                        placeholder=" " trim="blur" maxlength="8">
                      <label for="TermId">{{'htmlText.nearDon' | translate}}</label>
                      <div></div>
                      <div class="regsiter-form-fields"></div>
                      <div class="register-error-message"
                                          *ngIf="!processorForm.get('nearestDenomination').valid && (processorForm.get('nearestDenomination').dirty || processorForm.get('nearestDenomination').touched)">
                                          <ngb-alert [dismissible]="false" [type]="'danger'"
                                            *ngIf="processorForm.get('nearestDenomination').errors.required">
                                            {{'errorMsg.NearDemonRequired' | translate}}
                                          </ngb-alert>
                                          <ngb-alert [dismissible]="false" [type]="'danger'"
                                            *ngIf="processorForm.get('nearestDenomination').errors.hasNumber || processorForm.get('nearestDenomination').errors.haslengthNearDemonRegex">
                                            {{'errorMsg.NearDemonNumRequired' | translate}}
                                          </ngb-alert>
                                        </div>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="form-group required floating-label">
                      <input type="number" class="form-control floating-input" formControlName="cashBox1Denomination" (keypress)='onkeypresscashBox1($event.target)'
                        placeholder=" " trim="blur" maxlength="12">
                      <label for="cashBox1Denomination">{{'htmlText.cashBox1Don' | translate}}</label>
                      <div></div>
                      <div class="regsiter-form-fields"></div>
                      <div class="register-error-message" style="top: 26px;"
                                          *ngIf="!processorForm.get('cashBox1Denomination').valid && (processorForm.get('cashBox1Denomination').dirty || processorForm.get('cashBox1Denomination').touched)">
                                          <ngb-alert [dismissible]="false" [type]="'danger'"
                                            *ngIf="processorForm.get('cashBox1Denomination').errors.required">
                                            {{'errorMsg.CashBox1DemonRequired' | translate}}
                                          </ngb-alert>
                                          <ngb-alert [dismissible]="false" [type]="'danger'"
                                          *ngIf="processorForm.get('cashBox1Denomination').errors.hasNumber || processorForm.get('cashBox1Denomination').errors.haslengthCashBox1DemonRegex">
                                          {{'errorMsg.CashBox1DemonNumRequired' | translate}}
                                        </ngb-alert>
                                        </div>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="form-group required floating-label">
                      <input type="number" class="form-control floating-input" formControlName="cashBox1Total" (keypress)='onkeypresscashBox1Total($event.target)'
                        placeholder=" " trim="blur" maxlength="12">
                      <label for="cashBox1Total">{{'htmlText.cashBox1' | translate}}</label>
                      <div class="regsiter-form-fields"></div>
                      <div class="register-error-message"
                                          *ngIf="!processorForm.get('cashBox1Total').valid && (processorForm.get('cashBox1Total').dirty || processorForm.get('cashBox1Total').touched)">
                                          <ngb-alert [dismissible]="false" [type]="'danger'"
                                            *ngIf="processorForm.get('cashBox1Total').errors.required">
                                            {{'errorMsg.CashBox1TotalRequired' | translate}}
                                          </ngb-alert>
                                          <ngb-alert [dismissible]="false" [type]="'danger'"
                                          *ngIf="processorForm.get('cashBox1Total').errors.hasNumber || processorForm.get('cashBox1Total').errors.haslengthCashBox1Regex">
                                          {{'errorMsg.CashBox1TotalNumRequired' | translate}}
                                        </ngb-alert>
                                        </div>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="form-group required floating-label">
                      <input type="number" class="form-control floating-input" formControlName="cashBox2Denomination" (keypress)='onkeypresscashBox2De($event.target)'
                        placeholder=" " trim="blur" maxlength="12">
                      <label for="cashBox2Denomination">{{'htmlText.cashBox2Don' | translate}}</label>
                      <div></div>
                      <div class="regsiter-form-fields"></div>
                      <div class="register-error-message" style="top: 26px;"
                                            *ngIf="!processorForm.get('cashBox2Denomination').valid && (processorForm.get('cashBox2Denomination').dirty || processorForm.get('cashBox2Denomination').touched)">
                                            <ngb-alert [dismissible]="false" [type]="'danger'"
                                              *ngIf="processorForm.get('cashBox2Denomination').errors.required">
                                              {{'errorMsg.CashBox2DenomRequired' | translate}}
                                            </ngb-alert>
                                            <ngb-alert [dismissible]="false" [type]="'danger'"
                                            *ngIf="processorForm.get('cashBox2Denomination').errors.hasNumber || processorForm.get('cashBox2Denomination').errors.haslengthCashBox2DemonRegex">
                                            {{'errorMsg.CashBox2DenomNumRequired' | translate}}
                                          </ngb-alert>
                                          </div>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="form-group required floating-label">
                      <input type="number" class="form-control floating-input" formControlName="cashBox2Total" (keypress)='onkeypresscashBox2To($event.target)'
                        placeholder=" " trim="blur" maxlength="12">
                      <label for="processorForm">{{'htmlText.cashBox2' | translate}}</label>
                      <div></div>
                      <div class="regsiter-form-fields"></div>
                      <div class="register-error-message" style="top: 26px;"
                                          *ngIf="!processorForm.get('cashBox2Total').valid && (processorForm.get('cashBox2Total').dirty || processorForm.get('cashBox2Total').touched)">
                                          <ngb-alert [dismissible]="false" [type]="'danger'"
                                            *ngIf="processorForm.get('cashBox2Total').errors.required">
                                            {{'errorMsg.CashBox2TotalRequired' | translate}}
                                          </ngb-alert>
                                          <ngb-alert [dismissible]="false" [type]="'danger'"
                                          *ngIf="processorForm.get('cashBox2Total').errors.hasNumber || processorForm.get('cashBox2Total').errors.haslengthCashBox2Regex">
                                          {{'errorMsg.CashBox2TotalNumRequired' | translate}}
                                        </ngb-alert>
                                        </div>
                    </div>
                  </div>
                  

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <p class="mt-2">Fields marked with an asterisk ( <span class="text_color"><strong>*</strong></span> ) are required.
    </p>
    <div class="d-flex justify-content-end mt-2">
      <button type="button" class="btn btn-primary mr-2" (click)="resetHostInv()" [disabled]="isEditHostInventory"><i class="feather icon-refresh-ccw"></i> Reset</button>
      <button class="btn btn-primary" type="submit" [disabled]="!processorForm.valid || isSaving" *ngIf="!isEditHostInventory">
      <span *ngIf="isSaving"
                  class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> <i class="feather icon-plus-square"></i> Submit</button>
      <button class="btn btn-primary" type="submit" [disabled]="!processorForm.valid || isSaving" *ngIf="isEditHostInventory">
        <span *ngIf="isSaving"
                    class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> <i class="feather icon-plus-square"></i> Save</button>
    </div>
  </div>

</form> -->

<!-- <div class="card-footer pb-2">
  <button (click)="submitContract();" class="mt-2 ml-2 text-right btn btn-primary float-right"
      [disabled]="!myForm.valid || isCreateContractLoading">
      <span *ngIf="isCreateContractLoading" class="spinner-border spinner-border-sm mr-1" role="status"
          aria-hidden="true"></span>Confirm</button>
  <button (click)="activeModal.close();" class="mt-2 text-right btn btn-danger mr-2 float-right mb-2">Cancel</button>
</div> -->