import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { apiLinks } from 'src/app/core/api-links/api-links';
import { CommonService } from 'src/app/providers/common.service';
import * as _ from 'lodash';
import { isEmpty } from 'lodash';
import { HostInventoryService } from 'src/app/providers/host-inventory.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-bulk-status-update',
  templateUrl: './bulk-status-update.component.html',
  styleUrls: ['./bulk-status-update.component.scss'],
  providers: [MessageService]
})
export class BulkStatusUpdateComponent implements OnInit {

  status:any[] = [
    {'name' : 'ACTIVE'},
    {'name' : 'DEACTIVATED'}
  ]

  @Input() totalTids:number = 0;
  bulkUpdateStatusForms: any = FormGroup;
  @Input() listHostInventory:any[];
  @Input() filterQuery:any;
  @Input() statusList:object[];
  tpnsList:any[] = [];
  tidList:any[] = [];
  tpnsId: any;
  isSaveLoading: boolean;
  isSubmit:any;
  isSaving:boolean = false;
  filterData: any = {};
  searchTerm = '';
  @Input() StatusUpdatefilterData:any = {};
  @Input() selectedTidList: { exceptTids: [], selectedTids: [], isSelectAll: false};
  @Input() isBulkUpdate = false;

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    private commonService: CommonService,
    private hostInventoryService: HostInventoryService,
    public modal: NgbActiveModal,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.bulkUpdateStatusForms = this.updateHostInventoryForm();

    if(!isEmpty(this.filterQuery?.status)){
      this.statusList = this.status.filter((items) => {
        return items.name != this.filterQuery.status;
      })
    }

    if(!this.isBulkUpdate){
        this.listHostInventory.map(data => {
          this.tpnsList = data.tpnsList ? data.tpnsList.split() : [];
          this.tidList = data.tid ? data.tid.split() : [];
        });
    }
  }
  updateHostInventoryForm(): any {
    return this.formBuilder.group({
      updateHostInventoryInfo: this.updateInfoForm()
    });
  }
  updateInfoForm(): FormGroup {
    return this.formBuilder.group({
      status: ["",[Validators.required]]
    })
  }

  forStatusUpdate(){
    this.isSubmit = true;
    this.isSaving = true;
    let formData = {};
      formData = {
        status: this.bulkUpdateStatusForms.value['updateHostInventoryInfo'].status,
        ...(this.isBulkUpdate && {
          processorIds: this.filterQuery.processorId,
          isBulkUpdate: this.isBulkUpdate,
          ...this.selectedTidList
        }),
        ...(!this.isBulkUpdate && {
          tpnsLists: this.tpnsList,
          tidLists: this.tidList,
        })
      }

    this.commonService.postCommonHttpClientWithParams(apiLinks.getTpnsIds, formData, this.StatusUpdatefilterData).subscribe(res => {
      this.isSaveLoading = false;
      this.showAlert(
        { status: "success", message: res.message },
        "success"
      );
      setTimeout(()=>{
        this.isSubmit = false;
        this.modal.close();
        this.refreshData();
      },500);
      
      this.isSaving = false;
      console.log(res);
    },(err)=>{
      this.showAlert(
        { status: "error", message: err.message },
        "error"
      );
    })
  }

    showAlert(data, alertType) {
      this.messageService.add({
        severity: alertType,
        summary: data.status,
        detail: data.message,
      });
    }

    refreshData(){
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>{
          this.router.navigate(["/list-host-inventory"]);
        });

    }
}
