import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { AuthService } from "./auth.service";
import {ErrorHandlerService} from './error-handler.service';
import { Asset } from '../core/model/asset';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class AssetService {
  getAllAssetsUrl = `${environment.steamApiUrl}/getAllAsset`;
  postAssetsUrl = `${environment.steamApiUrl}/createAsset`;
  getDeviceModalsUrl = `${environment.apiUrl}/device-models-by-manufacturer/`;
  getManufacturerUrl = `${environment.apiUrl}/manufacturers`;
  getCustomerUrl = `${environment.apiUrl}/customers-all`;
  getSerialNumber = `${environment.steamApiUrl}/getSerialNumber/`;

  constructor(
    private handleError:ErrorHandlerService,
    private httpClient: HttpClient
  ) { }

  getAllData(httpReqUrl): Observable<Asset[]> {
    return this.httpClient.get<Asset>(httpReqUrl).pipe(
      map((res: any) => {
        return res.data;
      }),
      catchError(this.handleError.handleError)
    );
  }

  getSerialNum(httpReqUrl, parameter) {
    httpReqUrl = httpReqUrl+parameter.serialNumber
    return this.httpClient.get(httpReqUrl).pipe(
      map((res: any) => {
        return res.data;
      }),
      catchError(this.handleError.handleError)
    );
  }


  createAsset(assetData) {
    return this.httpClient.post(this.postAssetsUrl, assetData).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.handleError.handleError)
    );
  }

  getAllAsset(params) {
    let httpParams = new HttpParams();
    // httpParams = httpParams.append('sort', '{"field": "updatedAt", "order": "DESC"}');
    // httpParams = httpParams.append('size', '20');
    Object.keys(params).forEach(function (key) {
      httpParams = httpParams.append(key, params[key]);
    });
    return this.httpClient.get(`${environment.steamApiUrl}/getAllAsset`, {params: httpParams}).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.handleError.handleError)
    );
  }

}
