import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { DividerModule } from "primeng/divider";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { SharedRoutingModule } from './shared-routing.module';
import { LoadingComponent } from './components/loading/loading.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { SearchBoxFilterComponent } from './components/search-box-filter/search-box-filter.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import {SafeHtmlPipe} from './pipe/safe-html.pipe';
import { NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { OpenBatchModalComponent } from './components/open-batch-modal/open-batch-modal.component';
import { DeleteCommonModalComponent } from './components/delete-common-modal/delete-common-modal.component';
import { EditTpnOpenBatchModalComponent } from './components/edit-tpn-open-batch-modal/edit-tpn-open-batch-modal.component';
import { InformationModalComponent } from './components/information-modal/information-modal.component';
import { JumpToButtonsComponentComponent } from './components/jump-to-buttons-component/jump-to-buttons-component.component';
import { LoginAsModalComponent } from './components/login-as-modal/login-as-modal.component';
import { DynamicFormComponentComponent } from './components/dynamic-form-component/dynamic-form-component.component';
import {InputNumberModule} from 'primeng/inputnumber';
import { ToastModule } from 'primeng/toast';
import { DownloaderLoaderComponent } from './components/downloader-loader/downloader-loader.component';
import { BackButtonComponent } from './components/back-button/back-button.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { NgxDropzoneModule } from 'ngx-dropzone';
//import { IsoLogoUploadComponent } from './components/iso-logo-upload/iso-logo-upload.component';
//import { RefreshButtonComponent } from './components/refresh-button/refresh-button.component';
import { SelectButtonModule } from "primeng/selectbutton";
import { CheckboxModule } from "primeng/checkbox";
import { FileUploadModule } from "primeng/fileupload";
import { FieldsetModule } from "primeng/fieldset";
import { RadioButtonModule } from "primeng/radiobutton";
import { UiSwitchModule } from "ngx-ui-switch";

//import { CapitalizePipe } from 'src/app/pipe/capitalize.pipe';
//import { ValueOrNaPipe } from 'src/app/pipe/value-or-na.pipe';
//import { MessageModule } from 'primeng/message';

//import { CapitalizePipe } from 'src/app/pipe/capitalize.pipe';
//import { HelperTooltipComponent } from './components/helper-tooltip/helper-tooltip.component';
import { ProcessorDataFormComponentComponent } from './components/Processordata-form-component/Processordata-form-component.component';
import { AlternateProcessorComponent } from './components/alternate-processor/alternate-processor.component';
import { ReactiveFormDynamicErrorMessageComponent } from './components/reactive-form-dynamic-error-message/reactive-form-dynamic-error-message.component';
import { RegalaProcessorComponent } from './components/regala-processor/regala-processor.component';
import { ZipCodeComponent } from './components/zip-code/zip-code.component';
import { IlustrationWithMessageComponent } from './components/ilustration-with-message/ilustration-with-message.component';
import { HelperTooltipComponent } from './components/helper-tooltip/helper-tooltip.component';

@NgModule({

  declarations: [LoadingComponent, DashboardComponent, SearchBoxFilterComponent,
    ConfirmationDialogComponent, SafeHtmlPipe, OpenBatchModalComponent,
    DeleteCommonModalComponent, EditTpnOpenBatchModalComponent, InformationModalComponent,
    JumpToButtonsComponentComponent, LoginAsModalComponent, DynamicFormComponentComponent,
    DownloaderLoaderComponent, BackButtonComponent,ProcessorDataFormComponentComponent, AlternateProcessorComponent, ReactiveFormDynamicErrorMessageComponent, RegalaProcessorComponent, ZipCodeComponent,IlustrationWithMessageComponent, HelperTooltipComponent],

  imports: [
    CommonModule,
    SharedRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgbTypeaheadModule,
    DividerModule,
    InputNumberModule,
    NgbModule,
    ToastModule,
    SelectButtonModule,
    CheckboxModule,
    FileUploadModule,
    FieldsetModule,
    RadioButtonModule,
    UiSwitchModule,
    TranslateModule,
    NgSelectModule,
    NgxMaskModule,
    NgxDropzoneModule
  ],
  exports: [LoadingComponent, DashboardComponent, SearchBoxFilterComponent,
    SafeHtmlPipe, JumpToButtonsComponentComponent,BackButtonComponent,
    BackButtonComponent,ProcessorDataFormComponentComponent, AlternateProcessorComponent, ReactiveFormDynamicErrorMessageComponent, RegalaProcessorComponent, IlustrationWithMessageComponent, HelperTooltipComponent]
})
export class SharedModule { }
