<p-toast position="top-right"></p-toast>
<p-dialog header="Success!" [(visible)]="displayBasic" [style]="{width: '50vw'}" [baseZIndex]="10000">
  <p>Password Update Successful! You can login to your Portal with the new Password </p>
  <ng-template pTemplate="footer">
    <p-button icon="pi pi-check" (click)="goToLogin();" label="Ok, Go to Login" styleClass="p-button-text"></p-button>
  </ng-template>
</p-dialog>
<div class="row w-100 mx-0 auth-page">
  <div class="col-md-8 col-xl-4 mx-auto">
    <div class="card cus-card">
      <div class="row">
        <div class="col-md-12 pl-md-2 pr-md-2">
          <div class="auth-form-wrapper px-4 py-4 ">
            <!-- <a routerLink="." class="noble-ui-logo d-block mb-2 text-center"><img
                              src="assets/images/iPOSpays.svg" alt="IPOSPAYS LOGO" width="150" /></a> -->
            <h5 class="font-weight-normal mb-4 text-center">Password Reset</h5>
            <form class="forms-sample" [formGroup]="resetForm" (submit)="onFormSubmit()">
              <div class="form-group">
                <label for="exampleInputEmail1">Password</label>
                <input type="text"
                  ngbPopover="Password must have  at least 8 characters that include at least 1 lowercase character, 1 uppercase character, 1 number, and 1 special character in (!@#$%^&*)"
                  placement="right" class="form-control" formControlName="password" placeholder="Password" required>
                <div
                  *ngIf="!resetForm.get('password').valid && (resetForm.get('password').dirty || resetForm.get('password').touched)">
                  <ngb-alert [dismissible]="false" [type]="'danger'" *ngIf="resetForm.get('password').errors.required">
                    {{'errorMsg.missingPassword' | translate}}
                  </ngb-alert>
                  <ngb-alert [dismissible]="false" [type]="'danger'"
                    *ngIf="resetForm.get('password').errors.hasPassword">
                    invalid password
                  </ngb-alert>
                </div>
              </div>
              <div class="form-group">
                <label for="exampleInputEmail1">Confirm Password</label>
                <input type="text" class="form-control" formControlName="cpassword" placeholder="Confirm Password"
                  required>
                <div
                  *ngIf="!resetForm.get('cpassword').valid && (resetForm.get('cpassword').dirty || resetForm.get('cpassword').touched)">
                  <ngb-alert [dismissible]="false" [type]="'danger'" *ngIf="resetForm.get('cpassword').errors.required">
                    {{'errorMsg.missingCPassword' | translate}}
                  </ngb-alert>
                  <ngb-alert [dismissible]="false" [type]="'danger'"
                    *ngIf="resetForm.get('cpassword').errors.NoPassswordMatch">
                    Password does not match
                  </ngb-alert>
                </div>
              </div>
              <div class="mt-3">
                <button type="submit" class="btn btn-primary mr-2 mb-2 mb-md-0 btn-block" [disabled]="isLoading">
                  <span *ngIf="isLoading" class="spinner-border spinner-border-sm" role="status"
                    aria-hidden="true"></span><span class="loader_ml">Update Password</span></button>
              </div>
            </form>
            <a routerLink="." class="noble-ui-logo d-block mb-2 text-center mt-3"><img src="assets/images/opcmmsx.png"
                alt="IPOSPAYS LOGO" width="40%" /></a>
            <p class="text-center font-weight-normal" style="color: #000; font-size: 12px;">powers your experience</p>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
