import { Component, OnInit,Input } from '@angular/core';
import { CommonService } from 'src/app/providers/common.service';
import { apiLinks } from 'src/app/core/api-links/api-links';
import { NgbModal,NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { MerchantService } from "../../../../providers/merchant.service";
import { HostInventoryService } from "../../../../providers/host-inventory.service";
import { MessageService } from "primeng/api";
import { get, isEmpty, some } from 'lodash';
import { InformationModalComponent } from 'src/app/shared/components/information-modal/information-modal.component';
import { AuthService } from 'src/app/providers/auth.service';

@Component({
  selector: 'app-builk-edit-tid',
  templateUrl: './builk-edit-tid.component.html',
  providers: [MessageService],
  styleUrls: ['./builk-edit-tid.component.scss']
})
export class BuilkEditTidComponent implements OnInit {

  @Input() hostId;
  isMasterSel: boolean;
  checkedCategoryList: any = [];
  tpnList:any=[];
  masterSelectBox = false;
  @Input() hostInventory;
  selectedProcessId:any;
  selectedProcessName:any;
  processorLoading:boolean=true;
  searchProcessorList:any=[];
  tidList=[];
  searchTidProcessorList=[];
  hostInventoryTid_Id:any;
  tid_Id:any;
  isSaveLoading:boolean=false;
  isLoading=false;
  userType = '';

  constructor(
    private commonService:CommonService,
    public modal: NgbActiveModal,
    private merchantService: MerchantService,
    private messageService:MessageService,
    private hostInventoryService: HostInventoryService,
    private ngbModal: NgbModal,
    private authService: AuthService) { }

  ngOnInit(): void {
    this.getTpnsByTid();
    this.loadProcessor();
  }

  getTpnsByTid(){
    this.userType = this.authService.getUser.userType;
    this.isLoading=true;
    this.commonService.getCommonHttpClientParams(`${apiLinks.getTpnsByTid}/${this.hostId}`)
    .subscribe((res)=>{
      this.tpnList=res.data;
     // console.log("getTpnsByTid", this.tpnList);

      this.isLoading=false;
      console.log(res);
    },(err)=>{
      this.isLoading=false;
      this.showAlert(
        { status: "error", message: err.message },
        "error"
      );
    });

  }

  loadProcessor() {
    this.processorLoading = true;
    this.merchantService.getAllData(this.merchantService.getProcessorUrl).subscribe((data: any) => {
      this.searchProcessorList = data.filter(processor => processor.name != 'BPAY');
      // if (this.searchProcessorList.length == 1) {
      //   this.selectedProcessId = this.searchProcessorList[0].id;
      // }
      this.processorLoading = false;
    });
  }


  changedProcessor(event) {
    //console.log(event);
    this.hostInventoryTid_Id=null;
    let params = {processorId:event.id, isShowCurrentCustomerTids: true, tidOwner: this.hostInventory.ownedBy};
    this.selectedProcessId = event.id;
    this.selectedProcessName = event.name;
    this.hostInventoryService.getTids(this.hostInventoryService.getTidsById, params).subscribe(data => {
      this.tidList = (this.selectedProcessId == this.hostInventory.processorId) ? data.filter(hostList => hostList.tid != this.hostInventory.tid) : data;
      this.searchTidProcessorList = this.tidList;
      console.log("this.tidList", this.tidList);
    })
  }

  async saveTid(){

    this.isSaveLoading = true;

    let payload = {
      currentTid:this.hostInventory?.id,
      tpnlist:this.checkedCategoryList,
      tid:this.hostInventoryTid_Id,
      processId:this.selectedProcessId,
      tid_Id:this.tid_Id,
      processName:this.selectedProcessName,
      currentProcessorId:this.hostInventory?.processorId,
      isUpdateSafeProcessor: false
    }
    let checkSafeProcessorPayload = {
      tpnlist:this.checkedCategoryList,
      currentProcessorId:this.hostInventory?.processorId
    }
    
    let isUpdateProcessor = false;
    if(this.selectedProcessId == this.hostInventory?.processorId){
      isUpdateProcessor = true;
    }
      try {
        const res = await this.commonService.postCommonHttpClient(apiLinks.checkSafeProcessorCounts,checkSafeProcessorPayload).toPromise();
        let safeProcessorTpnList = get(res.data,'rows');
        let safeProcessorTpnCount = get(res.data,'count');
        let totalTpnCount = !isEmpty(this.checkedCategoryList) ? this.checkedCategoryList.length : 0;
        if(isEmpty(safeProcessorTpnList) || isUpdateProcessor){
          payload = {...payload, ...(isUpdateProcessor && {isUpdateProcessor: false})};
          this.replaceTid(payload);
        } else {
          let bodyContent = `There Are ${totalTpnCount} TPN's with this TID. Among which ${safeProcessorTpnCount} TPN's were ${this.hostInventory?.processorName} as SafeProcessor`;
          let modalRef = this.ngbModal.open(InformationModalComponent, { ariaLabelledBy: 'modal-basic-title', centered: true, backdrop: 'static', keyboard: false });
          modalRef.componentInstance.header = `Change TID Confirmation`;
          modalRef.componentInstance.body = bodyContent;
          modalRef.componentInstance.isOpenChangeTidModal = true;
          modalRef.componentInstance.isShowPartialUpdateButton = totalTpnCount != safeProcessorTpnCount;
          modalRef.componentInstance.partialUpdateButtonContent = `Update W/O SafeProcessor(${totalTpnCount - safeProcessorTpnCount})`;
          modalRef.componentInstance.overAllUpdateButtonContent = `UpdateAll(${totalTpnCount})`;
          modalRef.result.then((result) => {
            if (result == 'updateOverAll') {
              payload.isUpdateSafeProcessor = true;
              this.replaceTid(payload);
            } else if (result == 'updatePartially') {
              let partialUpdateTpnList = this.checkedCategoryList.filter((tpnId) => {
                return !safeProcessorTpnList.some((tpn) => tpn.id === tpnId);
              });
              payload.tpnlist = partialUpdateTpnList;
              this.replaceTid(payload);
            } else if (result == 'close') {
              this.isSaveLoading = false;
            }
          })
        }
      } catch (err) {
        this.showAlert(
          { status: "error", message: err.message },
          "error"
        );
      }
  }

  replaceTid(payload) {
    this.commonService.postCommonHttpClient(apiLinks.replaceTID, payload).subscribe(res => {
      this.isSaveLoading = false;
      this.showAlert(
        { status: "success", message: res.message },
        "success"
      );
      setTimeout(() => {
        this.modal.close();
      }, 500);

    }, (err) => {
      this.isSaveLoading = false;
      this.showAlert(
        { status: "error", message: err.message },
        "error"
      );
    })
  }

  tidSelect(event){
    this.tid_Id = event.id;
   // console.log("tidSelect",this.tid_Id);
  }

  getModal(data){
    return data.identifier
  }

  checkUncheckAll(isCheckedEvent) {
    isCheckedEvent.target.checked ? this.isMasterSel = true : this.isMasterSel = false;
    for (var i = 0; i < this.tpnList.length; i++) {
      this.tpnList[i].isSelected = this.isMasterSel;
    }
    this.getCheckedItemList();
  }

  isAllSelected() {
    this.isMasterSel = this.tpnList.every(function(item:any) {
        return item.isSelected == true;
      })
    this.getCheckedItemList();
  }

  getCheckedItemList(){
    this.checkedCategoryList = [];
    for (var i = 0; i < this.tpnList.length; i++) {
      if(this.tpnList[i].isSelected)
      this.checkedCategoryList.push(this.tpnList[i].id);
    }
   // console.log("getCheckedItemList", this.checkedCategoryList);
    if (this.checkedCategoryList.length == this.tpnList.length){
       this.masterSelectBox = true
      } else {
         this.masterSelectBox = false
      };
  }

  showAlert(data, alertType) {
    this.messageService.add({
      severity: alertType,
      summary: data.status,
      detail: data.message,
    });
  }


}
