import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { apiLinks } from 'src/app/core/api-links/api-links';
import { CommonService } from 'src/app/providers/common.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-information-modal',
  templateUrl: './information-modal.component.html',
  styleUrls: ['./information-modal.component.scss'],
  providers:[MessageService]
})
export class InformationModalComponent implements OnInit {

  @Input() header: String;
  @Input() body: String;
  @Input() successFlag: boolean;
  @Input() negativeFlag: boolean;
  @Input() message: boolean;
  @Input() okButton: boolean;
  @Input() commitButton: boolean;
  @Input() commitButtonText: string;
  @Input() cancelButton: boolean;
  @Input() invoiceData: any;
  @Input() invoiceId: any;
  @Input() disableButton: any;
  @Input() goToQueryParams:any;
  @Input() goToRoute:any;
  @Input() buttonName:any;
  @Input() gotoButton:boolean;
  @Input() enableISOLogo:boolean;
  @Input() onlyCancelButton:boolean;
  @Output() deleteIsoLogo = new EventEmitter<any>();
  spinLoader : boolean;
  @Input() isOpenChangeTidModal = false;
  @Input() isShowPartialUpdateButton = false;
  @Input() partialUpdateButtonContent = '';
  @Input() overAllUpdateButtonContent = '';
  constructor(public modal: NgbActiveModal,
              private commonService: CommonService,
              private messageService:MessageService
              ) { }

  ngOnInit(): void {
  }

  commitInvoice(){

    this.spinLoader = true;
    const {id} = this.invoiceData;
    this.commonService.getCommonHttpClientQueryParams(apiLinks.commitInvoice, {invoiceId:id,status: this.commitButtonText.toUpperCase() == "APPROVE" ? "APPROVED": "COMMITTED"}).subscribe(res => {
      this.modal.close('success');
      this.spinLoader = false;
    },
    err => {
      console.log("in err", err);
      return this.showAlert(err, 'error');
    });

  }
  showAlert(data, alertType) {
    this.messageService.add({
      severity: alertType,
      summary: data.status,
      detail: data.message,
    });
    this.spinLoader = false;
    // this.modal.close();
  }
  deleteISOLogo() {
    this.spinLoader = true;
    this.deleteIsoLogo.emit('success');
  }
}
