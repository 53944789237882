<nav class="sidebar">
  <div class="sidebar-header">

    <!--- Logo -->
    <a routerLink="/" class="sidebar-brand img_center">
        <img alt="" [src]="sideBarImage" class="img_logo">
    </a>

    <!--- Toggler -->
    <div class="sidebar-toggler not-active" #sidebarToggler (click)="toggleSidebar($event)">
      <!-- <i data-feather="arrow-left"></i> -->
      <span></span>
      <span></span>
      <span></span>
    </div>
  </div>
  <div class="sidebar-body" (mouseenter)="operSidebarFolded()" (mouseleave)="closeSidebarFolded()" [perfectScrollbar]>

    <!--- Sidemenu start -->
    <ul class="sidebar-nav metismenu text-capitalize" id="sidebar-menu" #sidebarMenu>
      <ng-container *ngFor="let item of menuItems; let i= index;">
        <li class="nav-item nav-category" *ngIf="item.isTitle">{{ item.label | translate }}</li>
        <!-- <li class="nav-item nav-category" *ngIf="item.isTitle">{{ item.label }}</li> -->

        <li class="nav-item" *ngIf="!item.isTitle">

          <a class="nav-link" *ngIf="hasItems(item)" href="javascript:void(0);">
            <i class="link-icon" [attr.data-feather]="item.icon" appFeatherIcon *ngIf="item.icon"></i>
            <span class="link-title">{{ item.label | translate }}</span>
            <span class="link-arrow" data-feather="chevron-down" appFeatherIcon></span>
          </a>

          <a class="nav-link nav-link-ref" [routerLink]="item.link" *ngIf="!hasItems(item)"
            [attr.data-parent]="item.parentId" [class.disabled]="isPermission(item.module ,item?.privilege)">
            <i class="link-icon" [attr.data-feather]="item.icon" appFeatherIcon *ngIf="item.icon"></i>
            <!-- <span class="link-title"> {{ item.label }}</span>             -->
            <span class="link-title">{{ customerToAgentOffice(item.label) | translate }} </span>

            <span class="badge badge-{{item.badge.variant}}" *ngIf="item.badge">{{item.badge.text}}</span>
          </a>

          <ul class="sidebar-nav sub-menu nav-second-level" *ngIf="hasItems(item)" aria-expanded="false">
            <li class="nav-item" *ngFor="let subitem of item.subItems" [ngClass]="{'side-nav-item': hasItems(subitem)}">
              <a class="nav-link side-nav-link-a-ref" *ngIf="hasItems(subitem)" href="javascript:void(0);" [class.disabled]="isPermission(item.module ,subitem?.privilege)">
                <i class="link-icon" [attr.data-feather]="subitem.icon" appFeatherIcon *ngIf="subitem.icon"></i>
                <span class="link-title"> {{ subitem.label | translate }} </span>
                <span class="link-arrow" data-feather="chevron-down" appFeatherIcon></span>
              </a>

              <a class="nav-link nav-link-ref" [routerLink]="subitem.link" *ngIf="!hasItems(subitem) && !isPermission(item.module ,subitem?.privilege)"
                [attr.data-parent]="subitem.parentId" [class.disabled]="isPermission(subitem.module ,subitem?.privilege)">
                <i class="link-icon" [attr.data-feather]="subitem.icon" appFeatherIcon *ngIf="subitem.icon"></i>
                {{subitem.label | translate}}
              </a>

              <ul class="sidebar-nav sub-menu nav-third-level" *ngIf="hasItems(subitem)" aria-expanded="false">

                <li class="nav-item" *ngFor="let subSubitem of subitem.subItems">
                  <a class="nav-link sub-nav-link nav-link-ref" href="{{subSubitem.link}}" *ngIf="!hasItems(subSubitem) && !isPermission(item.module ,subSubitem?.privilege) && subSubitem?.isExternalURL == true;else showSubRouterLink"
                [attr.data-parent]="subSubitem.parentId" [class.disabled]="isPermission(subSubitem.module ,subSubitem?.privilege)" target="{{subSubitem?.target == '_blank' ? '_blank' : '_self'}}">
                <i class="link-icon" [attr.data-feather]="subSubitem.icon" appFeatherIcon *ngIf="subSubitem.icon"></i>
                {{subSubitem.label | translate}}
              </a>
              <ng-template #showSubRouterLink>
                <a class="nav-link sub-nav-link nav-link-ref" [routerLink]="subSubitem.link" [routerLinkActive]="['active']"
                  [attr.data-parent]="subSubitem.parentId" [class.disabled]="isPermission(subSubitem?.module ,subSubitem?.privilege)" target="{{subSubitem?.target == '_blank' ? '_blank' : '_self'}}">
                  <i class="link-icon" [attr.data-feather]="subitem.icon" appFeatherIcon *ngIf="subSubitem?.icon"></i>
                  {{ subSubitem?.label | translate }}
                </a>
              </ng-template>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ng-container>
    </ul>
    <!--- Sidemenu end -->
  </div>
</nav>
