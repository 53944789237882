import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType, HttpHeaders, HttpParams } from "@angular/common/http";
import { throwError } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { AuthService } from "./auth.service";
import {apiLinks} from "../core/api-links/api-links";

@Injectable({
  providedIn: 'root'
})
export class TransactionService {

  getInvoice = `${environment.steamApiUrl}/transaction-invoice`;

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService
  ) { }

  getAllTransactions(filter) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append("sort", `{"field": "createdAt", "order": "DESC"}`);
    httpParams = httpParams.append("size","20");
    Object.keys(filter).forEach(function (key) {
      httpParams = httpParams.append(key, filter[key]);
    });
    return this.httpClient.get(`${environment.apiUrl}/transactions?`,  { params: httpParams }).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.handleError)
    );
  }

  getInvoiceNum(httpReqUrl, parameter) {
    httpReqUrl = httpReqUrl+"/"+parameter.rrn+"/"+parameter.txName
    return this.httpClient.get(httpReqUrl).pipe(
      map((res: any) => {
        return res.data;
      }),
      catchError(this.handleError)
    );
  }

  getReceiptDetails(id){
    return this.httpClient.get(`${apiLinks.receiptDetails}/${id}`).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.handleError)
    )
  }

  getAlterReceiptDetails(id){
    return this.httpClient.get(`${apiLinks.receiptAlterDetails}/${id}`).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.handleError)
    )
  }

  getCustomerReceiptDetails(id, viewType){
    let apiName =""
    if(viewType == 1) {
      apiName = `${apiLinks.txExtns}/${id}`;
    } else {
      apiName = `${apiLinks.txAltExtn}/${id}`;
    }
    console.log({viewType, apiName})
    return this.httpClient.get(apiName).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.handleError)
    )
  }

  // Customer view-receipt sms
  getCustomerReceipt(id){
    return this.httpClient.get(`${apiLinks.customerReceipt}/${id}`).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.handleError)
    )
  }

  getAlterCustomerReceipt(id){
    return this.httpClient.get(`${apiLinks.customerAlterReceipt}/${id}`).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.handleError)
    )
  }

  // getTransactionExtension(id) {
  //   return this.httpClient.get(`${apiLinks.customerReceiptDetails}/${id}`).pipe(
  //     map((res: any) => {
  //       return res;
  //     }),
  //     catchError(this.handleError)
  //   )
  // }
  handleError(error) {
    let errorMessage = {};
    if (error.error instanceof ErrorEvent) {
      errorMessage["message"] = error.error.message;
    } else if (error.error.errors instanceof Array) {
      return throwError({errors:error.error.errors});
    } else {
      errorMessage["status"] = error.status;
      errorMessage["message"] = error.error.message
        ? error.error.message
        : error.message;
    }
    return throwError(errorMessage);
  }
}
