import { Injectable } from "@angular/core";
import {
  CanActivate,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
} from "@angular/router";
import { Router } from "@angular/router";
import { AuthService } from "../../providers/auth.service";
import { Users } from "../model/user";

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser: Users = this.authService.getUser;
    // console.log(currentUser);

    if (currentUser) {
      if(currentUser.userType != "SYSTEM" && currentUser.userType != "ISO" && currentUser.userType != "SUB_ISO"){
        const url = state.url;
        if ( url == "/dashboard") {
          this.router.navigate(["/user-management/users"]);
          return false
        }
      }
      // logged in so return true
      return true;
    }

    // not logged in so redirect to login page with the return url
    //console.log(state.url);
    if (state.url == "/dashboard") {
      this.router.navigate(["/auth/login"]);
    } else {
      this.router.navigate(["/auth/login"], {
        queryParams: { returnUrl: state.url },
      });
    }

    return false;
  }
}
