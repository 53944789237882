import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
  Renderer2,
  Inject,
} from "@angular/core";
import { DOCUMENT } from "@angular/common";

import MetisMenu from "metismenujs/dist/metismenujs";

import { MENU } from "./menu";
import { MenuItem } from "./menu.model";
import { Router, NavigationEnd } from "@angular/router";
import { AuthService } from "../../../providers/auth.service";
import { RoleFeatures } from "../../../core/model/user";
import { from, Observable, of } from "rxjs";
import { Privileges } from "../../../core/model/privileges.enum";
import { CustomerService } from "src/app/providers/customer.service";
import { CommonService } from "src/app/providers/common.service";
import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { catchError, map } from "rxjs/operators";
import * as _ from "lodash";
import { UserType } from "src/app/core/model/user-type.enum";
@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent implements OnInit, AfterViewInit {
  @ViewChild("sidebarToggler") sidebarToggler: ElementRef;

  menuItems = [];
  @ViewChild("sidebarMenu") sidebarMenu: ElementRef;
  privileges = Privileges;
  customerPrivilege: any;
  sideBarLogoName: any;
  sideBarImage: any ;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private authService: AuthService,
    router: Router,
    public customerService : CustomerService,
    private commonService : CommonService,
    private httpClient: HttpClient,
  ) {
    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        /**
         * Activating the current active item dropdown
         */
        this._activateMenuDropdown();

        /**
         * closing the sidebar
         */
        if (window.matchMedia("(max-width: 991px)").matches) {
          this.document.body.classList.remove("sidebar-open");
        }
      }
    });

  }

  ngOnInit(): void {
    this.menuItems = this.getMenuForCurrentUser(
      MENU,
      this.authService.getUser.roleFeatures
    );
    if(!_.isEmpty(this.authService.getUser.customerLogo)){
      this.sideBarImage = `${environment.apiFullUrl}/${environment.portalImagePath}/${this.authService.getUser.customerLogo}`;
    }else{
       this.sideBarImage = "../../../../assets/images/opcmmsx.png";
      }

    /**
     * Sidebar-folded on desktop (min-width:992px and max-width: 1199px)
     */

    const desktopMedium = window.matchMedia(
      "(min-width:992px) and (max-width: 1199px)"
    );
    desktopMedium.addListener(this.iconSidebar);
    this.iconSidebar(desktopMedium);
  }

  ngAfterViewInit() {
    // activate menu item
    new MetisMenu(this.sidebarMenu.nativeElement);

    this._activateMenuDropdown();
  }

  // Agent Office(Sub Iso) Hierarchy
  customerToAgentOffice(item){
    //console.log(this.authService.getUser.userType);
    return this.authService?.getUser?.userType!=UserType.SYSTEM && item=='Customer'?UserType.AGENT_OFFICE:item;

  }

  /**
   * Toggle sidebar on hamburger button click
   */
  toggleSidebar(e) {
    this.sidebarToggler.nativeElement.classList.toggle("active");
    this.sidebarToggler.nativeElement.classList.toggle("not-active");
    if (window.matchMedia("(min-width: 992px)").matches) {
      e.preventDefault();
      this.document.body.classList.toggle("sidebar-folded");
    } else if (window.matchMedia("(max-width: 991px)").matches) {
      e.preventDefault();
      this.document.body.classList.toggle("sidebar-open");
    }
  }

  /**
   * Toggle settings-sidebar
   */
  toggleSettingsSidebar(e) {
    e.preventDefault();
    this.document.body.classList.toggle("settings-open");
  }

  /**
   * Open sidebar when hover (in folded folded state)
   */
  operSidebarFolded() {
    if (this.document.body.classList.contains("sidebar-folded")) {
      this.document.body.classList.add("open-sidebar-folded");
    }
  }

  /**
   * Fold sidebar after mouse leave (in folded state)
   */
  closeSidebarFolded() {
    if (this.document.body.classList.contains("sidebar-folded")) {
      this.document.body.classList.remove("open-sidebar-folded");
    }
  }

  /**
   * Sidebar-folded on desktop (min-width:992px and max-width: 1199px)
   */
  iconSidebar(e) {
    if (e.matches) {
      this.document.body.classList.add("sidebar-folded");
    } else {
      this.document.body.classList.remove("sidebar-folded");
    }
  }

  /**
   * Returns true or false if given menu item has child or not
   * @param item menuItem
   */
  hasItems(item: MenuItem) {
    return item.subItems !== undefined ? item.subItems.length > 0 : false;
  }

  /**
   * Reset the menus then hilight current active menu item
   */
  _activateMenuDropdown() {
    this.resetMenuItems();
    this.activateMenuItems();
  }

  /**
   * Resets the menus
   */
  resetMenuItems() {
    const links = document.getElementsByClassName("nav-link-ref");

    for (let i = 0; i < links.length; i++) {
      const menuItemEl = links[i];
      menuItemEl.classList.remove("mm-active");
      const parentEl = menuItemEl.parentElement;

      if (parentEl) {
        parentEl.classList.remove("mm-active");
        const parent2El = parentEl.parentElement;

        if (parent2El) {
          parent2El.classList.remove("mm-show");
        }

        const parent3El = parent2El.parentElement;
        if (parent3El) {
          parent3El.classList.remove("mm-active");

          if (parent3El.classList.contains("side-nav-item")) {
            const firstAnchor = parent3El.querySelector(".side-nav-link-a-ref");

            if (firstAnchor) {
              firstAnchor.classList.remove("mm-active");
            }
          }

          const parent4El = parent3El.parentElement;
          if (parent4El) {
            parent4El.classList.remove("mm-show");

            const parent5El = parent4El.parentElement;
            if (parent5El) {
              parent5El.classList.remove("mm-active");
            }
          }
        }
      }
    }
  }

  /**
   * Toggles the menu items
   */
  activateMenuItems() {
    const links = document.getElementsByClassName("nav-link-ref");

    let menuItemEl = null;

    for (let i = 0; i < links.length; i++) {
      // tslint:disable-next-line: no-string-literal
      if (window.location.pathname === links[i]["pathname"]) {
        menuItemEl = links[i];

        break;
      }
    }

    if (menuItemEl) {
      menuItemEl.classList.add("mm-active");
      const parentEl = menuItemEl.parentElement;

      if (parentEl) {
        parentEl.classList.add("mm-active");

        const parent2El = parentEl.parentElement;
        if (parent2El) {
          parent2El.classList.add("mm-show");
        }

        const parent3El = parent2El.parentElement;
        if (parent3El) {
          parent3El.classList.add("mm-active");

          if (parent3El.classList.contains("side-nav-item")) {
            const firstAnchor = parent3El.querySelector(".side-nav-link-a-ref");

            if (firstAnchor) {
              firstAnchor.classList.add("mm-active");
            }
          }

          const parent4El = parent3El.parentElement;
          if (parent4El) {
            parent4El.classList.add("mm-show");

            const parent5El = parent4El.parentElement;
            if (parent5El) {
              parent5El.classList.add("mm-active");
            }
          }
        }
      }
    }
  }

  getUserRole() {
    const role = this.authService.getUser.role;
    return role.trim().replace("_", " ").toLowerCase();
  }
  isPermission(modulename, privilege = "") {
    // console.log("previ",this.authService.getPrivileges(modulename).features);

    if (this.authService.getPrivileges(modulename).features !== undefined) {
      this.customerPrivilege = this.authService.getPrivileges(modulename).features;
      // console.log(modulename,privilege,this.customerPrivilege.indexOf(privilege));
      return this.customerPrivilege.indexOf(privilege) > -1 ? false : true;
    }
    return;
  }
  getMenuForCurrentUser(
    defaultMenu: MenuItem[],
    allowedModules: RoleFeatures[]
  ) {
    let result = [];
    allowedModules.map((Module) => {
      let allowedMenu = this.getAllowedMenu(defaultMenu, Module);
      if (allowedMenu instanceof Array && allowedMenu.length != 0) {
        result.push(...allowedMenu);
      }
    });
    let total =  result.sort((acc, item) => {
      return acc.indexId - item.indexId;
    });
    
    let userType = this.authService?.getUser?.userType;
    if(userType != 'SYSTEM' && userType != 'ISO' && userType != 'SUB_ISO'){
      total = total.filter(data => data.indexId != 0);
    }
    
    return total
  }

  getAllowedMenu(defaultMenu, Module) {
    const result = defaultMenu.filter(
      (menu) => menu.module === Module.module
    );
    return result;
  }

// Disable dropdown menu items if all the previleges is remove from that module.
  atleastOneRoleAvailable(module: string) {
    const privilege = this.authService.getPrivileges(module);
    const feature = _.get(privilege, 'features', true);
    return _.isEmpty(feature)
  }

}
