import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { BaseComponent } from "./views/layout/base/base.component";
import { AuthGuard } from "./core/guard/auth.guard";
import { ModuleGuard } from "./core/guard/module.guard";
import { ErrorPageComponent } from "./views/pages/error-page/error-page.component";
import { PasswordResetComponent } from "../app/views/pages/auth/password-reset/password-reset.component";
import { Modules } from "./core/model/modules.enum";
import { Privileges } from "./core/model/privileges.enum";
import { ViewRecepitComponent } from '../app/views/pages/transaction-management/view-recepit/view-recepit.component';
import { BillingInvoiceComponent } from "./views/pages/billingInvoice/billing-invoice/billing-invoice.component";
import { ListHostInventoryComponent } from "./views/pages/host-inventory/list-host-inventory/list-host-inventory.component";
import { AddHostInventoryComponent } from "./views/pages/host-inventory/add-host-inventory/add-host-inventory.component";
import { ProcessorListComponent } from "./views/pages/processor-management/processor-list/processor-list.component";
import { AddProcessorComponent } from "./views/pages/processor-management/add-processor/add-processor.component";
import { environment } from "src/environments/environment";

const routes: Routes = [
  {
    path: "auth",
    loadChildren: () =>
      import("./views/pages/auth/auth.module").then((m) => m.AuthModule),
  },
  {
    path: "view-recepit/:id/:type",
    component: ViewRecepitComponent,
  },
  {
    path: "",
    component: BaseComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "dashboard",
        loadChildren: () =>
          import("./views/pages/dashboard/dashboard.module").then(
            (m) => m.DashboardModule
          ),
      },
      {
        path: "user-management",
        loadChildren: () =>
          import("./views/pages/user-management/user-management.module").then(
            (m) => m.UserManagementModule
          ),
        canActivate: [ModuleGuard],
        data: { module: Modules.USER, privilege: Privileges.VIEW_USER },
      },
      {
        path: "my-tickets",
        loadChildren: () =>
          import("./views/pages/my-tickets/my-tickets.module").then(
            (m) => m.MyTicketsModule
          ),
        canActivate: [ModuleGuard],
        data: { module: Modules.MY_TICKETS, privilege: Privileges.VIEW_TICKET },
      },
      {
        path: "customer-management",
        loadChildren: () =>
          import(
            "./views/pages/customer-management/customer-management.module"
          ).then((m) => m.CustomerManagementModule),
        canActivate: [ModuleGuard],
        data: { module: Modules.CUSTOMER, privilege: Privileges.VIEW_CUSTOMER },
      },
      {
        path: "params-management",
        loadChildren: () =>
          import(
            "./views/pages/params-management/params-management.module"
          ).then((m) => m.ParamsManagementModule),
        canActivate: [ModuleGuard],
        data: { module: Modules.STEAM, privilege: Privileges.VIEW_DEVICE },
      },
      {
        path: "application-management",
        loadChildren: () =>
          import(
            "./views/pages/steam/steam.module"
          ).then((m) => m.SteamModule),
        // canActivate: [ModuleGuard],
        // data: { module: Modules.STEAM, privilege: Privileges.VIEW_APPLICATION },
      },
      {
        path: "steam-param",
        loadChildren: () =>
          import(
            "./views/pages/steam/steam.module"
          ).then((m) => m.SteamModule),
        // canActivate: [ModuleGuard],
        // data: { module: Modules.STEAM, privilege: Privileges.VIEW_APPLICATION },
      },

      {
        path: "asset-management",
        loadChildren: () =>
          import(
            "./views/pages/steam/steam.module"
          ).then((m) => m.SteamModule),
      },
      {
        path: "downloadlog-management",
        loadChildren: () =>
          import(
            "./views/pages/steam/steam.module"
          ).then((m) => m.SteamModule),
      },
      {
        path: "virtual-terminal",
        loadChildren: () =>
          import(
            "./views/pages/virtual-terminal/virtual-terminal.module"
          ).then((m) => m.VirtualTerminalModule),
      },
      {
        path: "consumer-list",
        loadChildren: () =>
          import(
            "./views/pages/consumer-management/consumer-management.module"
          ).then((m) => m.ConsumerManagementModule),
      },
      {
        path: "device-alert-management",
        loadChildren: () =>
        import(
          "./views/pages/steam/steam.module"
        ).then((m) => m.SteamModule),
      },
      {
        path: "app-auditlog-list",
        loadChildren: () =>
        import(
          "./views/pages/auditlog-management/auditlog-management.module"
        ).then((m) => m.AuditlogManagementModule),
      },
      
      {
        path: "transaction-management",
        loadChildren: () =>
          import(
            "./views/pages/transaction-management/transaction-management.module"
          ).then((m) => m.TransactionManagementModule),
      },
      {
        path: "merchant-management",
        loadChildren: () =>
          import(
            "./views/pages/merchant-management/merchant-management.module"
          ).then((m) => m.MerchantManagementModule)
      },
      {
        path: "merchant-settings",
        loadChildren: () =>
          import(
            "./views/pages/merchant-settings/merchant-settings.module"
          ).then((m) => m.MerchantSettingsModule)
      },
      {
        path: "customer-settings",
        loadChildren: () =>
          import(
            "./views/pages/customer-settings/customer-settings.module"
          ).then((m) => m.CustomerSettingsModule)
      },
      {
        path: "batch-management",
        loadChildren: () =>
          import(
            "./views/pages/batch-management/batch-management.module"
          ).then((m) => m.BatchManagementModule)
      },
      {
        path: "spin-troubleshooting",
        loadChildren: () =>
        import(
          "./views/pages/troubleshooting/troubleshooting.module"
        ).then((m) => m.TroubleshootingModule),
      },
      {
        path: "billing-invoice",
        canActivate: [ModuleGuard],
        component:BillingInvoiceComponent,
        data:{module:Modules.BILLING, privilege: Privileges.VIEW_INVOICE}
      },
      {
        path: "list-host-inventory",
        canActivate: [ModuleGuard],
        component:ListHostInventoryComponent,
        data:{module:Modules.HOST_INVENTORY, privilege: Privileges.VIEW_HOST_INVENTORY}
      },
      {
        path: "add-host-inventory",
        component: AddHostInventoryComponent,
      },
      {
        path: "processor-list",
        canActivate: [ModuleGuard],
        component:ProcessorListComponent,
        data:{module:Modules.HOST_INVENTORY, privilege: Privileges.VIEW_HOST_INVENTORY}
      },
      {
        path: "add-processor",
        component: AddProcessorComponent,
      },
      {
        path: "risk-management",
        loadChildren: () =>
          import(
            "./views/pages/risk-management/risk-management.module"
          ).then((m) => m.RiskManagementModule),
         canActivate: [ModuleGuard],
        data: { module: Modules.RISK_MANAGEMENT, privilege: Privileges.VIEW_RISK_DETAILS },

      },
      { path: "", redirectTo: "dashboard", pathMatch: "full" },
      // { path: "", redirectTo: "user-management", pathMatch: "full" },
      // { path: '**', redirectTo: 'dashboard', pathMatch: 'full' }
    ],
  },
  {
    path: "userVerify",
    component: PasswordResetComponent,
  },
  {
    path: "error",
    component: ErrorPageComponent,
    data: {
      type: 404,
      title: "Page Not Found",
      desc: "Oopps!! The page you were looking for doesn't exist.",
    },
  },
  {
    path: "error/:type",
    component: ErrorPageComponent,
  },
  { path: "**", redirectTo: "error", pathMatch: "full" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: "top" })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
