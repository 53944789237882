import {environment} from '../../../environments/environment';

export var apiLinks={
    deviceUrl : `${environment.apiUrl}/tpns`,
    deviceNameUrl : `${environment.apiUrl}/allTpns`,
    templateJsonUrl : `${environment.steamApiUrl}/getTemplateJson`,
    valueJsonUrl : `${environment.steamApiUrl}/tpn-details`,
    ticketTxn: `${environment.steamApiUrl}/tpn-details`,
    receiptDetails:`${environment.steamApiUrl}/receipt-details`,
    customerReceipt:`${environment.steamApiUrl}/customer-receipt-details`,
    receiptAlterDetails:`${environment.steamApiUrl}/alter-receipt-details`,
    customerAlterReceipt:`${environment.steamApiUrl}/alter-customer-receipt-details`,
    txExtns:`${environment.apiUrl}/txExtn`,
    latestBuilds:`${environment.steamApiUrl}/latest-builds`,
    checkSum:`${environment.steamApiUrl}/check-sum`,
    vtPosparam:`${environment.steamApiUrl}/vtPosparam`,
    processTransaction:`${environment.paymentApiUrl}/process-transaction`,
    paymentForm: `${environment.paymentApiUrl}/payment-form`,
    sendEmailorSMS:`${environment.apiUrl}/sendEmailorSMS`,
    openBatch: `${environment.apiUrl}/open-batch-report`,
    adjustTip: `${environment.apiUrl}/txn-details-portal`,
    batches: `${environment.apiUrl}/batches`,
    posEntry: `${environment.apiUrl}/posEntry`,
    allTansData: `${environment.apiUrl}/transReport`,
    distCustomerDba: `${environment.apiUrl}/customersDBA`,
    tpnIds: `${environment.apiUrl}/getAllTpnIds`,
    dbaBatches: `${environment.apiUrl}/dba-batches`,
    dbaTotalBatches: `${environment.apiUrl}/total-dba-batches`,
    allDBAsByUser: `${environment.apiUrl}/getAllDbasByUser`,
    settleBatch: `${environment.paymentApiUrl}/process-transaction`,
    saveAsTemplate: `${environment.steamApiUrl}/pos-template`,
    templateNames: `${environment.steamApiUrl}/pos-template-names`,
    applyTemplate: `${environment.steamApiUrl}/apply-pos-template`,
    deleteTemplateParam: `${environment.steamApiUrl}/delete-template-param`,
    txBaseExtnsId: `${environment.apiUrl}/txBaseExtn`,
    pinpanTpnDevices: `${environment.apiUrl}/pinpad-tpn-devices`,
    pinpadTpns:`${environment.apiUrl}/pinpad-tpns`,
    editCards: `${environment.apiUrl}/editConsumerCards`,
    createConsumerWithoutCard: `${environment.apiUrl}/createConsumerWithoutCard`,
    createTpn: `${environment.apiUrl}/tpns`,
    createInvoice: `${environment.apiUrl}/createInvoice`,
    getInvoice: `${environment.apiUrl}/getAllInvoice`,
    getAlltickets: `${environment.apiUrl}/getAlltickets`,
    getSamelevelUsers: `${environment.apiUrl}/get-samelevel-users`,
    updateTicketActions: `${environment.apiUrl}/update-ticket-actions`,
    addComments: `${environment.apiUrl}/add-comments`,
    getAllComments: `${environment.apiUrl}/get-all-comments`,
    getHelpdeskEmails: `${environment.apiUrl}/get-helpdesk-emails`,
    updateHelpdeskEmails: `${environment.apiUrl}/update-helpdesk-emails`,
    uploadInvoice: `${environment.apiUrl}/uploadInvoice`,
    uploadAsset: `${environment.steamApiUrl}/uploadAsset`,
    exportFile: `${environment.apiUrl}/exportFile`,
    druidSQL: `${environment.druidApiUrl}/druid/v2/sql`,
    updateCustomer: `${environment.apiUrl}/editCustomer`,
    checkJobTitle: `${environment.apiUrl}/checkJobTitle`,
    tpnBatches: `${environment.apiUrl}/tpn-batches`,
    getAllUserCount: `${environment.apiUrl}/usersCount`,
    storeDbas: `${environment.apiUrl}/getAllStoreDbasByUser`,
    isoCheckOpenBatch: `${environment.apiUrl}/isoCheckOpenBatch/`,
    getMerchantRoleFeature: `${environment.apiUrl}/getMerchantRoleFeature`,
    getStoreById: `${environment.apiUrl}/stores`,
    pinpadTransaction: `${environment.apiUrl}/pinpad-txn`,
    deleteTpn: `${environment.apiUrl}/deleteTpn`,
    isoDeleteMerchant: `${environment.apiUrl}/isoDeleteMerchant`,
    deleteStore: `${environment.apiUrl}/deleteStore`,
    deviceModels:`${environment.apiUrl}/device-models`,
    getManufacturer:`${environment.apiUrl}/manufacturers`,
    getAllProfiles:`${environment.apiUrl}/profiles`,
    getAllProcessors: `${environment.apiUrl}/processors`,
    getIntegrationList:`${environment.apiUrl}/getIntegrationList`,
    settlementEmailAlert: `${environment.apiUrl}/settlementEmailAlertConfig`,
    sendEmailWhileSettlement: `${environment.apiUrl}/sendEmailWhileSettlement`,
    getMerchantById: `${environment.apiUrl}/merchant`,
    getFileUpload: `${environment.apiUrl}/sampleasset`,
    deleteMultipleTPN: `${environment.apiUrl}/deleteMultipleTpn`,
    getCustomerById: `${environment.apiUrl}/customer`,
    generateTpn: `${environment.apiUrl}/generateTPN/`,
    getEmails: `${environment.apiUrl}/secPass-users`,
    getDeviceEventTypes: `${environment.steamApiUrl}/event-types`,
    verifyEmailOfMerchantAdmin: `${environment.apiUrl}/verifyEmailOfMerchantAdmin`,
    spinRegister:`${environment.steamApiUrl}/spinRegister`,
    latestBuildForBulkAssign:`${environment.apiUrl}/checkBulkAssignBuild`,
    updateBulkAssign:`${environment.apiUrl}/bulkAssignBuild`,
    getAllBillingInvoice:`${environment.apiUrl}/billingInvoice`,
    getAllAuditLogs: `${environment.apiUrl}/get-all-audit-logs`,
    getSelectedTPNLog: `${environment.apiUrl}/get-selected-tpn`,
    posParamsDisplayNames: `${environment.steamApiUrl}/posParamDisplayNames`,
    createNote: `${environment.apiUrl}/createNotes`,
    viewNotes: `${environment.apiUrl}/listNotes`,
    processorBytxn: `${environment.apiUrl}/getProcessorsByTxn`,
    getUserById: `${environment.apiUrl}/users/`,
    getAllTpnIdsForProxyTest: `${environment.apiUrl}/getAllTpnIdsForProxyTest`,
    spinInfo:`${environment.steamApiUrl}/spinInfo`,
    regProxyTest:`${environment.steamApiUrl}/spinRegProxyTest`,
    proxyGetSerialNumberTest:`${environment.steamApiUrl}/spinTestSerialNumber`,
    clientEmailAlert:`${environment.apiUrl}/client-email-alert`,
    commitInvoice:`${environment.apiUrl}/commitInvoice/`,
    hostInventory: `${environment.apiUrl}/hostInventory`,
    getTpnsByTid: `${environment.apiUrl}/getTpnsByTid`,
    replaceTID: `${environment.apiUrl}/replaceTID`,
    getProcessors: `${environment.apiUrl}/processorList`,
    addProcessor: `${environment.apiUrl}/addProcessor`,
    checkProcessor: `${environment.apiUrl}/checkProcessor/`,
    editProcessor:`${environment.apiUrl}/editProcessor`,
    uploadHostInventory:`${environment.apiUrl}/uploadHostInventory`,
    getHostInventoryFileUpload: `${environment.apiUrl}/sampleHostInventory`,
    getTotalBatchByCustomer:`${environment.apiUrl}/getTotalBatchByCustomer`,
    editCustomerLogo:`${environment.apiUrl}/editCustomerLogo`,
    countLogoImage: `${environment.steamApiUrl}/countLogoImage`,
    customersByApplication: `${environment.steamApiUrl}/customers-application/`,
    updateCustomerToApp: `${environment.steamApiUrl}/updateCustomerToApp/`,
    templateTypes: `${environment.steamApiUrl}/templateTypes`,
    downloadBillInvoicePDF:`${environment.apiUrl}/downLoadInvoicePDF`,
    txAltExtn: `${environment.apiUrl}/tx-alter-extn`,
    processVoidACHTx:`${environment.apiUrl}/processVoidACHTx`,
    getStoreType: `${environment.apiUrl}/getStoreType`,
    getAllStoreById: `${environment.apiUrl}/allStores`,
    batchDetailsFilterPortal:`${environment.apiUrl}/batch-details-filter-portal`,
    batchTippedReportPortal:`${environment.apiUrl}/batch-tipped-report-portal`,
    transactions:`${environment.apiUrl}/transactions`,
    openBatchWithreport: `${environment.apiUrl}/open-batch-filter-portal`,
    updateCustomerIdInApp:`${environment.steamApiUrl}/updateCustomerIdInApp/`,
    spinProxyLogs: `${environment.steamApiUrl}/spinProxyLogs`,
    viewSpinProxyReqRes:`${environment.steamApiUrl}/viewSpinProxyReqRes`,
    exportDownloadLogs: `${environment.steamApiUrl}/downloadDownloadLogsReport`,
    getRecurringPaymentsList:`${environment.apiUrl}/getRecurringPaymentsList`,
    inventoryItems: `${environment.apiUrl}/inventory`,
    getAllPromotions: `${environment.apiUrl}/promotions`,
    getAllProxyLoginLogs: `${environment.apiUrl}/proxyLoginLogs`,
    deactivatedMerchants:`${environment.apiUrl}/deactivatedMerchants`,
    updateDefaultLabel:`${environment.apiUrl}/updateDefaultLabel`,
    getDeviceInfoByTpn:`${environment.apiUrl}/getDeviceInfoByTpn`,
    getOwnershipInfoByTpn:`${environment.apiUrl}/getOwnershipInfoByTpn`,
    buildForBulkEdit:`${environment.apiUrl}/checkBulkEditParam`,
    getTemplateJson:`${environment.steamApiUrl}/getTemplateJson`,
    bulkUpdateTemplateJson:`${environment.apiUrl}/bulkUpdateParam`,
    getCustomerLogoByCustomerId:`${environment.apiUrl}/getCustomerLogoByCustomerId`,
    pagoReg: `${environment.steamApiUrl}/pagoReg`,
    getStatusHostInventory:`${environment.apiUrl}/getStatusHostInventory`,
    merchantsDba:`${environment.apiUrl}/getAllmerchantsDbaByUsers`,
    posEntryTransaction: `${environment.apiUrl}/posEntryTransaction`,
    getTpnsIds:`${environment.apiUrl}/getTpnsIds`,
    getCustomerDBAs: `${environment.apiUrl}/getCustomerDBAs`,
    checkSafeProcessorCounts: `${environment.apiUrl}/checkSafeProcessorCounts`,
    tidOwnerBasedTpns: `${environment.apiUrl}/tidOwnerBasedTpns`,
    getAllAlternateProcessors: `${environment.apiUrl}/getAllAlternateProcessors`,
    getAgentInfo: `${environment.apiUrl}/getAgentInfo`,
    zipCodes: `${environment.apiUrl}/zipcodes`,
    securityCheck: `${environment.apiUrl}/security-check`,
    riskRules: {
        risk: `${environment.apiUrl}/riskRule`,
        activateTpn: `${environment.apiUrl}/riskRule/activateTpn`,
        template: `${environment.apiUrl}/riskRule/template`,
        bulkUpdate: `${environment.apiUrl}/riskRule/bulkUpdate`
    },
    getAllMerchants: `${environment.apiUrl}/merchants`,
    getAllTpnIds: `${environment.apiUrl}/getAllTpnIds`,
}
