<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">{{'Upload HostInventory' | translate}}</h5>
  <div class="d-flex">
    <button (click)="download()" class="d-flex align-items-center btn btn-primary md-2 mr-md-2">{{'asset.SampleFile' | translate}}
    </button>
    <button type="button" class="close" (click)="activeModal.close('by: close icon')" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</div>
<app-loading *ngIf="isLoader"></app-loading>
<form action="" [formGroup]="hostInventoryForms" (submit)="forFileConfirmation();" *ngIf="!isLoader">
  <div class="modal-body">
    <div class="container">
      <div class="container" formGroupName="uploadHostInventoryInfo">
        <div class="row ">
          <div class="col-lg-8 col-sm-12 mx-auto">
            <div class="mt-md-1 mb-3 pt-4">
              <div class="form-row form-style col-md-12">
                <div class="form-group form__div col-md-6 col-sm-11">
                  <input type="text" name="jobName" class="form__input" placeholder=" " formControlName="jobName"
                    autocomplete="off">
                  <label class="form__label" for="jobName">
                    <h6>{{'asset.JobName' | translate}} *</h6>
                  </label>

                  <ngb-alert [dismissible]="false" [type]="'danger'" class="text-right pt-3"
                    *ngIf="hostInventoryForms.get(['uploadHostInventoryInfo','jobName']).errors?.required && submit">
                    <i class="link-icon feather icon-alert-triangle">
                    </i>
                  </ngb-alert>

                  <div class="error-message"
                    *ngIf="(hostInventoryForms.get(['uploadHostInventoryInfo','jobName']).touched || submit)">
                    <ngb-alert [dismissible]="false" [type]="'danger'" class="pt-md-5"
                      *ngIf="hostInventoryForms.get(['uploadHostInventoryInfo','jobName']).invalid && hostInventoryForms.get(['uploadHostInventoryInfo','jobName']).errors?.pattern">
                      <i class="link-icon feather icon-alert-triangle"></i>
                      {{'errorMsg.invalidJobName' | translate}}
                    </ngb-alert>
                  </div>

                </div>
                <div class="form-group form__div col-md-6">
                  <div class="form-group">
                    <input (change)="onFileChanges($event)" type="file" name="img[]" class="file-upload-default"
                      id="uploadFile" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet">
                    <div class="input-group col-xs-12" (click)="openFileBrowser($event, 'uploadFile')">
                      <input type="text" formControlName="uploadFile" placeholder="Upload File"
                        class="form-control file-upload-info" [attr.readonly]="true">
                      <span class="input-group-append">
                        <button type="button" class="file-upload-browse btn btn-primary">
                          <i data-feather="folder" appFeatherIcon class="icon-sm"></i>
                        </button>
                      </span>
                    </div>

                    <div class="error-message"
                      *ngIf="(hostInventoryForms.get(['uploadHostInventoryInfo','uploadFile']).touched && fileValid && (nullFile != true))">
                      <ngb-alert [dismissible]="false" [type]="'danger'" class="pt-md-1">
                        <i class="link-icon feather icon-alert-triangle"></i>
                        {{'errorMsg.invalidFileType' | translate}}
                      </ngb-alert>
                    </div>

                    <div class="error-message"
                      *ngIf="nullFile">
                      <ngb-alert [dismissible]="false" [type]="'danger'" class="pt-md-1">
                        <i class="link-icon feather icon-alert-triangle"></i>
                        {{'errorMsg.invalidFileType' | translate}}
                      </ngb-alert>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
  </div>
  <div class="modal-footer">
    <div class="col-md-12 d-flex justify-content-end">
      <button type="button" (click)="resetUploadForm();"
        class="btn col-md-2 col-sm-4 btn-danger btn-block shadow-sm button mt-md-2 mr-md-1">{{'asset.Reset' | translate}}
      </button>
      <button type="submit"
        class="btn col-md-3 col-sm-4 btn-primary btn-block shadow-sm button mt-md-2 mr-md-1">
        {{'asset.Upload' | translate}}
      </button>
    </div>
  </div>
</form>
<p-toast position="top-right"></p-toast>

