import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";
import { TransactionService } from '../../../../providers/transaction.service';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { environment } from "../../../../../environments/environment";
import { isUndefined } from 'lodash';
import * as _ from 'lodash';

@Component({
  selector: 'app-view-recepit',
  templateUrl: './view-recepit.component.html',
  styleUrls: ['./view-recepit.component.scss']
})
export class ViewRecepitComponent implements OnInit {

  @ViewChild('screen') screen: ElementRef;

  baseUrl = environment.apiUrl;
  receiptData: any;
  receiptId: any;
  receiptType: any;
  receipt_invoice:any;
  receipt_Merchant:any;
  receipttxdta:any;
  invoiceVold= "";
  // bloburl:any = 'bloburl';
  types = {
    MC: 'Merchant Copy',
    CC: 'Customer Copy'
  };
  isLoading: boolean = true;

  alter:any;
  isNewReceipt = true;

  constructor(private transactionService: TransactionService, private route: ActivatedRoute) {
    this.route.queryParams
    .subscribe(params => {
      console.log(params.alter);
      this.alter=params.alter;
    });
   }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.receiptId = params.get('id');
      this.receiptType = this.types["CC"];


      // this.receiptType = this.types[params.get('type')];
      //console.log(this.receiptId);
      this.getReceipt(this.receiptId);
    })
  }

  download(file) {
    html2canvas(this.screen.nativeElement, { scrollY: 0, allowTaint: true, useCORS: true, logging: true }).then((canvas) => {
      let a = document.createElement("a");
      let x = new Date().toLocaleString();
      if (file == 'png') {
        this.pngDownload(a, canvas.toDataURL('image/png'), `${this.receipt_Merchant}_Invoice-${this.receipt_invoice}_(${this.receipttxdta}).png`);
      } else {
        this.pdfDownload(canvas.toDataURL('image/png'), `${this.receipt_Merchant}_Invoice-${this.receipt_invoice}_(${this.receipttxdta}).pdf`);
      }
    });
  }

  pngDownload(a, img, fileName) {
    a.href = img;
    a.download = fileName;
    a.webkitImageSmoothingEnabled = false;
    a.mozImageSmoothingEnabled = false;
    a.imageSmoothingEnabled = false
    a.click();
  }

  pdfDownload(imgData, fileName) {
    const doc = new jsPDF('p', 'mm');
    doc.addImage(imgData, 'PNG', 65, 0, 70, 170);
    doc.save(fileName);
    // window.open(doc.output(this.bloburl, { filename: fileName }), '_blank')
  }

  getReceipt(id) {

    console.log(this.alter);

    let serviceLink=this.alter=='true'?'getAlterCustomerReceipt':'getCustomerReceipt';

    this.transactionService[serviceLink](id).subscribe((res) => {
      this.receiptData = res.data;
      this.receipt_invoice = res.data.txnDetails.invoiceNumber;
      this.receipt_Merchant = res.data.receiptDetails.h1;
      const receiptFormat = _.get(res, 'data.receiptDetails.RECEIPT_FORMAT', '2');
      if (receiptFormat == '1' && _.get(res, 'data.txnDetails.txName') == 'WITHDRAWAL') {
        this.isNewReceipt = false;
      } else {
        this.isNewReceipt = true;
      }
      this.receipttxdta = res.data.txnDetails.txDate;
      this.isLoading = false;
      this.getInvoice(this.receiptData);
    });
  }

  //get invoice number
  getInvoice(receiptDate) {
    let params = {
      rrn: receiptDate.txnDetails.rrn,
      txName: receiptDate.txnDetails.txName,
    }
    this.transactionService.getInvoiceNum(this.transactionService.getInvoice, params).subscribe(res => {
      (!_.isEmpty(res?.invoiceNumber)) ? this.invoiceVold = res.invoiceNumber : this.invoiceVold = '';
    });
  }

  timeSub(time) {
    if(_.isEmpty(time)) {
      return "";
    }
    let txTime = time.substring(0, time.length - 3);
    return txTime;
  }



  //check flag condition for invoice show
  get(invoice, flag) {
    return (flag == 'Y') ? this.invoiceVold : invoice
  }

  getImage(image){
    console.log("image", image);
    return `assets/images/merchantlogos/${image}`;
  }

  transMode(EntryMode) {
    let entryMode = EntryMode.substring(0, 2);
    return entryMode == "90" ? "SWIPE"
      : entryMode == "80" ? "SWIPE"
        : entryMode == "05" ? "CHIP"
          : entryMode == "01" ? "MANUAL"
          :entryMode == "10" ? "COF"
            : entryMode == "07" ? "CONTACTLESS"
            : entryMode == "91" ? "CONTACTLESS"
              : entryMode == "11" ? "CONTACTLESS"
              : entryMode == "71" ? "CONTACTLESS"
                : ""
  }

  transType(data) {
    console.log("trans Type", data);
    return (data == "DEBIT") ? "DEBIT"
            : ""
  }

  maskedPan(cardNumber) {
    //  return cardNumber
      return cardNumber.replace(/\w(?=\w{4})/g, "*");
    }

    cardLabel(cardLabel, transactionType){
      return (transactionType == "DEBIT") ? "DEBIT"
             : cardLabel
    }

  approval(approvalCode, txName, hostResponseCode){
    return (txName == "TICKET") ? "Local"
    : (hostResponseCode == "00") ? ""
    : approvalCode
  }

  transAmount(data) {
    return (data / 100);
  }

  isApproval(data) {
    return data == "00" || data == "000" || data == "00000" ? "APPROVED"
      : data == null ? ""
      : data == "85" ? "CARD OK"
      : data == "10" ? "PARTIAL APPROVAL"
        : "DECLINED"
  }

  sumTip(tipAmount, tipAdjust, tipAmountFee) {
    return (tipAmount + tipAdjust + tipAmountFee)
  }

  sumAmount(baseAmount, feeAmount) {
    if (!isNaN(baseAmount)) {
      baseAmount = parseFloat(baseAmount);
    }else if(isUndefined(baseAmount)){
      baseAmount = 0;
    }
    if (!isNaN(feeAmount)) feeAmount = parseFloat(feeAmount);
    return baseAmount + (feeAmount || 0);
  }

  withdrawAmount(purchasedAmt, baseAmount, tipAmount){
    return !isUndefined(purchasedAmt) &&  !_.isNull(purchasedAmt) && !isUndefined(tipAmount) ? (baseAmount + tipAmount) : baseAmount ;
  }

  sumDueAmount(amountDue, baseAmount, tipAmount){
    let withdrawAmount = !isUndefined(tipAmount) ? (baseAmount + tipAmount) : baseAmount ;
    return (_.isNumber(amountDue) || !_.isEmpty(amountDue)) && amountDue != 0 && amountDue != withdrawAmount;
  }
  
  errorHandleF(data) {
    return (data == null || data == "")? "" : data;
  }

  errorHandle(data) {
    return (data == null || data == "")? "" : data;
  }

  defaultName(data) {
    return (data == null || data == "")? "Fee" : data;
  }

  defaultLTL(data) {
    return (data == null || data == "")? "Local Tax" : data;
  }

  defaultSTL(data) {
    return (data == null || data == "")? "State Tax" : data;
  }

  defaultMFL(data) {
    return (data == null || data == "")? "Fee" : data;
  }

  defaultSCH(data) {
    return (data == null || data == "")? "Fee" : data;
  }

  cardholderName(data) {
    return (data == null || data == "")? "" : data;
  }

  getDate(data){
    //2022-08-17T00:00:00.000Z
    let date = data.slice(0, -14);
    return date;
  }

  pinBlock(pinBlock) {
    let result;
    switch (pinBlock) {
      case '0':
        result = ""
        break;
      case 'XXXXXXXXXXXXXXXX':
        result = ""
        break;
      case 'NOCVM':
        result = ""
        break;
      default:
        result = "PIN VERIFIED OK"
        break;
    }

    return result;
  }

  ebt_type(trans_type, processorCode) {
    return (processorCode == '000000') ? "Food"
    : (processorCode == '090000') ? "Cash"
    : ""
  }

  cashDis(disLabel){
    return (disLabel == null || disLabel == "")? "Cashback" : disLabel;
  }

  // getTxName(txName, ProcessCode, flag) {
  //   return (ProcessCode == '090000' && flag == 'Y') ? "EBT CASH"
  //   : (ProcessCode == '000000' && flag == 'Y') ? "EBT FOOD"
  //   : txName
  // }

  getTxName(txName, txType, hostResponseCode) {
    // console.log('txName', txName, 'ProcessCode',ProcessCode, 'flag', flag, 'txType', txType);
     txName = (txType == 'DEBIT') && (hostResponseCode != '00000') && (txName != 'BALANCE INQUIRY') ? txName = 'CASHLESS ATM  ' + txName : txName;
     return txName;
   }

  showVoidLabel(voidFlag, txName) {
    return voidFlag == 'Y' && !txName.startsWith('VOID') ? 'VOID' : '';
  }

  txHandle(data) {
    return (data == null || data == "") ? "" : data;
  }

  getFeeDetails(feeDetails, txDetails){
    let isFeeEnabled = feeDetails?.FEE;
    let forCustomFeeDiscloseFeeEnabled = feeDetails?.CF_DFC;
    let forSurchargeFeeDiscloseFeeEnabled = feeDetails?.SR_DF_CUS;
    if(isFeeEnabled == 1 && txDetails.tipAmount == 0 && txDetails.tipAdjAmount == 0){
      if(forCustomFeeDiscloseFeeEnabled == 0 || forSurchargeFeeDiscloseFeeEnabled == 0){
        if(txDetails.customFee != 0 || txDetails.surcharge != 0 || txDetails.serviceFee != 0) { return true } else return false;
      } else {
         return true;
      }
    } else {
      return true;
    }
  }
  showMaskedCardNum(data) {
    if(_.isEmpty(data)) {
      return "NA";
    } else {
      return _.padStart(data.substring(data.length - 4), 16, '*')
    }
  }

  isShowAmountDue(amount: any, tipAmount: number) {
    return (_.isNumber(amount) || !_.isEmpty(amount)) && amount != 0 && !_.isNull(tipAmount) && tipAmount != 0;
  } 

  isAmountValid(amount: number, txAmount?: number){
    return (_.isNumber(amount) || !_.isEmpty(amount)) && amount != 0 && (!_.isEmpty(txAmount) || amount != txAmount);
  }
}
