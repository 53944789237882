import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, ErrorHandler, Injector } from '@angular/core';
import { Observable, throwError } from "rxjs";
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, map,filter } from 'rxjs/operators';
import { apiLinks } from '../core/api-links/api-links';
import { Router,NavigationEnd  } from '@angular/router';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {

  constructor() { }

  handleError(error) {
    let errorMessage = {};

    if (error.error instanceof ErrorEvent) {
      errorMessage["message"] = error.error.message;
    } else if (error.error.errors instanceof Array) {
      errorMessage["status"] = "Error!";
      errorMessage["message"] = error.error.errors[0].message;
    } 
    else if (error instanceof HttpErrorResponse) {
      switch (error.status) {
        case 504:      //login
          errorMessage["status"] = error.status;
          errorMessage["message"] = "Gateway timeout";
          return throwError(errorMessage);

        case 500:
          errorMessage["status"] = error.status;
          errorMessage["message"] = error.error.message
            ? error.error.message
            : error.status;
          errorMessage["isFeeError"] = error.error.isFeeError?error.error.isFeeError:'';
          return throwError(errorMessage);
        default:
          errorMessage["status"] = error.status;
          errorMessage["message"] = error.error.message
            ? error.error.message
            : error.message ? error.statusText :error;
          errorMessage["isFeeError"] = error.error.isFeeError?error.error.isFeeError:'';
          return throwError(errorMessage);

      }
    } else {
      errorMessage["status"] = error.status;
      errorMessage["message"] = error.error.message
        ? error.error.message
        : error.message;
      errorMessage["isFeeError"] = error.error.isFeeError?error.error.isFeeError:'';
        
    }
    return throwError(errorMessage);
  }
}

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  currentRoute: string;
  constructor(private injector: Injector,
    private httpClient:HttpClient,
    private router: Router) {

      this.router.events.pipe(
        filter((event) => event instanceof NavigationEnd)
        ).subscribe((x:any) => {
          this.currentRoute = x.url; 
        });
     
     }
  handleError(error: Error): void {

    // console.log(error);
    console.log("error.message");
    console.log(error);
    console.log("error.stack");
    console.log(error.stack);
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;

    if (chunkFailedMessage.test(error.message)) {
        // if (confirm("New version available. Load New Version?")) {
            window.location.reload();
            return;
        // }
    }

  
    if(environment.enableClientError) {
      this.postCommonHttpClient(apiLinks.clientEmailAlert, { error:error.stack,errorName:`${this.currentRoute} - ${error.message}` });
    }

    
    // console.log(typeof error);
    // console.log(error instanceof Response);

    }

    postCommonHttpClient(url, data) {
      //console.log("----------------->",url, data);
      return this.httpClient.post(url, data).pipe(
        map((res: any) => {
         
          return res;
        })
      ).subscribe((res)=>{
        //console.log("----------------->",res);
      });

    }
}