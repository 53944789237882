
<p-toast position="top-right" [baseZIndex]="5000"></p-toast>
<app-loading *ngIf="isLoading"></app-loading>
<!-- dashboard count start -->
<!-- <app-dashboard-count></app-dashboard-count> -->

<app-filter-host-inventory-management id="home" (updateFilterData)="applyFilters($event)"
(refreshData)="refreshData($event)" *ngIf="!isLoading"
(selectedFilters)="applyFilter($event)" (batchStatus)="onCalenderOpen($event)"
[total]="total" [isMasterSel]="isMasterSel" [HostInventoryList]="removeObj" [updateStatus]="updateStatusSubject.asObservable()" [isEnableBulkUpdateTid]="isEnableBulkUpdateTid"
[fromDate]="filterData['fromDate']" [toDate]="filterData['toDate']"
[dateRange]="filterData['dateRange']" [rangeEvents]="dateRangeSubject.asObservable()"
(dateSelect)="onCalenderOpen($event)" (isHostFilterLoader)="isHostFilterLoader($event)" [selectedTidList]="selectedTidList"></app-filter-host-inventory-management>

<!-- <app-device-filter id="home"></app-device-filter> -->

<app-loading *ngIf="(!isLoading && searching) || isFilterSearch"></app-loading>

<div class="row chat-wrapper mt-3" *ngIf="listHostInventory.length != 0 && !isLoading && !searching && !isFilterSearch">
  <div class="col-md-12">
    <div class="card">
      <div class="card-body">
        <div class="row position-relative">
          <div class="col-lg-5 chat-aside border-lg-right">
            <div class="aside-content">
              <div class="aside-header">
              </div>
              <div class="card-header list-header width-heade-align d-flex">
                <div class="form-check form-check-flat form-check-primary" style="margin-left: -18px;">
                  <label placement="bottom" triggers="mouseenter:mouseleave" ngbPopover="{{'hostInventory.select' | translate}}" class="form-check-label">
                      <input type="checkbox" class="form-check-iform-check-labelnput" name="selectBox" value="h1"
                      (change)="checkUncheckAll($event)" [(ngModel)]="masterSelectBox">
                      <i class="input-frame"></i>
                  </label>
              </div>
                <p class="card-text mt-1 col-md-12 pl-0 text-left mt-2"><span class="font-weight-bold">
                  {{'HostInventory' | translate}} ({{exceptTids.length > 0 ? 'Selected all except '+exceptTids.length+' Tid(s)' : selectedTids.length > 0 ? 'Selected '+selectedTids.length+' Tid(s)' : total}})</span></p>
              </div>
              <div class="aside-body">
                <ul ngbNav #defaultNav="ngbNav" [(activeId)]="defaultNavActiveId" class="nav-tabs mt-3">
                  <li [ngbNavItem]="1">
                    <ng-template ngbNavContent>
                      <div #container class="container" class="ps" [perfectScrollbar]>
                        <p class="text-muted mb-1"></p>
                        <div (click)="selected(index)"  [class.active]="viewMode == index" class="ist-unstyled chat-list px-1 mb-2 back-ground-color back-hover"
                          *ngFor="let hostInventory of listHostInventory; let index = index">

                          <div class="chat-item pr-1">

                            <a (click)="getHostInvDetails(hostInventory)" class="d-flex align-items-center">
                              <div class="flex-grow">

                                <div>
                                  <div  class="d-flex justify-content-between">

                                    <div class="d-flex">
                                      <div class="form-check form-check-flat form-check-primary float-left">
                                        <label class="form-check-label">
                                            <input type="checkbox" class="form-check-input" name="selectBox"
                                            (change)="isAllSelected($event, hostInventory.tid,hostInventory.processorName)" [(ngModel)]="hostInventory.isSelected" value="{{hostInventory.id}}">
                                            <i class="input-frame"></i>
                                        </label>
                                      </div>
                                    <p class="text-body tx-size over-flow ml-1 float-left mt-2">{{hostInventory?.processorName}}</p>
                                    </div>

                                    <p  class="text-body mt-2 over-flow text-right tag" placement="bottom" triggers="mouseenter:mouseleave"
                                    ngbPopover="{{hostInventory.status}}">
                                      <span *ngIf="hostInventory?.status" class="tags" [ngClass]="displayColor(hostInventory.status)">{{hostInventory.status}}</span>
                                  </div>
                                  <hr>
                                  <div class="d-flex row justify-content-between">
                                    <div class="col-md-4">
                                      <p placement="top" triggers="mouseenter:mouseleave" ngbPopover="{{hostInventory?.tid}}"
                                        class="text-body over-flow ml-1">
                                        <span class="large-fontsize-label">
                                          <strong>{{'hostInventory.tid' | translate}}:</strong>
                                        </span>
                                        <span class="large-fontsize"> {{hostInventory?.tid}}</span>
                                      </p>
                                    </div>
                                    <div class="col-md-4 pl-0" *ngIf="userType == 'SYSTEM'">
                                      <p placement="top" triggers="mouseenter:mouseleave" ngbPopover="{{hostInventory?.ownerDba}}"
                                        class="text-body over-flow">
                                        <span class="large-fontsize-label">
                                          <strong>{{'customers.owner' | translate}}:</strong>
                                        </span>
                                        <span class="large-fontsize"> {{hostInventory?.ownerDba}}</span>
                                      </p>
                                    </div>
                                    <div class="col-md-4 text-right">
                                      <p placement="top" triggers="mouseenter:mouseleave" ngbPopover="{{hostInventory?.ownerId}}"
                                        class="text-body over-flow mr-1">
                                        <span class="large-fontsize-label">
                                          <strong>{{'hostInventory.ownerId' | translate}}:</strong>
                                        </span>
                                        <span class="large-fontsize"> {{hostInventory?.ownerId}}</span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div class="mt-2">
                        <ngb-pagination class="d-flex justify-content-end mob-media mt-4" [collectionSize]="collectionSize" [(page)]="page"
                          [maxSize]="5" [rotate]="true" [pageSize]="size" (pageChange)="getPage(page)"></ngb-pagination>
                    </div>
                    </ng-template>
                  </li> <!-- End chat tab-->
                </ul>
                <div [ngbNavOutlet]="defaultNav" class="mt-3"></div>
              </div>
            </div>
          </div>

          <div class="col-lg-7 chat-content">
            <app-loading *ngIf="isLoadingTidInfo"></app-loading>
            <!-- *ngIf="hiddenViewDetails" -->
            <div *ngIf="!isLoadingTidInfo">
              <div class="chat-header border-bottom pb-2">
                <div class="d-flex justify-content-between">
                  <div class="d-flex align-items-center">
                    <i class="feather icon-corner-up-left icon-md mr-2 ml-n2 text-muted d-lg-none"></i>
                    <div>
                      <h5>{{getViewHostInv?.processorName}}</h5>
                      <!-- <P><span class="text-muted">{{getViewUser?.user_from_customer?.user_type_role_from_user?.description}}</span></P>
                      <P><span
                          class="text-muted">{{getViewUser?.dba}}</span>
                      </P> -->
                    </div>
                  </div>
                  <!-- <div class="d-flex align-items-center mr-n1">
                    <div>
                      <h5>{{'customers.lastLogin' | translate}}</h5>
                      <P><span class="text-muted">{{(getlastLogin(getViewUser?.user_from_customer?.loggedDetails)
                          | dateTimePipe) || '-- -- ----'}}</span></P>
                    </div>
                  </div> -->
                </div>
              </div>

              <div class="d-flex justify-content-end mt-2 mb-2 back-ground-color p-2">
                <div class="row mr-2">
                  <button href="" (click)="isUpdateSingleTidStatus()" [disabled]="!isPermission(privileges.EDIT_HOST_INVENTORY)" class="btn btn-primary mr-2">
                    <i class="feather fast-forward" ngbTooltip="fast-forward"></i>{{ 'htmlText.statusUpdate' | translate }}</button>
                  <button href="" (click)="routeToEditMerchant(getViewHostInv)" [disabled]="!isPermission(privileges.EDIT_HOST_INVENTORY)"
                    class="btn btn-primary mr-2 ">
                    <i class="feather icon-edit-2" ngbTooltip="Edit"></i> {{'htmlText.edit' | translate}}
                  </button>
                </div>
                <!-- <div class="ml-md-2" *ngIf="user == 'SYSTEM'">
                  <div class="d-inline-block" ngbDropdown #myDrop="ngbDropdown">
                    <button class="btn btn-primary" id="dropdownManual" ngbDropdownToggle [disabled]="getViewUser?.user_from_customer?.status != 'ACTIVATED' || isCreateContractLoading">
                      <span *ngIf="isCreateContractLoading" class="spinner-border spinner-border-sm mr-1" role="status"
                        aria-hidden="true"></span>More</button>
                    <div ngbDropdownMenu aria-labelledby="dropdownManual">
                      <button ngbDropdownItem (click)="loginAs(getViewUser?.email,getViewUser?.user_from_customer?.secondaryPassword)"> <i data-feather="user" appFeatherIcon
                          class="icon-sm mr-2" style="color:red"></i><span style="color:red">Login As</span></button>
                      <button ngbDropdownItem (click)="viewContract(getViewUser?.customer_to_customer_processors?.customerId)" [disabled]="!fetchUserStatus(getViewUser)"> <i data-feather="file-text" appFeatherIcon
                        class="icon-sm mr-2"></i><span>Manage Contract</span></button>
                        <button ngbDropdownItem (click)="openGenerateInvoiceModal()" [disabled]="!fetchUserStatusInvoice(getViewUser)"> <i data-feather="file-text" appFeatherIcon
                          class="icon-sm mr-2"></i><span>Generate Invoice</span></button>
                    </div>
                  </div>
                </div> -->
              </div>

              <div class="chat-body">
                <div class=" mt-2 mb-2 back-ground-color p-2">
                  <div class="row col-md-12 mb-4 mt-4">
                    <div class="col-md-4 col-lg-4">
                      <div>
                        <h5 placement="bottom" triggers="mouseenter:mouseleave" ngbPopover="{{'hostInventory.processorName' | translate}}" class="over-flow">{{'hostInventory.processorName' | translate}}</h5>
                        <p placement="bottom" triggers="mouseenter:mouseleave" ngbPopover="{{getViewHostInv?.processorName || ''}}" class="tx-13 over-flow"><span class="tx-size text-muted">{{getViewHostInv?.processorName || ''}}</span></p>
                      </div>
                    </div>
                    <div class="col-md-4 col-lg-4">
                      <div>
                        <h5 placement="bottom" triggers="mouseenter:mouseleave" ngbPopover="{{'hostInventory.tid' | translate}}" class="over-flow">{{'hostInventory.tid' | translate}}</h5>
                        <p placement="bottom" triggers="mouseenter:mouseleave" ngbPopover="{{getViewHostInv?.tid}}" class="tx-13 over-flow"><span class="tx-size text-muted">{{getViewHostInv?.tid || ''}}</span></p>
                      </div>
                    </div>
                    <div class="col-md-4 col-lg-4">
                      <div>
                        <h5 placement="bottom" triggers="mouseenter:mouseleave" ngbPopover="{{'hostInventory.ownerId' | translate}}" class="over-flow">{{'hostInventory.ownerId' | translate}}</h5>
                        <p placement="bottom" triggers="mouseenter:mouseleave" ngbPopover="{{getViewHostInv?.ownerId}}" class="tx-13 over-flow"><span class="tx-size text-muted">{{getViewHostInv?.ownerId || ''}}</span></p>
                      </div>
                    </div>
                  </div>

                  <div class="row col-md-12 mb-4 mt-4">
                    <div class="col-md-4 col-lg-4">
                      <div>
                        <div>
                          <h5 placement="bottom" triggers="mouseenter:mouseleave" ngbPopover="{{'hostInventory.nearestDenomination' | translate}}" class="over-flow">{{'hostInventory.nearestDenomination' | translate}}</h5>
                          <p placement="bottom" triggers="mouseenter:mouseleave" ngbPopover="{{getViewHostInv?.nearestDenomination || ''}}" class="over-flow"><span class="tx-size text-muted">{{getViewHostInv?.nearestDenomination || ''}}</span></p>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-4 col-lg-4">
                      <div>
                        <div>
                          <h5 placement="bottom" triggers="mouseenter:mouseleave" ngbPopover="{{'hostInventory.cashBox1Denomination' | translate}}" class="over-flow">{{'hostInventory.cashBox1Denomination' | translate}}</h5>
                          <p placement="bottom" triggers="mouseenter:mouseleave" ngbPopover="{{getViewHostInv?.cashBox1Denomination || ''}}" class="tx-13 over-flow"><span class="tx-size text-muted">{{getViewHostInv?.cashBox1Denomination || ''}}</span></p>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 col-lg-4">
                      <div>
                        <div>
                          <h5 placement="bottom" triggers="mouseenter:mouseleave" ngbPopover="{{'hostInventory.cashBox1Total' | translate}}" class="over-flow">{{'hostInventory.cashBox1Total' | translate}}</h5>
                          <p placement="bottom" triggers="mouseenter:mouseleave" ngbPopover="{{getViewHostInv?.cashBox1Total || ''}}" class="tx-13"><span class="tx-size text-muted">{{getViewHostInv?.cashBox1Total || ''}}</span></p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row col-md-12 mb-4 mt-4">
                    <div class="col-md-4 col-lg-4">
                      <div>
                        <div>
                          <h5 placement="bottom" triggers="mouseenter:mouseleave" ngbPopover="{{'hostInventory.cashBox2Denomination' | translate}}" class="over-flow">{{'hostInventory.cashBox2Denomination' | translate}}</h5>
                          <p placement="bottom" triggers="mouseenter:mouseleave" ngbPopover="{{getViewHostInv?.cashBox2Denomination || ''}}" class="tx-13 over-flow"><span class="tx-size text-muted">{{getViewHostInv?.cashBox2Denomination || ''}}</span></p>
                        </div>
                      </div>
                    </div>
                      <div class="col-md-4 col-lg-4">
                      <div>
                        <div>
                          <h5 placement="bottom" triggers="mouseenter:mouseleave" ngbPopover="{{'hostInventory.cashBox2Total' | translate}}" class="over-flow">{{'hostInventory.cashBox2Total' | translate}}</h5>
                          <p placement="bottom" triggers="mouseenter:mouseleave" ngbPopover="{{getViewHostInv?.cashBox2Total || ''}}" class="tx-13"><span class="tx-size text-muted">{{getViewHostInv?.cashBox2Total || ''}}</span></p>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 col-lg-4">
                      <div>
                        <h5 placement="bottom" triggers="mouseenter:mouseleave" ngbPopover="{{'hostInventory.encMasterKey' | translate}}" class="over-flow">{{'hostInventory.encMasterKey' | translate}}</h5>
                        <p placement="bottom" triggers="mouseenter:mouseleave" ngbPopover="{{getViewHostInv?.encMasterKey}}" class="tx-13 over-flow"><span class="tx-size text-muted">{{getViewHostInv?.encMasterKey || ''}}</span></p>
                      </div>
                    </div>
                  </div>

                  <div class="row col-md-12 mb-4 mt-4">
                    <div class="col-md-4 col-lg-4">
                      <div>
                        <div>
                          <h5 placement="bottom" triggers="mouseenter:mouseleave" ngbPopover="{{'hostInventory.status' | translate}}" class="over-flow">{{'hostInventory.status' | translate}}</h5>
                          <p placement="bottom" triggers="mouseenter:mouseleave" ngbPopover="{{getViewHostInv?.status || ''}}" class="tx-13"><span class="tx-size text-muted">{{getViewHostInv?.status || ''}}</span></p>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 col-lg-4">
                      <div>
                        <div>
                          <h5 placement="bottom" triggers="mouseenter:mouseleave" ngbPopover="{{'hostInventory.cashBoxStatus' | translate}}" class="over-flow">{{'hostInventory.cashBoxStatus' | translate}}</h5>
                          <p placement="bottom" triggers="mouseenter:mouseleave" ngbPopover="{{getViewHostInv?.cashBoxStatus || ''}}" class="tx-13"><span class="tx-size text-muted">{{getViewHostInv?.cashBoxStatus || ''}}</span></p>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 col-lg-4">
                      <div>
                        <div>
                          <h5 placement="bottom" triggers="mouseenter:mouseleave" ngbPopover="{{'hostInventory.noTpns' | translate}}" class="over-flow">{{'hostInventory.noTpns' | translate}}</h5>

                          <div class="d-flex">
                            <p placement="bottom" triggers="mouseenter:mouseleave"
                            ngbPopover="{{getViewHostInv?.noTpns || ''}}"
                            class="tx-13 m-2">
                            <span class="tx-size text-muted">{{getViewHostInv?.tpnsCount || 0}}</span>
                            </p>

                            <button *ngIf="getViewHostInv?.tpnsCount" (click)="openBulkeditTidModal(getViewHostInv?.id)" [disabled]="!isPermission(privileges.EDIT_HOST_INVENTORY)"
                            class="btn btn-primary mr-2 ">
                            {{'hostInventory.ChangeTID' | translate}}
                            </button>
                          </div>


                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row col-md-12 mb-4 mt-4" *ngIf="getViewHostInv?.hostInventory_from_notes?.ownerNotes">
                      <div>
                        <div>
                          <h5 placement="bottom" triggers="mouseenter:mouseleave" ngbPopover="{{'hostInventory.ownerNotes' | translate}}" class="over-flow">{{'hostInventory.ownerNotes' | translate}}</h5>
                          <p placement="bottom" triggers="mouseenter:mouseleave" ngbPopover="{{getViewHostInv?.hostInventory_from_notes?.ownerNotes || ''}}" class="tx-13"><span class="tx-size text-muted">{{getViewHostInv?.hostInventory_from_notes?.ownerNotes || ''}}</span></p>
                        </div>
                      </div>
                  </div>

                  <div class="row col-md-12 mb-4 mt-4" *ngIf="getViewHostInv?.hostInventory_from_notes?.agentInfo">
                    <div>
                      <div>
                        <h5 placement="bottom" triggers="mouseenter:mouseleave" ngbPopover="{{'hostInventory.agentInfo' | translate}}" class="over-flow">{{'hostInventory.agentInfo' | translate}}</h5>
                        <p placement="bottom" triggers="mouseenter:mouseleave" ngbPopover="{{getViewHostInv?.hostInventory_from_notes?.agentInfo || ''}}" class="tx-13"><span class="tx-size text-muted">{{getViewHostInv?.hostInventory_from_notes?.agentInfo || ''}}</span></p>
                      </div>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- DATE FILTER MODEL -->
<ng-template #content let-modal>
  <div class="modal-header">
    <h5 class="modal-title font-weight-bold" id="modal-basic-title">{{'parameter.SelectTimePeriod' | translate}}</h5>
    <button type="button" class="close" aria-label="Close" (click)="closeDateSelectionFilter()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <ng-select [items]="dateFilterList" bindLabel="displayName" bindValue="name" placeholder="Date Selection" [clearable]="false"
        [(ngModel)]="dateSelectionTypes" (change)="selectedFilter($event, 'dateRange')">
    </ng-select>
    <div class="input-group mt-md-2" *ngIf="dateSelectionTypes=='Custom'">
      <input class="form-control" (dateSelect)="onDateSelection($event)" placeholder="From" ngbDatepicker [maxDate]="currentDate"
      #d="ngbDatepicker" [(ngModel)]="firstDate" readonly>
      <div class="input-group-append">
          <button class="btn btn-outline-primary mdi mdi-calendar-blank-outline"
              (click)="d.toggle()" type="button"></button>
      </div>
      <input class="form-control ml-md-4" placeholder="To" ngbDatepicker (dateSelect)="toDateSelection($event)"
          [maxDate]="currentDate" #p="ngbDatepicker" [minDate]="startingDate" [disabled]="!startingDate" [(ngModel)]="lastDate" readonly>
      <div class="input-group-append">
          <button class="btn btn-outline-primary mdi mdi-calendar-blank-outline"
              (click)="p.toggle()" type="button"></button>
      </div>
  </div>
  </div>
  <div class="modal-footer" *ngIf="dateSelectionTypes != 'Custom'">
    <button type="button" [disabled]="!(dateSelectionTypes)" class="btn btn-primary" data-dismiss="modal"
      (click)="onDateFilterSubmit()">{{'parameter.ShowReport' |
      translate}}</button>
  </div>

  <div class="modal-footer" *ngIf="dateSelectionTypes == 'Custom'">
    <button type="button" [disabled]="!startDate" class="btn btn-primary" data-dismiss="modal"
      (click)="onDateFilterSubmit()">{{'parameter.ShowReport' |
      translate}}</button>
  </div>
</ng-template>

<ng-template #OpenLoader class="text-center m-4" let-modal>
  <div class="modal-header d-flex justify-content-between">
    <div class="row thumbs_icon">
      <div class="ml-2">
        <h5 class="modal-title" id="exampleModalLongTitle"><span class="font-weight-bold text_baseline">{{'htmlText.isFilterAlertTitle'| translate}}</span>
        </h5>
      </div>
    </div>
  </div>
  <div class="modal-body text-center px-5 py-5">
    <h5 class="text-center mt-2 mb-2">{{'htmlText.isFilterAlert'| translate}}</h5>
  </div>
  <div class="text-right pb-2">
    <button (click)="modal.close();closeCall()"
      class="mt-2 text-right btn btn-danger mr-2">{{'htmlText.cancel'| translate}}</button>
  </div>
</ng-template>

