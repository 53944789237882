import { Injectable } from "@angular/core";
import { HttpClient, HttpEventType, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { apiLinks } from "../core/api-links/api-links";
import { AuthService } from "./auth.service";
import { ErrorHandlerService } from './error-handler.service';
import { Merchant } from '../core/model/merchant';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class MerchantService {

  getAllMerchantUrl = `${environment.apiUrl}/merchants`; //get Url
  postMerchantUrl = `${environment.apiUrl}/merchants`; // post Url
  getStoreUrl = `${environment.apiUrl}/stores/:merchantId`; // get store by merchantId
  updateMerchantByIdUrl = `${environment.apiUrl}/merchants/:merchantId`; // update Url
  deleteMerchantByIdUrl = `${environment.apiUrl}/merchants/:merchantId`; // delete Url
  getManufacturerUrl = `${environment.apiUrl}/manufacturers`; // get manufacture
  getApplicationSignUrl = `${environment.apiUrl}/applicationSignature`; // get application sign
  getDeviceModalsUrl = `${environment.apiUrl}/device-models-by-manufacturer/`; // get device model
  getEmail = `${environment.apiUrl}/getEmail/`; // get email
  getDBA = `${environment.apiUrl}/getDba/`; // get dba
  getProcessorUrl = `${environment.apiUrl}/processors`; // get processor
  getCustomerProcessorUrl = `${environment.apiUrl}/customerProcessors/`; // get Customer processor
  getProfileUrl = `${environment.apiUrl}/profiles`; // get profiles
  postCreateDefaultParam = `${environment.steamApiUrl}/create-default-param`;
  routingType = 'assets/data/routing_type.json';
  //deleteAllMerchantForMerchantIdUrl = `${environment.apiUrl}/`;
  getMerchantById = `${environment.apiUrl}/merchants/`;
  getMerchant = `${environment.apiUrl}/getMerchantById/`;
  tpnById = `${environment.apiUrl}/tpns/getTpn/`; // get tpn by Id
  getMerchantByTpnId = `${environment.apiUrl}/merchants/getMerchant/`;
  updateTpnById = `${environment.apiUrl}/tpns/:tpnId`;
  editTpnById = `${environment.apiUrl}/editTPN`;
  isoDeleteMerchant = `${environment.apiUrl}/isoDeleteMerchant`
  merchantDataById = `${environment.apiUrl}/getmerchant/`;
  editMerchantById = `${environment.apiUrl}/editMerchant`;
  getStoreDataById= `${environment.apiUrl}/getStoreById/`;
  editStoreById = `${environment.apiUrl}/editStore`;
  addStoreById = `${environment.apiUrl}/stores`;
  isoDeleteStore = `${environment.apiUrl}/deleteStore`;
  routingMode = 'assets/data/routing_mode.json';
  transCount = `${environment.apiUrl}/transCount/`;
  getIsoAgents = `${environment.apiUrl}/getIsoAgents`;

  constructor(private handleError: ErrorHandlerService,
    private httpClient: HttpClient) { }

  getAllRoutingType() {
    return this.httpClient.get(this.routingType).pipe(
      map((res: any) => {
        return res;
      }),
    );
  }

  getAllRoutingMode(){
    return this.httpClient.get(this.routingMode).pipe(
      map((res: any) => {
        return res;
      }),
    );
  }

  getAllData(httpReqUrl): Observable<Merchant[]> {
    return this.httpClient.get<Merchant>(httpReqUrl).pipe(
      map((res: any) => {
        return res.data;
      }),
      catchError(this.handleError.handleError)
    );
  }

  // get Merchant List
  getAllMerchant(isFilter) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append("size","20");
    Object.keys(isFilter).forEach(function (key) {
      httpParams = httpParams.append(key, isFilter[key]);
    });
    return this.httpClient.get(`${this.getAllMerchantUrl}`, { params: httpParams }).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.handleError.handleError)
    );
  }

  // Get Email
  get_Email(httpReqUrl, parameter) {
    httpReqUrl = httpReqUrl + parameter.email
    return this.httpClient.get(httpReqUrl).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.handleError.handleError)
    );
  }

  // Get DBA
  get_Dba(httpReqUrl, parameter) {
    httpReqUrl = httpReqUrl + parameter.dba
    return this.httpClient.get(httpReqUrl).pipe(
      map((res: any) => {
        return res.data;
      }),
      catchError(this.handleError.handleError)
    );
  }

  // create Merchant
  createMerchant(data) {
    return this.httpClient.post(this.postMerchantUrl, data).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.handleError.handleError)
    );
  }

  // create_default_param
  create_default_param(data) {
    return this.httpClient.post(this.postCreateDefaultParam, data).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.handleError.handleError)
    );
  }

  // Update Merchant
  updateMerchantById(id, data) {
    return this.httpClient.put(`${this.updateMerchantByIdUrl}/${id}`, data).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.handleError.handleError)
    );
  }

  // Delete Merchant
  deleteMerchantById(data) {
    return this.httpClient.post(`${this.deleteMerchantByIdUrl}`, data).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.handleError.handleError)
    );
  }

  getStoreName(httpReqUrl, parameter) {
  //  console.log(parameter);
    httpReqUrl = httpReqUrl + parameter.merchantId;
   // console.log(httpReqUrl);
    return this.httpClient.get(httpReqUrl).pipe(
      map((res: any) => {
        return res.data;
      }),
      catchError(this.handleError.handleError)
    );
  }

  getById(httpReqUrl, parameter) {
     httpReqUrl = httpReqUrl + parameter.tpnId;
      return this.httpClient.get(httpReqUrl).pipe(
        map((res: any) => {
          return res.data;
        }),
        catchError(this.handleError.handleError)
      );
  }

  getMerchantByTpn(httpReqUrl, parameter) {
    httpReqUrl = httpReqUrl + parameter.tpnId;
     return this.httpClient.get(httpReqUrl).pipe(
       map((res: any) => {
         return res.data;
       }),
       catchError(this.handleError.handleError)
     );
 }

   // Update Tpns
   updateTpnBy_Id(id, data) {
    return this.httpClient.put(`${this.updateTpnById}/${id}`, data).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.handleError.handleError)
    );
  }

    // edit TPN
    EditTpn(data) {
      return this.httpClient.post(this.editTpnById, data).pipe(
        map((res: any) => {
          return res;
        }),
        catchError(this.handleError.handleError)
      );
    }

    deleteMerchantIso(){
      return this.httpClient.get(this.isoDeleteMerchant).pipe(
        map((res: any) => {
          return res;
        }),
        catchError(this.handleError.handleError)
      )
    }

  //get Merchant by Id
  merchantById(httpReqUrl, parameter) {
    //console.log("parameter",parameter);
    httpReqUrl = httpReqUrl + parameter.merchantId;
    //console.log(httpReqUrl);
     return this.httpClient.get(httpReqUrl).pipe(
       map((res: any) => {
         return res.data;
       }),
       catchError(this.handleError.handleError)
     );
 }

     // edit merchant
     EditMerchant(data) {
      return this.httpClient.post(this.editMerchantById, data).pipe(
        map((res: any) => {
          return res;
        }),
        catchError(this.handleError.handleError)
      );
    }

    getStoreData(httpReqUrl, parameter) {
      httpReqUrl = httpReqUrl + parameter.id;
       return this.httpClient.get(httpReqUrl).pipe(
         map((res: any) => {
           return res.data;
         }),
         catchError(this.handleError.handleError)
       );
   }

   EditStore(data) {
    return this.httpClient.post(this.editStoreById, data).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.handleError.handleError)
    );
  }

  AddStore(data) {
    return this.httpClient.post(this.addStoreById, data).pipe(
      map((res:any) => {
        return res;
      }),
      catchError(this.handleError.handleError)
    );
  }

    // Get TransCount
    get_transCount(httpReqUrl, parameter) {
      httpReqUrl = httpReqUrl + parameter.tpn
      return this.httpClient.get(httpReqUrl).pipe(
        map((res: any) => {
          return res.data;
        }),
        catchError(this.handleError.handleError)
      );
    }

}
