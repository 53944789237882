import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { AuthService } from "../../../../providers/auth.service";
import { Privileges } from "../../../../core/model/privileges.enum";
import { Modules } from "../../../../core/model/modules.enum";
import { CommonService } from "../../../../providers/common.service";
import { apiLinks } from "../../../../core/api-links/api-links";
import { Observable, Subject, Subscription, concat, of } from "rxjs";
import * as _ from "lodash";
import * as moment from "moment";
import {
  NgbModal,
  NgbModalOptions,
  NgbModalRef,
} from "@ng-bootstrap/ng-bootstrap";
import { DownloaderLoaderComponent } from "src/app/shared/components/downloader-loader/downloader-loader.component";
import { TILDE } from "@angular/cdk/keycodes";
import { isUndefined, isEmpty, get, pickBy, reduce, some } from "lodash";
import { BulkUploadHostInventoryComponent } from "../bulk-upload-host-inventory/bulk-upload-host-inventory.component";
import { BulkStatusUpdateComponent } from "../bulk-status-update/bulk-status-update.component";
import { catchError, debounceTime, map, switchMap, tap } from "rxjs/operators";

const dateFilterTypes = {
  Month: "Last 30 days",
  Week: "This Week",
  Yesterday: "Yesterday",
  Today: "Today",
};

@Component({
  selector: "app-filter-host-inventory-management",
  templateUrl: "./filter-host-inventory-management.component.html",
  styleUrls: ["./filter-host-inventory-management.component.scss"],
})
export class FilterHostInventoryManagementComponent implements OnInit {
  hostInventryPrivilege: any;
  privileges = Privileges;
  processorList: any;
  isShow: boolean = false;
  filterQuery: any = {};
  apiName = "hostInventory";
  hostInventoryStatus: string[];

  orderBy = [
    { field: "updatedAt", order: "ASC" },
    { field: "updatedAt", order: "DESC" },
  ];

  @Output() updateFilterData = new EventEmitter<any>();
  @Output() selectedFilters = new EventEmitter<any>();
  @Output() refreshData = new EventEmitter<any>();
  @Output() dateSelect = new EventEmitter<any>();
  @Output() batchStatus = new EventEmitter<any>();
  @Output() isHostFilterLoader = new EventEmitter<any>();
  @Input() rangeEvents: Observable<void>;
  @Input() fromDate: any;
  @Input() toDate: any;
  @Input() dateRange: any;
  @Input() paramDest: any;
  @Input() total: number;
  @Input() listHostInventory: any = [];
  @Input() HostInventoryList: any = [];
  @Input() updateStatus: Observable<void>;
  @ViewChild("OpenLoader") OpenLoader: ElementRef;
  @Input() tidSelecedLength: number;
  @Input() isMasterSel:boolean;
  @Input() selectedTidList = { exceptTids: [], selectedTids: [], isSelectAll: false};
  @Input() isEnableBulkUpdateTid = false;
  modalReference: NgbModalRef;
  modalReferenceForOpenBatch: NgbModalRef;
  statusList: any[];
  selectedProcessor = [];
  selectedOrder = [];
  selectedstatus: string[];
  selectedDate: string;
  eventsSubject: Subject<void> = new Subject<void>();
  is_filter: boolean = false;


  private rangeEventsSubscription: Subscription;
  quickUpdateStatusSubscription: Subscription;

  filterQueryForInput: {};
  @Input() filterData: any = {};
  messageService: any;
  modalRef;
  allHostInventoryData: any;
  selectedCustomerId = [];
  userType = "";
  customersDba$: Observable<any[]>;
  inputDba$ = new Subject<string>();
  isCustomersLoading = false;
  agent$: Observable<any[]>;
  searchedAgent = [];
  isAgentLoading = false;
  inputAgent$ = new Subject<string>();
  owner$: Observable<any[]>;
  searchedOwner = [];
  isOwnerLoading = false;
  inputOwner$ = new Subject<string>();
  isDisableSearchButton = false;
  disableButtonContent = '';

  
  constructor(
    private authService: AuthService,
    private commonService: CommonService,
    private ngbModal: NgbModal
  ) {}

  ngOnInit(): void {
    this.userType = this.authService.getUser.userType;
    this.filterQuery = { ...this.filterData };
    this.hostInventryPrivilege = this.authService.getPrivileges(
      Modules.HOST_INVENTORY
    ).features;

    if (this.rangeEvents) {
      this.rangeEventsSubscription = this.rangeEvents.subscribe((data) => {
        //console.log(data);
        this.filterQuery["fromDate"] = data["fromDate"];
        this.filterQuery["toDate"] = data["toDate"];
        this.filterQuery["dateRange"] = data["dateRange"];

        if (!isEmpty(data["fromDate"])) {
          this.selectedDate =
            moment(data["fromDate"]).format("MM-DD-YYYY") +
            " - " +
            moment(data["toDate"]).format("MM-DD-YYYY");
        } else if (data["dateRange"] == "None") {
          data["dateRange"] = "";
          this.clearFilterDate();
        } else if (!isEmpty(data["dateRange"])) {
          this.selectedDate = dateFilterTypes[data["dateRange"]];
        }
      });
    }

    const quickUpdateStatusSubscription = this.updateStatus.subscribe(
      (value) => {
        this.total = 1;
        this.listHostInventory = value;
        console.log("quickUpdateStatusSubscription >>>>>>>>>>>>>", value);
        this.openStatusUpdateModal();
      }
    );

    this.getAllProcessors();
    this.applyPreviousFilters();
    this.getAllStatusOnHostInventory();
    this.getSearchedDbas();
    this.getSearchedAgent();
    this.getSearchedOwner();
  }

  isPermission(privilege) {
    return this.hostInventryPrivilege.indexOf(privilege) != -1;
  }

  getAllProcessors() {
    this.commonService
      .getCommonHttpClientParams(apiLinks.getProcessors)
      .subscribe(
        (res) => {
          this.processorList = get(res,'data.rows').filter(processor => processor.name != 'BPAY');
        },
        (err) => {}
      );
  }

  // HostInventory Get Status Data
  getAllStatusOnHostInventory() {
  //  console.log(">>>>>>>>>>>> status");
    this.commonService
      .getCommonHttpClientParams(apiLinks.getStatusHostInventory)
      .subscribe(
        (res) => {
          this.hostInventoryStatus = res.data;
      //    console.log("res >>>>>>>>>>>>>>", this.hostInventoryStatus);
        },
        (err) => {
          console.log("err", err);
        }
      );
  }

  toggleDisplay() {
    this.isShow = !this.isShow;
  }

  updateFilter(filterData) {
    this.updateFilterData.emit(filterData);
  }

  hostFilterLoader(loader) {
    this.isHostFilterLoader.emit(loader);
  }

  selectedFilter(data, propertyName) {
    this.isDisableSearchButton = false;
    // console.log("==========>",data, propertyName);

    this.filterQuery["isFilter"] = true;
    this.filterQuery["fromDate"] = this.fromDate ? this.fromDate : "";
    this.filterQuery["toDate"] = this.toDate ? this.toDate : "";
    this.filterQuery["dateRange"] = this.dateRange ? this.dateRange : "";

    //  console.log(propertyName, data);
    if (_.isUndefined(data) || _.isEmpty(data)) {
      this.filterQuery[propertyName] = "";
    } else if (propertyName === "processorId") {
      this.filterQuery[propertyName] = _.map(data, "id");
    } else if (propertyName === "dateRange") {
      this.filterQuery[propertyName] = data.name;
    } else if (propertyName === "sort") {
      this.filterQuery[propertyName] = data.order;
    } else if (propertyName === "status") {
      this.filterQuery[propertyName] = data.status;
    } else if(propertyName === "customerId") {
      this.filterQuery[propertyName] = _.map(data, "id");
    } else if (propertyName === "hostInventoryAgentInfo" || propertyName === "hostInventoryOwnerNotes" ) {
      this.filterQuery[propertyName] = data;
      const agentInfoLength = (get(this.filterQuery, 'hostInventoryAgentInfo', '')).length;
      const ownerNotesLength = (get(this.filterQuery, 'hostInventoryOwnerNotes', '')).length;

      this.isDisableSearchButton = !(
        (agentInfoLength === 0 && ownerNotesLength > 2) ||
        (ownerNotesLength === 0 && agentInfoLength > 2) || (ownerNotesLength > 2 && agentInfoLength > 2)
      );
      this.disableButtonContent = this.isDisableSearchButton ? `${(ownerNotesLength == 0 || ownerNotesLength > 2) ? 'Agent Info' : 'Owner Notes'} should be 3 Characters` : '';

    } 

    //console.log(this.filterQuery);
    this.filterQuery["search"] = false;

    this.selectedFilters.emit(
      _.pickBy(this.filterQuery, function (value, key) {
        return !(value === "");
      })
    );
  }

  clear() {
    //  console.log("clear, date");

    this.filterQuery = { isFilter: false };

    this.selectedProcessor = [];
    this.selectedOrder = [];
    this.selectedstatus = [];
    this.selectedCustomerId = [];
    this.searchedAgent = [];
    this.searchedOwner = [];

    this.eventsSubject.next();
    this.clearFilterDate();

    this.filterQuery["search"] = true;
    this.selectedFilters.emit(
      _.pickBy(this.filterQuery, function (value, key) {
        return !(value === "");
      })
    );
    this.isFilterApply();
    console.log("this.filterQuery clear", this.filterQuery);
  }

  clearFilterDate() {
    this.selectedDate = null;
    this.dateSelect.emit(false);
  }

  openDateRangeModel() {
    this.batchStatus.emit(true);
  }

  search() {
    this.filterQuery["isFilter"] = true;
    this.filterQuery["fromDate"] = this.fromDate ? this.fromDate : "";
    this.filterQuery["toDate"] = this.toDate ? this.toDate : "";
    this.filterQuery["dateRange"] = this.dateRange ? this.dateRange : "";
    this.filterQuery["search"] = true;

    this.selectedFilters.emit(
      _.pickBy(this.filterQuery, function (value, key) {
        return !(value === "");
      })
    );
    this.filterQueryForInput = this.filterQuery;
    this.isFilterApply();
   // console.log("this.filterQuery >>>>>>>>>>", this.filterQuery);
  }

  ngOnDestroy() {
    if (this.rangeEvents) {
      this.rangeEventsSubscription.unsubscribe();
    }
  }

  showAlert(data, alertType) {
    this.messageService.add({
      severity: alertType,
      summary: data.status,
      detail: data.message,
    });
  }

  refreshPage() {
    //this.transRefresh.refresh.next(true);
    this.refreshData.emit("refresh");
  }

  fillDateInputs() {
    if (this.filterQuery.fromDate) {
      this.filterQuery["fromDate"] = this.fromDate;
      this.filterQuery["toDate"] = this.toDate;
      this.selectedDate =
        moment(this.filterQuery["fromDate"]).format("MM-DD-YY") +
        " - " +
        moment(this.filterQuery["toDate"]).format("MM-DD-YY");
    }
    if (this.filterQuery["dateRange"]) {
      if (this.filterQuery["dateRange"] == "None") return;
      this.selectedDate = dateFilterTypes[this.filterQuery["dateRange"]];
    }
  }

  applyPreviousFilters() {
    if (!isEmpty(this.filterData)) {
      this.selectedProcessor = this.filterData.processorIds || null;
      this.isShow = true;
      this.fillDateInputs();
    }
  }

  // DOWNLOAD CLICK FUNCTION
  allData() {
    this.openLoaderModal();
    let downloadQuery = this.filterQuery;
    if (!isEmpty(this.filterData)) {
      downloadQuery["searchTerm"] = this.filterData["searchTerm"];
    }
    downloadQuery["page"] = 1;
    downloadQuery["download"] = true;
    downloadQuery["isFilter"] = true;

   // console.log("download >>>>>>>>>>>>", downloadQuery);
  //  console.log("download >>>>>>>>>>>>", downloadQuery);

    this.commonService
      .getCommonHttpClientQueryParams(apiLinks.hostInventory, downloadQuery)
      .subscribe(
        (res) => {
          downloadQuery["download"] = false;
          this.allHostInventoryData = res;
          this.download(this.allHostInventoryData);
        },
        (err) => {
          downloadQuery["download"] = false;
          console.log(err);
        }
      );
  }

  // OPEN DOWNLOAD LOADING MODEL
  openLoaderModal() {
    window.scroll(0, 0);
    let ngbModalOptions: NgbModalOptions = {
      backdrop: "static",
      keyboard: false,
      centered: true,
      size: "lg",
    };
    this.modalRef = this.ngbModal.open(
      DownloaderLoaderComponent,
      ngbModalOptions
    );
    this.modalRef.componentInstance.isLoading = true;
    this.modalRef.componentInstance.body = "Downloading";
  }

  download(hostInventoryData) {
    let hostInventorys = [];
    let fileName =
      "HostInventory_Report_" + moment().format("DDMMYYYYHHmmss") + ".xlsx";
    for (let hostInventory of hostInventoryData.data.items) {
      hostInventorys.push({
        tid: hostInventory.tid,
        ownerId: hostInventory.ownerId,
        stan: hostInventory.stan,
        processorName: hostInventory.processorName,
        nearestDemo: hostInventory.nearestDenomination,
        cashBox1Demo: hostInventory.cashBox1Denomination,
        cashBox1Total: hostInventory.cashBox1Total,
        cashBox2Demo: hostInventory.cashBox2Denomination,
        cashBox2Total: hostInventory.cashBox2Total,
        status: hostInventory.status,
        noTpn: hostInventory.tpnsCount,
        tpnsList: hostInventory.tpnsList,
        cashBoxSta: hostInventory.cashBoxStatus,
        createdOn: this.estTimeConvertCreatedOn(hostInventory.createdAt),
        ownerDba: hostInventory.ownerDba,
        agentInfo: get(hostInventory, 'hostInventory_from_notes.agentInfo', ''),
        ownerNotes: get(hostInventory, 'hostInventory_from_notes.ownerNotes', '')
      });
    }

    // reduce( hostInventoryData.data.items, (result, value)=>{
    //   value['createdOn'] = this.estTimeConvertCreatedOn(value.createdOn);
    //   result.push(value);
    //   return result;
    // }, []);

    let worksheetData = {
      worksheet: [
        {
          name: "Host Inventory",
          columns: [
            { header: "TID", key: "tid", width: 25 },
            { header: "OWNER ID", key: "ownerId", width: 20 },
            { header: "STAN", key: "stan", width: 20 },
            { header: "PROCESSOR NAME", key: "processorName", width: 30 },
            { header: "Owner", key: "ownerDba", width: 30 },
            { header: "NEAREST DENOMINATION", key: "nearestDemo", width: 25 },
            { header: "CASHBOX1 DENOMINATION", key: "cashBox1Demo", width: 25 },
            { header: "CASHBOX1 TOTAL", key: "cashBox1Total", width: 25 },
            { header: "CASHBOX2 DENOMINATION", key: "cashBox2Demo", width: 25 },
            { header: "CASHBOX2 TOTAL", key: "cashBox2Total", width: 25 },
            { header: "STATUS", key: "status", width: 25 },
            { header: "CREATED ON (EST)", key: "createdOn", width: 30 },
            { header: "NO TPNs", key: "noTpn", width: 10 },
            {
              header: "TPNs",
              key: "tpnsList",
              width: 80,
              sytle: { alignment: { wrapText: true } },
            },
            { header: "CASHBOX STATUS", key: "cashBoxSta", width: 25 },
            { header: "Agent Info", key: "agentInfo", width: 80, sytle: { alignment: { wrapText: true } }, },
            { header: "Owner Notes", key: "ownerNotes", width: 80, sytle: { alignment: { wrapText: true } }, },
          ],
          rows: hostInventorys,
          isRow: 0,
        },
      ],
      fileName: fileName,
    };
    this.commonService.downloadAsExcel(worksheetData).then((res) => {
      this.modalRef.close();
    });
  }

  estTimeConvertCreatedOn(date) {
    return moment.tz(date, "America/New_York").format("MM-DD-YYYY HH:mm:ss");
  }

  openUploadModal() {
    this.modalRef = this.ngbModal
      .open(BulkUploadHostInventoryComponent, { size: "lg" })
      .result.then((result) => {
        if (_.isUndefined(result)) {
          return;
        }
        if (result.status == "Success") {
          this.showAlert(result, "success");
        }
      })
      .catch((res) => {});
  }

  async isFilterSelected() {
    this.openStatusUpdateModal();
  }

  openStatusUpdateModal() {
    let statusUpdateFilterQuery = this.filterQuery;
    if (!isEmpty(this.filterData)) {
      statusUpdateFilterQuery["searchTerm"] = this.filterData["searchTerm"];
    }
    statusUpdateFilterQuery["page"] = 1;
    statusUpdateFilterQuery["isFilter"] = true;

    let modalRef = this.ngbModal.open(BulkStatusUpdateComponent, {
      size: "md",
      ariaLabelledBy: "modal-basic-title",
      centered: true,
      backdrop: "static"
    });
    modalRef.componentInstance.totalTids = this.selectedTidLength();
    modalRef.componentInstance.filterQuery = this.filterQuery;
    modalRef.componentInstance.StatusUpdatefilterData = statusUpdateFilterQuery;
    modalRef.componentInstance.selectedTidList = this.selectedTidList
    modalRef.componentInstance.isBulkUpdate = true;

    modalRef.result
      .then((result) => {
        if (_.isUndefined(result)) {
          return;
        }
        if (result.status == "Success") {
          this.showAlert(result, "success");
        }
      })
      .catch((res) => {});
  }

  openUpdateWarning() {
    this.modalReferenceForOpenBatch = this.ngbModal.open(this.OpenLoader, {
      ariaLabelledBy: "modal-basic-title",
      centered: true,
      backdrop: "static",
      keyboard: false,
    });
  }

  selectedTidLength(){
    if(!_.isEmpty(_.get(this.selectedTidList,'exceptTids'))){
      return this.total - this.selectedTidList.exceptTids.length;
    }else if(!_.isEmpty(_.get(this.selectedTidList,'selectedTids'))){
      return this.selectedTidList.selectedTids.length;
    }else{
      return this.total;
    }
  }
  isFilterApply() {
    if (!isEmpty(this.filterQuery)) {
      this.is_filter =
        !isEmpty(this.filterQuery?.status) && this.filterQuery?.isFilter
          ? true
          : false;
    }
  }

  getSearchedDbas(){
    this.customersDba$ = concat(
      of([]),
      this.inputDba$.pipe(
        debounceTime(400),
        tap(() => this.isCustomersLoading = true),
        switchMap(term => {
          if(term == null || term.trim().length < 3) 
          {
            this.isCustomersLoading = false;
            return of([]);
          }
          return this.getAllDBA(term.trim()).pipe(
            (map(res => {
              return res.data;
            })
            ),
            catchError(() => of([])),
            tap(() => this.isCustomersLoading = false)
          )
        })
      )
    )
  };
  getAllDBA(term = '') {
    let queryParam = {
      ...(!_.isEmpty(term) && {searchedDbaEmailTerm: term}),
    }
    return this.commonService.getCommonHttpClientQueryParams(apiLinks.getCustomerDBAs, queryParam);
  }
  getSearchedAgent(){
    this.agent$ = concat(
      of([]),
      this.inputAgent$.pipe(
        debounceTime(400),
        tap(() => this.isAgentLoading = true),
        switchMap(term => {
          if(term == null || term.trim().length < 3) 
          {
            this.isAgentLoading = false;
            return of([]);
          }
          return this.getAllAgentInfo(term.trim(), 'agentInfo').pipe(
            (map(res => {
              return res.data;
            })
            ),
            catchError(() => of([])),
            tap(() => this.isAgentLoading = false)
          )
        })
      )
    )
  };

  getAllAgentInfo(term = '', filterType) {
    let queryParam = {
      ...(!_.isEmpty(term) && {searchedAgentInfoTerm: term, filterType: filterType}),
    }
    return this.commonService.getCommonHttpClientQueryParams(apiLinks.getAgentInfo, queryParam);
  }

  getSearchedOwner(){
    this.owner$ = concat(
      of([]),
      this.inputOwner$.pipe(
        debounceTime(400),
        tap(() => this.isOwnerLoading = true),
        switchMap(term => {
          if(term == null || term.trim().length < 3) 
          {
            this.isOwnerLoading = false;
            return of([]);
          }
          return this.getAllAgentInfo(term.trim(), 'ownerNotes').pipe(
            (map(res => {
              return res.data;
            })
            ),
            catchError(() => of([])),
            tap(() => this.isOwnerLoading = false)
          )
        })
      )
    )
  };
}
