import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CustomValidators } from "../../../../core/custom-validator/custom-validators";
import * as _ from 'lodash';
import {
  FormGroup,
  FormControl,
  FormBuilder,
  Validators,
} from "@angular/forms";
import { MerchantService } from "../../../../providers/merchant.service";
import { HostInventoryService } from "../../../../providers/host-inventory.service";
import { MessageService } from "primeng/api";
import { CommonService } from "src/app/providers/common.service";
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';
import { isUndefined } from 'lodash';
import { apiLinks } from 'src/app/core/api-links/api-links';
import { AuthService } from 'src/app/providers/auth.service';
import { UserType } from 'src/app/core/model/user-type.enum';
import { ownedBy } from '../../../../core/model/mcc';
import { Observable, Subject, concat, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InformationModalComponent } from 'src/app/shared/components/information-modal/information-modal.component';

@Component({
  selector: 'app-add-host-inventory',
  templateUrl: './add-host-inventory.component.html',
  styleUrls: ['./add-host-inventory.component.scss'],
  providers: [MessageService],
})
export class AddHostInventoryComponent implements OnInit {

  hostInventoryForm: FormGroup;
  searchProcessorList: any;
  isSpinnerTid:boolean;
  isSpinnerOwner:boolean;
  errMsgs: string;
  errMsgsOwner: string;
  isSelectedPro:boolean = false;
  processorLoading = false;
  disableMessage = true;
  isSaving:boolean = false;
  isEditHostInventory = false;
  editHostId: string;
  selectBox:any;
  stateOptions: any=[{
    label: "ACTIVE",
    value: "ACTIVE"
  },
  {
    label: "DEACTIVE",
    value: "DEACTIVATED"
  }

];
  edit_host: boolean = false;

  customersDba$: Observable<any[]>;
  inputDba$ = new Subject<string>();
  isCustomersLoading = false;
  editCustomerData = [];
  isOwnerReadOnly = false;
  editHostData: any;
  @ViewChild('TidConfirmationModal') TidConfirmationModal: ElementRef;
  confirmationModalHeader = '';
  confirmationModalBody= '';
  changeTidForm: FormGroup;
  selectedProcessId = '';
  selectedProcessName = '';
  processorbasedTidList = [];
  isConfirmationLoading = false;
  tid_Id = '';
  editedFormData: any;
  private tidProcessorSubject = new Subject<{ tid: string, processorId: string }>();
  private destroy$ = new Subject<void>();

  constructor(
    private formBuilder: FormBuilder,
    private merchantService: MerchantService,
    private HostInventoryService: HostInventoryService,
    private messageService: MessageService,
    public CommonService: CommonService,
    private router: Router,
    private route: ActivatedRoute,
    private auth: AuthService,
    private ngbModal: NgbModal,
  ) { }

  async ngOnInit(): Promise<void> {
    this.hostInventoryForm = this.hostInventoryInfoForm();
    this.changeTidForm = this.createTidChangeForm();
    this.loadProcessor();
    await this.editHostInventorySubscribe();
    this.getSearchedDbas();
    if(this.getUserType() != UserType.SYSTEM){
      this.isOwnerReadOnly = true;
    }
    if(this.getUserType() != UserType.SYSTEM && !this.isEditHostInventory){
      this.patchOwnerInfo();
    }
    this.patchTidValue();
  }

  patchOwnerInfo(){
    let currentCustomerId = _.get(this.auth.getUser,'customerId');
    this.getAllDBA('',currentCustomerId).toPromise().then(res => {
      this.editCustomerData = res.data;
      this.hostInventoryForm.get("customerId").patchValue(this.editCustomerData[0].id);
      this.getSearchedDbas();
    })
  }
  getUserType() {
    return this.auth.getUser.userType;
  }

  async editHostInventorySubscribe(){
    this.route.queryParams.subscribe(async (param) => {
      this.editHostData = param;
      let ownedById = '';
      if(!_.isEmpty(param.ownedBy) && !_.isEmpty(param.editHost)){
        await this.getAllDBA('',param.ownedBy).toPromise().then(res => {
          ownedById = res.data[0].id;
          this.editCustomerData = res.data;
          this.getSearchedDbas();
        })
      }
      if (!isUndefined(param.editHost) && param.editHost) {
        this.hostInventoryForm.patchValue({
          processorId: param.processorId,
          customerId: ownedById,
          cashBox1Denomination: param.cashBox1Denomination,
          cashBox1Total: param.cashBox1Total,
          cashBox2Denomination: param.cashBox2Denomination,
          cashBox2Total: param.cashBox2Total,
          nearestDenomination: param.nearestDenomination,
          ownerId: param.ownerId,
          tid: param.tid,
          encMasterKey: param.encMasterKey,
          selectBox: (param.cashBoxStatus == 'ENABLE') ? true : false,
          cashBoxStatus:(param.cashBoxStatus == 'ENABLE') ? 'ENABLE' : 'DISABLE',
          status:param?.status,
          ownerNotes: param.ownerNotes,
          agentInfo: param.agentInfo
        });
        this.isSelectedPro = true;
        this.editHostId = param.id;
        this.clearValidatorsAndValidity(this.hostInventoryForm, ['componentKey1', 'componentKey2']);
        this.isEditHostInventory = true;
      }
    });
  }

  clearValidatorsAndValidity(form: FormGroup, controlNames: string[]) {
    controlNames.forEach(controlName => {
      const control = form.get(controlName);
      control.clearValidators();
      control.updateValueAndValidity();
    });
  }
  
  hostInventoryInfoForm(): FormGroup {
    const alphaRegex = /^[a-zA-Z0-9]*$/;
    const lengthTidRegex = /^.{4,15}$/;
    const lengthOwnerRegex = /^.{4,8}$/;
    const lengthKey1Regex = /^.{32,32}$/;
    const lengthKey2Regex = /^.{32,32}$/;
    const numberRegex = /^[0-9]*$/;
    const lengthNearDemonRegex = /^.{1,8}$/;
    const lengthCashBox1Regex = /^.{1,9}$/;
    const lengthCashBox2Regex = /^.{1,9}$/;
    const lengthCashBox1DemonRegex = /^.{1,9}$/;
    const lengthCashBox2DemonRegex =  /^.{1,9}$/;
    const lengthNotesDispensed1Regex = /^.{1,12}$/;
    const lengthNotesDispensed2Regex = /^.{1,12}$/;
    const componentKey1 = /^[0-9a-fA-F]+$/;
    const componentKey2 = /^[0-9a-fA-F]+$/;

    return this.formBuilder.group({
      processorId: [[], Validators.required],
      customerId: [[], Validators.required],
      tid: ["", [Validators.required,
      CustomValidators.PatternValidator(alphaRegex, { hasAlphaNum: true }),
      CustomValidators.PatternValidator(lengthTidRegex, { hasMidlength: true })]],
      ownerId: ["", [Validators.required,
      CustomValidators.PatternValidator(alphaRegex, { hasAlphaNum: true }),
      CustomValidators.PatternValidator(lengthOwnerRegex, { hasOwnerRegex: true })]],
      componentKey1: ["", [Validators.required,
      CustomValidators.PatternValidator(componentKey1, { hasAlphaRegex: true }),
      CustomValidators.PatternValidator(lengthKey1Regex, { hasKey1Regex: true })]],
      componentKey2: ["", [Validators.required,
        CustomValidators.PatternValidator(componentKey2, { hasAlphaRegex: true }),
        CustomValidators.PatternValidator(lengthKey2Regex, { haskey2Regex: true })]],
      nearestDenomination: ["", [Validators.required,
      CustomValidators.PatternValidator(numberRegex, { hasNumber: true }),
      CustomValidators.PatternValidator(lengthNearDemonRegex, { haslengthNearDemonRegex: true })]],
      cashBox1Total: ["", [Validators.required,
      CustomValidators.PatternValidator(numberRegex, { hasNumber: true }),
      CustomValidators.PatternValidator(lengthCashBox1Regex, { haslengthCashBox1Regex: true })]],
      cashBox2Total: ["", [Validators.required,
      CustomValidators.PatternValidator(numberRegex, { hasNumber: true }),
      CustomValidators.PatternValidator(lengthCashBox2Regex, { haslengthCashBox2Regex: true })]],
      cashBox1Denomination: ["", [Validators.required,
      CustomValidators.PatternValidator(numberRegex, { hasNumber: true }),
      CustomValidators.PatternValidator(lengthCashBox1DemonRegex, { haslengthCashBox1DemonRegex: true })]],
      cashBox2Denomination: ["", [Validators.required,
      CustomValidators.PatternValidator(numberRegex, { hasNumber: true }),
      CustomValidators.PatternValidator(lengthCashBox2DemonRegex, { haslengthCashBox2DemonRegex: true })]],
      selectBox: [false],
      cashBoxStatus:['DISABLE'],
      status: ["ACTIVE"],
      encMasterKey:[""],
      agentInfo: ["", [Validators.maxLength(250), Validators.pattern("[A-Za-z0-9 _$'.,-@&\\n]{1,251}")]],
      ownerNotes: ["", [Validators.maxLength(250), Validators.pattern("[A-Za-z0-9 _$'.,-@&\\n]{1,251}")]]
    })
  }

    // Processor List
    loadProcessor() {
      this.processorLoading = true;
      this.merchantService.getAllData(this.merchantService.getProcessorUrl).subscribe((data: any) => {
        this.searchProcessorList = data.filter(processor => processor.name != 'BPAY');
        this.processorLoading = false;
      });
    }

    onkeypressNear(target){
      if(target.value.length == 8) return false;
    }
    onkeypresscashBox1(target){
      if(target.value.length == 9) return false;
    }
    onkeypresscashBox1Total(target){
      if(target.value.length == 9) return false;
    }
    onkeypresscashBox2De(target){
      if(target.value.length == 9) return false;
    }
    onkeypresscashBox2To(target){
      if(target.value.length == 9) return false;
    }

    getPredefinedFields(){
      return {
        // encMasterKey: this.hostInventoryForm.get('key1').value + this.hostInventoryForm.get('key2').value,
        isSwitchhostInventory: true
      }
    }

    removeProperty(data) {
      ['selectBox'].forEach(e => delete data[e]);
      return data;
    }

    onFormSubmit() {
        const formData = {
          ...this.getPredefinedFields(),
          ...this.hostInventoryForm.value,
        };
        let value = this.removeProperty(formData);
        console.log("formData",formData);
        if(!this.isEditHostInventory){
          console.log("Add Inventory", value);

          this.createHostInventory(value);
        }else{
          this.editHostInventory({...value,id:this.editHostId});
        }
    }

    createTidChangeForm(): FormGroup {
      return this.formBuilder.group({
        selectedProcessor: [null, Validators.required],
        selectedTid: [null, Validators.required],
      });
    }

    changedTid(event){
      this.tid_Id = event.id;
    }

    changedProcessor(event) {
      this.changeTidForm.get('selectedTid').patchValue(null);
      let params = {processorId:event.id, isShowCurrentCustomerTids: true, tidOwner: this.editHostData.ownedBy};
      this.selectedProcessId = event.id;
      this.selectedProcessName = event.name;
      this.HostInventoryService.getTids(this.HostInventoryService.getTidsById, params).subscribe(data => {
        this.processorbasedTidList = (this.selectedProcessId == this.editHostData.processorId) ? data.filter(hostList => hostList.tid != this.editHostData.tid) : data;
      })
    }

    async changeTid(){
      this.isConfirmationLoading = true;
      let tpnsList = this.editHostData.tpnsList.split(",").map(Number);
      let payload = {
        currentTid:this.editHostData.id,
        tpnlist: tpnsList,
        tid:this.changeTidForm.get('selectedTid').value,
        processId:this.selectedProcessId,
        tid_Id:this.tid_Id,
        processName:this.selectedProcessName,
        currentProcessorId:this.editHostData.processorId,
        isUpdateSafeProcessor: false,
        isChangeTidOwner: true
      }
      let checkSafeProcessorPayload = {
        tpnlist: tpnsList,
        currentProcessorId:this.editHostData.processorId,
        isChangeTidOwner: true
      }
      
      let isUpdateProcessor = false;
      if(this.selectedProcessId == this.editHostData.processorId){
        isUpdateProcessor = true;
      }
      try {
        const res = await this.CommonService.postCommonHttpClient(apiLinks.checkSafeProcessorCounts,checkSafeProcessorPayload).toPromise();
          let safeProcessorTpnList = _.get(res.data,'rows');
          let safeProcessorTpnCount = _.get(res.data,'count');
          let totalTpnCount = this.editHostData.tpnsCount;
          if(_.isEmpty(safeProcessorTpnList) || isUpdateProcessor){
            payload = {...payload, ...(isUpdateProcessor && {isUpdateProcessor: false})};
            this.replaceTid(payload);
          } else {
            let bodyContent = `There Are ${totalTpnCount} TPN(s) with this TID. Among which ${safeProcessorTpnCount} TPN(s) were ${this.editHostData?.processorName} as SafeProcessor`;
            let modalRef = this.ngbModal.open(InformationModalComponent, { ariaLabelledBy: 'modal-basic-title', centered: true, backdrop: 'static', keyboard: false });
            modalRef.componentInstance.header = `Change TID Confirmation`;
            modalRef.componentInstance.body = bodyContent;
            modalRef.componentInstance.isOpenChangeTidModal = true;
            modalRef.componentInstance.isShowPartialUpdateButton = false;
            modalRef.componentInstance.partialUpdateButtonContent = `Update W/O SafeProcessor(${totalTpnCount - safeProcessorTpnCount})`;
            modalRef.componentInstance.overAllUpdateButtonContent = `UpdateAll(${totalTpnCount})`;
            modalRef.result.then((result) => {
              if (result == 'updateOverAll') {
                payload.isUpdateSafeProcessor = true;
                this.replaceTid(payload);
              } else if (result == 'close') {
                this.isConfirmationLoading = false;
              }
            })
          }
        } catch (err) {
          this.showAlert(
            { status: "error", message: err.message },
            "error"
          );
          this.isConfirmationLoading = false;
        }
    }

    replaceTid(payload) {
      this.CommonService.postCommonHttpClient(apiLinks.replaceTID, payload).subscribe(res => {
        this.editHostinventoryData(this.editedFormData);
        this.isConfirmationLoading = false;
        this.showAlert(
          { status: "success", message: res.message },
          "success"
        );
        setTimeout(() => {
          this.ngbModal.dismissAll();
        }, 500);
  
      }, (err) => {
        this.isConfirmationLoading = false; 
        this.showAlert(
          { status: "error", message: err.message },
          "error"
        );
      })
    }

    editHostInventory(formData){
      this.editedFormData = formData;
      this.isSaving = true;
      if(formData.customerId != _.get(this.editHostData, 'ownedBy', '')  &&  _.get(this.editHostData,'tpnsCount','0') != '0'){ 
        let modalRef = this.ngbModal.open(this.TidConfirmationModal, { ariaLabelledBy: 'modal-basic-title', centered: true, backdrop: 'static', keyboard: false });
        this.confirmationModalHeader = `Change Owner Confirmation`;
        this.confirmationModalBody = `Currently, ${this.editHostData.tid} is assigned to ${this.editHostData.tpnsCount} TPN(s). Before proceeding, please change the TID for these ${this.editHostData.tpnsCount} TPN(s) and then proceed with the action.`;
        modalRef.result.then((result) => {
          if(result == 'cancel'){
            this.isSaving = false;
          }
        })
      }else{
        this.editHostinventoryData(formData);
      }
      
    }
    
    editHostinventoryData(formData){
      this.HostInventoryService.editInventory(formData).subscribe(
        (res) => {
          console.log(res);
          let successMsg = {
            message: res.message,
            status: "success",
          };
          this.showAlert(successMsg, "success");
          setTimeout(() => {
            this.isSaving = false;
            this.hostInventoryForm.reset();
            this.router.navigate(['/list-host-inventory/']);
          }, 1000);
        },
        (err) => {
          console.log(err);
          const alertType = err.status == 411 ? "warn" : "error";
          this.showAlert(err, alertType);
          this.isSaving = false;
        }
      );
    }

    createHostInventory(formData) {
      this.isSaving = true;
      this.HostInventoryService.createHostInven(formData).subscribe(
        (res) => {
          let successMsg = {
            message: res.message,
            status: "success",
          };
          this.showAlert(successMsg, "success");
          setTimeout(() => {
            this.isSaving = false;
            this.hostInventoryForm.reset();
            this.router.navigate(['/list-host-inventory/']);
          }, 1000)
        },
        (err) => {
          console.log(err);
          this.showAlert(err, "error");
          this.isSaving = false;
        }
      );
    }

    tidFieldExistenceCheck(event) {
      this.errMsgs = '';
      console.log(this.hostInventoryForm);
      if(this.hostInventoryForm.get('tid').valid && this.hostInventoryForm.get('processorId').valid){
        let params = {
          tid: this.hostInventoryForm.get('tid').value,
          processorId: this.hostInventoryForm.get('processorId').value
        }
        if(params.tid != this.editHostData.tid){
          this.isSpinnerTid = true;
          this.tidProcessorSubject.next(params);
        }
      }
    }

    patchTidValue(){
      this.tidProcessorSubject.pipe(
        debounceTime(300), 
        distinctUntilChanged(),
        switchMap(params => {
          this.destroy$.next();
          this.destroy$.complete();
    
          return this.HostInventoryService.getTid(this.HostInventoryService.getTidById, params).pipe(takeUntil(this.destroy$));
        })
      ).subscribe(data => {
        if (_.isEmpty(data)) {
          this.isSpinnerTid = false;
        } else {
          this.errMsgs = "No";
          this.existsWarningMsg("TID is already exists in this Processor.", 'tid');
          this.isSpinnerTid = false;
        }
      });
    }

    ownerFieldExistenceCheck(event){
      // if(this.hostInventoryForm.get('ownerId').valid && this.hostInventoryForm.get('processorId').valid){
      //   this.isSpinnerOwner = true;
      // let params = {
      //   ownerId: this.hostInventoryForm.get('ownerId').value,
      //   processorId: this.hostInventoryForm.get('processorId').value
      // }
      // this.HostInventoryService.getOwner(this.HostInventoryService.getOwnerById, params).subscribe(data => {
      //   if (_.isEmpty(data)) {
      //     this.errMsgsOwner = '';
      //     this.isSpinnerOwner = false;
      //   }else {
      //     this.errMsgsOwner = "No";
      //     this.existsWarningMsg("Owner ID is already exists in this Processor.", 'ownerId');
      //     this.isSpinnerOwner = false;
      //   }
      // })
      // }
    }

    showAlert(data, alertType) {
      this.messageService.add({
        severity: alertType,
        summary: data.status,
        detail: data.message,
      });
    }

    existsWarningMsg(text, fromName) {
      Swal.fire({
        html: `<h5>${text}</h5>`,
        icon: "warning",
        showCancelButton: false,
        confirmButtonText: "Ok",
        reverseButtons: true
      })
    }

    selectPro(event) {
      let owner = event.OwnerId;

      if(!_.isEmpty(event.id)){
        this.isSelectedPro = true;
        this.fromReset();
      }

      this.hostInventoryForm.patchValue({
        ownerId : owner
      });
    }

    fromReset(){
    let controlNames = ['tid', 'ownerId','componentKey1','componentKey2','nearestDenomination','cashBox1Total','cashBox2Total','cashBox1Denomination',
    'cashBox2Denomination'];
    controlNames.map((value: string) => this.hostInventoryForm.get(value).reset());
    }

    resetHostInv(){
      this.hostInventoryForm.reset();
    }

    ngOnDestroy(){
      console.log("Destroyed");
      this.editHostId ='';
      this.isEditHostInventory = false;
    }

    checkEvent(isCheckedEvent:any){
      if(isCheckedEvent.target.checked == true){
        this.hostInventoryForm.get('cashBoxStatus').patchValue("ENABLE");
      }else if(isCheckedEvent.target.checked == false){
        this.hostInventoryForm.get('cashBoxStatus').patchValue("DISABLE");
      }
    }

    getSearchedDbas(){
      this.customersDba$ = concat(
        of(this.editCustomerData ? this.editCustomerData : []),
        this.inputDba$.pipe(
          debounceTime(400),
          tap(() => this.isCustomersLoading = true),
          switchMap(term => {
            if(term == null || term.trim().length < 3) 
            {
              this.isCustomersLoading = false;
              return of([]);
            }
            return this.getAllDBA(term.trim(), '').pipe(
              (map(res => {
                return res.data;
              })
              ),
              catchError(() => of([])),
              tap(() => this.isCustomersLoading = false)
            )
          })
        )
      )
    };

    getAllDBA(term = '', ownedById = '') {
      let queryParam = {
        ...(!_.isEmpty(term) && {searchedDbaEmailTerm: term}),
        ...(!_.isEmpty(ownedById) && {ownedById})

      }
      return this.CommonService.getCommonHttpClientQueryParams(apiLinks.getCustomerDBAs, queryParam);
    }
}
