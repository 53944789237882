<div class="px-0 col-12">
  <div class="input-group" [ngClass]="isStyleFlag ? 'tpn_label' : ''">
    <!-- <div class="col-2 px-0">
    </div> -->
    <!-- /btn-group -->
    <!-- <div class="col-1">
      <div class="clearfix" *ngIf="searching">
        <div class="spinner-border float-right text-muted" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div> -->
    <div class="col px-0" *ngIf="!isTpnLabel">
      <input type="text" class="form-control" [formControl]="searchFilter" [placeholder]="placeholder"
        *ngIf="customerView  == undefined">

      <div class="d-flex" *ngIf="customerView  == true">
        <input id="typeahead-focus-dba" placeholder="Search by Store" type="text" class="form-control"
          (selectItem)="selectDba($event)" (ngModelChange)="getSearchKey($event)" (focusout)="stopLoader()"
          [(ngModel)]="searchByStoreModel" [ngbTypeahead]="searchStore" [resultFormatter]="storeFormatter"
          [resultTemplate]="storeResult" />
        <ng-template #storeResult let-r="result" let-t="term">
          <ngb-highlight [result]="r.dba" [term]="t"></ngb-highlight>
        </ng-template>
        <div class="input-group-prepend">
          <div class="input-group-text">
            <i class="feather icon-search"></i>
          </div>
        </div>
      </div>
      <div class="d-flex" *ngIf="customerView  == false && queryParam?.enableBuildNumber">
        <input type="number" class="form-control" [formControl]="searchBuildNumber" placeholder="Search by build #"
          maxlength="10">
        <div class="input-group-prepend">
          <div class="input-group-text">
            <i class="feather icon-search"></i>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="!isTpnLabel">
      <div class="input-group-prepend" *ngIf="customerView == undefined">
        <div class="input-group-text">
          <i class="feather icon-search"></i>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="isTpnLabel" [ngClass]="isStyleFlag ? 'tpn_default_label' : ''">
    <ng-select [ngClass]="isStyleFlag ? 'tpn_label' : ''" [items]="showDefaultLabel$ | async" bindValue="tpn"
      bindLabel="tpn" [multiple]="!enableSingleSelect" [selectOnTab]="false" [hideSelected]="true"
      [closeOnSelect]="enableSingleSelect" [formControl]="searchedTpn" [loading]="tpnLoading"
      notFoundText="No Tpn/DefaultLabels found" [placeholder]="placeholder"
      typeToSearchText="Please enter 4 or more characters" [typeahead]="inputTpn$">
      <ng-template ng-option-tmp let-item="item">
        <div>
          {{!item?.defaultLabel ? item?.tpn : item?.tpn +' ('+item?.defaultLabel+')'}}
        </div>
      </ng-template>
      <ng-template ng-header-tmp *ngIf="!enableSingleSelect">
        <button [disabled]="isSelectAllTpn" (click)="selectAll()" class="btn btn-sm btn-secondary">Select all</button>
        <button [disabled]="isSelectAllTpn" (click)="unselectAll()" class="ml-1 btn btn-sm btn-secondary">Unselect
          all</button>
      </ng-template>
    </ng-select>
  </div>
</div>
