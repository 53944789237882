import { Inject, Injectable, InjectionToken } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from "rxjs";
import { AuthService } from "../providers/auth.service";
import { map, catchError, switchMap, retry, tap } from "rxjs/operators";
import { apiLinks } from "../core/api-links/api-links";
import { timeout } from 'rxjs/operators';
import { isEmpty } from 'lodash';
export const DEFAULT_TIMEOUT = new InjectionToken<number>('defaultTimeout');
import { UserService } from '../providers/user.service';
@Injectable()
export class HttpInterceptorInterceptor implements HttpInterceptor {
  currentUser;

  constructor(@Inject(DEFAULT_TIMEOUT) protected defaultTimeout: number, private authService: AuthService, private userService: UserService) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.currentUser = this.authService.getUser;

    const timeoutValue = request.headers.get('timeout') || this.defaultTimeout;
    const timeoutValueNumeric = Number(timeoutValue);
    //console.log(this.currentUser); 

    if ([apiLinks.paymentForm, apiLinks.processTransaction].indexOf(request.url) < 0) {
      request = request.clone({
        withCredentials: true,
      });
    }

    if (this.currentUser) {
      request = this.sendToken(request);
    }

    return next.handle(request).pipe(
      // tap(event => {
      //   if (event instanceof HttpResponse) {
      //     let webVersion = event.headers.get('webVersion'); 
      //     if(isEmpty(localStorage.getItem("webVersion")) && !isEmpty(webVersion)) {
      //       localStorage.setItem("webVersion", webVersion);
      //     } else if(!isEmpty(webVersion) && !isEmpty(localStorage.getItem("webVersion")) &&  webVersion != localStorage.getItem("webVersion")) {
      //       localStorage.setItem("webVersion", webVersion);
      //       window.location.reload();
      //     }
      //     // http response status code
      //     console.log(event.status);
      //   }
      // }, error => {
      //  // http response status code
      //     console.log("----response----");
      //     console.error("status code:");
      //     console.error(error.status);
      //     console.error(error.message);
      //     console.log(error.url);
      //     console.log("--- end of response---");
      //     if ((this.currentUser && error.status == 401) && (!error.url.includes("druid"))) {
      //       this.authService.logout();
      //     }
      //     return throwError(error);
      // }),
      timeout(timeoutValueNumeric),
      catchError((error: HttpErrorResponse) => {
        if ((this.currentUser && error.status == 401) && (!error.url.includes("druid"))) {
          this.authService.logout();
          //location.reload();
          //console.log(error);
          // this.authService.refreshToken(this.currentUser.refreshToken).pipe(
          //   retry(2),
          //   switchMap(() => {
          //     request = this.sendToken(request);
          //     return next.handle(request);
          //   })
          // );
        }
        return throwError(error);
      })
    );
  }

  sendToken(request: HttpRequest<any>) {
    // console.log(this.currentUser.roleFeatures)
    return request.clone({
      url: request.url.replace('http://', 'https://'),
      setHeaders: {
        xaccesstoken: this.currentUser.accessToken
        // roleFeatures: JSON.stringify(this.currentUser.roleFeatures)
      },
    });
  }
}
