import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { HostInventoryService } from "../../../../providers/host-inventory.service";
import * as _ from 'lodash';
import { Privileges } from 'src/app/core/model/privileges.enum';
import { MessageService } from "primeng/api";
import { isEmpty,get } from 'lodash';
import { CommonService } from 'src/app/providers/common.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { apiLinks } from 'src/app/core/api-links/api-links';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/providers/auth.service';
import { Modules } from 'src/app/core/model/modules.enum';
import {BuilkEditTidComponent} from '../builk-edit-tid/builk-edit-tid.component';
import { NgbDate, NgbActiveModal, NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { Subject } from 'rxjs';
import * as moment from 'moment';
import { StoreService } from 'src/app/providers/store.service';
import { BulkStatusUpdateComponent } from '../bulk-status-update/bulk-status-update.component';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-list-host-inventory',
  templateUrl: './list-host-inventory.component.html',
  styleUrls: ['./list-host-inventory.component.scss']
})
export class ListHostInventoryComponent implements OnInit {

  listHostInventory:any[] = [];
  listHostInventoryView: Subscription
  getViewHostInv:any;
  privileges = Privileges;
  viewMode: number = 0;
  isLoading:boolean = true;
  apiName = 'hostInventory';
  filterQuery = {};
  defaultNavActiveId;
  page:number = 1;
  size = 20;
  queryString:any;
  collectionSize:any;
  customerPrivilege:any;
  hostInventoryPrivilege: any;
  total: number;


  searchTerm = '';
  hostInventoryListUnfilteredData: any;
  defaultCollectionSize = 0;
  filterData: any = {};

  dateRangeSubject: Subject<any> = new Subject<any>();
  updateStatusSubject: Subject<any> = new Subject<any>();
  dateSelectionTypes: any;

  startDate: any;
  disabled: boolean = true;
  endDate: any;
  startingDate: any;
  firstDate: any;
  lastDate: any;
  timePeriodModal: NgbModalRef;

  modalReference: NgbModalRef;
  modalReferenceForOpenBatch: NgbModalRef;
  @ViewChild('OpenLoader') OpenLoader: ElementRef;
  searchFilterData: any = {};
  filterFromSearchbox = {};
  masterSelectBox = false;
  isMasterSel: boolean = false;
  checkedCategoryList: any = [];
  isFilterApply:boolean = true;
  tidSelecedLength:number = 0;
  isSelectedArray:any = [];
  tidBasedTpnsData ={};
  isLoadingTidInfo = false;
  tpnListData: Subscription;
  private destory$ = new Subject<void>();

  dateFilterList = [
    { displayName: 'Today', name: 'Today' },
    { displayName: 'Yesterday', name: 'Yesterday' },
    { displayName: 'This Week', name: 'Week' },
    { displayName: 'Last 30 days', name: "Month" },
    { displayName: 'Custom', name: "Custom" },
    { displayName: 'None', name: 'None' }
  ];

  current = new Date();
  currentDate = {
    year: this.current.getFullYear(),
    month: this.current.getMonth() + 1,
    day: this.current.getDate()
  };

  @ViewChild('content') content: ElementRef;
  previousFilterData: any;

  searching = false;
  isFilterSearch: boolean = false;

  removeObj:any = [];
  isTidSelectAll = false;
  isSingleSelect = false;
  exceptTids = [];
  selectedTids = [];
  selectedTidList = { exceptTids: [], selectedTids: [], isSelectAll: false};
  isEnableBulkUpdateTid = false;
  userType = '';

  constructor(
    private messageService: MessageService,
    private commonService:CommonService,
    private authService: AuthService,
    private router: Router,
    private ngbModal: NgbModal,
    private refreshHostInventory:StoreService

  ) { }

  ngOnInit(): void {
   // console.log("Page Number >>>>>>>>>>>>>>>>>>>>>>>>>>", this.page);
    this.userType = this.authService.getUser.userType;
    this.getAllHostInventory({isFilter:false, page:this.page, size:this.size, ...this.filterData});
    this.customerPrivilege = this.authService.getPrivileges(Modules.HOST_INVENTORY).features;
    this.hostInventoryPrivilege = this.authService.getPrivileges(Modules.HOST_INVENTORY);

    this.refreshHostInventory.bulkHostInventory.subscribe(result => {
      if(result){
        this.getAllHostInventory({isFilter:false, page:this.page, size:this.size, ...this.filterData});
      }
    })
  }

 async getAllHostInventory(filters: any) {
  //  this.isLoading = true;
  this.searching = true;
    let queryString = this.queryString?{queryString: this.queryString}:{};
    this.listHostInventoryView = this.commonService.getCommonHttpClientQueryParams(apiLinks.hostInventory,filters).subscribe(async (res)=>{
    this.hostInventoryListUnfilteredData =  this.listHostInventory = res.data.items;
    if(this.masterSelectBox || this.isSingleSelect){
      this.selectAllTid();
    }

      this.total = this.collectionSize = res.data.totalItems;
      this.isLoading = false;
      this.searching = false;
      this.messageService.clear();
      this.viewMode = 0;
      this.getViewHostInv = this.listHostInventory.length > 0 ? this.listHostInventory[0] : [];
      if(!isEmpty(this.getViewHostInv)){
        this.getTpnLists(this.getViewHostInv);
      }
      if (this.listHostInventory.length == 0) {
        this.messageService.clear();
        this.showNoDataMessage(false, 'Sorry! No Host Inventory Datas.');
      }
    },
    (err) => {
      this.showAlert(err, "error");
      this.isLoading = false;
      this.searching = false;
    })
  }

  getTpnLists(hostList){
    this.isLoadingTidInfo = true;
    if(this.tpnListData)[
      this.tpnListData.unsubscribe()
    ]
    this.tpnListData = this.commonService.getCommonHttpClientParams(`${apiLinks.tidOwnerBasedTpns}/${hostList.ownedBy}/${hostList.id}`).pipe(takeUntil(this.destory$)).subscribe(async (res)=>{
      this.tidBasedTpnsData = res;
      this.isLoadingTidInfo = false;
      this.getViewHostInv = {...this.getViewHostInv, ...this.tidBasedTpnsData};
      this.tpnListData.unsubscribe();
    },
    (err) => {
      this.isLoadingTidInfo = false;
    })
  }
  applyFilters(filteredData) {
    this.checkUncheckAll(false);
    let {data, searchTerm} = filteredData;
    this.queryString = searchTerm ? searchTerm : '';
    if(!_.isEmpty(data)) {
      this.listHostInventory = data.data.items;
      this.getViewHostInv = data.data.items[0];
      if(!isEmpty(this.getViewHostInv)) {
        this.getTpnLists(this.getViewHostInv);
      }
      this.total = this.collectionSize = data.data.totalItems;
      this.page = data.data.currentPage;
      this.isFilterSearch = false;
      this.searching = false;
      if(this.listHostInventory.length == 0){
        this.showNoDataMessage(false, 'Sorry! No Host Inventory Datas.');
      }
    } else {
     console.log("NO DATA");
     this.isFilterSearch = false;
    }
    this.viewMode = 0;
  }

  isHostFilterLoader(loader){
    this.isFilterSearch = loader;
    this.searching = true;
  }
  showNoDataMessage(condition:boolean, details) {
    this.messageService.add({
      severity: 'info',
      summary:'Info Message',
      detail: details,
      sticky: condition
    });
  }

  getHostInvDetails(hostInventory) {
    this.getViewHostInv = hostInventory;
  }
  
  selected(index) {
    if (this.viewMode != index){
      this.getTpnLists(this.getViewHostInv);
    } else {
      this.getViewHostInv = {...this.getViewHostInv, ...this.tidBasedTpnsData};
    }
    this.viewMode = index; 
  }

  showAlert(data, alertType) {
    this.messageService.add({
      severity: alertType,
      summary: data.status,
      detail: data.message,
    });
  }

  // refreshPage(){
  //   this.getAllHostInventory();
  // }

  getPage(page: number) {
    document.getElementById("home").scrollIntoView({ behavior: "smooth" });
    this.page = page;
    let filterData;
    if (this.filterData.search) {
      filterData = this.filterData
    }
    this.getAllHostInventory({ page: this.page, size: this.size, ...this.filterFromSearchbox,queryString: this.queryString ? this.queryString : "", ...this.filterData });
    // console.log(">>>>>>>>>>>>>> page: number", page);
  }

  routeToEditMerchant(data) {
    let ownerNotes = '';
    let agentInfo = '';
    if (!isEmpty(data.hostInventory_from_notes.ownerNotes)) {
      ownerNotes = data.hostInventory_from_notes.ownerNotes;
    }
    if (!isEmpty(data.hostInventory_from_notes.agentInfo)) {
      agentInfo =data.hostInventory_from_notes.agentInfo
    }
    data.ownerNotes = ownerNotes;
    data.agentInfo = agentInfo;
    this.router.navigate(['/add-host-inventory'],{queryParams:{...data,editHost:true}});
  }

  isPermission(privilege) {
      return this.hostInventoryPrivilege && this.hostInventoryPrivilege.features.indexOf(privilege) != -1;
  }

  openBulkeditTidModal(hostId){

    let modalRef = this.ngbModal.open(BuilkEditTidComponent, { backdrop: 'static', keyboard: false, size: 'xl', centered: true });

    modalRef.componentInstance.hostId = hostId;
    modalRef.componentInstance.hostInventory = this.getViewHostInv;

    modalRef.result.then((result) => {
      this.getAllHostInventory({isFilter:false, page:this.page, size:this.size, queryString: this.queryString ? this.queryString : "",...this.filterData});

    })
      .catch((res) => { });
  }

  updateFilterData(filteredData) {

   // console.log("updateFilterData", filteredData);

    let {data, searchTerm} = filteredData;
    this.searchTerm = searchTerm;

    if(!_.isEmpty(data)) {
      this.listHostInventory = data.data.items;
      this.total = data.data.totalItems;
      this.getViewHostInv = this.listHostInventory.length > 0 ? this.listHostInventory[0] : [];
    } else {
      this.listHostInventory = this.hostInventoryListUnfilteredData;
      this.total = this.defaultCollectionSize;
    }
    this.page = 1;
    this.getAllHostInventory({ page: this.page, ...this.filterData });
  }

  refreshData(data){
    if(data == 'refresh' ) {
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>{
        this.router.navigate(["/list-host-inventory"]);
      });
    }
  }

  applyFilter(filterData) {
    this.checkUncheckAll(false);
      if (filterData?.isFilter == false) {
        this.searchTerm = ''
        this.filterFromSearchbox = {}
      }
      this.filterData = filterData;
      this.previousFilterData = { ...filterData };
      if (!this.filterData.isFilter) {
        this.previousFilterData = {}
        this.filterData = {}
      }
      this.page = 1;
      //this.viewMode = 0;
      if (filterData.search) {
        this.searchFilterData = filterData;
        this.getAllHostInventory({ page: this.page, size: this.size, ...this.filterData })
      }
    }

    onCalenderOpen(data) {

      if(data) {
        this.timePeriodModal = this.ngbModal.open(this.content, { ariaLabelledBy: 'modal-basic-title', centered: true, backdrop: 'static', keyboard: false })
      }else{
        this.dateSelectionTypes = null;
        this.firstDate = null;
        this.lastDate = null;
        this.startDate = null
        this.endDate = null
      }
    }

    onDateSelection(date: NgbDate) {
      this.startingDate = date;

     // console.log("this.startingDate", this.startingDate);

      if (!this.endDate) {
        this.toDateSelection(date)
      }
      if (date.after(this.lastDate)) {
        this.toDateSelection(date)
      }
      this.startDate = moment(new Date(date.year, date.month - 1, date.day, 0, 0, 0));
      this.startDate = this.startDate.format('YYYY-MM-DD HH:mm:ss');
      this.disabled = false;
    }

    toDateSelection(date: NgbDate) {
      this.lastDate = date
      this.endDate = moment(new Date(date.year, date.month - 1, date.day, 23, 59, 59));
      this.endDate = this.endDate.format('YYYY-MM-DD HH:mm:ss');
    }

    onDateFilterSubmit() {
      if (this.dateSelectionTypes == 'Custom') {
        this.filterData['dateRange'] = '';
        this.filterData['fromDate'] = this.startDate;
        this.filterData['toDate'] = this.endDate;
      } else {
        this.filterData['dateRange'] = this.dateSelectionTypes;
        this.filterData['fromDate'] = '';
        this.filterData['toDate'] = '';
      }
      this.page = 1;

      this.previousFilterData = { ... this.filterData }

      this.dateRangeSubject.next(this.filterData);

     // this.getDeviceList({ page: this.page, size: this.size, ...this.filterData, ...this.filterFromSearchbox });
     this.getAllHostInventory({ page: this.page, size: this.size, ...this.filterFromSearchbox, queryString: this.queryString ? this.queryString : "",...this.filterData });

      this.timePeriodModal.close();

    }

    closeDateSelectionFilter() {
      this.timePeriodModal.close();
      this.firstDate = this.lastDate = [];
      this.dateSelectionTypes = [];
      this.startingDate = null;
      this.startDate = null;
      this.endDate = null;
    }

  ngOnDestroy() {
    this.destory$.next();
    this.destory$.complete();
    if (this.listHostInventoryView) this.listHostInventoryView.unsubscribe();
  }

  selectedFilter(data, propertyName){

  }

  displayColor(data:string){
    return data;
  }

  isUpdateSingleTidStatus(){
    const getChangeArray = [];
    getChangeArray.push(this.getViewHostInv);
      let modalRef = this.ngbModal.open(BulkStatusUpdateComponent, {
        size: "md",
      });
      modalRef.componentInstance.totalTids = 1;
      modalRef.componentInstance.listHostInventory = getChangeArray;
      modalRef.componentInstance.filterQuery = {status:this.getViewHostInv?.status};
      modalRef.componentInstance.isBulkUpdate = false;
      modalRef.result
        .then((result) => {
          if (_.isUndefined(result)) {
            return;
          }
          if (result.status == "Success") {
            this.showAlert(result, "success");
          }
        })
        .catch((res) => {});

  }

  clearSelectedTids(){
    this.selectedTids = [];
    this.exceptTids = [];
  }

  checkUncheckAll(isCheckedEvent){
    const isFilterApply = ((this.filterData?.isFilter === true) && !isEmpty(this.filterData?.status) && !isEmpty(get(this.filterData,'processorId[0]'))) ? true : false;
    this.isSingleSelect = false;
    this.masterSelectBox = this.isTidSelectAll = isCheckedEvent ? isCheckedEvent.target.checked : false;
    if(this.isTidSelectAll && !isFilterApply){
      this.modalReferenceForOpenBatch = this.ngbModal.open(this.OpenLoader, { ariaLabelledBy: 'modal-basic-title', centered: true, backdrop: 'static', keyboard: false });
    }else{
      this.clearSelectedTids();
      this.selectAllTid();
      this.getSelectedTids();
    }
  }

  selectAllTid(){
    for (let hostInventory of this.listHostInventory) {

      if(!this.isTidSelectAll && _.some(this.selectedTids,{tid:hostInventory.tid, processorName:hostInventory.processorName})){
        hostInventory.isSelected = true;
      }else if(!this.isTidSelectAll && !_.some(this.selectedTids, {tid:hostInventory.tid, processorName:hostInventory.processorName})){
        hostInventory.isSelected = false;
      }else if(this.isTidSelectAll && _.some(this.exceptTids, {tid:hostInventory.tid, processorName:hostInventory.processorName})){
        hostInventory.isSelected = false;
      }else if(this.isTidSelectAll && !_.some(this.exceptTids, {tid:hostInventory.tid, processorName:hostInventory.processorName})){
        hostInventory.isSelected = true;
      }
      
    }
  }

  isAllSelected(event, tid, processorName) {
    const isFilterApply = ((this.filterData?.isFilter === true) && !isEmpty(this.filterData?.status) && !isEmpty(get(this.filterData, 'processorId[0]'))) ? true : false;
    if (!isFilterApply) {
      this.modalReferenceForOpenBatch = this.ngbModal.open(this.OpenLoader, { ariaLabelledBy: 'modal-basic-title', centered: true, backdrop: 'static', keyboard: false });
    } else {
      this.isSingleSelect = true;
      let isCheckedEvent = event.target.checked;
      if (!this.isTidSelectAll && !isCheckedEvent) {

        _.remove(this.selectedTids, (data) => {
          return data.tid == tid;
        });

      } else if (!this.isTidSelectAll && isCheckedEvent) {
        this.selectedTids.push({tid,processorName});
      } else if (this.isTidSelectAll && !isCheckedEvent) {
        this.exceptTids.push({tid,processorName});
      } else if (this.isTidSelectAll && isCheckedEvent) {
        _.remove(this.exceptTids, (data) => {
          return data.tid == tid;
        });
      }
      this.getSelectedTids();
    }

  }

  getSelectedTids() {

    this.selectedTidList = {
      isSelectAll: this.isTidSelectAll,
      selectedTids: this.selectedTids,
      exceptTids: this.exceptTids,
    };
    this.isEnableBulkUpdateTid = this.isTidSelectAll || !_.isEmpty(this.selectedTids) || !_.isEmpty(this.exceptTids);
  }

  closeCall() {
    this.filterData["queryString"] = this.queryString;
    this.getAllHostInventory({ isFilter: false, page: this.page, size: this.size, ...this.filterData });
    this.masterSelectBox = this.isSingleSelect = false;
  }

}
