<div class="main-wrapper">

  <!-- partial:sidebar -->
  <app-sidebar></app-sidebar>
  <!-- partial -->

  <div class="page-wrapper">

    <!-- partial:navbar -->
    <app-navbar></app-navbar>
    <!-- partial -->

    <div class="page-content">

      <!-- Spinner for lazyload modules -->
      <!-- <div *ngIf="isLoading" class="spinner-wrapper">
        <img src="assets/gif/dejavooLoader.gif" alt="DEJAVOO LOADER" width="100"/>
      </div> -->
      <app-loading *ngIf="isLoading"></app-loading>

      <div contentAnimate *ngIf="!isLoading">
        <router-outlet></router-outlet>
      </div>

    </div>

    <!-- partial:footer -->
    <!-- <app-footer></app-footer> -->
    <!-- partial -->

  </div>


</div>