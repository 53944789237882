import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal,NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { MessageService } from 'primeng/api';
import { apiLinks } from 'src/app/core/api-links/api-links';
import { CommonService } from 'src/app/providers/common.service';
import { Workbook } from 'exceljs';
import * as _ from 'lodash';
import * as fs from 'file-saver';
import { StoreService } from 'src/app/providers/store.service';
import { timeInterval } from 'rxjs/operators';

@Component({
  selector: 'app-bulk-upload-host-inventory',
  templateUrl: './bulk-upload-host-inventory.component.html',
  styleUrls: ['./bulk-upload-host-inventory.component.scss'],
  providers: [MessageService]
})
export class BulkUploadHostInventoryComponent implements OnInit {

  hostInventoryForms: FormGroup;
  initialFormValues: any;
  isLoader = false;
  submit = false;
  fileValid = false;
  nullFile = false;
  saveAndAddCardLoader: boolean;
  fileData = {
    fileLocation: {}
  }
  fileUploadControlName: string;
  processingTransaction = false;
  currentCustomer: any = 0;

  constructor(
    public modal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    private commonService: CommonService,
    public activeModal: NgbActiveModal,
    private transRefresh: StoreService
  ) { }

  ngOnInit(): void {
    this.hostInventoryForms = this.createHostInventoryForms();
    this.initialFormValues = this.hostInventoryForms.value;
  }

  createHostInventoryForms(): FormGroup {
    return this.formBuilder.group({
      uploadHostInventoryInfo: this.uploadInfoForm()
    });
  }

  openFileBrowser(event: any, controlName: string) {
    event.preventDefault();
    let element: HTMLElement = document.querySelector(`#${controlName}`) as HTMLElement;
    element.click();
    this.fileUploadControlName = controlName;
  }

  showAlertMessage(alertType, summary, data, sticky) {
    this.messageService.clear();
    this.messageService.add({
      severity: alertType,
      summary: summary,
      detail: data,
      sticky: sticky
    });
    this.saveAndAddCardLoader = false;
  }

  onFileUpload() {
    this.isLoader = true;
    this.messageService.clear();
    this.submit = true;
    if(this.hostInventoryForms.get('uploadHostInventoryInfo').valid){
      this.fileValid = false;
      this.nullFile = false;
      console.log("uploadFile", this.hostInventoryForms.get('uploadHostInventoryInfo').value);
      this.uploadingFile(this.hostInventoryForms.get('uploadHostInventoryInfo').value);
    }
  }

  async onFileChanges(event:any) {
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      let fileNameExtCheck = await this.commonService.doubleExtensionCheck(file.name);
      if(fileNameExtCheck) {
        alert(`File name (${file.name}) with mulitple format is not supported`);
      } else {
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.patchValue('uploadHostInventoryInfo','uploadFile', file.name);
          this.fileData[this.fileUploadControlName] = file;
        };
      }
    }
    event.target.value = '';
  }

  patchValue(groupName, controlName, value) {
    this.hostInventoryForms.get([groupName, controlName]).patchValue(value);
  }

  resetUploadForm() {
    this.hostInventoryForms.get('uploadHostInventoryInfo').reset(this.initialFormValues);
    this.processingTransaction = false;
    this.currentCustomer = 0;
    this.submit = false;
    this.nullFile = false;
  }

  uploadingFile(formData) {
    const uploadData = new FormData();
    Object.keys(formData).forEach(keyName => {
      if (_.has(this.fileData, keyName)) {
        uploadData.append(keyName, this.fileData[keyName]);
      } else if(keyName === 'customersId'){
        uploadData.append(keyName, JSON.stringify(formData[keyName]));
      } else {
        uploadData.append(keyName, formData[keyName]);
      }
    });

    this.commonService.postCommonHttpClient(apiLinks.uploadHostInventory, uploadData).subscribe(
      async (res) => {
        console.log("RES",res);
      if(res.data){
        this.createWorkSheet(res.data, 'excel');
      }
       this.transRefresh.bulkHostInventory.next(true);
        let successMsg = {
          message: res.message,
          status: "Success"
        };
       this.showAlertMessage("success", 'success',res.message, false);
        setTimeout(() => {
        this.activeModal.close('by: close icon');
        }, 500);
      },
      (err) => {
        console.log(err);
        this.isLoader = false;
        this.showAlertMessage('error', 'Error', err.message, true );
      }
    );
  }

  uploadInfoForm(): FormGroup{
    return this.formBuilder.group({
      jobName: ["", [Validators.required, Validators.pattern("[a-zA-Z0-9 ']{2,25}")],],
      uploadFile: ["",[Validators.required]]
    })
  }

  forFileConfirmation() {
    this.messageService.clear();
    this.submit = true;
    console.log("here", this.hostInventoryForms.get(['uploadHostInventoryInfo', 'uploadFile']).value);
    if( this.hostInventoryForms.get(['uploadHostInventoryInfo', 'uploadFile']).value == null || this.hostInventoryForms.get(['uploadHostInventoryInfo', 'uploadFile']).value == '') {
      console.log("Here comes the validatiuon");
      this.nullFile = true;
    }
    let arrayOfUpload = this.hostInventoryForms.get(['uploadHostInventoryInfo', 'uploadFile']).value.split(".");
    let uploadFileFormat = arrayOfUpload[arrayOfUpload.length - 1];
    if(uploadFileFormat != 'xlsx') {
      this.fileValid = true;
    }
    console.log("getting value", this.hostInventoryForms.get(['uploadHostInventoryInfo', 'uploadFile']));
    if (this.hostInventoryForms.get('uploadHostInventoryInfo').valid  && uploadFileFormat == 'xlsx') {
      this.fileValid = false;
      this.onFileUpload();
    }
  }

  download(){
    this.commonService.getCommonFileDownload(apiLinks.getHostInventoryFileUpload).subscribe(data=>{
      let downloadURL = window.URL.createObjectURL(data);
      fs.saveAs(downloadURL,"SampleHostInventoryUpload.xlsx");
    });
  }

  createWorkSheet(HostInvenDetails, fileType){

    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("HostInventoryDetails");

  //   worksheet.getCell('A1').font = {
  //     name: 'Comic Sans MS',
  //     family: 4,
  //     size: 16,
  //     underline: true,
  //     bold: true
  // }

    worksheet.columns = [
      { header: "PROCESSOR", key: "processorName", width: 45, style: { font: { name: 'Arial Black' } } },
      { header: "OWNER", key: "ownerId", width: 45 },
      { header: "TID", key: "tid", width: 35 },
      { header: "ENC/MASTERKEY", key: "encMasterKey", width: 45 },
      { header: "NEAREST DENOM", key: "nearestDenomination", width: 35 },
      { header: "CASH BOX1 DENOM", key: "cashBox1Denomination", width: 45 },
      { header: "CASH BOX1 TOTAL", key: "cashBox1Total", width: 45 },
      { header: "CASH BOX2 DENOM", key: "cashBox2Denomination", width: 45 },
      { header: "CASH BOX2 TOTAL", key: "cashBox2Total", width: 35 },
      { header: "CASHBOX MONITOR", key: "cashBoxStatus", width: 35 },
      { header: "ISO EMAIL", key: "ownedByEmail", width: 35 }
    ];

    worksheet.addRows(HostInvenDetails);

    worksheet.eachRow({ includeEmpty: true }, function(row, rowNumber){
      row.eachCell(function(cell, colNumber){
       cell.font = {
         name: 'Arial',
         family: 2,
         bold: false,
         size: 10,
       };
       cell.alignment = {
         vertical: 'middle', horizontal: 'left'
       };
        if (rowNumber >= 1) {
         for (var i = 1; i < 11; i++) {
           row.getCell(i).border = {
           top: {style:'thin'},
           left: {style:'thin'},
           bottom: {style:'thin'},
           right: {style:'thin'}
         };
       }
      }
     });
    });
    worksheet.getCell('A1').fill = {type: 'pattern',pattern:'solid',fgColor:{argb:'C7C7C7'}};
    worksheet.getCell('B1').fill = {type: 'pattern',pattern:'solid',fgColor:{argb:'C7C7C7'}};
    worksheet.getCell('C1').fill = {type: 'pattern',pattern:'solid',fgColor:{argb:'C7C7C7'}};
    worksheet.getCell('D1').fill = {type: 'pattern',pattern:'solid',fgColor:{argb:'C7C7C7'}};
    worksheet.getCell('E1').fill = {type: 'pattern',pattern:'solid',fgColor:{argb:'C7C7C7'}};
    worksheet.getCell('F1').fill = {type: 'pattern',pattern:'solid',fgColor:{argb:'C7C7C7'}};
    worksheet.getCell('G1').fill = {type: 'pattern',pattern:'solid',fgColor:{argb:'C7C7C7'}};
    worksheet.getCell('H1').fill = {type: 'pattern',pattern:'solid',fgColor:{argb:'C7C7C7'}};
    worksheet.getCell('I1').fill = {type: 'pattern',pattern:'solid',fgColor:{argb:'C7C7C7'}};
    worksheet.getCell('J1').fill = {type: 'pattern',pattern:'solid',fgColor:{argb:'C7C7C7'}};
    worksheet.getCell('K1').fill = {type: 'pattern',pattern:'solid',fgColor:{argb:'C7C7C7'}};

    this.processingTransaction = true;
    this.identifyFileTypeAndDownload(workbook, fileType);

  }

  identifyFileTypeAndDownload(workbook, fileType){
    if(fileType == 'excel'){
      return workbook.xlsx.writeBuffer().then(function (data) {
        var blob = new Blob([data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
        fs.saveAs(blob, "HostInventoryDetails.xlsx");
    })
   }
  }

}
