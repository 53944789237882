<p-toast position="top-right" [baseZIndex]="5000"></p-toast>
<div
  class="d-flex justify-content-between align-items-center flex-wrap grid-margin shadow p-3 mb-2 bg-white rounded mr-2 ml-2">
  <div class="d-flex align-items-center flex-wrap text-nowrap" *ngIf="!isLoading">
    <button type="button" class="btn btn-outline-primary btn-xs d-none d-md-block mr-4"
      [routerLink]="['/add-processor']" routerLinkActive="active" [disabled]="!isPermission(privileges.ADD_PROCESSOR)">
      <i class="link-icon feather icon-file-plus"></i>
    </button>
  </div>
  <div class="mx-auto col-5">
    <div class="input-group">
    </div>
    <app-search-box-filter class="col-5 px-0" (filteredData)="applyFilters($event)" [apiNameProperty]="apiName"
      [queryParam]="filterQuery" placeholder="{{'processorList.searchPlaceHolder' | translate}}">
    </app-search-box-filter>
  </div>

  <div class="d-flex align-items-center flex-wrap text-nowrap ml-md-4">
    <div>
      <button type="button" placement="left" triggers="mouseenter:mouseleave" (click)="refreshPage()"
        ngbPopover="Refresh" class="btn btn-outline-primary btn-circle"><i
          class="link-icon feather icon-refresh-cw"></i>
      </button>
    </div>
  </div>
</div>

<app-loading *ngIf="isLoading"></app-loading>
<div class="row chat-wrapper mt-3" *ngIf="processorList.length != 0 && !isLoading">
  <div class="col-md-12">
    <div class="card">
      <div class="card-body">
        <div class="row position-relative">
          <div class="col-lg-5 chat-aside border-lg-right">
            <div class="aside-content">
              <div class="aside-header">
              </div>
              <div class="aside-body" style="margin-top: -38px">
                <ul ngbNav #defaultNav="ngbNav" [(activeId)]="defaultNavActiveId" class="nav-tabs mt-3">
                  <li [ngbNavItem]="1">
                    <ng-template ngbNavContent>
                      <div #container class="container" class="ps" [perfectScrollbar]>
                        <p class="text-muted mb-1"></p>
                        <div (click)="selected(index)" [class.active]="viewMode == index"
                          class="ist-unstyled chat-list px-1 mb-2 back-ground-color back-hover"
                          *ngFor="let processor of processorList; let index = index">
                          <div class="chat-item pr-1">
                            <a (click)="getHostInvDetails(processor)" class="d-flex align-items-center">
                              <div class="flex-grow">
                                <div>
                                  <div class="d-flex justify-content-between">
                                    <p class="text-body tx-size over-flow ml-1">{{processor?.name}}</p>
                                    <p class="text-body col-3 over-flow text-right" placement="bottom"
                                      triggers="mouseenter:mouseleave">
                                      <span [class]="setClass(processor.status)">{{processor.status}}</span>
                                    </p>
                                  </div>
                                  <hr>
                                  <div class="d-flex justify-content-between">
                                    <p class="text-body over-flow ml-1"><span
                                        class="large-fontsize-label"><strong>{{'processorList.FREQ' |
                                          translate}}:</strong></span><span class="large-fontsize">
                                        {{processor?.frequency}}</span>
                                    </p>
                                    <p class="text-body mr-1"><span
                                        class="large-fontsize-label"><strong>{{'processorList.MAX' | translate}}
                                          :</strong></span><span class="large-fontsize">
                                        {{processor?.max}}</span>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div class="mt-2">
                        <ngb-pagination class="d-flex justify-content-end mob-media mt-4"
                          [collectionSize]="collectionSize" [(page)]="page" [maxSize]="5" [rotate]="true"
                          [pageSize]="size" (pageChange)="getPage(page)"></ngb-pagination>
                      </div>
                    </ng-template>
                  </li> <!-- End chat tab-->
                </ul>
                <div [ngbNavOutlet]="defaultNav" class="mt-3"></div>
              </div>
            </div>
          </div>

          <div class="col-lg-7 chat-content">
            <div>
              <div class="chat-header border-bottom pb-2">
                <div class="d-flex justify-content-between">
                  <div class="d-flex align-items-center">
                    <i class="feather icon-corner-up-left icon-md mr-2 ml-n2 text-muted d-lg-none"></i>
                    <div>
                      <h5>{{viewingProcessor?.name}}</h5>
                    </div>
                  </div>
                </div>
              </div>

              <div class="d-flex justify-content-end mt-2 mb-2 back-ground-color p-2">
                <div class="row mr-2">

                  <button href="" (click)="routeToEditProcessor(viewingProcessor)" class="btn btn-primary mr-2 " [disabled]="!isPermission(privileges.EDIT_PROCESSOR)">
                    <i class="feather icon-edit-2" ngbTooltip="Edit"></i> {{'htmlText.edit' | translate}}
                  </button>
                </div>
              </div>

              <div class="chat-body">
                <div class=" mt-2 mb-2 back-ground-color p-2">
                  <div class="row col-md-12 mb-4 mt-4">
                    <div class="col-md-4 col-lg-4">
                      <div>
                        <h5 placement="bottom" triggers="mouseenter:mouseleave"
                          ngbPopover="{{'processorList.processor' | translate}}" class="over-flow">
                          {{'processorList.processor' | translate}}</h5>
                        <p placement="bottom" triggers="mouseenter:mouseleave"
                          ngbPopover="{{viewingProcessor?.name || ''}}" class="tx-13 over-flow"><span
                            class="tx-size text-muted">{{viewingProcessor?.name || ''}}</span></p>
                      </div>
                    </div>
                    <div class="col-md-4 col-lg-4">
                      <div>
                        <h5 placement="bottom" triggers="mouseenter:mouseleave"
                          ngbPopover="{{'processorList.frequency' | translate}}" class="over-flow">
                          {{'processorList.frequency' | translate}}</h5>
                        <p placement="bottom" triggers="mouseenter:mouseleave"
                          ngbPopover="{{viewingProcessor?.frequency}}" class="tx-13 over-flow"><span
                            class="tx-size text-muted">{{viewingProcessor?.frequency || ''}}</span></p>
                      </div>
                    </div>
                    <div class="col-md-4 col-lg-4">
                      <div>
                        <h5 placement="bottom" triggers="mouseenter:mouseleave"
                          ngbPopover="{{'processorList.max' | translate}}" class="over-flow">{{'processorList.max' |
                          translate}}</h5>
                        <p placement="bottom" triggers="mouseenter:mouseleave" ngbPopover="{{viewingProcessor?.max}}"
                          class="tx-13 over-flow"><span class="tx-size text-muted">{{viewingProcessor?.max ||
                            ''}}</span></p>
                      </div>
                    </div>
                  </div>

                  <div class="row col-md-12 mb-4 mt-4">
                    <div class="col-md-4 col-lg-4">
                      <div>
                        <div>
                          <h5 placement="bottom" triggers="mouseenter:mouseleave"
                            ngbPopover="{{'processorList.status' | translate}}" class="over-flow">
                            {{'processorList.status' | translate}}</h5>
                          <p placement="bottom" triggers="mouseenter:mouseleave"
                            ngbPopover="{{viewingProcessor?.status || ''}}" class="over-flow"><span
                              class="tx-size text-muted">{{viewingProcessor?.status || ''}}</span></p>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-4 col-lg-4">
                      <div>
                        <div>
                          <h5 placement="bottom" triggers="mouseenter:mouseleave"
                            ngbPopover="{{'processorList.hostbased' | translate}}" class="over-flow">
                            {{'processorList.hostbased' | translate}}</h5>
                          <p placement="bottom" triggers="mouseenter:mouseleave"
                            ngbPopover="{{viewingProcessor?.hostBasedAutoSettlement || ''}}" class="tx-13 over-flow">
                            <span class="tx-size text-muted">{{viewingProcessor?.hostBasedAutoSettlement || ''}}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 col-lg-4">
                      <div>
                        <div>
                          <h5 placement="bottom" triggers="mouseenter:mouseleave"
                            ngbPopover="{{'processorList.ownerId' | translate}}" class="over-flow">
                            {{'processorList.ownerId' | translate}}</h5>
                          <p placement="bottom" triggers="mouseenter:mouseleave"
                            ngbPopover="{{viewingProcessor?.OwnerId || ''}}" class="tx-13"><span
                              class="tx-size text-muted">{{viewingProcessor?.OwnerId || ''}}</span></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>