import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Subject, Subscription } from 'rxjs';
import { CommonService } from 'src/app/providers/common.service';
import { apiLinks } from "../../../../core/api-links/api-links";
import {isEmpty, get, capitalize} from 'lodash';
import { CustomerService } from "../../../../providers/customer.service";
import { InformationModalComponent } from 'src/app/shared/components/information-modal/information-modal.component';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import * as fs from 'file-saver';
import { DownloaderLoaderComponent } from 'src/app/shared/components/downloader-loader/downloader-loader.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-billing-invoice',
  templateUrl: './billing-invoice.component.html',
  styleUrls: ['./billing-invoice.component.scss']
})
export class BillingInvoiceComponent implements OnInit {

  page = 1;
  limit = 20;
  size = 20;
  total: number;
  totalPages: number;
  totalPage: number;
  isPagination = false;
  billingInvoiceListViewSub: Subscription;
  billingInvoiceListData: any;
  viewMode: number = 0;
  isLoading: boolean;
  billingInvoiceListUnFilteredData: any;
  masterCollectionSize: any;
  collectionSize: any;
  viewInvoice: any;
  isDownloadStatus: boolean = false;
  eventsSubject: Subject<void> = new Subject<void>();
  isShow: boolean = false;
  searchTerm: '';
  filterQuery: any;
  statusTypes = [];
  startYear = 2022;
  yearList:Number[] = [];
  // selectedMonth = Number(moment().format("M")) -1;
  selectedMonth;
  selectedYear: any;
  startingYear = 2022;
  validMonthYear = false;
  modalReference: NgbModalRef;
  paramDestination: any;

  monthList = moment.months().map((data, index) => {
    return {label:data, value: index};
  });

  constructor(
    private commonService: CommonService,
    private messageService: MessageService,
    private CustomerService: CustomerService,
    private ngbModal: NgbModal,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.jumpFromCustomersInvoiceGeneration();
    // this.getInvoiceDetails({ isFilter: false, page: this.page, size: this.size });
    this.filterQuery = {};
    this.isLoading = true;
    for(let i = this.startYear; i <= this.startingYear; i++) {
      this.yearList.push(i);
    }
  }

  status = ['COMMITTED','UNCOMMITTED','APPROVED','DECLINED'
    // { id: '0', name: 'COMMITTED' },
    // { id: '1', name: 'UNCOMMITTED' },
    // { id: '2', name: 'APPROVED' },
    // { id: '3', name: 'DECLINED' },
  ];

  selectedFilter(data, propertyName) {
    this.filterQuery['isFilter'] = true;
    this.filterQuery['size'] = 20;
    if(isEmpty(data) && data == '') {
      this.filterQuery[propertyName] = "";
    }
    else if(propertyName === 'status') {
      this.filterQuery[propertyName] = (data);
    }
    else if(propertyName === 'year'){
      this.filterQuery[propertyName] = (data);
    }
    else if(propertyName === 'month'){
      this.filterQuery[propertyName] = (data?.value + 1);
    }
    else if(propertyName === 'queryString') {
      this.filterQuery[propertyName] = data?.data != null ? data?.data : data?.data != undefined ? data?.data : ''
    }

    if(this.searchTerm){
      this.filterQuery['queryString'] = this.searchTerm;
    }
    this.getInvoiceDetails( { isFilter: true, page:this.page, ...this.filterQuery});
  }

  clear() {
    this.filterQuery = {'hostResponseCode':'', year:'', month:'', status:''};
    this.selectedYear = [];
    this.statusTypes = [];
    this.selectedMonth=[];
    this.eventsSubject.next();
    this.getInvoiceDetails( { isFilter: false, page:this.page, ...this.filterQuery});
  }

  getInvoiceDetails(filter) {
    this.billingInvoiceListViewSub = this.commonService.getCommonHttpClientQueryParams(apiLinks.getAllBillingInvoice, filter).subscribe(res => {
      this.isLoading = false;
      this.billingInvoiceListUnFilteredData = this.billingInvoiceListData = res.data.items;
      this.masterCollectionSize = this.collectionSize = res.data.totalItems;
      this.messageService.clear();
      this.viewInvoice = this.billingInvoiceListData.length > 0 ? this.billingInvoiceListData[0] : [];
      this.viewMode = 0;
      if (isEmpty(this.billingInvoiceListData)) {
        this.messageService.clear();
        this.showNoDataMessage();
      }
    },
      (err) => {
        this.showAlert(err, "error");
      }
    )
  }

  getPage(page: number) {
    this.page = page;
    this.getInvoiceDetails({ isFilter: false, page: this.page, size: this.size });
  }

  applyFilters(filteredData) {
    // this.getInvoiceList(filteredData);
    let {data, searchTerm} = filteredData;
    this.searchTerm=searchTerm;
    if(!isEmpty(data)) {
      this.billingInvoiceListData = data.data.items;
      this.viewInvoice = this.billingInvoiceListData.length > 0 ? this.billingInvoiceListData[0] : [];
      this.viewMode = 0;
      this.collectionSize = data.data.totalItems;
      this.total = data.data.totalItems;
    } else {
      this.billingInvoiceListData = this.billingInvoiceListUnFilteredData;
      if(this.billingInvoiceListData){
        this.viewInvoice = this.billingInvoiceListData.length > 0 ? this.billingInvoiceListData[0] : [];
      }
      this.viewMode = 0;
      this.collectionSize = this.masterCollectionSize;
      this.total = this.totalPage;
    }
  }

  toggleDisplay() {
    this.isShow = !this.isShow;
  }

  getUserDetails(invoice) {
    this.viewInvoice = invoice;
  }

  showNoDataMessage() {
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: 'Sorry! No Invoice exists.',
      sticky: true
    });
  }

  showAlert(data, alertType) {
    this.messageService.add({
      severity: alertType,
      summary: data.status,
      detail: data.message,
    });
  }

  selected(index) {
    this.viewMode = index;
  }

  refreshPage() {
    // this.refreshUsers$.next(true);
    this.getInvoiceDetails({page:this.page, size: 20, ...this.filterQuery})
  }

  openLoaderModal(){
    window.scroll(0,0);
    let ngbModalOptions: NgbModalOptions = {
      backdrop : 'static',
      keyboard : false,
      centered: true,
      size: 'lg',
    };
    this.modalReference =  this.ngbModal.open(DownloaderLoaderComponent, ngbModalOptions);
    this.modalReference.componentInstance.isLoading = true;
    this.modalReference.componentInstance.body = "Downloading";
  }

  downLoadISOInvoice() {
    const { isoId, month, year, excelFileLocation } = this.viewInvoice;
    if(excelFileLocation == "NA") {
      this.openLoaderModal();
      this.isDownloadStatus = true;
      this.commonService.getCommonHttpClientQueryParams(this.CustomerService.calculateISOMonthlyBillingSummary,
        { isoId, month, year, action: 'invoiceBillingExcelData' }).subscribe(
          ({ message: data }) => {
            if (data) {
              // this.invoiceConfirmationModal.close();
              // this.isCreateContractLoading = false;
              // this.showAlert({status:'success', message}, 'success');
              this.download(data);
            }
          },
          (err) => {
            this.modalReference.close();
            this.isDownloadStatus = false;
            this.showAlert(err, 'error');
          });
    } else {
      this.downLoadISOInvoiceBill("excel");
    }
  }

  download(data: { summary: any; details: any; merchantSummary: any; }) {
    this.isDownloadStatus = false;
    const { invoiceNumber, month, year } = this.viewInvoice;
    let summary = [data.summary];
    let fileName = `${data.summary.isoName}-${month}-${year}.xlsx`;
    let worksheetData = {
      "worksheet": [
        {
          "name": 'Details',
          "columns": [
            { header: "ISO DBA", key: "isoDba", width: 25 },
            { header: "Agent Office", key: "subIsoDba", width: 25 },
            { header: "Merchant DBA", key: "merchantDba", width: 25 },
            { header: "Store DBA", key: "storeName", width: 25 },
            { header: "Month/Year", key: "monthYear", width: 10 },
            { header: "TPN #", key: "tpn#", width: 15, style: { numFmt: '0' } },
            { header: "Activated On", key: "activatedOn", width: 13 },
            { header: "Deactivated On", key: "deactivatedOn", width: 13 },
            { header: "Bill as ( Primary/Add-on)", key: "billAs", width: 10 },
            { header: "# of billing days", key: "noOfBillingDays", width: 10 },
            { header: "Primary TPN Contract rate", key: "primaryTpnContractFee", width: 10, style:{numFmt:'$#,##0.0000;[Red]-$#,##0.0000'}},
            { header: "Add-on Contract rate", key: "addOnTpnContractFee", width: 10, style:{numFmt:'$#,##0.0000;[Red]-$#,##0.0000'} },
            { header: "Fixed Fee", key: "fixedFee", width: 10, style:{numFmt:'$#,##0.0000;[Red]-$#,##0.0000'} },
            { header: "Spin (Primary/Add-on)", key: "isPrimarySpin", width: 25 },
            { header: "Primary Spin rate", key: "spinPrimaryFee", width: 10, style:{numFmt:'$#,##0.0000;[Red]-$#,##0.0000'}},
            { header: "Add-on Spin rate", key: "spinAddOnFee", width: 10, style:{numFmt:'$#,##0.0000;[Red]-$#,##0.0000'} },
            { header: "Spin Fee", key: "spinFixedFee", width: 10, style:{numFmt:'$#,##0.0000;[Red]-$#,##0.0000'} },
           // { header: "# of Cloudpos Tx", key: "cPosTx", width: 10 },
           // { header: "CloudPOS Volume", key: "cPosVol", width: 10, style:{numFmt:'$#,##0.0000;[Red]-$#,##0.0000'} },
          //  { header: "CloudPOS Contract rate per Tx", key: "cPosTxContractFeePerTx", width: 10, style:{numFmt:'$#,##0.0000;[Red]-$#,##0.0000'} },
           // { header: "CloudPOS Contract rate on volume", key: "cPosTxContractVolPerTx", width: 10, style:{numFmt:'0.00%'} },
          //  { header: "CloudPOS Tx Fee", key: "cPosTxFee", width: 15 , style:{numFmt:'$#,##0.0000;[Red]-$#,##0.0000'}},
           // { header: "CloudPOS Volume Fee", key: "cPosTxVolFee", width: 15 , style:{numFmt:'$#,##0.0000;[Red]-$#,##0.0000'}},
          //  { header: "# of TOP Tx", key: "topTx", width: 15 },
          //  { header: "TOP volume", key: "topVol", width: 15 , style:{numFmt:'$#,##0.0000;[Red]-$#,##0.0000'}},
          //  { header: "TOP Contract rate per Tx", key: "topTxContractFeePerTx", width: 15 , style:{numFmt:'$#,##0.0000;[Red]-$#,##0.0000'}},
           // { header: "TOP Contract rate on volume", key: "topTxContractVolPerTx", width: 15 , style:{numFmt:'0.00%'}},
          //  { header: "TOP Tx Fee", key: "topTxFee", width: 15 , style:{numFmt:'$#,##0.0000;[Red]-$#,##0.0000'}},
          //  { header: "TOP Volume Fee", key: "topTxVolFee", width: 15 , style:{numFmt:'$#,##0.0000;[Red]-$#,##0.0000'}},
            { header: "Primary POS setup fee", key: "posPrimarySetUpFee", width: 10, style:{numFmt:'$#,##0.0000;[Red]-$#,##0.0000'}},
            { header: "Add-on POS setup fee", key: "posAddOnSetUpFee", width: 10, style:{numFmt:'$#,##0.0000;[Red]-$#,##0.0000'} },
            { header: "Setup Fee", key: "setUpFixedFee", width: 10, style:{numFmt:'$#,##0.0000;[Red]-$#,##0.0000'} },
            { header: "# of POS Tx", key: "posTx", width: 15 },
            { header: "POS volume", key: "posVol", width: 15 , style:{numFmt:'$#,##0.0000;[Red]-$#,##0.0000'}},
            { header: "POS Contract rate per Tx", key: "posTxContractFeePerTx", width: 15 , style:{numFmt:'$#,##0.0000;[Red]-$#,##0.0000'}},
            { header: "POS Contract rate on volume", key: "posTxContractVolPerTx", width: 15 , style:{numFmt:'0.00%'}},
            { header: "POS Tx Fee", key: "posTxFee", width: 15 , style:{numFmt:'$#,##0.0000;[Red]-$#,##0.0000'}},
            { header: "POS Volume Fee", key: "posTxVolFee", width: 15 , style:{numFmt:'$#,##0.0000;[Red]-$#,##0.0000'}},
            { header: "# of Tx SMS", key: "txSMS", width: 15 },
          //  { header: "# of Promo SMS", key: "promotionalSMS", width: 15 },
            { header: "Contract - Tx SMS", key: "txSmsContractFee", width: 15 , style:{numFmt:'$#,##0.0000;[Red]-$#,##0.0000'}},
          //  { header: "Contract - Promo SMS", key: "promotionalSmsContractFee", width: 15 , style:{numFmt:'$#,##0.0000;[Red]-$#,##0.0000'}},
            { header: "Tx SMS Fee", key: "txSmsFee", width: 15 , style:{numFmt:'$#,##0.0000;[Red]-$#,##0.0000'}},
          //  { header: "Promo SMS Fee", key: "promotionalSmsFee", width: 15 , style:{numFmt:'$#,##0.0000;[Red]-$#,##0.0000'}},
            { header: "Total", key: "totalBilledAmt", width: 20 , style:{numFmt:'$#,##0.00;[Red]-$#,##0.00',font:{bold: true,}}},
          ],
          "rows": data.details,
          "isRow": 0,
          frozen:true
        },
        {
          name: "MerchantSummary",
          "columns": [
            { header: "ISO DBA", key: "isoDBA", width: 25 },
            { header: "Merchant DBA", key: "merchantDBA", width: 15 },
            { header: "# of TPNs", key: "tpnCount", width: 15 },
            { header: "Fixed Fee", key: "fixedFee", width: 15, style:{numFmt:'$#,##0.0000;[Red]-$#,##0.0000'} },
            { header: "Spin Fee", key: "spinFixedFee", width: 15, style:{numFmt:'$#,##0.0000;[Red]-$#,##0.0000'} },
          //  { header: "CloudPos Variable Fee", key: "cPosVariableFee", width: 15 , style:{numFmt:'$#,##0.0000;[Red]-$#,##0.0000'}},
            { header: "Pos Variable Fee", key: "posVariableFee", width: 15 , style:{numFmt:'$#,##0.0000;[Red]-$#,##0.0000'}},
          //  { header: "TOP Variable Fee", key: "topVariableFee", width: 15 , style:{numFmt:'$#,##0.0000;[Red]-$#,##0.0000'}},
            { header: "Tx SMS Fee", key: "smsVariableFee", width: 15 , style:{numFmt:'$#,##0.0000;[Red]-$#,##0.0000'}},
            { header: "Total", key: "total", width: 15, style:{numFmt:'$#,##0.00;[Red]-$#,##0.00',font:{bold: true,}} }
          ],
          "rows": data.merchantSummary,
          "isRow": 0,
          frozen:true
        },
        {
          name: "Summary",
          "columns": [
            { header: "ISO DBA", key: "isoName", width: 25 },
            { header: "# of Merchants", key: "totalMerchant", width: 15 },
            { header: "# of TPNs", key: "totalTpn", width: 15 },
            { header: "Fixed Fee", key: "fixedFee", width: 15, style:{numFmt:'$#,##0.0000;[Red]-$#,##0.0000'} },
            { header: "Spin Fee", key: "spinFixedFee", width: 15, style:{numFmt:'$#,##0.0000;[Red]-$#,##0.0000'} },
            { header: "Variable Fee", key: "variableFee", width: 15 , style:{numFmt:'$#,##0.0000;[Red]-$#,##0.0000'}},
            { header: "SMS Fee", key: "smsFee", width: 15 , style:{numFmt:'$#,##0.0000;[Red]-$#,##0.0000'}},
            // { header: "Add On SMS Fee", key: "promotionalSmsFee", width: 15, style:{numFmt:'$#,##0.0000;[Red]-$#,##0.0000'} },
            { header: "Total", key: "total", width: 15, style:{numFmt:'$#,##0.00;[Red]-$#,##0.00',font:{bold: true,}} }
          ],
          "rows": summary,
          "isRow": 0
        }
      ],
      "fileName": fileName
    };

    this.commonService.downloadAsExcel(worksheetData).then(res => {
      this.modalReference.close();
    });
  }

  months = moment.monthsShort();
  getMonthName(value){
    return this.months[value - 1];
  }


  ngOnDestroy() {
    if (this.billingInvoiceListViewSub) this.billingInvoiceListViewSub.unsubscribe();
  }

  commitInvoice(status) {
    const {month, year, invoiceNumber} = this.viewInvoice;
    let currentMonth = Number(moment().format("M"));
    let currentYear = Number(moment().format("YYYY"));
    let invoiceCommitAlert = `Do you want to ${capitalize(status)} Invoice# `+invoiceNumber;

    let modalRef = this.ngbModal.open(InformationModalComponent, { ariaLabelledBy: 'modal-basic-title', centered: true, backdrop: 'static', keyboard: false });
    modalRef.componentInstance.header = "Invoice Confirmation";
    if(currentMonth == month && year == currentYear) {
      invoiceCommitAlert = `Do you want to proceed with ${capitalize(status)} invoice of current month (${moment().format("MMM")} - ${currentYear}).`;
      // modalRef.componentInstance.header = `Are you sure?`
    }
    modalRef.componentInstance.body = invoiceCommitAlert;
    modalRef.componentInstance.cancelButton = true;
    modalRef.componentInstance.commitButton = true;
    modalRef.componentInstance.commitButtonText = capitalize(status);
    modalRef.componentInstance.invoiceData = this.viewInvoice;
    // modalRef.componentInstance.invoiceStatus = status;
    modalRef.result.then((result) => {
      if (result == 'success') {
        this.showAlert({ status: `${capitalize(status)} Invoice`, message: `Invoice is ${status} Successfully` }, "success");
        this.refreshPage();
      }
    })
  }
  enableApprovalButton(invoiceData) {
    let currentUser = JSON.parse(localStorage.getItem('current_user'));
    return !(get(invoiceData,'invoiceStatus') == 'COMMITTED' && currentUser.userTypeRole === 'FINANCE_ADMIN');
  }
  downLoadISOInvoiceBill(fileType) {
    this.openLoaderModal();
      this.isDownloadStatus = true;
    this.commonService.getCommonFileDownload(apiLinks.downloadBillInvoicePDF+"/"+this.viewInvoice.id+"/"+fileType).subscribe(data => {
      let downloadURL = window.URL.createObjectURL(data);
      let fileName ="";
      if(fileType == 'pdf') {
        fileName = this.viewInvoice.pdfFileLocation.split("/");
      } else {
        fileName = this.viewInvoice.excelFileLocation.split("/");
      }
      fs.saveAs(downloadURL,fileName[fileName.length -1]);
      this.isDownloadStatus = false;
      this.modalReference.close();
    })
  }
  isNull(fileLocation) {
    return fileLocation == "NA";
  }

  jumpFromCustomersInvoiceGeneration() {
    return this.route.queryParams.subscribe(async (param) => {
      if (isEmpty(param)) {
        this.getInvoiceDetails({ isFilter: true, page: this.page, size: this.size, ...this.filterQuery});
      }
      else {
        // else if (param.tpn) {
          // this.paramDestination = param.route;
          this.getInvoiceDetails({ isFilter: true, page: this.page, size: this.size, status: param.status, year:param.year, month:param.month,queryString:param.queryString });

          // this.getInvoiceDetails({ isFilter: false, page: this.page, size: this.size });

        // } else if (param.dba) {
        //   this.paramDestination = param.route;
        //   this.getDeviceList({ isFilter: true, page: this.page, size: this.size, queryString: param.dba });
        // }
      }
    });
  }
}
