<p-toast position="top-right"></p-toast>
<div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">{{'Change TID' | translate}}</h5>

    <button type="button" class="close" (click)="modal.close()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="card list-view card-list-bg">

        <div class="card-body carding">
            <div class="row">

                <div class="col-md-7 scrolling-box">
                    <app-loading *ngIf="isLoading"></app-loading>
                    <div *ngIf="tpnList.length!=0" class="form-check form-check-flat form-check-primary pb-1 d-flex justify-content-between">
                        <div>
                            <label class="form-check-label">
                                <input type="checkbox" class="form-check-iform-check-labelnput" name="selectBox" value="h1"
                                    (change)="checkUncheckAll($event)" [(ngModel)]="masterSelectBox">
                                <i class="input-frame"></i>
                                <p class="ml-4">{{'htmlText.selectAll' | translate}}({{tpnList.length}})</p>
                            </label>
                        </div>

                    </div>


                    <div class="chat-body tpn-body">
                        <ngb-alert [dismissible]="false" [type]="'warning'" class="mt-2" *ngIf="tpnList.length == 0 && !isLoading">
                            <i class="link-icon feather icon-alert-triangle"></i>
                            {{'htmlText.noResFou' | translate}}
                        </ngb-alert>
                        <ul class="messages modal_height list-unstyled chat-list px-1">

                            <li class="chat-item" *ngFor="let tpns of tpnList; let i = index;">
                                <div class=" flex-grow pb-1 list-unstyled chat-list">
                                    <dl *ngIf="tpns.status == 'ACTIVE' || tpns.status == 'Active'" class="row border-bottom">
                                        <div class="col-md-12 row tpn-body-style back-hover">

                                            <div class="form-check form-check-flat form-check-primary ml-3 col-md-1">
                                                <label class="form-check-label">
                                                    <input type="checkbox" class="form-check-input" name="selectBox"
                                                        (change)="isAllSelected()" [(ngModel)]="tpns.isSelected"
                                                        value="{{tpns.id}}">
                                                    <i class="input-frame"></i>
                                                </label>
                                            </div>
                                            <div class="col-md-11 row pt-md-2">
                                                <div class="col-md-4 col-lg-4 col-sm-4">
                                                    <dt class="tpn_font_col">
                                                        <span placement="bottom" triggers="mouseenter:mouseleave" ngbPopover="Tpn"
                                                            class="mdi mdi-point-of-sale mr-1 mdi-20px font-weight-bold tpn_font_col">
                                                            {{'device.TPN' | translate}}
                                                        </span>
                                                    </dt>
                                                    <dd class="tpn_font_col overflow-hidden">
                                                        <div container="body">
                                                            <p>{{tpns?.tpn}}</p>

                                                        </div>
                                                    </dd>
                                                </div>
                                                <div class="col-md-4 col-lg-4 col-sm-4">
                                                    <dt>
                                                        <span placement="bottom" triggers="mouseenter:mouseleave"
                                                            ngbPopover="Device Model"
                                                            class="mdi mdi-devices mr-1 mdi-20px font-weight-bold tpn_font_col">
                                                            {{'htmlText.devModel' | translate}}
                                                        </span>
                                                    </dt>
                                                    <dd class="tpn_font_col">
                                                        <p>{{getModal(tpns?.deviceInfo)}}</p>
                                                    </dd>
                                                </div>
                                                <div class="col-md-4 col-lg-4 col-sm-4">
                                                    <dt>
                                                        <span placement="bottom" triggers="mouseenter:mouseleave"
                                                            ngbPopover="App Sign"
                                                            class="mdi mdi-application-cog mr-1 mdi-20px font-weight-bold tpn_font_col">
                                                            {{'htmlText.appSign' | translate}}
                                                        </span>
                                                    </dt>
                                                    <dd class="tpn_font_col">
                                                        <p>{{tpns?.applicationSign}}</p>
                                                    </dd>
                                                </div>

                                                <div class="col-md-4 col-lg-4 col-sm-4">
                                                    <dt>
                                                        <span placement="bottom" triggers="mouseenter:mouseleave"
                                                            ngbPopover="Assigned Buildno"
                                                            class="mdi mdi-application-cog mr-1 mdi-20px font-weight-bold tpn_font_col">
                                                            {{'htmlText.assBuild' | translate}}
                                                        </span>
                                                    </dt>
                                                    <dd class="tpn_font_col">
                                                        <p>{{tpns?.assignedBuildNumber}}</p>
                                                    </dd>
                                                </div>
                                                <div class="col-md-4 col-lg-4 col-sm-4">
                                                    <dt>
                                                        <span placement="bottom" triggers="mouseenter:mouseleave"
                                                            ngbPopover="Build Number"
                                                            class="mdi mdi-application-cog mr-1 mdi-20px font-weight-bold tpn_font_col">
                                                            {{'htmlText.buildNo' | translate}}
                                                        </span>
                                                    </dt>
                                                    <dd class="tpn_font_col">
                                                        <p>{{tpns?.buildNumber}}</p>
                                                    </dd>
                                                </div>
                                                <div class="col-md-4 col-lg-4 col-sm-4" placement="top" triggers="mouseenter:mouseleave"
                                                    ngbPopover="{{tpns?.createdAt | dateTimePipe}}">
                                                    <dt>
                                                        <span class="mdi mdi-calendar-blank-outline mr-1 mdi-20px font-weight-bold tpn_font_col">
                                                            {{'htmlText.createdAt' | translate}}
                                                        </span>
                                                    </dt>
                                                    <dd class="tpn_font_col">
                                                        <p class="over-flow">{{tpns?.createdAt | dateTimePipe}}</p>
                                                    </dd>
                                                </div>
                                                <div *ngIf="userType == 'SYSTEM'" class="col-md-4 col-lg-4 col-sm-4" placement="top" triggers="mouseenter:mouseleave"
                                                    ngbPopover="{{hostInventory?.ownerDba}}">
                                                    <dt>
                                                        <span class="mdi mdi-account-cog mr-1 mdi-20px font-weight-bold tpn_font_col">
                                                            {{'htmlText.iso' | translate}}
                                                        </span>
                                                    </dt>
                                                    <dd class="tpn_font_col">
                                                        <p class="over-flow">{{hostInventory?.ownerDba | translate}}</p>
                                                    </dd>
                                                </div>
                                                <div class="col-md-4 col-lg-4 col-sm-4">
                                                    <dt>
                                                        <span placement="bottom" triggers="mouseenter:mouseleave"
                                                            ngbPopover="Build Number"
                                                            class="mdi mdi-store mr-1 mdi-20px font-weight-bold tpn_font_col">
                                                            {{'htmlText.storeDba' | translate}}
                                                        </span>
                                                    </dt>
                                                    <dd class="tpn_font_col">
                                                        <p>{{tpns?.merchantInfo?.dba}}</p>
                                                    </dd>
                                                </div>
                                                <div class="col-md-4 col-lg-4 col-sm-4">
                                                    <dt>
                                                        <span placement="bottom" triggers="mouseenter:mouseleave"
                                                            ngbPopover="Build Number"
                                                            class="mdi mdi-form-select mr-1 mdi-20px font-weight-bold tpn_font_col">
                                                            {{'htmlText.SerialNo' | translate}}
                                                        </span>
                                                    </dt>
                                                    <dd class="tpn_font_col">
                                                        <p>{{tpns?.serialNumber}}</p>
                                                    </dd>
                                                </div>
                                            </div>

                                        </div>
                                    </dl>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-5 chat-aside border-lg-right">

                    <h5>{{'hostInventory.CurrentTID' | translate}}</h5>
                    <div class="aside-content">

                        <div class="aside-body">
                            <div #container class="container" class="ps" [perfectScrollbar]>
                                <p class="text-muted mb-1"></p>
                                <div [class.active]="true"
                                    class="ist-unstyled chat-list p-3 mb-2 back-ground-color back-hover"
                                    >
                                    <div class="chat-item pr-1">
                                        <a class="d-flex align-items-center">
                                            <div class="flex-grow">
                                                <div>
                                                    <p class="text-body tx-size over-flow ml-1">
                                                        {{hostInventory?.processorName}}</p>
                                                    <hr>
                                                    <div class="d-flex justify-content-between">
                                                        <p class="text-body over-flow ml-1"><span
                                                                class="large-fontsize-label"><strong>{{'hostInventory.tid'
                                                                    | translate}}:</strong></span><span
                                                                class="large-fontsize"> {{hostInventory?.tid}}</span>
                                                        </p>
                                                        <p class="text-body mr-1"><span
                                                                class="large-fontsize-label"><strong>{{'hostInventory.ownerId'
                                                                    | translate}}
                                                                    :</strong></span><span class="large-fontsize">
                                                                {{hostInventory?.ownerId}}</span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="checkedCategoryList.length!=0">
                        <h5 class="mt-2">{{'hostInventory.ChangeTo' | translate}}</h5>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group required input_mar_pro mt-2">
                                    <!-- <label for="processor">{{'htmlText.prsc' | translate}}</label><br><br> -->
                                    <ng-select class="disable-clear-all" [clearable]="false" [items]="searchProcessorList"
                                        [multiple]="false" [loading]="processorLoading" bindLabel="name" bindValue="id"
                                        labelForId="processor" placeholder="Select processor" clearAllText="Clear"
                                        notFoundText="No processor found" [(ngModel)]="selectedProcessId"
                                        (change)="changedProcessor($event)">
                                    </ng-select>
                                    <div class="regsiter-form-fields-select"></div>
                                    <!-- <div class="register-error-message"
                                                      *ngIf="!merchantForm.get(['deviceInfo','processorId']).valid && (merchantForm.get(['deviceInfo','processorId']).dirty || merchantForm.get(['deviceInfo','processorId']).touched)">
                                                      <div *ngIf="merchantForm.get(['deviceInfo','processorId'])?.errors">
                                                        <ngb-alert [dismissible]="false" [type]="'danger'"
                                                          *ngIf="merchantForm.get(['deviceInfo','processorId']).errors.required">
                                                          {{'errorMsg.ProcessorRequired' | translate}}
                                                        </ngb-alert>
                                                      </div>
                                                    </div> -->
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group required floating-label input_mar_pro mt-2">

                                    <ng-select class="disable-clear-all" [clearable]="false" [items]="searchTidProcessorList"
                                        bindLabel="tid" bindValue="tid" labelForId="tid"
                                        placeholder="Select TID" clearAllText="Clear" maxlength="15" notFoundText="No TID found"
                                        [(ngModel)]="hostInventoryTid_Id" (change)="tidSelect($event)">
                                    </ng-select>
                                    <div class="regsiter-form-fields"></div>
                                    <!-- <div class="register-error-message"
                                                    *ngIf="!profileForm.get(['processorData','tid']).valid && (profileForm.get(['processorData','tid']).dirty || profileForm.get(['processorData','tid']).touched)">
                                                    <ngb-alert [dismissible]="false" [type]="'danger'"
                                                      *ngIf="profileForm.get(['processorData','tid']).errors.required">
                                                      {{'errorMsg.freTidRequired' | translate}}
                                                    </ngb-alert>
                                                    <ngb-alert [dismissible]="false" [type]="'danger'"
                                                      *ngIf="profileForm.get(['processorData','tid']).errors.hastidNumber || profileForm.get(['processorData','tid']).errors.hastidAgentRegex">
                                                      {{'errorMsg.freTidLengthRequired' | translate}}
                                                    </ngb-alert>
                                                  </div> -->
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 text-right">
                                <!-- <button href="" (click)="saveTid()" class="btn btn-primary mr-2 " [disabled]="!selectedProcessId ||
                                !hostInventoryTid_Id">
                                    {{'hostInventory.save' | translate}}
                                </button> -->

                                <button type="submit" (click)="saveTid()" class="btn btn-primary float-right mb-2 mr-2" [disabled]="!selectedProcessId ||
                                !hostInventoryTid_Id || isSaveLoading">
                                    <span *ngIf="isSaveLoading" class="spinner-border spinner-border-sm" role="status"
                                      aria-hidden="true"></span><span class="loader_ml">{{'hostInventory.save' | translate}}</span>
                                  </button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>



            <!-- <div class="card-footer">
            <ngb-pagination
              *ngIf="tpnsLabel(getViewMerchant) != 0  && isPagination == true || tpnCollectionSize"
              class="d-flex justify-content-end mob-media mt-md-2" [collectionSize]="tpnCollectionSize"
              [(page)]="tpnPage" [maxSize]="3" [rotate]="true" [pageSize]="tpnPageSize"
              (pageChange)=getTpnPage();></ngb-pagination>
          </div> -->
        </div>
    </div>
</div>
