import { Injectable, ErrorHandler } from "@angular/core";
import { BehaviorSubject, Subject, Observable, throwError } from "rxjs";
import { Router } from "@angular/router";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { map, catchError } from "rxjs/operators";
import { apiLinks } from "../core/api-links/api-links";
import { Modules } from "../core/model/modules.enum";
import { isEmpty } from "lodash";

import { Users } from "../core/model/user";
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private currentUser = new BehaviorSubject(null);
  private logoutUrl = `${environment.apiUrl}/user-logout`;
  private userUrl = `${environment.apiUrl}/users`;
  private errorHandler: ErrorHandler;

  constructor(private router: Router, private httpClient: HttpClient) {
    const getUser = localStorage.getItem("current_user");
    this.currentUser.next(JSON.parse(getUser));
  }

  get getUser() {
    return this.currentUser.value;
  }

  setUser(user) {
    localStorage.setItem("current_user", JSON.stringify(user));
    this.currentUser.next(user);
    return user;
  }

  updateUser(newData) {
    let user={
      ...this.getUser,
      ...newData
    };
    localStorage.removeItem("current_user");
    localStorage.setItem("current_user", JSON.stringify(user));
    this.currentUser.next(user);
    return user;
  }

  logout() {
    localStorage.removeItem("current_user");
    localStorage.setItem("ip", '');
    localStorage.setItem("port", '');
    this.currentUser.next(null);
    this.router.navigate(["/auth/login"]);
  }

  logoutService() {
    return this.httpClient.delete(`${this.logoutUrl}`).pipe(
      map((res) => {
        this.logout();
        return res;
      })
    );
  }

  getPrivileges(moduleName) {
    if (this.getUser !== null) {
      let roleFeatures = this.getUser.roleFeatures;
      return roleFeatures.find(({ module }) => module == moduleName);
    }
    return false;
  }

  isFeatureEnabled(moduleName, featureName) {

    if(this.getPrivileges(moduleName)){
      let featureList = this.getPrivileges(moduleName).features;
      return featureList.indexOf(featureName) > -1 ? true : false;
    }else{
      return false;
    }


  }
  getUserType() {
    return this.getUser.userType;
  }
  getCustomerID() {
    return this.getUser?.isoIdentified;
  }

  handleError(error) {
    let errorMsg = {};

    if (error instanceof ErrorEvent) {
      errorMsg["status"] = error.error.status;
      errorMsg["message"] = error.error.message;
    } else {
      errorMsg["status"] = error.status;
      errorMsg["message"] = error.error;
    }

    return throwError(errorMsg);
  }

  refreshToken(token): Observable<any> {
    return;
  }



  encryptData(data) {

    try {
      return CryptoJS.AES.encrypt(JSON.stringify(data), environment.secretKey).toString();
    } catch (e) {
      console.log(e);
    }

  }

  decryptData(data) {

    console.log("bytes");
    try {
      const bytes = CryptoJS.AES.decrypt(data, environment.secretKey);

      if (bytes.toString()) {

        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      }
      return data;
    } catch (e) {
      console.log(e);
    }

  }

  subIsoToAgentOffice(item){
    //console.log(this.authService.getUser.userType);
    const searchRegExp = /\b(?:Sub Iso|SUB_ISO)\b/gi;

    const replaceWith = 'Agent Office';
    const result = item.replace(searchRegExp, replaceWith);

    return result;

  }

  getSecurityCheck() {  
    return this.httpClient.get(`${apiLinks.securityCheck}`).pipe(
       map((res:any) => {
         return res?.data;
       })
     );
    }

  assignPrivileges(module: Modules, access) {
      const userAccess = this.getPrivileges(module);
      if (isEmpty(userAccess)) {
        return access
      }
      const features = userAccess?.features as string[]
      for (const key of Object.keys(access)) {
        if (features.includes(key)) {
          access[key] = true
        }
      }
      return access
  }

}
