<nav class="navbar">
  <a href="" class="sidebar-toggler" (click)="toggleSidebar($event)">
    <i class="feather icon-menu"></i>
  </a>
  <div class="navbar-content">
    <!-- <form class="search-form">
      <div class="input-group">
        <div class="input-group-prepend">
          <div class="input-group-text">
            <i class="feather icon-search"></i>
          </div>
        </div>
        <input type="text" class="form-control" id="navbarForm" placeholder="Search here...">
      </div>
    </form> -->
    <ul class="navbar-nav">
      <li class="nav-item" ngbDropdown>
        <a class="nav-link" ngbDropdownToggle id="languageDropdown" role="button">
            <i class="flag-icon flag-icon-{{selectedLanguage.icon}} mt-1" title="us"></i> <span class="font-weight-medium ml-1 mr-1 d-none d-md-inline-block">{{selectedLanguage.name}}</span>
        </a>
        <div ngbDropdownMenu aria-labelledby="languageDropdown">
          <a *ngFor="let language of languages; let i = index;" ngbDropdownItem class="py-2" (click)="useLanguage(i)"><i class="flag-icon flag-icon-{{language.icon}}" title="{{language.code}}" id="{{language.code}}"></i> <span class="ml-1"> {{language.name}} </span></a>
          <!-- <a ngbDropdownItem class="py-2" (click)="useLanguage('es')"><i class="flag-icon flag-icon-es" title="es" id="es"></i> <span class="ml-1"> Spanish </span></a>
          <a ngbDropdownItem class="py-2" (click)="useLanguage('fr')"><i class="flag-icon flag-icon-fr" title="fr" id="fr"></i> <span class="ml-1"> French </span></a>
          <a ngbDropdownItem class="py-2" (click)="useLanguage('in')"><i class="flag-icon flag-icon-in" title="in" id="in"></i> <span class="ml-1"> Tamil </span></a> -->
        </div>
      </li>
      <!-- <li class="nav-item nav-apps" ngbDropdown>
        <a class="nav-link" ngbDropdownToggle id="appsDropdown">
          <i class="link-icon feather icon-grid"></i>
        </a>
        <div ngbDropdownMenu aria-labelledby="appsDropdown">
          <div class="dropdown-header d-flex align-items-center justify-content-between">
            <p class="mb-0 font-weight-medium">Web Apps - test</p>
            <a href="" (click)="false" class="text-muted">{{'navbar-options.edit' | translate}}</a>
          </div>
          <div class="dropdown-body">
            <div class="d-flex align-items-center apps">
              <a routerLink="/apps/chat"><i class="feather icon-message-square"></i><p>{{'navbar-options.chat' | translate}}</p></a>
              <a routerLink="/apps/calendar"><i class="feather icon-calendar"></i><p>{{'navbar-options.calendar' | translate}}</p></a>
              <a routerLink="/apps/email/inbox"><i class="feather icon-mail"></i><p>{{'navbar-options.email' | translate}}</p></a>
              <a routerLink="/general/profile"><i class="feather icon-instagram"></i><p>{{'navbar-options.profile' | translate}}</p></a>
            </div>
          </div>
          <div class="dropdown-footer d-flex align-items-center justify-content-center">
            <a href="" (click)="false">{{'navbar-options.view-all' | translate}}</a>
          </div>
        </div>
      </li> -->
     
      <!-- <li class="nav-item nav-messages" ngbDropdown>
        <a class="nav-link" ngbDropdownToggle id="messageDropdown">
          <i class="link-icon feather icon-mail"></i>
        </a>
        <div ngbDropdownMenu aria-labelledby="messageDropdown">
          <div class="dropdown-header d-flex align-items-center justify-content-between">
            <p class="mb-0 font-weight-medium">9 {{'navbar-options.new-messages' | translate }} -test</p>
            <a href="" (click)="false" class="text-muted">{{'navbar-options.clear-all' | translate}}</a>
          </div>
          <div class="dropdown-body">
            <a href="" (click)="false" class="dropdown-item">
              <div class="figure">
                <img src="https://via.placeholder.com/30x30" alt="userr">
              </div>
              <div class="content">
                <div class="d-flex justify-content-between align-items-center">
                  <p>testing</p>
                  <p class="sub-text text-muted">2 {{'navbar-options.mins-ago' | translate}}</p>
                </div>	
                <p class="sub-text text-muted">{{'navbar-options.project-status' | translate}}</p>
              </div>
            </a>
          </div>
          <div class="dropdown-footer d-flex align-items-center justify-content-center">
            <a href="" (click)="false">{{'navbar-options.view-all' | translate}}</a>
          </div>
        </div>
      </li> -->
      <!-- <li class="nav-item nav-notifications" ngbDropdown>
        <a class="nav-link" ngbDropdownToggle id="notificationDropdown">
          <i class="link-icon feather icon-bell"></i>
          <div class="indicator">
            <div class="circle"></div>
          </div>
        </a>
        <div ngbDropdownMenu aria-labelledby="notificationDropdown">
          <div class="dropdown-header d-flex align-items-center justify-content-between">
            <p class="mb-0 font-weight-medium">6 {{'navbar-options.new-notifications' | translate}} - test</p>
            <a href="" (click)="false" class="text-muted">Clear all</a>
          </div>
          <div class="dropdown-body">
            <a href="" (click)="false" class="dropdown-item">
              <div class="icon">
                <i class="feather icon-user-plus"></i>
              </div>
              <div class="content">
                <p>testing</p>
                <p class="sub-text text-muted">2 {{'navbar-options.sec-ago' | translate}}</p>
              </div>
            </a>
          </div>
          <div class="dropdown-footer d-flex align-items-center justify-content-center">
            <a href="" (click)="false">{{'navbar-options.view-all' | translate}}</a>
          </div>
        </div>
      </li> -->

       <!-- start Theme Color --> 
       <li class="nav-item nav-messages" ngbDropdown>
        <a class="nav-link" ngbDropdownToggle id="messageDropdown">
          <i class="link-icon feather icon-settings"></i>
        </a>
        <div ngbDropdownMenu aria-labelledby="notificationDropdown">
          <div class="dropdown-header d-flex align-items-center justify-content-between">
            <p class="mb-0 font-weight-medium">{{'navbar-options.theme-color' | translate}}</p>
          </div>

          <div class="dropdown-body">
              <div class="custom-control custom-checkbox">
                <input type="radio" class="custom-control-input" name="Cyan" 
                (change)="onSidebarThemeChange('theme-cyan-light')" id="theme-cyan-light" 
                [checked] = "iscyanchecked">
                <label class="custom-control-label" for="theme-cyan-light">Cyan</label>
              </div>

              <div class="custom-control custom-checkbox">
                <input type="radio" class="custom-control-input" name="Bluegrey" 
                (change)="onSidebarThemeChange('theme-bluegrey-light')" id="theme-bluegrey-light" 
                [checked] = "isbluechecked">
                <label class="custom-control-label" for="theme-bluegrey-light">Bluegrey</label>
              </div>

            <!-- <a class="dropdown-item" href="#">
              <div class="custom-control custom-checkbox">
                <input type="radio" class="custom-control-input" id="checkbox3" name="option2" (change)="onSidebarThemeChange('theme-bluegrey-light')" [checked] = "isbluechecked">
                <label class="custom-control-label" for="checkbox3">Bluegrey</label>
              </div>
            </a> -->
          </div>

        </div> 
      </li>
      <!-- end Theme Color -->
      <li class="nav-item nav-profile" ngbDropdown>
        <a class="nav-link" ngbDropdownToggle id="profileDropdown">
          <p-avatar [label]="getInitials()" shape="circle"></p-avatar>
        </a>
        <div ngbDropdownMenu aria-labelledby="profileDropdown" *ngIf="currentUser.email">
          <div class="dropdown-header d-flex flex-column align-items-center">
            <div class="figure mb-3">
              <p-avatar [label]="getInitials()" shape="circle" size="xlarge"></p-avatar>
            </div>
            <div class="info text-center">
              <p class="name font-weight-bold mb-0">{{currentUser.firstName}} {{currentUser.lastName}}</p>
              <p class="email text-muted mb-0">{{currentUser.email}}</p>
              <p class="user-type text-muted mb-3">{{agentOfficeRoleChange(currentUser.userType)}}</p>
            </div>
          </div>
          <div class="dropdown-body">
            <ul class="profile-nav p-0 pt-3">
              <!-- <li class="nav-item">
                <a routerLink="/general/profile" class="nav-link">
                  <i class="feather icon-user"></i>
                  <span>{{'navbar-options.profile' | translate}}</span>
                </a>
              </li> -->
              <!-- <li class="nav-item">
                <a href="" (click)="false" class="nav-link">
                  <i class="feather icon-edit"></i>
                  <span>{{'navbar-options.edit-profile' | translate}}</span>
                </a>
              </li> -->
              <!-- <li class="nav-item">
                <a href="" (click)="false" class="nav-link">
                  <i class="feather icon-repeat"></i>
                  <span>{{'navbar-options.switch-user' | translate}}</span>
                </a>
              </li> -->
              <li class="nav-item">
                <a (click)="onLogout()" class="nav-link">
                  <i class="feather icon-log-out"></i>
                  <span>{{'navbar-options.logout' | translate}}</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </li>
    </ul>
  </div>
</nav>