import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";
import * as CryptoJS from 'crypto-js';
import { CustomValidators } from "../../../../core/custom-validator/custom-validators";
import { ActivatedRoute, Router } from "@angular/router";
import { UserService } from "../../../../providers/user.service";
import { MessageService } from "primeng/api";
import { environment } from "../../../../../environments/environment";

@Component({
  selector: "app-password-reset",
  templateUrl: "./password-reset.component.html",
  styleUrls: ["./password-reset.component.scss"],
  providers: [MessageService],
})
export class PasswordResetComponent implements OnInit {
  public resetForm: FormGroup;
  displayBasic: boolean;
  queryParams = {};
  isLoading:boolean = false;
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private userService: UserService,
    private router: Router,
    private messageService: MessageService
  ) {
    //console.log(this.route.snapshot.queryParams);
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.queryParams = params;
    });
    this.resetForm = this.createForgotPasswordForm();
  }

  createForgotPasswordForm(): FormGroup {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
    return this.formBuilder.group(
      {
        password: [
          "",
          [
            Validators.required,
            CustomValidators.PatternValidator(regex, { hasPassword: true }),
          ],
        ],
        cpassword: ["", [Validators.required]],
      },
      { validators: CustomValidators.PassswordMatch }
    );
  }

  onFormSubmit() {
    if (!this.resetForm.valid) {
      this.validateAllFields(this.resetForm);
      //this.showSuccessDialog();
    } else {
      this.passwordReset(this.resetForm.value, this.queryParams);
      this.isLoading = true;
    }
  }

  validateAllFields(form: FormGroup) {
    Object.keys(form.controls).forEach((field) => {
      const control = form.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFields(control);
      }
    });
  }

  passwordReset(resetForm, queryParams) {
    if (
      this.queryParams &&
      Object.keys(this.queryParams).length === 0 &&
      this.queryParams.constructor === Object
    ) {
      let errMsg = {
        status: "Unauthorized Access",
        message: "You do not have permission to reset password",
      };

      this.showAlert(errMsg, "error");
    } else {
      let encryptedPassword = this.encryptPassword(resetForm.password);
      let encryptedConfirmPassword = this.encryptPassword(resetForm.cpassword);
      resetForm.password = encryptedPassword;
      resetForm.cpassword = encryptedConfirmPassword;
      let formData = {
        ...resetForm,
        ...queryParams,
      };
      this.userService.resetPassword(formData).subscribe(
        (res) => {
          // this.showAlert(
          //   { status: "Success!", message: res.message },
          //   "success"
          // );
          //

          this.showSuccessDialog();
          this.isLoading = false;
        },
        (err) => {
          this.isLoading = false;
          this.showAlert(err, "error");
        }
      );
    }
  }

  encryptPassword(password) {
    return CryptoJS.AES.encrypt(password, environment.secretKeyCryptoToken).toString();
  }

  decryptPassword(password) {
    return CryptoJS.AES.decrypt(password, environment.secretKeyCryptoToken).toString(CryptoJS.enc.Utf8);
  }

  goToLogin() {
    this.displayBasic = false;
    this.isLoading = false;
    this.router.navigate(["/auth/login"]);
  }

  showAlert(data, alertType) {
    this.messageService.add({
      severity: alertType,
      summary: data.status,
      detail: data.message,
      life: 3000,
    });
  }

  showSuccessDialog() {
    this.displayBasic = true;
  }
}
