<p-toast position="top-right"></p-toast>
<div class="container-fluid">
  <form (ngSubmit)="onFormSubmit()" [formGroup]="hostInventoryForm" autocomplete="off">
    <div class="card mb-4">
      <div class="card-body" style="    margin-top: -21px;
      margin-bottom: -21px;">
      <div class="row">
        <div class="col-md-3">
          <div class="form-group required float-form-group">
            <label for="processor">Processor</label><br><br>
            <ng-select class="disable-clear-all" [clearable]="false" [items]="searchProcessorList"
              [loading]="processorLoading" bindLabel="name" bindValue="id" labelForId="processor"
              placeholder="Select processor" clearAllText="Clear" formControlName="processorId"
              notFoundText="No processor found" (change)="selectPro($event)">
            </ng-select>
            <div class="regsiter-form-fields-select"></div>
            <div class="register-error-message"
              *ngIf="!hostInventoryForm.get('processorId').valid && (hostInventoryForm.get('processorId').dirty || hostInventoryForm.get('processorId').touched)">
              <div *ngIf="hostInventoryForm.get('processorId')?.errors">
                <ngb-alert [dismissible]="false" [type]="'danger'"
                  *ngIf="hostInventoryForm.get('processorId').errors.required">
                  {{'errorMsg.ProcessorRequired' | translate}}
                </ngb-alert>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3" *ngIf="isSelectedPro">
          <div class="form-group required float-form-group">
            <div class="owner-adjust">
              <span class="font-weight-light pl-1">{{'customers.owner' | translate}} * </span>
              <span *ngIf="!isOwnerReadOnly" class="btn p-0" placement="right" container="body" triggers="mouseenter:mouseleave"
                [ngbPopover]="'Please enter 3 or more characters'">
                <i class="link-icon feather icon-help-circle"></i>
              </span>
            </div>
            <ng-select [items]="customersDba$ | async" bindLabel="dba" bindValue="id" placeholder="Search DBA or Email"
              formControlName="customerId" [closeOnSelect]="true" [clearable]="true" [loading]="isCustomersLoading"
              notFoundText="No customer found" typeToSearchText="Please enter 3 or more characters" [typeahead]="inputDba$" [readonly]="isOwnerReadOnly">
              <ng-template ng-option-tmp let-item="item">
                {{item.dba}} <br />
                <small>{{item.email}}</small>
              </ng-template>
            </ng-select>
          </div>
        </div>
      </div>
      </div>
    </div>
    <div *ngIf="isSelectedPro">
      <div class="card">
        <div class="card-body">
          <div class="mt-4">
            <div class="col-md-12">
              <div>
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-3">
                      <div class="form-group required floating-label">
                        <input type="text" class="form-control floating-input" formControlName="tid" placeholder=" "
                          trim="blur" (ngModelChange)="tidFieldExistenceCheck($event)" maxlength="15">
                        <span class="spinner-border spinner-border-sm loader_right" role="status" aria-hidden="true"
                          *ngIf="isSpinnerTid"></span>
                        <label for="tid">{{'htmlText.tid' | translate}}</label>
                        <div class="regsiter-form-fields"></div>
                        <div class="register-error-message"
                          *ngIf="!hostInventoryForm.get('tid').valid && (hostInventoryForm.get('tid').dirty || hostInventoryForm.get('tid').touched)">
                          <ngb-alert [dismissible]="false" [type]="'danger'"
                            *ngIf="hostInventoryForm.get('tid').errors.required">
                            {{'errorMsg.TIDRequired' | translate}}
                          </ngb-alert>
                          <ngb-alert [dismissible]="false" [type]="'danger'"
                            *ngIf="hostInventoryForm.get('tid').errors.hasAlphaNum || hostInventoryForm.get('tid').errors.hasMidlength">
                            {{'errorMsg.TIDAlphaNumrRequired' | translate}}
                          </ngb-alert>
                        </div>
                        <ngb-alert style="margin-top: -24px;" [dismissible]="false" [type]="'danger'"
                                          *ngIf="errMsgs && hostInventoryForm.get('tid').valid">
                                          {{'errorMsg.tidAlreadyExists' | translate}}
                                        </ngb-alert>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="form-group required floating-label">
                        <input type="text" class="form-control floating-input" formControlName="ownerId" placeholder=" "
                          trim="blur" maxlength="8" (ngModelChange)="ownerFieldExistenceCheck($event)">
                        <span class="spinner-border spinner-border-sm loader_right" role="status" aria-hidden="true"
                          *ngIf="isSpinnerOwner"></span>
                        <label for="ownerId">{{'htmlText.ownerId' | translate}}</label>
                        <div class="regsiter-form-fields"></div>
                        <div class="register-error-message"
                          *ngIf="!hostInventoryForm.get('ownerId').valid && (hostInventoryForm.get('ownerId').dirty || hostInventoryForm.get('ownerId').touched)">
                          <ngb-alert [dismissible]="false" [type]="'danger'"
                            *ngIf="hostInventoryForm.get('ownerId').errors.required">
                            {{'errorMsg.OwnerRequired' | translate}}
                          </ngb-alert>
                          <ngb-alert [dismissible]="false" [type]="'danger'"
                            *ngIf="hostInventoryForm.get('ownerId').errors.hasAlphaNum || hostInventoryForm.get('ownerId').errors.hasOwnerRegex">
                            {{'errorMsg.OwnerAlphaNumRequired' | translate}}
                          </ngb-alert>
                        </div>
                        <!-- <ngb-alert style="margin-top: -24px;" [dismissible]="false" [type]="'danger'"
                                          *ngIf="errMsgsOwner && hostInventoryForm.get('ownerId').valid">
                                          {{'errorMsg.ownerAlreadyExists' | translate}}
                                        </ngb-alert> -->
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="form-group required floating-label">
                        <input type="number" class="form-control floating-input" formControlName="nearestDenomination"
                          (keypress)='onkeypressNear($event.target)' placeholder=" " trim="blur" maxlength="8">
                        <label for="TermId">{{'htmlText.nearDon' | translate}}</label>
                        <div></div>
                        <div class="regsiter-form-fields"></div>
                        <div class="register-error-message"
                          *ngIf="!hostInventoryForm.get('nearestDenomination').valid && (hostInventoryForm.get('nearestDenomination').dirty || hostInventoryForm.get('nearestDenomination').touched)">
                          <ngb-alert [dismissible]="false" [type]="'danger'"
                            *ngIf="hostInventoryForm.get('nearestDenomination').errors.required">
                            {{'errorMsg.NearDemonRequired' | translate}}
                          </ngb-alert>
                          <ngb-alert [dismissible]="false" [type]="'danger'"
                            *ngIf="hostInventoryForm.get('nearestDenomination').errors.hasNumber || hostInventoryForm.get('nearestDenomination').errors.haslengthNearDemonRegex">
                            {{'errorMsg.NearDemonNumRequired' | translate}}
                          </ngb-alert>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="form-group required floating-label">
                        <input type="number" class="form-control floating-input" formControlName="cashBox1Denomination"
                          (keypress)='onkeypresscashBox1($event.target)' placeholder=" " trim="blur" maxlength="9">
                        <label for="cashBox1Denomination">{{'htmlText.cashBox1Don' | translate}}</label>
                        <div></div>
                        <div class="regsiter-form-fields"></div>
                        <div class="register-error-message" style="top: 26px;"
                          *ngIf="!hostInventoryForm.get('cashBox1Denomination').valid && (hostInventoryForm.get('cashBox1Denomination').dirty || hostInventoryForm.get('cashBox1Denomination').touched)">
                          <ngb-alert [dismissible]="false" [type]="'danger'"
                            *ngIf="hostInventoryForm.get('cashBox1Denomination').errors.required">
                            {{'errorMsg.CashBox1DemonRequired' | translate}}
                          </ngb-alert>
                          <ngb-alert [dismissible]="false" [type]="'danger'"
                            *ngIf="hostInventoryForm.get('cashBox1Denomination').errors.hasNumber || hostInventoryForm.get('cashBox1Denomination').errors.haslengthCashBox1DemonRegex">
                            {{'errorMsg.CashBox1DemonNumRequired' | translate}}
                          </ngb-alert>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="form-group required floating-label">
                        <input type="number" class="form-control floating-input" formControlName="cashBox1Total"
                          (keypress)='onkeypresscashBox1Total($event.target)' placeholder=" " trim="blur"
                          maxlength="9">
                        <label for="cashBox1Total">{{'htmlText.cashBox1' | translate}}</label>
                        <div class="regsiter-form-fields"></div>
                        <div class="register-error-message"
                          *ngIf="!hostInventoryForm.get('cashBox1Total').valid && (hostInventoryForm.get('cashBox1Total').dirty || hostInventoryForm.get('cashBox1Total').touched)">
                          <ngb-alert [dismissible]="false" [type]="'danger'"
                            *ngIf="hostInventoryForm.get('cashBox1Total').errors.required">
                            {{'errorMsg.CashBox1TotalRequired' | translate}}
                          </ngb-alert>
                          <ngb-alert [dismissible]="false" [type]="'danger'"
                            *ngIf="hostInventoryForm.get('cashBox1Total').errors.hasNumber || hostInventoryForm.get('cashBox1Total').errors.haslengthCashBox1Regex">
                            {{'errorMsg.CashBox1TotalNumRequired' | translate}}
                          </ngb-alert>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="form-group required floating-label">
                        <input type="number" class="form-control floating-input" formControlName="cashBox2Denomination"
                          (keypress)='onkeypresscashBox2De($event.target)' placeholder=" " trim="blur" maxlength="9">
                        <label for="cashBox2Denomination">{{'htmlText.cashBox2Don' | translate}}</label>
                        <div></div>
                        <div class="regsiter-form-fields"></div>
                        <div class="register-error-message" style="top: 26px;"
                          *ngIf="!hostInventoryForm.get('cashBox2Denomination').valid && (hostInventoryForm.get('cashBox2Denomination').dirty || hostInventoryForm.get('cashBox2Denomination').touched)">
                          <ngb-alert [dismissible]="false" [type]="'danger'"
                            *ngIf="hostInventoryForm.get('cashBox2Denomination').errors.required">
                            {{'errorMsg.CashBox2DenomRequired' | translate}}
                          </ngb-alert>
                          <ngb-alert [dismissible]="false" [type]="'danger'"
                            *ngIf="hostInventoryForm.get('cashBox2Denomination').errors.hasNumber || hostInventoryForm.get('cashBox2Denomination').errors.haslengthCashBox2DemonRegex">
                            {{'errorMsg.CashBox2DenomNumRequired' | translate}}
                          </ngb-alert>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="form-group required floating-label">
                        <input type="number" class="form-control floating-input" formControlName="cashBox2Total"
                          (keypress)='onkeypresscashBox2To($event.target)' placeholder=" " trim="blur" maxlength="9">
                        <label for="hostInventoryForm">{{'htmlText.cashBox2' | translate}}</label>
                        <div></div>
                        <div class="regsiter-form-fields"></div>
                        <div class="register-error-message" style="top: 26px;"
                          *ngIf="!hostInventoryForm.get('cashBox2Total').valid && (hostInventoryForm.get('cashBox2Total').dirty || hostInventoryForm.get('cashBox2Total').touched)">
                          <ngb-alert [dismissible]="false" [type]="'danger'"
                            *ngIf="hostInventoryForm.get('cashBox2Total').errors.required">
                            {{'errorMsg.CashBox2TotalRequired' | translate}}
                          </ngb-alert>
                          <ngb-alert [dismissible]="false" [type]="'danger'"
                            *ngIf="hostInventoryForm.get('cashBox2Total').errors.hasNumber || hostInventoryForm.get('cashBox2Total').errors.haslengthCashBox2Regex">
                            {{'errorMsg.CashBox2TotalNumRequired' | translate}}
                          </ngb-alert>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="form-group">
                        <div class="form-check form-check-flat form-check-primary">
                          <label class="form-check-label pointer-events">
                            <input type="checkbox" class="form-check-input" (change)="checkEvent($event)"
                              formControlName="selectBox">
                            <i class="input-frame"></i>
                            <span class="ml-4">{{'htmlText.cashBoxStatus' | translate}}</span>
                          </label>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group floating-label">
                        <textarea type="text" class="form-control floating-textarea" rows="4" placeholder="" formControlName="agentInfo" maxlength="250"></textarea>
                        <label for="agentInfo">{{'htmlText.agentInfo' | translate}}</label>
                        <div class="regsiter-form-fields"></div>
                        <div class="register-error-message"
                          *ngIf="!hostInventoryForm.get('agentInfo').valid && (hostInventoryForm.get('agentInfo').dirty || hostInventoryForm.get('agentInfo').touched)">
                            <ngb-alert [dismissible]="false" [type]="'danger'"
                              *ngIf="hostInventoryForm.get('agentInfo').errors?.maxlength">
                              {{'errorMsg.agentInfoMaxLen' | translate}}
                            </ngb-alert>
                            <ngb-alert [dismissible]="false" [type]="'danger'"
                              *ngIf="hostInventoryForm.get('agentInfo').errors?.pattern">
                              {{'errorMsg.agentInfoPattern' | translate}}
                            </ngb-alert>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="form-group floating-label">
                        <textarea type="text" class="form-control floating-textarea" rows="4" placeholder="" formControlName="ownerNotes" maxlength="250"></textarea>
                        <label for="ownerNotes">{{'htmlText.ownerNotes' | translate}}</label>
                        <div class="regsiter-form-fields"></div>
                        <div class="register-error-message"
                        *ngIf="!hostInventoryForm.get('ownerNotes').valid && (hostInventoryForm.get('ownerNotes').dirty || hostInventoryForm.get('ownerNotes').touched)">
                          <ngb-alert [dismissible]="false" [type]="'danger'"
                            *ngIf="hostInventoryForm.get('ownerNotes').errors?.maxlength">
                            {{'errorMsg.ownerNotesMaxLen' | translate}}
                          </ngb-alert>
                          <ngb-alert [dismissible]="false" [type]="'danger'"
                            *ngIf="hostInventoryForm.get('ownerNotes').errors?.pattern">
                            {{'errorMsg.ownerNotesPattern' | translate}}
                          </ngb-alert>
                      </div>
                      </div>
                    </div>

                    <div class="col-md-4" style="margin-top: -26px;/* margin-bottom: -14px; */"
                      *ngIf="isEditHostInventory">
                      <div class="form-group">
                        <label for="labelStatus" style="margin-top: -8px; margin-left: 10px;">{{'processorList.status' |
                          translate}}</label>
                        <div style="margin-top: 18px;">
                          <p-selectButton [options]="stateOptions" formControlName="status">
                          </p-selectButton>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- component keys -->
      <div class="card mt-4" *ngIf="!isEditHostInventory">
        <div class="card-body">
          <p class="font-weight-bold mb-4">Component Keys</p>
          <div class="row">
            <div class="col-md-3">
              <div class="form-group required floating-label">
                <input type="text" class="form-control floating-input" formControlName="componentKey1" placeholder=" "
                  trim="blur" maxlength="32" tabindex="-1">
                <label for="encMasterKey">Key1</label>
                <div class="regsiter-form-fields"></div>
                <div class="register-error-message"
                  *ngIf="!hostInventoryForm.get('componentKey1').valid && (hostInventoryForm.get('componentKey1').dirty || hostInventoryForm.get('componentKey1').touched)">
                  <ngb-alert [dismissible]="false" [type]="'danger'"
                    *ngIf="hostInventoryForm.get('componentKey1').errors.required">
                    {{'errorMsg.Key1Required' | translate}}
                  </ngb-alert>
                  <ngb-alert [dismissible]="false" [type]="'danger'"
                    *ngIf="hostInventoryForm.get('componentKey1').errors.hasKey1Regex || hostInventoryForm.get('componentKey1').errors.hasAlphaRegex">
                    {{'errorMsg.key1bitsRequired' | translate}}
                  </ngb-alert>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group required floating-label">
                <input type="text" class="form-control floating-input" formControlName="componentKey2" placeholder=" "
                  trim="blur" maxlength="32" tabindex="-1" [readonly]="isEditHostInventory">
                <label for="Key2">Key2</label>
                <div class="regsiter-form-fields"></div>
                <div class="register-error-message"
                  *ngIf="!hostInventoryForm.get('componentKey2').valid && (hostInventoryForm.get('componentKey2').dirty || hostInventoryForm.get('componentKey2').touched)">
                  <ngb-alert [dismissible]="false" [type]="'danger'"
                    *ngIf="hostInventoryForm.get('componentKey2').errors.required">
                    {{'errorMsg.key2Required' | translate}}
                  </ngb-alert>
                  <ngb-alert [dismissible]="false" [type]="'danger'"
                    *ngIf="hostInventoryForm.get('componentKey2').errors.haskey2Regex || hostInventoryForm.get('componentKey2').errors.hasAlphaRegex">
                    {{'errorMsg.key2bitsRequired' | translate}}
                  </ngb-alert>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Encrypt Master Key -->
      <div class="card mt-5" *ngIf="isEditHostInventory">
        <div class="card-body">
          <p class="font-weight-bold mb-4">Encrypt Master Key</p>
          <div class="row">
            <div class="col-md-5">
              <div class="form-group required floating-label">
                <input type="text" class="form-control floating-input" formControlName="encMasterKey" placeholder=" "
                  trim="blur" maxlength="32" tabindex="-1" [readonly]="isEditHostInventory">
                <label for="encMasterKey">Cryptogram</label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <p class="mt-2">Fields marked with an asterisk ( <span class="text_color"><strong>*</strong></span> ) are
        required.
      </p>
      <div class="d-flex justify-content-end mt-2">
        <button type="button" class="btn btn-primary mr-2" (click)="resetHostInv()" [disabled]="isEditHostInventory"><i
            class="feather icon-refresh-ccw"></i> Reset</button>
        <button class="btn btn-primary" type="submit" [disabled]="!hostInventoryForm.valid || isSaving || errMsgs"
          *ngIf="!isEditHostInventory">
          <span *ngIf="isSaving" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> <i
            class="feather icon-plus-square"></i> Submit</button>
        <button class="btn btn-primary" type="submit" [disabled]="!hostInventoryForm.valid || isSaving || errMsgs"
          *ngIf="isEditHostInventory">
          <span *ngIf="isSaving" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> <i
            class="feather icon-plus-square"></i> Save</button>
      </div>
    </div>

  </form>
</div>

<ng-template #TidConfirmationModal class="text-center m-4" let-modal>
  <div class="modal-header d-flex justify-content-between">
    <div class="row thumbs_icon">
      <div class="ml-2">
        <h5 class="modal-title" id="exampleModalLongTitle"><span class="font-weight-bold text_baseline">{{confirmationModalHeader}}</span>
        </h5>
      </div>
    </div>
  </div>
  <div class="modal-body text-center px-5 py-5">
    <form [formGroup]="changeTidForm">
      <h5 class="text-center mt-2 mb-2">{{confirmationModalBody}}</h5>
          <div class="row mt-4">
            <div class="col-md-6">
              <ng-select class="text-left" [items]="searchProcessorList" formControlName="selectedProcessor"
                bindLabel="name" bindValue="id" placeholder="Select processor" [clearable]="false"
                (change)="changedProcessor($event)">
              </ng-select>
            </div>
            <div class="col-md-6">
              <ng-select class="text-left" [items]="processorbasedTidList" formControlName="selectedTid" bindLabel="tid"
                bindValue="tid" placeholder="Select TID" [clearable]="false" notFoundText="No TID found" (change)="changedTid($event)">
              </ng-select>
            </div>
          </div>
    </form>
  </div>
  <div class="text-right pb-2">
    <button (click)="changeTid()" class="mx-2 text-right btn btn-primary float-right"
        [disabled]="!changeTidForm.valid || isConfirmationLoading">
        <span *ngIf="isConfirmationLoading" class="spinner-border spinner-border-sm mr-1" role="status"
            aria-hidden="true"></span>Confirm</button>
    <button (click)="modal.close('cancel')" [disabled]="isConfirmationLoading"
        class="text-right btn btn-danger mr-2 float-right mb-2">Cancel</button>
  </div>
</ng-template>