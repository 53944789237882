import { Component, Output, OnInit, EventEmitter, Input, ViewChild} from '@angular/core';
import * as _ from 'lodash';
import { UserService } from '../../../providers/user.service';
import { DeviceService } from '../../../providers/device.service';
import { CustomerService } from 'src/app/providers/customer.service';
import { ApplicationService } from 'src/app/providers/application.service';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, switchMap, map, filter, catchError, tap } from 'rxjs/operators';
import { Observable, of, OperatorFunction, Subscription, concat, Subject } from 'rxjs';
import { AssetService } from 'src/app/providers/asset.service';
import { BatchService } from 'src/app/providers/batch.service';
import { DevicealertService } from 'src/app/providers/devicealert.service';
import { DownloadlogService } from 'src/app/providers/downloadlog.service';
import { TransactionService } from 'src/app/providers/transaction.service';
import { ConsumerManagementService } from 'src/app/providers/consumer-management.service';
import { CommonService } from 'src/app/providers/common.service';
import { apiLinks } from 'src/app/core/api-links/api-links';
import { MerchantService } from 'src/app/providers/merchant.service';
import { NgbTypeaheadConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { pickBy } from 'lodash';


@Component({
  selector: 'app-search-box-filter',
  templateUrl: './search-box-filter.component.html',
  styleUrls: ['./search-box-filter.component.scss'],
  providers: [NgbTypeaheadConfig]
})
export class SearchBoxFilterComponent implements OnInit {
  @Input() apiNameProperty: string;
  @Input() searchByTpnModel: string;
  @Input() searchByStoreModel: string;
  @Input() queryParam:any;
  @Input() placeholder: string;
  @Input() initialDate: any;
  @Input() finalDate: any;
  @Input() customerView: any;
  @Input() filterName: any;
  @Input() clear: any;
  @Input() value: string | null = null
  @Input() isBackButton = false
  @Output() filteredData = new EventEmitter<any>();
  @Output() apiStatus = new EventEmitter<any>();
  @Output() stores = new EventEmitter<any>();
  @Output() tpnNumber = new EventEmitter<any>();
  @Output() checkSearchBarValue = new EventEmitter<any>();
  @ViewChild('instance', { static: true }) instance: NgbTypeahead;
  @Output() isSearchFilter = new EventEmitter<any>();
  @Output() hostFilterLoader = new EventEmitter<any>();
  @Output() filteredTpn = new EventEmitter<any>();
  @Input() isTpnLabel: boolean = false;
  @Input() isStyleFlag: boolean = false;
  @Input() isDisableEmitTpn: boolean = false;
  @Input() enableSingleSelect: boolean = false;
  @Input() hideSelected = true;
  @Input() showDeletedTpns: boolean = false;
  @Input() isShowSuspendedTpns: boolean = false; 

  showDefaultLabel$: Observable<any[]>;
  inputTpn$ = new Subject<string>();
  tpnLoading: boolean;
  isSelectAllTpn: boolean = false;
  totalTpns: any;
  enteredstoreDba = '';
  //
 
  //
  
  model: any='';
  //searchByTpnModel: any='';
  searchByTpnModelPrevious: any;
  //searchByStoreModel:any='';
  modelPrevious: any;
  getSearchTerm: any = [];
  tpnResult = [];
  dbaResult = [];
  dbaList = [];
  tpnList = [];
  dbaWithStoreId = [];
  storeId: any;
  searching:any;
  searchFailed:any;
  searchValue: any;
  searchAboveFourInputs=['currentBuildNumber'];
  private eventsSubscription: Subscription;
  private storeDbaClearSubscription: Subscription;
  private tpnClearSubscription: Subscription;
  private enteredStoreValuerSubscription: Subscription;
  @Input() events: Observable<void>;
  @Input() storeDbaClearEvent: Observable<void>;
  @Input() tpnClearEvent: Observable<void>;
  @Input() enteredStoreValue: Observable<void>;

  constructor(private userService: UserService, private deviceService:DeviceService, private customerService: CustomerService, private assetService: AssetService,
              private batchService: BatchService, private applicationService: ApplicationService, private devicealertService: DevicealertService, private downloadLogService: DownloadlogService, private transactionService: TransactionService,
              private consumerManagementService: ConsumerManagementService,
    private commonService: CommonService, private merchantService: MerchantService) {

  }

  sendAPI={
    user:(data)=>{
      return this.userService.getAllUsersByUserId(data);
    },
    device: (data) => {
      return this.deviceService.getAllDevice(data)
    },
    customer:(data)=>{      
      return this.customerService.getAllCustomersByCustomerId(data);
    },
    asset: (data) => {
      return this.assetService.getAllAsset(data);
    },
    application: (data) => {
      return this.applicationService.getAllApplicationData(data);
    },
    batches: (data) => {
      if(data.status == 'close'){
        data.fromDate = this.initialDate;
        data.toDate = this.finalDate;
      }
      return this.batchService.getAllStores(data);
    },
    deviceAlert: (data) => {
      return this.devicealertService.getAllDeviceAlert(data);
    },
    downloadLog : (data) => {
      return this.downloadLogService.getAllDownloadLog(data);
    },
    transaction: (data) => {
      return this.transactionService.getAllTransactions(data);
    },
    consumer: (data) => {
      return this.consumerManagementService.getAllConsumersList(data);
    },
    invoice: (data) => {
      return this.commonService.getCommonHttpClientQueryParams(apiLinks.getInvoice, data);
    },
    merchant: (data) => {
      return this.merchantService.getAllMerchant(data);
    },
    tickets: (data) => {
      return this.commonService.getCommonHttpClientQueryParams(apiLinks.getAlltickets, data);
    },
    auditLogs: (data) => {
      return this.commonService.getCommonHttpClientQueryParams(apiLinks.getAllAuditLogs, data);
    },
    invoiceList: (data) => {
      return this.commonService.getCommonHttpClientQueryParams(apiLinks.getAllBillingInvoice, data);
    },
    recurringPayments: (data) => {
      return this.commonService.getCommonHttpClientQueryParams(apiLinks.getRecurringPaymentsList, data);
    },

    proxyLoginLogs: (data) => {
      return this.commonService.getCommonHttpClientQueryParams(apiLinks.getAllProxyLoginLogs, data);
    },

    hostInventory: (data) => {
      console.log("data from invoice", data);
      return this.commonService.getCommonHttpClientQueryParams(apiLinks.hostInventory, data);
    },
    processors: (data) => {
      console.log("data from processors", data);
      return this.commonService.getCommonHttpClientQueryParams(apiLinks.getProcessors, data);
    },

    promotions: (data) => {
      return this.commonService.getCommonHttpClientQueryParams(apiLinks.getAllPromotions, data);

    },
    inventory: (data) => {
      return this.commonService.getCommonHttpClientQueryParams(apiLinks.inventoryItems, data);

    }
    
  }
  searchFilter = new FormControl(this.value);
  searchBuildNumber = new FormControl();
  searchFilter$: Observable<any>[];
  searchedTpn = new FormControl();

  ngOnInit(clear = false): void {

    this.searchBuildNumber.valueChanges.pipe(
      filter(res => {
        let searchLength=0;
        return _.trim(res).length > searchLength || _.trim(res).length==0
      }),

      debounceTime(500),
      distinctUntilChanged(),
      switchMap((val) =>{
        this.apiStatus.emit({isLoading:true});
        
        let searchTerm = _.trim(val);
          console.log("queryParam buildnum string: ", this.queryParam);
          if(this.searchFilter.value){
            this.queryParam['queryString']= this.searchFilter.value;
          }
          this.queryParam['buildNum']= searchTerm;
          let data={ isFilter: true, page:1, size:20,...this.queryParam };
          return this.sendAPI[this.apiNameProperty](data);

        })
    )
    .subscribe(data => {
      console.log("searching finished");
      this.apiStatus.emit({isLoading:false});
      console.log(data, this.searchFilter.value);
      if(!data) {
        console.log('empty');
        return false;
      }

      this.filteredData.emit({data, searchTerm: this.searchFilter.value,searchTermName:this.filterName?this.filterName:"queryString", ...(this.searchBuildNumber.value != null && {buildNum: this.searchBuildNumber.value})});
      console.log('after emit');
    },(err) => {

      this.apiStatus.emit({isLoading:false});

   });
    this.searchFilter.valueChanges.pipe(
      filter(res => {
        let searchLength=0;
        if(this.searchAboveFourInputs.indexOf(this.filterName)!=-1){
          searchLength=4;
        }
        console.log(_.trim(res).length); 
        return _.trim(res).length > searchLength || _.trim(res).length==0
      }),

      // if its a back button search we don't want to fetch the data initially hence disabling to 
      // avoid unnecessary requests !
      filter(res => {
        return !this.isBackButton
      }),
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((val) =>{
        // console.log('searchTerm---------------');
        console.log('inside filter', val)

        this.apiStatus.emit({isLoading:true});
        
        let searchTerm = _.trim(val);

        if(this.queryParam['dateRange'] == 'None'){
            this.queryParam['dateRange'] = '';
          }
          // if(val.length == 0) {
          //   console.log('length 0---------------');
           
          //   return of({})
          // } else if(searchTerm.length == 0) {
          //   console.log('searchTerm 0---------------');
            
          //   return of(false)
          // } else {  
            //console.log(this.queryParam);   

          //   this.queryParam['queryString']= searchTerm;
          //   let data={ isFilter: true, page:1, size:20,...this.queryParam };
          //   return this.sendAPI[this.apiNameProperty](data);
          // }
          if(this.apiNameProperty == 'user'){
            this.queryParam['isFilter'] = true;
          }
        console.log("queryParam string: ", this.queryParam, this.searchFilter.value);
          // if(!_.isUndefined(this.queryParam)) {
            // console.log("I am coming to assign query param");
            
            if(this.filterName){
              if(this.filterName=='amount' && searchTerm!='') {
                this.queryParam[this.filterName]= (Math.round(parseFloat(searchTerm)*100)).toString();
              }else{
                this.queryParam[this.filterName]= searchTerm;
              }
              
            }else{
              this.queryParam['queryString']= searchTerm;
              this.hostFilterLoader.emit(true);
            }
            // search by build num download logs
            if(this.queryParam?.enableBuildNumber && this.queryParam?.enableBuildNumber == true) {
              if(this.searchBuildNumber.value) {
                this.queryParam['buildNum'] = this.searchBuildNumber.value;
              }
            }
          // }
          let data={ isFilter: true, page:1, size:20,...this.queryParam };
          return this.sendAPI[this.apiNameProperty](data);

        })
    )
    .subscribe(data => {
      console.log("searching finished");
      this.apiStatus.emit({isLoading:false});
      console.log(data, this.searchFilter.value);
      
      let getSearchObjectLength:any = pickBy(this.queryParam, function(value, key) {
        return !(value === '');
      });

      if(Object.keys(getSearchObjectLength).length > 1){
        this.isSearchFilter.emit(true);
      }else{
        this.isSearchFilter.emit(false);
      }
      if(!data) {
        console.log('empty');
        return false;
      }

      this.filteredData.emit({data, searchTerm: this.searchFilter.value,searchTermName:this.filterName?this.filterName:"queryString",...(this.searchBuildNumber.value != null && {buildNum: this.searchBuildNumber.value})});
    },(err) => {

      this.apiStatus.emit({isLoading:false});

   });
   if(this.events){
     this.eventsSubscription = this.events.subscribe(() => {
       this.searchFilter = new FormControl();
       this.searchBuildNumber = new FormControl();
       this.searchedTpn = new FormControl();
       this.ngOnInit(true);
     });

     this.filteredData.emit({ data: null, searchTerm: this.value ? this.value : this.searchFilter.value, searchTermName: this.filterName ? this.filterName : "queryString" });
  }

  if(this.storeDbaClearEvent){
    this.storeDbaClearSubscription = this.storeDbaClearEvent.subscribe((item:any) => {
      if(item){
        this.searchByStoreModel='';
      }
    });
  }

  if(this.tpnClearEvent){
    this.tpnClearSubscription = this.tpnClearEvent.subscribe((item:any) => {
      if(item){
        this.searchedTpn.setValue(null, { emitEvent: false });
      }
  })
    
  }


  if(this.enteredStoreValue){
    this.enteredStoreValuerSubscription = this.enteredStoreValue.subscribe((item:any) => {
      
      this.enteredstoreDba = item;

      console.log('this.enteredstoreDba----->',this.enteredstoreDba);
    });
  }

  this.loadTpnLabel();
  this.searchedTpn.valueChanges.subscribe(data => {
    this.filteredTpn.emit(data);
  });
  
  if (!clear) {
      this.searchFilter.setValue(this.value, { emitEvent: false });
      this.searchedTpn.setValue(this.value, {emitEvent: !this.isDisableEmitTpn});
    }
  //  this.eventsSubscription.unsubscribe();
  }

  ngOnDestroy() {
    
    if(this.events){
      this.eventsSubscription.unsubscribe();
    }
    if(this.storeDbaClearEvent){
      this.storeDbaClearSubscription.unsubscribe();
    }
    if(this.tpnClearEvent){
      this.tpnClearSubscription.unsubscribe();
    }

    if(this.enteredStoreValue){
      this.enteredStoreValuerSubscription.unsubscribe();
    }
  }

  ngOnChanges(changes) {
        
    //console.log("curr",changes.searchByTpnModel.currentValue);
    // You can also use categoryId.previousValue and 
    // categoryId.firstChange for comparing old and new values
    
  }


  // selectTpn(item: any) {  
  //   console.log('item', item);  
  //   item.preventDefault();
  //   this.searching = false;
  //   this.searchByTpnModel= item.item.tpn;
  //   this.searchByStoreModel = '';    
  //   //this.searchByTpnModel = item.item;
  //  // this.searchByTpnModelPrevious = this.searchByTpnModel;
  //   this.tpnNumber.emit(item.item.tpn);
    
  // }

  selectDba(item: any){

    item.preventDefault();
    // console.log(item.id);
    this.searching = false;
    this.searchByStoreModel = item.item.dba;
    // this.searchByTpnModel='';
    this.stores.emit(item.item);
    
   // return;
    // 
    // this.modelPrevious = this.model;
    // var result = this.dbaWithStoreId.filter(function(findStore){
    //   if(findStore[0] == item.item) {
    //     return findStore[1];
    //   }
    // });
    // result.forEach(element => {
    //   this.storeId = element[1];
    // });
    
    //console.log('storeId', this.storeId);
  }

  formatter = (result: string) => result.toUpperCase();

  storeFormatter = (x: any) => x.dba;
  tpnFormatter = (x: any) => x.tpn;

  // search: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) =>
  //   text$.pipe(
  //     debounceTime(200),
  //     distinctUntilChanged(),
  //     map(term => term === '' ? []
  //       : this.dbaList.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
  //   )

  searchStore: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) =>
  text$.pipe(
    debounceTime(300),
    distinctUntilChanged(),
    tap(() => this.searching = true),
    switchMap(term =>
      this.commonService.getResponseDataForFilter(apiLinks.storeDbas, {queryString: term,isFilter:true,page:1,size:10}).pipe(
        tap((item) => {
          console.log(item);
          this.searchFailed = false;
          this.searching = false;
        }),
        catchError(() => {
          this.searching = false;
          this.searchFailed = true;
          return of([]);
        }))
    ),
    tap(() => this.searching = false)
  )

  searchTpn: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) =>
  text$.pipe(
    debounceTime(300),
    distinctUntilChanged(),
    tap(() => this.searching = true),
    switchMap(term =>
      this.commonService.getResponseDataForFilter(apiLinks.tpnIds, {query: term,isFilter:true,page:1,size:5}).pipe(
        tap((item) => {
          console.log(item);
          this.searching = false;
          this.searchFailed = false
        }),
        catchError(() => {
          this.searching = false;
          this.searchFailed = true;
          return of([]);
        }))
    ),
    tap(() => this.searching = false)
  )
    // searchForTpn: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) =>
    // text$.pipe(
    //   debounceTime(200),
    //   distinctUntilChanged(),
    //   map(term => term === '' ? []
    //     : this.tpnList.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    // )

    getSearchKey($event){
      this.searchValue = $event;
      _.isEmpty($event) ? this.searching = false: '';
      this.checkSearchBarValue.emit($event);
    }

    stopLoader(){
      _.isEmpty(this.searchValue) || (_.isEmpty(this.searchByStoreModel) || _.isEmpty(this.searchByTpnModel))? this.searching = false: '';
    }
    loadTpnLabel() {
      this.showDefaultLabel$ = concat(
        of([]), // default items
        this.inputTpn$.pipe(
          debounceTime(400),
          tap(() => this.tpnLoading = true),
          switchMap(term => {
            if(term == null || term.trim().length < 4) 
            {
              this.isSelectAllTpn = true;
              this.tpnLoading = false;
              return of([]);
            }
            return this.getAllTpns(term.trim()).pipe(
              (map(res => {
                this.isSelectAllTpn = false;
                this.totalTpns = res.data;
                return res.data;
              })
              ),
              catchError(() => of([])), // empty list on error
              tap(() => this.tpnLoading = false)
            )
          })
        )
      );
    }
    getAllTpns(term: string = '') {
      return this.commonService.getCommonHttpClientQueryParams(apiLinks.tpnIds, { isFilter: true, tpnDefaultLabel: term? term: '', storeDba: this.enteredstoreDba ? this.enteredstoreDba : "", isShowDeactivedTpns: this.showDeletedTpns, isShowSuspendedTpns: this.isShowSuspendedTpns});
    }
    selectAll() {
      this.searchedTpn.patchValue(this.totalTpns.map(data => data.tpn));
    }
  
    unselectAll() {
      this.searchedTpn.patchValue([]);
    }
}
