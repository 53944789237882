<p-toast position="top-center" key="myToast"></p-toast>
<router-outlet></router-outlet>

<div class="no-connection" *ngIf=""></div>

<!-- <p-toast position="top-right" key="deactivateMerchant"></p-toast> -->
<!-- <p-toast position = "top-right"key ="deactivateStore"></p-toast> -->
<!-- <p-toast position = "top-right"key ="deactivateTpn"></p-toast> -->

<div *ngIf="noInternetConnection" style="text-align: center;"></div>    
<div *ngIf="!noInternetConnection" style="text-align: center;"></div>

<ng-template #logOutModal class="text-center m-4" let-modal>
    <div class="modal-header d-flex justify-content-between">
        <div class="row">
          <div class="ml-2">
            <h5 class="modal-title" id="exampleModalLongTitle"><span class="font-weight-bold text_baseline"> Inactivity Detected</span>
            </h5>
          </div>
        </div>
      </div>
      <div class="modal-body text-center px-5 py-5">
        <h5>You will be logged out in <span class="font-weight-bold ml-1">{{ count }}</span></h5>
      </div>
      <div class="card-footer text-center">
        <button (click)="continueSession();modal.close()" class="btn btn-success w-80">Stay</button>
      </div>
</ng-template>