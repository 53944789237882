import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { isEmpty } from 'lodash';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { apiLinks } from 'src/app/core/api-links/api-links';
import { Modules } from 'src/app/core/model/modules.enum';
import { Privileges } from 'src/app/core/model/privileges.enum';
import { AuthService } from 'src/app/providers/auth.service';
import { CommonService } from 'src/app/providers/common.service';
import { BuilkEditTidComponent } from '../../host-inventory/builk-edit-tid/builk-edit-tid.component';

@Component({
  selector: 'app-processor-list',
  templateUrl: './processor-list.component.html',
  styleUrls: ['./processor-list.component.scss']
})
export class ProcessorListComponent implements OnInit {

  processorList = [];
  listHostInventoryView: Subscription
  viewingProcessor:any;
  privileges = Privileges;
  viewMode: number = 0;
  isLoading:boolean = false;
  apiName = 'processors';
  filterQuery = {};
  defaultNavActiveId;
  page:number = 1;
  size = 20;
  queryString:any;
  collectionSize : number;
  processorPrivileges: any;

  constructor(
    private messageService: MessageService,
    private commonService:CommonService,
    private router: Router,
    private authService: AuthService,
    private ngbModal: NgbModal

  ) { }

  ngOnInit(): void {
    this.getAllProcessorList();
    this.processorPrivileges = this.authService.getPrivileges(Modules.PROCESSOR);
  }

  getAllProcessorList() {
    this.isLoading = true;
    let queryString = this.queryString?{queryString: this.queryString}:{};
    this.listHostInventoryView = this.commonService.getCommonHttpClientQueryParams(apiLinks.getProcessors,{page:this.page, size:this.size,...queryString}).subscribe((res)=>{
      this.processorList = res.data.rows;
      this.collectionSize = res.data.count;
      this.isLoading = false;
      this.messageService.clear();
      this.viewMode = 0;
      this.viewingProcessor = this.processorList.length > 0 ? this.processorList[0] : [];

      if (isEmpty(this.processorList)) {
        this.messageService.clear();
        this.showNoDataMessage(false, 'Sorry! No Host Inventory Datas.');
      }
    },
    (err) => {
      this.showAlert(err, "error");
      this.isLoading = false;
    })
  }

  applyFilters(filteredData) {
    let {data, searchTerm} = filteredData;
    this.queryString=searchTerm;
    if(!isEmpty(data)) {
      this.processorList = data.data.rows;
      this.collectionSize = data.data.count
      this.viewingProcessor = this.processorList.length > 0 ? this.processorList[0] : [];
      if(this.processorList.length == 0){
        this.showNoDataMessage(false, 'Sorry! No Processors found.');
      }
    } else {
      this.collectionSize = 0;
    }
    this.viewMode = 0;
  }

  showNoDataMessage(condition:boolean, details) {
    this.messageService.add({
      severity: 'info',
      summary:'Info Message',
      detail: details,
      sticky: condition
    });
  }

  getHostInvDetails(processor) {
    this.viewingProcessor = processor;
  }

  selected(index) {
    this.viewMode = index;
  }

  showAlert(data, alertType) {
    this.messageService.add({
      severity: alertType,
      summary: data.status,
      detail: data.message,
    });
  }

  refreshPage(){
    this.getAllProcessorList();
  }

  getPage(page: number) {
    document.getElementById("home").scrollIntoView({ behavior: "smooth" });
    this.page = page;
    this.getAllProcessorList();
  }

  routeToEditProcessor(data) {
    // return
    this.router.navigate(['/add-processor'],{queryParams:{...data,editProcessor:true}});
  }

  setClass(data) {
    if (data == 'ENABLE') {
      return "badge badge-success"
    } else {
      return "badge badge-danger"
    }
  }

  isPermission(privilege) {
      return this.processorPrivileges && this.processorPrivileges.features.indexOf(privilege) != -1;
  }


}
