import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Inject,
  Renderer2,
  Input,
} from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from "../../../providers/auth.service";
import { ThemeService } from "ng2-charts";
// import { AppThemeService } from "src/app/providers/app.theme.service";
import { UserService } from "src/app/providers/user.service";
// import { THEMES } from "./themes";
import { FormGroup, FormControl, FormBuilder, Validators } from "@angular/forms";
import { replace } from "lodash";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit {
  
  currentUser = {
    firstName: "",
    email: "",
    lastName: "",
    userType:""
  };

  iscyanchecked : boolean = true;
  isbluechecked : boolean = false;

  languages = [
    { code: "en", name: "English", icon: "us" },
    { code: "es", name: "Spanish", icon: "es" },
    // { code: "fr", name: "French", icon: "fr" },
    // { code: "in", name: "Tamil", icon: "in" },
  ];
  selectedLanguage = this.languages[0];
  defaultTheme: any;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private router: Router,
    private translate: TranslateService,
    private authService: AuthService,
    // private appThemeService: AppThemeService,
    private userService: UserService,
    private formBuilder: FormBuilder,
  ) {
    translate.setDefaultLang(this.selectedLanguage.code);
  }

  ngOnInit(): void {
    this.getCurrentUser();
    this.defaultTheme = this.authService.getUser.themeColor;
    if(this.defaultTheme == "theme-cyan-light") {
      this.iscyanchecked = true;
      this.isbluechecked = false;
    }
    if (this.defaultTheme == 'theme-bluegrey-light') {     
      this.isbluechecked = true;
      this.iscyanchecked = false;
    }
    //this.onSidebarThemeChange(this.defaultTheme);
    // console.log("this.defaultTheme",this.defaultTheme);
  }

  /**
   * Sidebar toggle on hamburger button click
   */
  toggleSidebar(e) {
    e.preventDefault();
    this.document.body.classList.toggle("sidebar-open");
  }

  /**
   * Logout
   */
  onLogout() {
    this.authService.logoutService().subscribe((res) => {
      console.log("logout");
    });

    // this.authService.testAxiosLogutService();
  }

  agentOfficeRoleChange(item){
    return replace(this.authService.subIsoToAgentOffice(item), '_',' ');;
  }

  useLanguage(index: number) {
    this.selectedLanguage = this.languages[index];
    this.translate.use(this.selectedLanguage.code);
  }
  /**
   * custom Switching sidebar light/dark
   */
  onSidebarThemeChange(data) {
    this.document.body.classList.remove(
      "sidebar-light",
      "theme-cyan-light",
      "theme-bluegrey-light"
    );
    this.document.body.classList.add(data);
    this.getTheme(data);
    this.document.body.classList.remove("settings-open");

    this.iscyanchecked = !this.iscyanchecked;
    this.isbluechecked = !this.isbluechecked;
    // this.userService.userFavTheme.next('cyan');

  }

  getCurrentUser() {
    this.currentUser = this.authService.getUser;
  }

  getTheme(themeClass){
    let params = {
      themeColor: themeClass
    }
    this.userService.getTheme(this.userService.getThemeColorById, params).subscribe(data => {
      this.authService.updateUser(params);
        console.log(data);
    });
  }

  getInitials() {
    let initials = this.currentUser.firstName[0]
    initials += this.currentUser.lastName ? this.currentUser.lastName[0] : ''
    return initials
  }
  
  // toogle() {
  //   this.iscyanchecked = !this.iscyanchecked;
  //   this.isbluechecked = !this.isbluechecked;
  // }
}
function data(data: any) {
  throw new Error("Function not implemented.");
}

