<app-loading *ngIf="isLoading"></app-loading>
<div *ngIf="!isLoading">
  <ng-container *ngIf="isNewReceipt">
  <div ngbDropdown class="pt-4">
    <button style="float:right;" class="btn btn-outline-primary btn_align" id="dropdownMenuActiveItem" ngbDropdownToggle> SaveAs</button>
    <div class="list_fixed" ngbDropdownMenu aria-labelledby="dropdownMenuActiveItem">
      <button ngbDropdownItem (click)="download('png')"><i class="link-icon feather icon-image"></i> PNG</button>
      <button ngbDropdownItem (click)="download('pdf')"><i class="link-icon feather icon-file-text"></i> PDF</button>
    </div>
  </div>

  <div id="showScroll" class="container">
    <div class="receipt" #screen>
      <div *ngIf="receiptData.defaultLogo.merchantLogo" class="logo"><img class="logo_align" src="{{baseUrl}}/{{getImage(receiptData.defaultLogo.merchantLogo)}}" alt="MERCHANTLOGO"></div>
      <div class="address">
            <p class="center_add">{{errorHandle(receiptData?.receiptDetails?.h1)}}</p>
            <p class="center_add">{{errorHandle(receiptData?.receiptDetails?.h2)}}</p>
            <p class="center_add">{{errorHandle(receiptData?.receiptDetails?.h3)}}</p>
            <p class="center_add">{{errorHandle(receiptData?.receiptDetails?.h4)}}</p>
            <p class="center_add"></p>
            <p class="center_add"></p>
      </div>
      <div class="transactionDetails">
        <div class="detail">{{errorHandle(getDate(receiptData?.txnDetails?.posRequestDate) | date:'MM-dd-YYYY')}}</div>
        <div class="detail"></div>
        <div class="detail">{{errorHandle(timeSub(receiptData?.txnDetails?.posRequestTime))}}</div>
      </div>

      <div class="survey bold" *ngIf="receiptData?.txnDetails?.transactionType != 'CASH' && receiptData?.txnDetails?.transactionType != 'GIFT' && receiptData?.txnDetails?.transactionType != 'LOYALTY'">
        <p class="txt_size_txname">{{ showVoidLabel(receiptData?.txnDetails?.voidFlag, receiptData?.txnDetails?.txName) }} {{getTxName(receiptData?.txnDetails?.txName , receiptData?.txnDetails?.transactionType, receiptData?.txnDetails?.hostResponseCode)}}</p>
      </div>

      <div class="transactionDetails" *ngIf="receiptData?.txnDetails?.transactionType == 'CASH'">
        <div class="detail">{{'htmlText.type' | translate}}:</div>
        <div class="detail"></div>
        <div class="detail">{{receiptData?.txnDetails?.transactionType}}</div>
      </div>

      <div class="transactionDetailsResp mb-2">
        <div class="detail">{{'htmlText._TPN' | translate}}:</div>
        <div class="detail"></div>
        <div class="detail">{{receiptData?.txnDetails?.tpn}}</div>
      </div>

      <!-- <div class="transactionDetails" *ngIf="receiptData?.txnDetails.transactionType != 'CASH'">
        <div class="detail">{{'htmlText._TID' | translate}}:</div>
        <div class="detail"></div>
        <div class="detail">{{receiptData?.txnDetails?.processorInfo?.tid}}</div>
      </div> -->

      <div class="transactionDetails" *ngIf="receiptData?.txnDetails?.transactionType != 'WALLET'">
        <div class="detail" *ngIf="receiptData?.txnDetails?.transactionType != 'CASH'">{{'htmlText.trans' | translate}} #: {{get(receiptData?.txnDetails?.invoiceNumber, receiptData?.txnDetails?.voidFlag) | number}}</div>
        <div class="detail" *ngIf="receiptData?.txnDetails?.transactionType == 'CASH'">{{'htmlText.trans' | translate}} #: {{receiptData?.txnDetails?.transactionId}}</div>
        <div class="detail"></div>
        <div class="detail">{{'htmlText.batch' | translate}} #: {{receiptData?.txnDetails?.tpnBatchNumber | number}}</div>
      </div>


      <div class="transactionCardName" *ngIf="receiptData?.txnDetails?.transactionType != 'CASH' && receiptData?.txnDetails?.transactionType != 'GIFT' && receiptData?.txnDetails?.transactionType != 'LOYALTY' && receiptData?.txnDetails?.transactionType != 'WALLET'">
        <div class="detail">{{cardLabel(receiptData?.txnDetails?.cardLabel, receiptData?.txnDetails?.transactionType)}}</div>
        <div class="detail"></div>
        <div class="detail" *ngIf="receiptData?.txnDetails?.txName != 'TICKET'" >{{transMode(receiptData?.txnDetails?.posEntryMode)}}</div>
        <div class="detail" *ngIf="receiptData?.txnDetails?.txName == 'TICKET'" >{{'htmlText.manual' | translate}}</div>
      </div>


      <div class="transactionDetails" *ngIf="receiptData?.txnDetails?.transactionType != 'CASH' && receiptData?.txnDetails?.transactionType != 'GIFT' && receiptData?.txnDetails?.transactionType != 'LOYALTY' && receiptData?.txnDetails?.transactionType != 'WALLET'">
        <div class="detail">{{showMaskedCardNum(receiptData?.txnDetails?.maskedPan)}}</div>
        <div class="detail"></div>
        <div class="detail">**/**</div>
      </div>

      <div class="survey bold" *ngIf="receiptData?.txnDetails?.transactionType == 'CASH' || receiptData?.txnDetails?.transactionType == 'GIFT' || receiptData?.txnDetails?.transactionType == 'LOYALTY'">
        <p class="txt_size_txname">{{txHandle(receiptData?.txnDetails?.txName)}}</p>
      </div>


      <div class="transactionDetails" *ngIf="(receiptData?.txnDetails?.transactionType == 'EBT')">
        <div class="detail">{{'htmlText.trace' | translate}} #:</div>
        <div class="detail"></div>
        <div class="detail">{{get(receiptData?.txnDetails?.invoiceNumber,
          receiptData?.txnDetails?.voidFlag) | number}}</div>
      </div>

      <div class="transactionDetails" *ngIf="(receiptData?.txnDetails?.transactionType == 'EBT')">
        <div class="detail">{{'htmlText.benefit' | translate}}:</div>
        <div class="detail"></div>
        <div class="detail">{{ebt_type(receiptData?.txnDetails?.transactionType, receiptData?.txnDetails?.processingCode)}}</div>
      </div>

      <div class="transactionDetails" *ngIf="receiptData?.txnDetails?.transactionType == 'WALLET'">
        <div class="detail">{{receiptData?.txnDetails?.sourceType}}:</div>
        <div class="detail"></div>
        <div class="detail">{{receiptData?.txnDetails?.transactionType}}</div>
      </div>

      <div class="transactionDetails mar_top_acct" *ngIf="receiptData?.txnDetails?.transactionType == 'GIFT' || receiptData?.txnDetails?.transactionType == 'LOYALTY'">
        <div class="detail">{{'htmlText.acct' | translate}}:</div>
        <div class="detail"></div>
        <div class="detail">{{showMaskedCardNum(receiptData?.txnDetails?.maskedPan)}}</div>
      </div>

      <div class="transactionDetails" *ngIf="receiptData?.txnDetails?.transactionType == 'GIFT' || receiptData?.txnDetails?.transactionType == 'LOYALTY'">
        <div class="detail">{{'htmlText.type' | translate}}:</div>
        <div class="detail"></div>
        <div class="detail">{{receiptData?.txnDetails?.transactionType}}</div>
      </div>

      <div class="transactionDetails" *ngIf="receiptData?.txnDetails?.transactionType == 'GIFT' || receiptData?.txnDetails?.transactionType == 'LOYALTY'">
        <div class="detail">{{'htmlText.entry' | translate}}:</div>
        <div class="detail"></div>
        <div class="detail">{{transMode(receiptData?.txnDetails?.posEntryMode)}}</div>
      </div>

      <div class="transactionDetails" *ngIf="receiptData?.txnDetails?.transactionType == 'GIFT' || receiptData?.txnDetails?.transactionType == 'LOYALTY'">
        <div class="detail">{{'htmlText.service' | translate}}:</div>
        <div class="detail"></div>
        <div class="detail">{{txHandle(receiptData?.txnDetails?.txName)}}</div>
      </div>

      <div class="paymentDetails bold" *ngIf="receiptData?.txnDetails?.transactionType == 'LOYALTY'">
        <div class="detail">{{'htmlText.points' | translate}}:</div>
        <div class="detail"></div>
        <div class="detail">{{receiptData?.txnDetails?.txAmount}}</div>
      </div>


    <div *ngIf="isAmountValid(receiptData?.txnDetails?.hostRspData?.purchasedAmt)"
      class="transactionDetailsResp bold">
      <div class="detail">{{ 'htmlText.enteredAmt' | translate }}:</div>
      <div class="detail"></div>
      <div class="detail">{{ transAmount(receiptData?.txnDetails?.hostRspData?.purchasedAmt) | currency }}</div>
    </div>

    <div *ngIf="((receiptData?.txnDetails?.hostRspData?.purchasedAmt != undefined) && (receiptData?.txnDetails?.tipAmount != 0 && receiptData?.txnDetails?.tipAmount != null) || (receiptData?.txnDetails?.tipAdjAmount != 0 && receiptData?.txnDetails?.tipAdjAmount != null))" class="paymentDetails bold">
      <div class="detail">{{'htmlText.tipAmt' | translate}}:</div>
      <div class="detail"></div>
      <div class="detail">{{transAmount(sumTip(receiptData?.txnDetails?.tipAmount,
        receiptData?.txnDetails?.tipAdjAmount, receiptData?.txnDetails?.TipAmountFee)) | currency}}</div>
    </div>

    <hr class="dashed-line" *ngIf="isShowAmountDue(receiptData?.txnDetails?.hostRspData?.purchasedAmt, receiptData?.txnDetails?.tipAmount)">

    <div *ngIf="isShowAmountDue(receiptData?.txnDetails?.hostRspData?.purchasedAmt, receiptData?.txnDetails?.tipAmount)" class="transactionDetailsResp bold">
      <div class="detail">{{ 'htmlText.amountDue' | translate }}:</div>
      <div class="detail"></div>
      <div class="detail">{{ transAmount(sumAmount(receiptData?.txnDetails?.hostRspData?.purchasedAmt, receiptData?.txnDetails?.tipAmount)) | currency }}</div>
    </div>

    <hr class="dashed-line" *ngIf="receiptData?.txnDetails?.transactionType != 'LOYALTY' && isAmountValid(receiptData?.txnDetails?.hostRspData?.purchasedAmt)">

    <div class="paymentDetails bold" *ngIf="receiptData?.txnDetails?.transactionType != 'LOYALTY'">
      <div class="detail">{{ (receiptData?.txnDetails?.hostRspData?.purchasedAmt == undefined) ? ('htmlText.basAmt' | translate) : ('htmlText.withdrawal' | translate) }}:</div>
      <div class="detail"></div>
      <div class="detail">{{transAmount(withdrawAmount(receiptData?.txnDetails?.hostRspData?.purchasedAmt, receiptData?.txnDetails?.txAmount, receiptData?.txnDetails?.tipAmount)) | currency}}</div>
    </div>


      <div *ngIf="(receiptData?.txnDetails?.DiscountFee != 0 && receiptData?.txnDetails?.DiscountFee != null)" class="paymentDetails bold">
        <div class="detail">{{'htmlText.disc' | translate}}:</div>
        <div class="detail"></div>
        <div class="detail">{{transAmount(receiptData?.txnDetails?.DiscountFee) | currency}}</div>
      </div>


      <div *ngIf="(receiptData?.txnDetails?.customFee != 0  && receiptData?.txnDetails?.customFee != null)" class="paymentDetails bold">
        <div class="detail">{{defaultName(receiptData?.signatureDetails?.customFeeLabel)}}:</div>
        <div class="detail"></div>
        <div class="detail">{{transAmount(receiptData?.txnDetails?.customFee) | currency}}</div>
      </div>
      <div *ngIf="(receiptData?.txnDetails?.serviceFee != 0 && receiptData?.txnDetails?.serviceFee != null)" class="paymentDetails bold">
        <div class="detail">{{defaultName(receiptData?.signatureDetails?.customFeeLabel)}}:</div>
        <div class="detail"></div>
        <div class="detail">{{transAmount(receiptData?.txnDetails?.serviceFee) | currency}}</div>
      </div>
      <div *ngIf="(receiptData?.txnDetails?.surcharge != 0 && receiptData?.txnDetails?.surcharge != null)" class="paymentDetails bold">
        <div class="detail">{{defaultSCH(receiptData?.signatureDetails?.merchantFeeLabel)}}:</div>
        <div class="detail"></div>
        <div class="detail">{{transAmount(receiptData?.txnDetails?.surcharge) | currency}}</div>
      </div>
      <!-- <div *ngIf="(receiptData?.txnDetails.merchantFee != 0 && receiptData?.txnDetails.merchantFee != null)" class="paymentDetails bold">
        <div class="detail">{{defaultMFL(receiptData?.signatureDetails?.merchantFeeLabel)}}:</div>
        <div class="detail"></div>
        <div class="detail">{{transAmount(receiptData?.txnDetails?.merchantFee) | currency}}</div>
      </div> -->
      <div *ngIf="(receiptData?.txnDetails?.merchantFee != 0 && receiptData?.txnDetails?.merchantFee != null)" class="paymentDetails bold">
        <div class="detail breakWord">{{defaultMFL(receiptData?.signatureDetails?.customFeeLabel)}}:</div>
        <div class="detail"></div>
        <div class="detail">{{transAmount(receiptData?.txnDetails?.merchantFee) | currency}}</div>
      </div>
      <div *ngIf="(receiptData?.txnDetails?.localTaxAmount != 0 && receiptData?.txnDetails?.localTaxAmount != null)" class="paymentDetails bold">
        <div class="detail">{{defaultLTL(receiptData?.signatureDetails?.localTaxLabel)}}:</div>
        <div class="detail"></div>
        <div class="detail">{{transAmount(sumAmount(receiptData?.txnDetails?.localTaxAmount, receiptData?.txnDetails?.LTaxFee)) | currency}}</div>
      </div>
      <div *ngIf="(receiptData?.txnDetails?.stateTaxAmount != 0 && receiptData?.txnDetails?.stateTaxAmount != null)" class="paymentDetails bold">
        <div class="detail">{{defaultSTL(receiptData?.signatureDetails?.stateTaxLabel)}}:</div>
        <div class="detail"></div>
        <div class="detail">{{transAmount(sumAmount(receiptData?.txnDetails?.stateTaxAmount, receiptData?.txnDetails?.GTaxFee)) | currency}}</div>
      </div>
      <div *ngIf="((receiptData?.txnDetails?.hostRspData?.purchasedAmt == undefined) && (receiptData?.txnDetails?.tipAmount != 0 && receiptData?.txnDetails?.tipAmount != null) || (receiptData?.txnDetails?.tipAdjAmount != 0 && receiptData?.txnDetails?.tipAdjAmount != null))" class="paymentDetails bold">
        <div class="detail">{{'htmlText.tipAmt' | translate}}:</div>
        <div class="detail"></div>
        <div class="detail">{{transAmount(sumTip(receiptData?.txnDetails?.tipAmount,
          receiptData?.txnDetails?.tipAdjAmount, receiptData?.txnDetails?.TipAmountFee)) | currency}}</div>
      </div>
      <div *ngIf="(receiptData?.txnDetails?.cashDiscount != 0 && receiptData?.txnDetails?.cashDiscount != null)" class="paymentDetails bold">
        <div class="detail">{{errorHandle(cashDis(receiptData?.signatureDetails?.discLabel))}}:</div>
        <div class="detail"></div>
        <div class="detail">{{transAmount(receiptData?.txnDetails?.cashDiscount) | currency}}</div>
      </div>
      <!-- <div *ngIf="((receiptData?.txnDetails.totalAmount != receiptData?.txnDetails.txAmount) && receiptData?.txnDetails.transactionType != 'CASH' && receiptData?.txnDetails.transactionType != 'LOYALTY')" -->

        <hr class="dashed-line" *ngIf="((receiptData?.txnDetails?.hostRspData?.purchasedAmt != undefined) && receiptData?.txnDetails?.transactionType != 'LOYALTY' && getFeeDetails(receiptData?.feeDetails, receiptData?.txnDetails))">

      <div *ngIf="(receiptData?.txnDetails?.transactionType != 'LOYALTY' && receiptData?.txnDetails?.txName != 'BALANCE INQUIRY' && getFeeDetails(receiptData?.feeDetails, receiptData?.txnDetails))"
        class="paymentDetails bold">
        <div class="detail">{{'htmlText.total' | translate}}:</div>
        <div class="detail"></div>
        <div class="detail">{{transAmount(receiptData?.txnDetails?.totalAmount) | currency}}</div>
      </div>
      
      <hr class="dashed-line" *ngIf="((receiptData?.txnDetails?.hostRspData?.purchasedAmt != undefined) && receiptData?.txnDetails?.transactionType != 'LOYALTY' && getFeeDetails(receiptData?.feeDetails, receiptData?.txnDetails))">
      
      <div *ngIf="(receiptData?.txnDetails?.hostRspData?.changeAmt && receiptData?.txnDetails?.hostRspData?.changeAmt != 0) || (receiptData?.txnDetails?.hostRspData?.purchasedAmt && receiptData?.txnDetails?.hostRspData?.purchasedAmt != 0)" class="paymentDetails bold">
        <div class="detail">{{'htmlText.tenderAmt' | translate}}:</div>
        <div class="detail"></div>
        <div class="detail">{{transAmount(sumAmount(receiptData?.txnDetails?.hostRspData?.changeAmt, receiptData?.txnDetails?.hostRspData?.purchasedAmt)) | currency}}</div>
      </div>

      <div *ngIf="isAmountValid(receiptData?.txnDetails?.hostRspData?.changeAmt)" class="paymentDetails bold">
        <div class="detail">{{ 'htmlText.changeDue' | translate }}:</div>
        <div class="detail"></div>
        <div class="detail">{{ transAmount(receiptData?.txnDetails?.hostRspData?.changeAmt) | currency }}</div>
      </div>

      <div class="transactionDetailsResp" *ngIf="receiptData?.txnDetails?.transactionType != 'CASH'">
        <div class="detail">{{'htmlText.resp' | translate}}:</div>
        <div class="detail">{{isApproval(receiptData?.txnDetails?.hostResponseCode)}}
          {{approval(receiptData?.txnDetails?.approvalCode, receiptData?.txnDetails?.txName, receiptData?.txnDetails?.hostResponseCode)}}</div>
        <div class="detail" *ngIf="!(receiptData?.txnDetails?.hostResponseCode == '00000')">{{receiptData?.txnDetails?.hostResponseCode}}</div>
      </div>

      <!-- <div class="transactionDetailsResp" *ngIf="receiptData?.txnDetails.transactionType != 'CASH'">
        <div class="detail">{{'htmlText._TID' | translate}}:</div>
        <div class="detail"></div>
        <div class="detail">{{receiptData?.txnDetails?.processorInfo?.tid}}</div>
      </div> -->
      <!-- <div class="transactionDetailsCode" *ngIf="receiptData?.txnDetails.transactionType != 'CASH' && receiptData?.txnDetails.transactionType != 'GIFT' && receiptData?.txnDetails.transactionType != 'LOYALTY' && receiptData?.txnDetails.transactionType != 'WALLET'">
        <div class="detail">{{'htmlText.code' | translate}}:</div>
        <div class="detail"></div>
        <div class="detail">{{errorHandle(receiptData?.txnDetails?.approvalCode)}}</div>
      </div> -->

      <div class="transactionDetailsRef" *ngIf="receiptData?.txnDetails?.transactionType != 'CASH' && receiptData?.txnDetails?.transactionType != 'WALLET'">
        <div class="detail">{{'htmlText.ref' | translate}} #:</div>
        <div class="detail"></div>
        <div class="detail">{{errorHandle(receiptData?.txnDetails?.rrn)}}</div>
      </div>

                <!-- bus Date -->
                <div *ngIf="receiptData?.txnDetails?.transactionType != 'CASH'" class="transactionDetailsCode">
                  <div class="detail" *ngIf="receiptData?.txnDetails?.transactionType != 'CASH'" >BUS.DATE:</div>
                  <div class="detail"></div>
                  <div class="detail">{{errorHandle(getDate(receiptData?.txnDetails?.posRequestDate) | date:'MM-dd-YYYY')}}</div>
                  </div>

      <div *ngIf="receiptData?.txnDetails?.transactionType != 'CASH' && receiptData?.txnDetails?.transactionType != 'GIFT' && receiptData?.txnDetails?.transactionType != 'LOYALTY' && receiptData?.txnDetails?.transactionType != 'WALLET' && ((receiptData?.txnDetails?.txName === 'SALE' && receiptData?.txnDetails?.voidFlag ==
      'N') || receiptData?.txnDetails?.txName === 'PREAUTH' || receiptData?.txnDetails?.txName === 'REFUND' || receiptData?.txnDetails?.txName === 'RETURN')" class="transactionDetailsTrans">
        <div class="detail" *ngIf="(receiptData?.txnDetails?.tsysRespData?.TxId != '') && !(receiptData?.txnDetails?.hostResponseCode == '00000')">TransId:</div>
        <div class="detail"></div>
        <div class="detail">{{receiptData?.txnDetails?.tsysRespData?.TxId}}</div>
      </div>
      <div *ngIf="receiptData?.signatureDetails?.customField?.appName" class="transactionDetailsCode">
        <div class="detail"*ngIf="receiptData?.signatureDetails?.customField?.appName != ''" >App Name:</div>
        <div class="detail"></div>
        <div class="detail">{{receiptData?.signatureDetails?.customField?.appName}}</div>
      </div>
      <div *ngIf="receiptData?.signatureDetails?.customField?.aid" class="transactionDetailsCode">
        <div class="detail"*ngIf="receiptData?.signatureDetails?.customField?.aid != ''" >AID:</div>
        <div class="detail"></div>
        <div class="detail">{{receiptData?.signatureDetails?.customField?.aid}}</div>
      </div>
      <div *ngIf="receiptData?.signatureDetails?.customField?.tvr" class="transactionDetailsCode">
        <div class="detail"*ngIf="receiptData?.signatureDetails?.customField?.tvr != ''" >TVR:</div>
        <div class="detail"></div>
        <div class="detail">{{receiptData?.signatureDetails?.customField?.tvr}}</div>
      </div>
      <div *ngIf="receiptData?.signatureDetails?.customField?.tsi" class="transactionDetailsCode">
        <div class="detail"*ngIf="receiptData?.signatureDetails?.customField?.tsi != ''" >TSI:</div>
        <div class="detail"></div>
        <div class="detail">{{receiptData?.signatureDetails?.customField?.tsi}}</div>
      </div>
<!-- <ng-container *ngIf="receiptData?.signatureDetails?.tagLabel && receiptData?.signatureDetails?.tagValue">
  <div class=" transactionDetails"
    *ngFor="let item of receiptData?.signatureDetails?.tagLabel.split(','); let i=index; let last=last"
    [ngClass]="!last ?  'mb-0': 'mb-2'">
    <div>{{item}} :</div>
    <div>{{receiptData?.signatureDetails?.tagValue.split(',')[i]}}</div>
  </div>
</ng-container> -->
      <!-- <div class="transactionDetailsApp">
              <div class="detail">APP Name:</div>
              <div class="detail"></div>
              <div class="detail">{{isBrandName(receiptData?.txnDetails.maskedPan)}}</div>
            </div>
            <div class="transactionDetailsAid">
              <div class="detail">AID:</div>
              <div class="detail"></div>
              <div class="detail">A000000003101001</div>
            </div>
            <div class="transactionDetailsTvr">
              <div class="detail">TVR:</div>
              <div class="detail"></div>
              <div class="detail">0000000000</div>
            </div> -->
      <!-- <div class="transactionDetailsTvr">
              <div class="detail">TSI:</div>
              <div class="detail"></div>
              <div class="detail">E45RDF</div>
            </div> -->
            <p *ngIf="receiptData?.txnDetails?.transactionType != 'CASH' && receiptData?.txnDetails?.transactionType != 'WALLET'" class="center agree description-break">{{errorHandle(receiptData?.receiptDetails?.DIS)}}
            </p>
            <div *ngIf="receiptData?.txnDetails?.rocketInfo?.distrubationMode" class="transactionDetailsResp">
              <div class="detail">{{'htmlText.dispenseMode' | translate}}:</div>
              <div class="detail"></div>
              <div class="detail">{{receiptData?.txnDetails?.rocketInfo?.distrubationMode}}</div>
            </div>
            <div *ngIf="receiptData?.txnDetails?.rocketInfo?.rocketProcessorId" class="transactionDetailsCode">
              <div class="detail">{{'htmlText.rocketId' | translate}}:</div>
              <div class="detail"></div>
              <div class="detail pl-1 custom-text-end">{{receiptData?.txnDetails?.rocketInfo?.rocketProcessorId}}</div>
            </div>
            <p class="para-align" *ngIf="receiptData?.signatureDetails?.customField?.rocketReimbMsg">{{receiptData?.signatureDetails?.customField?.rocketReimbMsg}}</p>
      <p class="center" *ngIf="(receiptData?.txnDetails?.pinBlock != 0 || receiptData?.txnDetails?.pinBlock != 'XXXXXXXXXXXXXXXX' || receiptData?.txnDetails?.pinBlock != 'NOCVM') && receiptData?.txnDetails?.transactionType != 'CASH' && receiptData?.txnDetails?.transactionType != 'GIFT' && receiptData?.txnDetails?.transactionType != 'LOYALTY' && receiptData?.txnDetails?.transactionType != 'WALLET'">{{pinBlock(receiptData?.txnDetails?.pinBlock)}}</p>
      <div *ngIf="(receiptData?.signatureDetails != null && receiptData?.signatureDetails?.signImage)" class="logo">
        <img style="width: 100px;" src="{{baseUrl}}/{{receiptData?.signatureDetails?.signImage}}"></div>
      <div *ngIf="(receiptData?.signatureDetails == null || receiptData?.signatureDetails?.signImage == null)"
        class="logo"><img class="img_align"></div>

        <span *ngIf="(receiptData?.txnDetails?.pinBlock == '0')">
          <hr *ngIf="receiptData?.txnDetails?.transactionType != 'CASH' && receiptData?.txnDetails?.transactionType != 'GIFT' && receiptData?.txnDetails?.transactionType != 'LOYALTY' && receiptData?.txnDetails?.transactionType != 'WALLET'" class="hr_align">
        </span>

        <span *ngIf="(receiptData?.txnDetails?.pinBlock == '0')">
          <p *ngIf="receiptData?.txnDetails?.transactionType != 'CASH' && receiptData?.txnDetails?.transactionType != 'GIFT' && receiptData?.txnDetails?.transactionType != 'LOYALTY' && receiptData?.txnDetails?.transactionType != 'WALLET'" class="sign font-weight-bold mb-3" >Signature</p>
        </span>

      <!-- <p class="sign font-weight-bold mb-3" *ngIf="(!receiptData?.signatureDetails?.chName)">Signature</p> -->
      <!-- <p class="sign font-weight-bold mb-1" *ngIf="(receiptData?.signatureDetails?.chName)">Cardholder Name</p> -->
      <p *ngIf="receiptData?.txnDetails?.transactionType != 'CASH' && receiptData?.txnDetails?.transactionType != 'GIFT' && receiptData?.txnDetails?.transactionType != 'LOYALTY' && receiptData?.txnDetails?.transactionType != 'WALLET'" class="center">{{cardholderName(receiptData?.signatureDetails?.chName)}}</p>
      <h6 class="center mt-2">{{receiptType}}</h6>
      <h6 class="center mt-2 description-break">{{errorHandleF(receiptData?.receiptDetails?.f1)}}</h6>
      <h6 class="center mt-2 description-break">{{errorHandleF(receiptData?.receiptDetails?.f2)}}</h6>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="!isNewReceipt">
  <div ngbDropdown class="pt-4">
    <button style="float:right;" class="btn btn-outline-primary btn_align" id="dropdownMenuActiveItem" ngbDropdownToggle> SaveAs</button>
    <div class="list_fixed" ngbDropdownMenu aria-labelledby="dropdownMenuActiveItem">
      <button ngbDropdownItem (click)="download('png')"><i class="link-icon feather icon-image"></i> PNG</button>
      <button ngbDropdownItem (click)="download('pdf')"><i class="link-icon feather icon-file-text"></i> PDF</button>
    </div>
  </div>

  <div id="showScroll" class="container">
    <div class="receipt" #screen>
      <div *ngIf="receiptData.defaultLogo.merchantLogo" class="logo"><img class="logo_align" src="{{baseUrl}}/{{getImage(receiptData.defaultLogo.merchantLogo)}}" alt="MERCHANTLOGO"></div>
      <div class="address">
            <p class="center_add">{{errorHandle(receiptData?.receiptDetails?.h1)}}</p>
            <p class="center_add">{{errorHandle(receiptData?.receiptDetails?.h2)}}</p>
            <p class="center_add">{{errorHandle(receiptData?.receiptDetails?.h3)}}</p>
            <p class="center_add">{{errorHandle(receiptData?.receiptDetails?.h4)}}</p>
            <p class="center_add"></p>
            <p class="center_add"></p>
      </div>
      <div class="transactionDetails">
        <div class="detail">{{errorHandle(getDate(receiptData?.txnDetails?.posRequestDate) | date:'MM-dd-YYYY')}}</div>
        <div class="detail"></div>
        <div class="detail">{{errorHandle(timeSub(receiptData?.txnDetails?.posRequestTime))}}</div>
      </div>

      <div class="survey bold" *ngIf="receiptData?.txnDetails?.transactionType != 'CASH' && receiptData?.txnDetails?.transactionType != 'GIFT' && receiptData?.txnDetails?.transactionType != 'LOYALTY'">
        <p class="txt_size_txname">{{ showVoidLabel(receiptData?.txnDetails?.voidFlag, receiptData?.txnDetails?.txName) }} {{getTxName(receiptData?.txnDetails?.txName , receiptData?.txnDetails?.transactionType, receiptData?.txnDetails?.hostResponseCode)}}</p>
      </div>

      <div class="transactionDetails" *ngIf="receiptData?.txnDetails?.transactionType == 'CASH'">
        <div class="detail">{{'htmlText.type' | translate}}:</div>
        <div class="detail"></div>
        <div class="detail">{{receiptData?.txnDetails?.transactionType}}</div>
      </div>

      <div class="transactionDetailsResp mb-2">
        <div class="detail">{{'htmlText._TPN' | translate}}:</div>
        <div class="detail"></div>
        <div class="detail">{{receiptData?.txnDetails?.tpn}}</div>
      </div>

      <!-- <div class="transactionDetails" *ngIf="receiptData?.txnDetails.transactionType != 'CASH'">
        <div class="detail">{{'htmlText._TID' | translate}}:</div>
        <div class="detail"></div>
        <div class="detail">{{receiptData?.txnDetails?.processorInfo?.tid}}</div>
      </div> -->

      <div class="transactionDetails" *ngIf="receiptData?.txnDetails?.transactionType != 'WALLET'">
        <div class="detail" *ngIf="receiptData?.txnDetails?.transactionType != 'CASH'">{{'htmlText.trans' | translate}} #: {{get(receiptData?.txnDetails?.invoiceNumber, receiptData?.txnDetails?.voidFlag) | number}}</div>
        <div class="detail" *ngIf="receiptData?.txnDetails?.transactionType == 'CASH'">{{'htmlText.trans' | translate}} #: {{receiptData?.txnDetails?.transactionId}}</div>
        <div class="detail"></div>
        <div class="detail">{{'htmlText.batch' | translate}} #: {{receiptData?.txnDetails?.tpnBatchNumber | number}}</div>
      </div>


      <div class="transactionCardName" *ngIf="receiptData?.txnDetails?.transactionType != 'CASH' && receiptData?.txnDetails?.transactionType != 'GIFT' && receiptData?.txnDetails?.transactionType != 'LOYALTY' && receiptData?.txnDetails?.transactionType != 'WALLET'">
        <div class="detail">{{cardLabel(receiptData?.txnDetails?.cardLabel, receiptData?.txnDetails?.transactionType)}}</div>
        <div class="detail"></div>
        <div class="detail" *ngIf="receiptData?.txnDetails?.txName != 'TICKET'" >{{transMode(receiptData?.txnDetails?.posEntryMode)}}</div>
        <div class="detail" *ngIf="receiptData?.txnDetails?.txName == 'TICKET'" >{{'htmlText.manual' | translate}}</div>
      </div>


      <div class="transactionDetails" *ngIf="receiptData?.txnDetails?.transactionType != 'CASH' && receiptData?.txnDetails?.transactionType != 'GIFT' && receiptData?.txnDetails?.transactionType != 'LOYALTY' && receiptData?.txnDetails?.transactionType != 'WALLET'">
        <div class="detail">{{showMaskedCardNum(receiptData?.txnDetails?.maskedPan)}}</div>
        <div class="detail"></div>
        <div class="detail">**/**</div>
      </div>

      <div class="survey bold" *ngIf="receiptData?.txnDetails?.transactionType == 'CASH' || receiptData?.txnDetails?.transactionType == 'GIFT' || receiptData?.txnDetails?.transactionType == 'LOYALTY'">
        <p class="txt_size_txname">{{txHandle(receiptData?.txnDetails?.txName)}}</p>
      </div>


      <div class="transactionDetails" *ngIf="(receiptData?.txnDetails?.transactionType == 'EBT')">
        <div class="detail">{{'htmlText.trace' | translate}} #:</div>
        <div class="detail"></div>
        <div class="detail">{{get(receiptData?.txnDetails?.invoiceNumber,
          receiptData?.txnDetails?.voidFlag) | number}}</div>
      </div>

      <div class="transactionDetails" *ngIf="(receiptData?.txnDetails?.transactionType == 'EBT')">
        <div class="detail">{{'htmlText.benefit' | translate}}:</div>
        <div class="detail"></div>
        <div class="detail">{{ebt_type(receiptData?.txnDetails?.transactionType, receiptData?.txnDetails?.processingCode)}}</div>
      </div>

      <div class="transactionDetails" *ngIf="receiptData?.txnDetails?.transactionType == 'WALLET'">
        <div class="detail">{{receiptData?.txnDetails?.sourceType}}:</div>
        <div class="detail"></div>
        <div class="detail">{{receiptData?.txnDetails?.transactionType}}</div>
      </div>

      <div class="transactionDetails mar_top_acct" *ngIf="receiptData?.txnDetails?.transactionType == 'GIFT' || receiptData?.txnDetails?.transactionType == 'LOYALTY'">
        <div class="detail">{{'htmlText.acct' | translate}}:</div>
        <div class="detail"></div>
        <div class="detail">{{showMaskedCardNum(receiptData?.txnDetails?.maskedPan)}}</div>
      </div>

      <div class="transactionDetails" *ngIf="receiptData?.txnDetails?.transactionType == 'GIFT' || receiptData?.txnDetails?.transactionType == 'LOYALTY'">
        <div class="detail">{{'htmlText.type' | translate}}:</div>
        <div class="detail"></div>
        <div class="detail">{{receiptData?.txnDetails?.transactionType}}</div>
      </div>

      <div class="transactionDetails" *ngIf="receiptData?.txnDetails?.transactionType == 'GIFT' || receiptData?.txnDetails?.transactionType == 'LOYALTY'">
        <div class="detail">{{'htmlText.entry' | translate}}:</div>
        <div class="detail"></div>
        <div class="detail">{{transMode(receiptData?.txnDetails?.posEntryMode)}}</div>
      </div>

      <div class="transactionDetails" *ngIf="receiptData?.txnDetails?.transactionType == 'GIFT' || receiptData?.txnDetails?.transactionType == 'LOYALTY'">
        <div class="detail">{{'htmlText.service' | translate}}:</div>
        <div class="detail"></div>
        <div class="detail">{{txHandle(receiptData?.txnDetails?.txName)}}</div>
      </div>

      <div class="paymentDetails bold" *ngIf="receiptData?.txnDetails?.transactionType == 'LOYALTY'">
        <div class="detail">{{'htmlText.points' | translate}}:</div>
        <div class="detail"></div>
        <div class="detail">{{receiptData?.txnDetails?.txAmount}}</div>
      </div>

    <div *ngIf="isAmountValid(receiptData?.txnDetails?.hostRspData?.purchasedAmt)"
      class="transactionDetailsResp bold">
      <div class="detail">{{ 'htmlText.enteredAmt' | translate }}:</div>
      <div class="detail"></div>
      <div class="detail">{{ transAmount(receiptData?.txnDetails?.hostRspData?.purchasedAmt) | currency }}</div>
    </div>

    <div *ngIf="((receiptData?.txnDetails?.hostRspData?.purchasedAmt != undefined) && (receiptData?.txnDetails?.tipAmount != 0 && receiptData?.txnDetails?.tipAmount != null) || (receiptData?.txnDetails?.tipAdjAmount != 0 && receiptData?.txnDetails?.tipAdjAmount != null))" class="paymentDetails bold">
      <div class="detail">{{'htmlText.tipAmt' | translate}}:</div>
      <div class="detail"></div>
      <div class="detail">{{transAmount(sumTip(receiptData?.txnDetails?.tipAmount,
        receiptData?.txnDetails?.tipAdjAmount, receiptData?.txnDetails?.TipAmountFee)) | currency}}</div>
    </div>

    <div *ngIf="isAmountValid(receiptData?.txnDetails?.hostRspData?.purchasedAmt)"
      class="transactionDetailsResp bold">
      <div class="detail">{{ 'htmlText.withdrawal' | translate }}:</div>
      <div class="detail"></div>
      <div class="detail">{{ transAmount(sumAmount(receiptData?.txnDetails?.txAmount, receiptData?.txnDetails?.tipAmount)) | currency }}</div>
    </div>

    <div *ngIf="(receiptData?.txnDetails?.merchantFee != 0 && receiptData?.txnDetails?.merchantFee != null)" class="paymentDetails bold">
      <div class="detail breakWord">{{defaultMFL(receiptData?.signatureDetails?.customFeeLabel)}}:</div>
      <div class="detail"></div>
      <div class="detail">{{transAmount(receiptData?.txnDetails?.merchantFee) | currency}}</div>
    </div>

    <hr class="dashed-line" *ngIf="receiptData?.txnDetails?.hostRspData?.purchasedAmt == undefined && isShowAmountDue(receiptData?.txnDetails?.hostRspData?.purchasedAmt, receiptData?.txnDetails?.tipAmount)">

    <!-- <div *ngIf="isShowAmountDue(receiptData?.txnDetails?.hostRspData?.purchasedAmt, receiptData?.txnDetails?.tipAmount)" class="transactionDetailsResp bold">
      <div class="detail">{{ 'htmlText.amountDue' | translate }}:</div>
      <div class="detail"></div>
      <div class="detail">{{ transAmount(sumAmount(receiptData?.txnDetails?.hostRspData?.purchasedAmt, receiptData?.txnDetails?.tipAmount)) | currency }}</div>
    </div> -->

    <!-- <hr class="dashed-line" *ngIf="receiptData?.txnDetails?.transactionType != 'LOYALTY' && isAmountValid(receiptData?.txnDetails?.hostRspData?.purchasedAmt)"> -->

    <!-- <div class="paymentDetails bold" *ngIf="receiptData?.txnDetails?.transactionType != 'LOYALTY'">
      <div class="detail">{{ (receiptData?.txnDetails?.hostRspData?.purchasedAmt == undefined) ? ('htmlText.basAmt' | translate) : ('htmlText.withdrawal' | translate) }}:</div>
      <div class="detail"></div>
      <div class="detail">{{transAmount(withdrawAmount(receiptData?.txnDetails?.hostRspData?.purchasedAmt, receiptData?.txnDetails?.txAmount, receiptData?.txnDetails?.tipAmount)) | currency}}</div>
    </div> -->


      <div *ngIf="(receiptData?.txnDetails?.DiscountFee != 0 && receiptData?.txnDetails?.DiscountFee != null)" class="paymentDetails bold">
        <div class="detail">{{'htmlText.disc' | translate}}:</div>
        <div class="detail"></div>
        <div class="detail">{{transAmount(receiptData?.txnDetails?.DiscountFee) | currency}}</div>
      </div>


      <div *ngIf="(receiptData?.txnDetails?.customFee != 0  && receiptData?.txnDetails?.customFee != null)" class="paymentDetails bold">
        <div class="detail">{{defaultName(receiptData?.signatureDetails?.customFeeLabel)}}:</div>
        <div class="detail"></div>
        <div class="detail">{{transAmount(receiptData?.txnDetails?.customFee) | currency}}</div>
      </div>
      <div *ngIf="(receiptData?.txnDetails?.serviceFee != 0 && receiptData?.txnDetails?.serviceFee != null)" class="paymentDetails bold">
        <div class="detail">{{defaultName(receiptData?.signatureDetails?.customFeeLabel)}}:</div>
        <div class="detail"></div>
        <div class="detail">{{transAmount(receiptData?.txnDetails?.serviceFee) | currency}}</div>
      </div>
      <div *ngIf="(receiptData?.txnDetails?.surcharge != 0 && receiptData?.txnDetails?.surcharge != null)" class="paymentDetails bold">
        <div class="detail">{{defaultSCH(receiptData?.signatureDetails?.merchantFeeLabel)}}:</div>
        <div class="detail"></div>
        <div class="detail">{{transAmount(receiptData?.txnDetails?.surcharge) | currency}}</div>
      </div>
      <!-- <div *ngIf="(receiptData?.txnDetails.merchantFee != 0 && receiptData?.txnDetails.merchantFee != null)" class="paymentDetails bold">
        <div class="detail">{{defaultMFL(receiptData?.signatureDetails?.merchantFeeLabel)}}:</div>
        <div class="detail"></div>
        <div class="detail">{{transAmount(receiptData?.txnDetails?.merchantFee) | currency}}</div>
      </div> -->
      <!-- <div *ngIf="(receiptData?.txnDetails?.merchantFee != 0 && receiptData?.txnDetails?.merchantFee != null)" class="paymentDetails bold">
        <div class="detail breakWord">{{defaultMFL(receiptData?.signatureDetails?.customFeeLabel)}}:</div>
        <div class="detail"></div>
        <div class="detail">{{transAmount(receiptData?.txnDetails?.merchantFee) | currency}}</div>
      </div> -->
      <div *ngIf="(receiptData?.txnDetails?.localTaxAmount != 0 && receiptData?.txnDetails?.localTaxAmount != null)" class="paymentDetails bold">
        <div class="detail">{{defaultLTL(receiptData?.signatureDetails?.localTaxLabel)}}:</div>
        <div class="detail"></div>
        <div class="detail">{{transAmount(sumAmount(receiptData?.txnDetails?.localTaxAmount, receiptData?.txnDetails?.LTaxFee)) | currency}}</div>
      </div>
      <div *ngIf="(receiptData?.txnDetails?.stateTaxAmount != 0 && receiptData?.txnDetails?.stateTaxAmount != null)" class="paymentDetails bold">
        <div class="detail">{{defaultSTL(receiptData?.signatureDetails?.stateTaxLabel)}}:</div>
        <div class="detail"></div>
        <div class="detail">{{transAmount(sumAmount(receiptData?.txnDetails?.stateTaxAmount, receiptData?.txnDetails?.GTaxFee)) | currency}}</div>
      </div>
      <div *ngIf="((receiptData?.txnDetails?.hostRspData?.purchasedAmt == undefined) && (receiptData?.txnDetails?.tipAmount != 0 && receiptData?.txnDetails?.tipAmount != null) || (receiptData?.txnDetails?.tipAdjAmount != 0 && receiptData?.txnDetails?.tipAdjAmount != null))" class="paymentDetails bold">
        <div class="detail">{{'htmlText.tipAmt' | translate}}:</div>
        <div class="detail"></div>
        <div class="detail">{{transAmount(sumTip(receiptData?.txnDetails?.tipAmount,
          receiptData?.txnDetails?.tipAdjAmount, receiptData?.txnDetails?.TipAmountFee)) | currency}}</div>
      </div>
      <div *ngIf="(receiptData?.txnDetails?.cashDiscount != 0 && receiptData?.txnDetails?.cashDiscount != null)" class="paymentDetails bold">
        <div class="detail">{{errorHandle(cashDis(receiptData?.signatureDetails?.discLabel))}}:</div>
        <div class="detail"></div>
        <div class="detail">{{transAmount(receiptData?.txnDetails?.cashDiscount) | currency}}</div>
      </div>
      <!-- <div *ngIf="((receiptData?.txnDetails.totalAmount != receiptData?.txnDetails.txAmount) && receiptData?.txnDetails.transactionType != 'CASH' && receiptData?.txnDetails.transactionType != 'LOYALTY')" -->

      <hr class="dashed-line" *ngIf="((receiptData?.txnDetails?.hostRspData?.purchasedAmt != undefined) && receiptData?.txnDetails?.transactionType != 'LOYALTY' && getFeeDetails(receiptData?.feeDetails, receiptData?.txnDetails))">

      <div *ngIf="(receiptData?.txnDetails?.transactionType != 'LOYALTY' && receiptData?.txnDetails?.txName != 'BALANCE INQUIRY' && getFeeDetails(receiptData?.feeDetails, receiptData?.txnDetails))"
        class="paymentDetails bold">
        <div class="detail">{{'htmlText.total' | translate}}:</div>
        <div class="detail"></div>
        <div class="detail">{{transAmount(receiptData?.txnDetails?.totalAmount) | currency}}</div>
      </div>
      
      <hr class="dashed-line" *ngIf="((receiptData?.txnDetails?.hostRspData?.purchasedAmt != undefined) && receiptData?.txnDetails?.transactionType != 'LOYALTY' && getFeeDetails(receiptData?.feeDetails, receiptData?.txnDetails))">
      
      <!-- <div *ngIf="(receiptData?.txnDetails?.hostRspData?.changeAmt && receiptData?.txnDetails?.hostRspData?.changeAmt != 0) || (receiptData?.txnDetails?.hostRspData?.purchasedAmt && receiptData?.txnDetails?.hostRspData?.purchasedAmt != 0)" class="paymentDetails bold">
        <div class="detail">{{'htmlText.tenderAmt' | translate}}:</div>
        <div class="detail"></div>
        <div class="detail">{{transAmount(sumAmount(receiptData?.txnDetails?.hostRspData?.changeAmt, receiptData?.txnDetails?.hostRspData?.purchasedAmt)) | currency}}</div>
      </div> -->

      <!-- <div *ngIf="isAmountValid(receiptData?.txnDetails?.hostRspData?.changeAmt)" class="paymentDetails bold">
        <div class="detail">{{ 'htmlText.changeDue' | translate }}:</div>
        <div class="detail"></div>
        <div class="detail">{{ transAmount(receiptData?.txnDetails?.hostRspData?.changeAmt) | currency }}</div>
      </div> -->

      <div class="transactionDetailsResp" *ngIf="receiptData?.txnDetails?.transactionType != 'CASH'">
        <div class="detail">{{'htmlText.resp' | translate}}:</div>
        <div class="detail">{{isApproval(receiptData?.txnDetails?.hostResponseCode)}}
          {{approval(receiptData?.txnDetails?.approvalCode, receiptData?.txnDetails?.txName, receiptData?.txnDetails?.hostResponseCode)}}</div>
        <div class="detail" *ngIf="!(receiptData?.txnDetails?.hostResponseCode == '00000')">{{receiptData?.txnDetails?.hostResponseCode}}</div>
      </div>

      <!-- <div class="transactionDetailsResp" *ngIf="receiptData?.txnDetails.transactionType != 'CASH'">
        <div class="detail">{{'htmlText._TID' | translate}}:</div>
        <div class="detail"></div>
        <div class="detail">{{receiptData?.txnDetails?.processorInfo?.tid}}</div>
      </div> -->
      <!-- <div class="transactionDetailsCode" *ngIf="receiptData?.txnDetails.transactionType != 'CASH' && receiptData?.txnDetails.transactionType != 'GIFT' && receiptData?.txnDetails.transactionType != 'LOYALTY' && receiptData?.txnDetails.transactionType != 'WALLET'">
        <div class="detail">{{'htmlText.code' | translate}}:</div>
        <div class="detail"></div>
        <div class="detail">{{errorHandle(receiptData?.txnDetails?.approvalCode)}}</div>
      </div> -->

      <div class="transactionDetailsRef" *ngIf="receiptData?.txnDetails?.transactionType != 'CASH' && receiptData?.txnDetails?.transactionType != 'WALLET'">
        <div class="detail">{{'htmlText.ref' | translate}} #:</div>
        <div class="detail"></div>
        <div class="detail">{{errorHandle(receiptData?.txnDetails?.rrn)}}</div>
      </div>

                <!-- bus Date -->
                <div *ngIf="receiptData?.txnDetails?.transactionType != 'CASH'" class="transactionDetailsCode">
                  <div class="detail" *ngIf="receiptData?.txnDetails?.transactionType != 'CASH'" >BUS.DATE:</div>
                  <div class="detail"></div>
                  <div class="detail">{{errorHandle(getDate(receiptData?.txnDetails?.posRequestDate) | date:'MM-dd-YYYY')}}</div>
                  </div>

      <div *ngIf="receiptData?.txnDetails?.transactionType != 'CASH' && receiptData?.txnDetails?.transactionType != 'GIFT' && receiptData?.txnDetails?.transactionType != 'LOYALTY' && receiptData?.txnDetails?.transactionType != 'WALLET' && ((receiptData?.txnDetails?.txName === 'SALE' && receiptData?.txnDetails?.voidFlag ==
      'N') || receiptData?.txnDetails?.txName === 'PREAUTH' || receiptData?.txnDetails?.txName === 'REFUND' || receiptData?.txnDetails?.txName === 'RETURN')" class="transactionDetailsTrans">
        <div class="detail" *ngIf="(receiptData?.txnDetails?.tsysRespData?.TxId != '') && !(receiptData?.txnDetails?.hostResponseCode == '00000')">TransId:</div>
        <div class="detail"></div>
        <div class="detail">{{receiptData?.txnDetails?.tsysRespData?.TxId}}</div>
      </div>
      <div *ngIf="receiptData?.signatureDetails?.customField?.appName" class="transactionDetailsCode">
        <div class="detail"*ngIf="receiptData?.signatureDetails?.customField?.appName != ''" >App Name:</div>
        <div class="detail"></div>
        <div class="detail">{{receiptData?.signatureDetails?.customField?.appName}}</div>
      </div>
      <div *ngIf="receiptData?.signatureDetails?.customField?.aid" class="transactionDetailsCode">
        <div class="detail"*ngIf="receiptData?.signatureDetails?.customField?.aid != ''" >AID:</div>
        <div class="detail"></div>
        <div class="detail">{{receiptData?.signatureDetails?.customField?.aid}}</div>
      </div>
      <div *ngIf="receiptData?.signatureDetails?.customField?.tvr" class="transactionDetailsCode">
        <div class="detail"*ngIf="receiptData?.signatureDetails?.customField?.tvr != ''" >TVR:</div>
        <div class="detail"></div>
        <div class="detail">{{receiptData?.signatureDetails?.customField?.tvr}}</div>
      </div>
      <div *ngIf="receiptData?.signatureDetails?.customField?.tsi" class="transactionDetailsCode">
        <div class="detail"*ngIf="receiptData?.signatureDetails?.customField?.tsi != ''" >TSI:</div>
        <div class="detail"></div>
        <div class="detail">{{receiptData?.signatureDetails?.customField?.tsi}}</div>
      </div>
<!-- <ng-container *ngIf="receiptData?.signatureDetails?.tagLabel && receiptData?.signatureDetails?.tagValue">
  <div class=" transactionDetails"
    *ngFor="let item of receiptData?.signatureDetails?.tagLabel.split(','); let i=index; let last=last"
    [ngClass]="!last ?  'mb-0': 'mb-2'">
    <div>{{item}} :</div>
    <div>{{receiptData?.signatureDetails?.tagValue.split(',')[i]}}</div>
  </div>
</ng-container> -->
      <!-- <div class="transactionDetailsApp">
              <div class="detail">APP Name:</div>
              <div class="detail"></div>
              <div class="detail">{{isBrandName(receiptData?.txnDetails.maskedPan)}}</div>
            </div>
            <div class="transactionDetailsAid">
              <div class="detail">AID:</div>
              <div class="detail"></div>
              <div class="detail">A000000003101001</div>
            </div>
            <div class="transactionDetailsTvr">
              <div class="detail">TVR:</div>
              <div class="detail"></div>
              <div class="detail">0000000000</div>
            </div> -->
      <!-- <div class="transactionDetailsTvr">
              <div class="detail">TSI:</div>
              <div class="detail"></div>
              <div class="detail">E45RDF</div>
            </div> -->
            <p *ngIf="receiptData?.txnDetails?.transactionType != 'CASH' && receiptData?.txnDetails?.transactionType != 'WALLET'" class="center agree description-break">{{errorHandle(receiptData?.receiptDetails?.DIS)}}
            </p>
            <div *ngIf="receiptData?.txnDetails?.rocketInfo?.distrubationMode" class="transactionDetailsResp">
              <div class="detail">{{'htmlText.dispenseMode' | translate}}:</div>
              <div class="detail"></div>
              <div class="detail">{{receiptData?.txnDetails?.rocketInfo?.distrubationMode}}</div>
            </div>
            <div *ngIf="receiptData?.txnDetails?.rocketInfo?.rocketProcessorId" class="transactionDetailsCode">
              <div class="detail">{{'htmlText.rocketId' | translate}}:</div>
              <div class="detail"></div>
              <div class="detail pl-1 custom-text-end">{{receiptData?.txnDetails?.rocketInfo?.rocketProcessorId}}</div>
            </div>
            <p class="para-align" *ngIf="receiptData?.signatureDetails?.customField?.rocketReimbMsg">{{receiptData?.signatureDetails?.customField?.rocketReimbMsg}}</p>
      <p class="center" *ngIf="(receiptData?.txnDetails?.pinBlock != 0 || receiptData?.txnDetails?.pinBlock != 'XXXXXXXXXXXXXXXX' || receiptData?.txnDetails?.pinBlock != 'NOCVM') && receiptData?.txnDetails?.transactionType != 'CASH' && receiptData?.txnDetails?.transactionType != 'GIFT' && receiptData?.txnDetails?.transactionType != 'LOYALTY' && receiptData?.txnDetails?.transactionType != 'WALLET'">{{pinBlock(receiptData?.txnDetails?.pinBlock)}}</p>
      <div *ngIf="(receiptData?.signatureDetails != null && receiptData?.signatureDetails?.signImage)" class="logo">
        <img style="width: 100px;" src="{{baseUrl}}/{{receiptData?.signatureDetails?.signImage}}"></div>
      <div *ngIf="(receiptData?.signatureDetails == null || receiptData?.signatureDetails?.signImage == null)"
        class="logo"><img class="img_align"></div>

        <span *ngIf="(receiptData?.txnDetails?.pinBlock == '0')">
          <hr *ngIf="receiptData?.txnDetails?.transactionType != 'CASH' && receiptData?.txnDetails?.transactionType != 'GIFT' && receiptData?.txnDetails?.transactionType != 'LOYALTY' && receiptData?.txnDetails?.transactionType != 'WALLET'" class="hr_align">
        </span>

        <span *ngIf="(receiptData?.txnDetails?.pinBlock == '0')">
          <p *ngIf="receiptData?.txnDetails?.transactionType != 'CASH' && receiptData?.txnDetails?.transactionType != 'GIFT' && receiptData?.txnDetails?.transactionType != 'LOYALTY' && receiptData?.txnDetails?.transactionType != 'WALLET'" class="sign font-weight-bold mb-3" >Signature</p>
        </span>

      <!-- <p class="sign font-weight-bold mb-3" *ngIf="(!receiptData?.signatureDetails?.chName)">Signature</p> -->
      <!-- <p class="sign font-weight-bold mb-1" *ngIf="(receiptData?.signatureDetails?.chName)">Cardholder Name</p> -->
      <p *ngIf="receiptData?.txnDetails?.transactionType != 'CASH' && receiptData?.txnDetails?.transactionType != 'GIFT' && receiptData?.txnDetails?.transactionType != 'LOYALTY' && receiptData?.txnDetails?.transactionType != 'WALLET'" class="center">{{cardholderName(receiptData?.signatureDetails?.chName)}}</p>
      <h6 class="center mt-2">{{receiptType}}</h6>
      <h6 class="center mt-2 description-break">{{errorHandleF(receiptData?.receiptDetails?.f1)}}</h6>
      <h6 class="center mt-2 description-break">{{errorHandleF(receiptData?.receiptDetails?.f2)}}</h6>
    </div>
  </div>
</ng-container>
</div>
