import { Injectable } from "@angular/core";
import { HttpClient, HttpEventType, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { environment } from "../../environments/environment";
import {apiLinks} from "../core/api-links/api-links";
import { AuthService } from "./auth.service";
import {ErrorHandlerService} from './error-handler.service';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {

  constructor( private httpClient: HttpClient, private handleError:ErrorHandlerService) { }

  getAllDevice(params) {
    console.log(params)
    let httpParams = new HttpParams();
    Object.keys(params).forEach(function (key) {
      httpParams = httpParams.append(key, params[key]);
    });
    console.log(httpParams);
    return this.httpClient.get(apiLinks.deviceUrl, {params: httpParams}).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.handleError.handleError)
    );
  }

  getAllDeviceName() {
    return this.httpClient.get(apiLinks.deviceNameUrl).pipe(
      map((res: any) => {
        // console.log("res", res.count)
        return res;
      }),
      catchError(this.handleError.handleError)
    );
  }

  getTemplateJson() {

    return {
      "version": 1,
      "author": "Denovo",
      "title": "Params Form",
      "deviceId": "",
      "Host_Config": {
        "txn": [
          "switch.denovosystem.tech",
          "3001"
        ],
        "stream": [
          "steam.denovosystem.tech",
          "3002"
        ],
        "portal": [
          "api.denovosystem.tech",
          "3002"
        ]
      },
      "sections": [
        {
          "name": "Receipt",
          "id": "RECEIPT",
          "description": "",
          "panels": [
            {
              "headerText": {
                "text": "Header"
              },
              "fields": [
                {
                  "label": "",
                  "inputType": "text",
                  "required": true,
                  "validations": [
                    {
                      "exp": "^[ A-Za-z0-9_@.,/#&+-]*$",
                      "errorMsg": "Header 1 can't contain !$%^*()=",
                      "name": "checkChar"
                    },
                    {
                      "exp": "^.{1,25}$",
                      "errorMsg": "Header 1 should not exceed the max length than 25",
                      "name": "checkLen"
                    }
                  ],
                  "defaultValue": "Header1",
                  "placeholder": "Please Enter Header 1",
                  "visible": true,
                  "posParam": "h1",
                  "editable": true,
                  "width": "12",
                  "canShow": {
                    "userType": [
                      "SYSTEM"
                    ],
                    "customerIds": []
                  }
                },
                {
                  "label": "",
                  "inputType": "text",
                  "required": true,
                  "validations": [
                    {
                      "exp": "^[ A-Za-z0-9_@.,/#&+-]*$",
                      "errorMsg": "Header 2 must be Alphanumeric ",
                      "name": "checkChar"
                    },
                    {
                      "exp": "^.{1,25}$",
                      "errorMsg": "Header 2 should not exceed the max length than 25",
                      "name": "checkLen"
                    }
                  ],
                  "defaultValue": "Header2",
                  "placeholder": "Please Enter Header 2",
                  "visible": true,
                  "posParam": "h2",
                  "editable": true,
                  "width": "12"
                },
                {
                  "label": "",
                  "inputType": "text",
                  "required": true,
                  "validations": [
                    {
                      "exp": "^[ A-Za-z0-9_@.,/#&+-]*$",
                      "errorMsg": "Header 3 must be Alphanumeric ",
                      "name": "checkChar"
                    },
                    {
                      "exp": "^.{1,25}$",
                      "errorMsg": "Header 3 should not exceed the max length than 25",
                      "name": "checkLen"
                    }
                  ],
                  "defaultValue": "Header3",
                  "placeholder": "Please Enter Header 3",
                  "visible": true,
                  "posParam": "h3",
                  "editable": true,
                  "width": "12"
                },
                {
                  "label": "",
                  "inputType": "text",
                  "required": true,
                  "validations": [
                    {
                      "exp": "^[ A-Za-z0-9_@.,/#&+-]*$",
                      "errorMsg": "Header 4 must be Alphanumeric",
                      "name": "checkChar"
                    },
                    {
                      "exp": "^.{1,25}$",
                      "errorMsg": "Header 4 should not exceed the max length than 25",
                      "name": "checkLen"
                    }
                  ],
                  "defaultValue": "Header4",
                  "placeholder": "Please Enter Header 4",
                  "visible": true,
                  "posParam": "h4",
                  "editable": true,
                  "width": "12"
                }
              ],
              "showHeader": true,
              "panelWidth": "6",
              "panelMinHeight": ""
            },
            {
              "headerText": {
                "text": "Print Choices"
              },
              "fields": [
                {
                  "label": "Merchant Copy",
                  "inputType": "radio",
                  "required": true,
                  "defaultValue": "2",
                  "visible": true,
                  "options": [
                    {
                      "value": "1",
                      "text": "Print"
                    },
                    {
                      "value": "2",
                      "text": "Don't Print (I will download from server)"
                    }
                  ],
                  "posParam": "MR_C",
                  "editable": true,
                  "tooltip": "Choose the any one from the options given below",
                  "width": "12"
                },
                {
                  "label": "Customer Copy",
                  "inputType": "checkbox",
                  "required": true,
                  "defaultValue": [
                    "1",
                    "2"
                  ],
                  "visible": true,
                  "options": [
                    {
                      "value": "1",
                      "text": "SMS"
                    },
                    {
                      "value": "2",
                      "text": "Email"
                    },
                    {
                      "value": "3",
                      "text": "Paper"
                    }
                  ],
                  "posParam": "CR_C",
                  "editable": true,
                  "tooltip": "Choose the any option from the options given below",
                  "width": "12"
                }
              ],
              "showHeader": true,
              "panelWidth": "6",
              "panelMinHeight": "268px"
            },
            {
              "headerText": {
                "text": "Disclaimer"
              },
              "fields": [
                {
                  "label": "Disclaimer",
                  "inputType": "textarea",
                  "required": true,
                  "validations": [
                    {
                      "exp": "^[ A-Za-z0-9_@.,/#&+-]*$",
                      "errorMsg": "This field can't contain !$%^*()=",
                      "name": "checkChar"
                    },
                    {
                      "exp": "^.{0,255}$",
                      "errorMsg": "This field can't have more than 255 characters",
                      "name": "checkCharLen"
                    }
                  ],
                  "defaultValue": "",
                  "placeholder": "Type here..",
                  "visible": true,
                  "posParam": "DIS",
                  "editable": true,
                  "width": "12",
                  "displayMode": "raw"
                }
              ],
              "showHeader": false,
              "panelWidth": "12"
            }
          ]
        },
        {
          "name": "Tip & Taxes",
          "id": "TIP_TAX",
          "panels": [
            {
              "headerText": {
                "text": "Tip"
              },
              "fields": [
                {
                  "label": "Tip",
                  "inputType": "switch",
                  "required": true,
                  "defaultValue": "0",
                  "visible": true,
                  "options": [
                    {
                      "value": "1",
                      "text": "Enable"
                    },
                    {
                      "value": "0",
                      "text": "Disable"
                    }
                  ],
                  "posParam": "TIP",
                  "editable": true,
                  "width": "12"
                },
                {
                  "label": "Tip Suggestion on Paper",
                  "inputType": "switch",
                  "required": true,
                  "defaultValue": "1",
                  "visible": true,
                  "options": [
                    {
                      "value": "1",
                      "text": "Enable"
                    },
                    {
                      "value": "0",
                      "text": "Disable"
                    }
                  ],
                  "posParam": "TS_PAP",
                  "editable": true,
                  "width": "6",
                  "showOnly": "{{TIP}}=='1'"
                },
                {
                  "label": "Tip Suggestion on POS",
                  "inputType": "switch",
                  "required": true,
                  "defaultValue": "0",
                  "visible": true,
                  "options": [
                    {
                      "value": "1",
                      "text": "Enable"
                    },
                    {
                      "value": "0",
                      "text": "Disable"
                    }
                  ],
                  "posParam": "TS_POS",
                  "editable": true,
                  "width": "6",
                  "showOnly": "{{TIP}}=='1'"
                },
                {
                  "label": "Suggestions",
                  "inputType": "number",
                  "required": true,
                  "validations": [
                    {
                      "exp": "^0*(?:[1-9][0-9]?|100)$",
                      "errorMsg": "Invalid Input!",
                      "name": "checkMinMax"
                    }
                  ],
                  "defaultValue": "10",
                  "placeholder": "Please Enter Suggestion 1",
                  "visible": true,
                  "posParam": "S1",
                  "editable": true,
                  "width": "6",
                  "showOnly": "{{TIP}}=='1' && ({{TS_PAP}}=='1' || {{TS_POS}}=='1')",
                  "displayMode": "raw",
                  "inputSuffix": "%"
                },
                {
                  "label": "<hide>",
                  "inputType": "number",
                  "required": true,
                  "validations": [
                    {
                      "exp": "^0*(?:[1-9][0-9]?|100)$",
                      "errorMsg": "Invalid Input!",
                      "name": "checkMinMax"
                    }
                  ],
                  "defaultValue": "20",
                  "placeholder": "Please Enter Suggestion 2",
                  "visible": true,
                  "posParam": "S2",
                  "editable": true,
                  "tooltip": "",
                  "width": "6",
                  "showOnly": "{{TIP}}=='1' && ({{TS_PAP}}=='1' || {{TS_POS}}=='1')",
                  "inputSuffix": "%"
                },
                {
                  "inputType": "number",
                  "required": true,
                  "validations": [
                    {
                      "exp": "^0*(?:[1-9][0-9]?|100)$",
                      "errorMsg": "Invalid Input!",
                      "name": "checkMinMax"
                    }
                  ],
                  "defaultValue": "30",
                  "placeholder": "Please Enter Suggestion 3",
                  "visible": true,
                  "posParam": "S3",
                  "editable": true,
                  "tooltip": "",
                  "width": "6",
                  "showOnly": "{{TIP}}=='1' && ({{TS_PAP}}=='1' || {{TS_POS}}=='1')",
                  "inputSuffix": "%"
                },
                {
                  "inputType": "number",
                  "required": true,
                  "validations": [
                    {
                      "exp": "^0*(?:[1-9][0-9]?|100)$",
                      "errorMsg": "Invalid Input!",
                      "name": "checkMinMax"
                    }
                  ],
                  "defaultValue": "40",
                  "placeholder": "Please Enter Suggestion 4",
                  "visible": true,
                  "posParam": "S4",
                  "editable": true,
                  "tooltip": "",
                  "width": "6",
                  "showOnly": "{{TIP}}=='1' && ({{TS_PAP}}=='1' || {{TS_POS}}=='1')",
                  "inputSuffix": "%"
                },
                {
                  "label": "<hide>",
                  "inputType": "group",
                  "width": "12",
                  "showOnly": "{{TIP}}=='1' && ({{TS_PAP}}=='1' || {{TS_POS}}=='1')",
                  "groupElements": [
                    {
                      "label": "Max Tip allowed is",
                      "inputType": "null",
                      "showOnly": "{{TIP}}=='1'"
                    },
                    {
                      "label": "<hide>",
                      "inputType": "number",
                      "inputSuffix": "%",
                      "required": true,
                      "validations": [
                        {
                          "exp": "^0*(?:[1-9][0-9]?|100)$",
                          "errorMsg": "Invalid Input!",
                          "name": "checkMinMax"
                        }
                      ],
                      "defaultValue": "",
                      "placeholder": "",
                      "visible": true,
                      "posParam": "MAX_TP",
                      "editable": true,
                      "tooltip": "",
                      "width": "4",
                      "showOnly": "{{TIP}}=='1'"
                    },
                    {
                      "label": " Or ",
                      "inputType": "null",
                      "showOnly": "{{TIP}}=='1'"
                    },
                    {
                      "label": "<hide>",
                      "inputType": "number",
                      "inputPrefix": "$",
                      "required": true,
                      "defaultValue": "100",
                      "placeholder": "",
                      "visible": true,
                      "posParam": "MAX_TA",
                      "editable": true,
                      "width": "4",
                      "showOnly": "{{TIP}}=='1'"
                    },
                    {
                      "label": "whichever is higher.",
                      "inputType": "null",
                      "showOnly": "{{TIP}}=='1'"
                    }
                  ]
                }
              ],
              "showHeader": true,
              "panelWidth": "8",
              "panelMinHeight": ""
            },
            {
              "headerText": {
                "text": "Taxes"
              },
              "fields": [
                {
                  "label": "Tax",
                  "inputType": "switch",
                  "required": true,
                  "defaultValue": "0",
                  "visible": true,
                  "options": [
                    {
                      "value": "1",
                      "text": "Enable"
                    },
                    {
                      "value": "0",
                      "text": "Disable"
                    }
                  ],
                  "posParam": "TAX",
                  "editable": true,
                  "width": "12"
                },
                {
                  "label": "State Tax",
                  "inputType": "number",
                  "required": true,
                  "validations": [
                    {
                      "exp": "^(2[0-8]|1[0-9]|[1-9])$",
                      "errorMsg": "Max allowed Tax is 28%!",
                      "name": "checkTax"
                    }
                  ],
                  "defaultValue": "10",
                  "placeholder": "Please Enter State Tax",
                  "visible": true,
                  "posParam": "STAX",
                  "editable": true,
                  "width": "6",
                  "showOnly": "{{TAX}}=='1'",
                  "inputSuffix": "%"
                },
                {
                  "label": "Local Tax",
                  "inputType": "number",
                  "required": true,
                  "validations": [
                    {
                      "exp": "^(2[0-8]|1[0-9]|[1-9])$",
                      "errorMsg": "Max allowed Tax is 28%!",
                      "name": "checkTax"
                    }
                  ],
                  "defaultValue": "20",
                  "placeholder": "Please Enter Local Tax",
                  "visible": true,
                  "posParam": "LTAX",
                  "editable": true,
                  "width": "6",
                  "showOnly": "{{TAX}}=='1'",
                  "inputSuffix": "%"
                }
              ],
              "showHeader": true,
              "panelWidth": "4"
            }
          ]
        },
        {
          "name": "Transaction",
          "id": "TRANSACTION",
          "panels": [
            {
              "headerText": {
                "text": "Tx Type"
              },
              "fields": [
                {
                  "label": "",
                  "inputType": "checkbox",
                  "required": true,
                  "defaultValue": [
                    "1",
                    "2"
                  ],
                  "visible": true,
                  "options": [
                    {
                      "value": "1",
                      "text": "Sale"
                    },
                    {
                      "value": "2",
                      "text": "Void"
                    },
                    {
                      "value": "3",
                      "text": "Ticket"
                    },
                    {
                      "value": "4",
                      "text": "Refund"
                    },
                    {
                      "value": "5",
                      "text": "Auth"
                    }
                  ],
                  "optionPerRow": "2",
                  "posParam": "TX_TYP",
                  "editable": true,
                  "width": "12"
                }
              ],
              "showHeader": true,
              "panelWidth": "6"
            },
            {
              "headerText": {
                "text": "AVS"
              },
              "fields": [
                {
                  "inputType": "radio",
                  "required": true,
                  "defaultValue": "4",
                  "visible": true,
                  "options": [
                    {
                      "value": "1",
                      "text": "Zip Code"
                    },
                    {
                      "value": "2",
                      "text": "Street #"
                    },
                    {
                      "value": "3",
                      "text": "Both Zip & Street"
                    },
                    {
                      "value": "4",
                      "text": "None"
                    }
                  ],
                  "optionPerRow": "2",
                  "posParam": "AVS",
                  "editable": true,
                  "width": "12"
                }
              ],
              "showHeader": true,
              "panelWidth": "6",
              "panelMinHeight": "107px"
            },
            {
              "headerText": {
                "text": "Card Type"
              },
              "fields": [
                {
                  "inputType": "checkbox",
                  "required": true,
                  "defaultValue": [
                    "1",
                    "2",
                    "3",
                    "4"
                  ],
                  "visible": true,
                  "options": [
                    {
                      "value": "1",
                      "text": "Credit"
                    },
                    {
                      "value": "2",
                      "text": "Debit"
                    },
                    {
                      "value": "3",
                      "text": "ebt"
                    },
                    {
                      "value": "4",
                      "text": "Gift"
                    },
                    {
                      "value": "5",
                      "text": "Cash"
                    },
                    {
                      "value": "6",
                      "text": "Prepaid"
                    }
                  ],
                  "optionPerRow": "2",
                  "posParam": "CRD_TYP",
                  "editable": true,
                  "width": "12"
                }
              ],
              "showHeader": true,
              "panelWidth": "6",
              "panelMinHeight": ""
            },
            {
              "headerText": {
                "text": "Card Schemes"
              },
              "fields": [
                {
                  "inputType": "checkbox",
                  "required": true,
                  "defaultValue": [
                    "1",
                    "2",
                    "3"
                  ],
                  "visible": true,
                  "options": [
                    {
                      "value": "1",
                      "text": "Visa"
                    },
                    {
                      "value": "2",
                      "text": "Master"
                    },
                    {
                      "value": "3",
                      "text": "Amex"
                    },
                    {
                      "value": "4",
                      "text": "Discover"
                    },
                    {
                      "value": "5",
                      "text": "Diner"
                    },
                    {
                      "value": "6",
                      "text": "JCB"
                    }
                  ],
                  "optionPerRow": "2",
                  "posParam": "CRD_SCH",
                  "editable": true,
                  "width": "12"
                }
              ],
              "showHeader": true,
              "panelWidth": "6",
              "panelMinHeight": ""
            },
            {
              "headerText": {
                "text": "Others"
              },
              "fields": [
                {
                  "label": "Manual Key Entry",
                  "inputType": "switch",
                  "required": true,
                  "defaultValue": "0",
                  "visible": true,
                  "options": [
                    {
                      "value": "1",
                      "text": "Yes"
                    },
                    {
                      "value": "0",
                      "text": "No"
                    }
                  ],
                  "posParam": "MKE",
                  "editable": true,
                  "width": "6"
                },
                {
                  "label": "Mandatory CVV",
                  "inputType": "switch",
                  "required": true,
                  "defaultValue": "0",
                  "placeholder": "",
                  "visible": true,
                  "options": [
                    {
                      "value": "1",
                      "text": "Yes"
                    },
                    {
                      "value": "0",
                      "text": "No"
                    }
                  ],
                  "posParam": "MCVV",
                  "editable": true,
                  "width": "6",
                  "displayMode": "raw"
                },
                {
                  "label": "Signature Line",
                  "inputType": "radio",
                  "required": true,
                  "defaultValue": "1",
                  "visible": true,
                  "options": [
                    {
                      "value": "1",
                      "text": "Based on CVM"
                    },
                    {
                      "value": "2",
                      "text": "For All Credit Tx"
                    }
                  ],
                  "posParam": "SL",
                  "editable": true,
                  "tooltip": "Choose the any one from the options given below",
                  "width": "12"
                }
              ],
              "showHeader": true,
              "panelWidth": "6"
            }
          ]
        },
        {
          "name": "Settlement",
          "id": "SETL",
          "panels": [
            {
              "headerText": {
                "text": "Settlement Options"
              },
              "fields": [
                {
                  "label": "Settlement Mode",
                  "inputType": "radio",
                  "required": true,
                  "defaultValue": "1",
                  "visible": true,
                  "options": [
                    {
                      "value": "1",
                      "text": "Manual"
                    },
                    {
                      "value": "2",
                      "text": "Automatic"
                    }
                  ],
                  "posParam": "SM",
                  "editable": true,
                  "width": "4"
                },
                {
                  "label": "Settlement Time",
                  "inputType": "time",
                  "required": true,
                  "defaultValue": {
                    "hour": 13,
                    "minute": 30
                  },
                  "visible": true,
                  "posParam": "AU_ST",
                  "editable": true,
                  "tooltip": "",
                  "width": "8",
                  "showOnly": "{{SM}}=='2'"
                },
                {
                  "label": "Print Report",
                  "inputType": "radio",
                  "required": true,
                  "defaultValue": "1",
                  "visible": true,
                  "options": [
                    {
                      "value": "1",
                      "text": "Only Confirmation"
                    },
                    {
                      "value": "2",
                      "text": "Summary"
                    },
                    {
                      "value": "3",
                      "text": "Summary & Detail"
                    }
                  ],
                  "posParam": "PR",
                  "editable": true,
                  "tooltip": "Choose the any one from the options given below",
                  "width": "4",
                  "showOnly": "{{SM}}=='2'"
                },
                {
                  "inputType": "group",
                  "showOnly": "{{SM}}=='2'",
                  "groupElements": [
                    {
                      "label": "Retry",
                      "inputType": "null",
                      "showOnly": "{{SM}}=='2'"
                    },
                    {
                      "label": "<hide>",
                      "inputType": "number",
                      "required": true,
                      "validations": [
                        {
                          "exp": "^0*(?:[1-9][0-9]?|100)$",
                          "errorMsg": "Invalid Input!",
                          "name": "checkMinMax"
                        }
                      ],
                      "visible": true,
                      "posParam": "RT",
                      "editable": true,
                      "width": "4",
                      "showOnly": "{{SM}}=='2'"
                    },
                    {
                      "label": "Times after every",
                      "inputType": "null",
                      "showOnly": "{{SM}}=='2'"
                    },
                    {
                      "label": "<hide>",
                      "inputType": "number",
                      "required": true,
                      "validations": [
                        {
                          "exp": "^0*(?:[1-9][0-9]?|100)$",
                          "errorMsg": "Invalid Input!",
                          "name": "checkMinMax"
                        }
                      ],
                      "visible": true,
                      "posParam": "RM",
                      "editable": true,
                      "width": "4",
                      "showOnly": "{{SM}}=='2'"
                    },
                    {
                      "label": "minutes.",
                      "inputType": "null",
                      "showOnly": "{{SM}}=='2'"
                    }
                  ]
                }
              ],
              "showHeader": true,
              "panelWidth": "12"
            }
          ]
        },
        {
          "name": "Fee",
          "id": "FEE",
          "panels": [
            {
              "headerText": {
                "text": ""
              },
              "fields": [
                {
                  "inputType": "switch",
                  "required": true,
                  "defaultValue": "0",
                  "visible": true,
                  "options": [
                    {
                      "value": "1",
                      "text": "Enable"
                    },
                    {
                      "value": "0",
                      "text": "Disable"
                    }
                  ],
                  "posParam": "FEE",
                  "editable": true,
                  "width": "4",
                  "label": "Fee"
                }
              ],
              "showHeader": false,
              "panelWidth": "12"
            },
            {
              "headerText": {
                "text": "Fee Type"
              },
              "fields": [
                {
                  "inputType": "radio",
                  "required": true,
                  "defaultValue": "",
                  "placeholder": "",
                  "visible": true,
                  "options": [
                    {
                      "value": "1",
                      "text": "Custom Fee"
                    },
                    {
                      "value": "2",
                      "text": "Surcharge"
                    },
                    {
                      "value": "3",
                      "text": "DTI"
                    }
                  ],
                  "posParam": "F_TYP",
                  "editable": true,
                  "width": "12"
                }
              ],
              "showHeader": true,
              "panelWidth": "3",
              "showOnly": "{{FEE}}=='1'"
            },
            {
              "headerText": {
                "text": "Custom Fee"
              },
              "fields": [
                {
                  "label": "<hide>",
                  "inputType": "group",
                  "groupElements": [
                    {
                      "label": "Apply",
                      "inputType": "null"
                    },

                    {
                      "inputType": "select",
                      "required": true,
                      "visible": true,
                      "options": [
                        {
                          "value": "1",
                          "text": "%"
                        },
                        {
                          "value": "2",
                          "text": "$"
                        }
                      ],
                      "posParam": "CF_PER",
                      "editable": true,
                      "width": "4",
                      "selectType": "single",
                      "defaultValue": "1"
                    },

                    {
                      "label": "<hide>",
                      "inputType": "number",
                      "validations": [
                        {
                          "exp": "^0*(?:[1-9][0-9]?|100)$",
                          "errorMsg": "Invalid Input!",
                          "name": "checkMinMax",
                          "showOnly": "{{CF_PER}}=='1'"
                        }
                      ],
                      "required": true,
                      "defaultValue": "100",
                      "visible": true,
                      "posParam": "CF_AMT",
                      "editable": true,
                      "width": "4"
                    },
                    {
                      "label": "Capped at",
                      "inputType": "null"
                    },
                    {
                      "label": "<hide>",
                      "inputType": "number",
                      "inputSuffix": "%",
                      "required": true,
                      "validations": [
                        {
                          "exp": "^0*(?:[1-9][0-9]?|100)$",
                          "errorMsg": "Invalid Input!",
                          "name": "checkMinMax"

                        }
                      ],
                      "visible": true,
                      "posParam": "CF_TX_AMT",
                      "editable": true,
                      "width": "4"
                    },
                    {
                      "label": "of Tx Amount",
                      "inputType": "null"
                    }
                  ]
                },

                {
                  "label": "Display Label",
                  "inputType": "text",
                  "required": true,
                  "placeholder": "Please Enter Display Name",
                  "visible": true,
                  "posParam": "CF_DL",
                  "editable": true,
                  "width": "8",
                  "displayMode": "raw"
                },
                {
                  "label": "Disclose Fee to Customer",
                  "inputType": "switch",
                  "required": true,
                  "defaultValue": "0",
                  "visible": true,
                  "options": [
                    {
                      "value": "1",
                      "text": "Yes"
                    },
                    {
                      "value": "0",
                      "text": "No"
                    }
                  ],
                  "posParam": "CF_DFC",
                  "editable": true,
                  "width": "6"
                },
                {
                  "label": "Apply Fee On Tip",
                  "inputType": "switch",
                  "required": true,
                  "defaultValue": "0",
                  "visible": true,
                  "options": [
                    {
                      "value": "1",
                      "text": "Yes"
                    },
                    {
                      "value": "0",
                      "text": "No"
                    }
                  ],
                  "posParam": "CF_AFT",
                  "editable": true,
                  "width": "6",
                  "displayMode": "raw"
                },
                {
                  "label": "Merchant Can Remove Fee",
                  "inputType": "switch",
                  "required": true,
                  "defaultValue": "0",
                  "visible": true,
                  "options": [
                    {
                      "value": "1",
                      "text": "Yes"
                    },
                    {
                      "value": "0",
                      "text": "No"
                    }
                  ],
                  "posParam": "CF_MCR",
                  "editable": true,
                  "width": "6"
                },
                {
                  "label": "Apply Fee On Debit Card",
                  "inputType": "switch",
                  "required": true,
                  "defaultValue": "0",
                  "visible": true,
                  "options": [
                    {
                      "value": "1",
                      "text": "Yes"
                    },
                    {
                      "value": "0",
                      "text": "No"
                    }
                  ],
                  "posParam": "CF_AFD",
                  "editable": true,
                  "width": "6"
                },
                {
                  "label": "Password Protect Remove Option",
                  "inputType": "switch",
                  "required": true,
                  "defaultValue": "0",
                  "visible": true,
                  "options": [
                    {
                      "value": "1",
                      "text": "Yes"
                    },
                    {
                      "value": "0",
                      "text": "No"
                    }
                  ],
                  "posParam": "CF_PPR",
                  "editable": true,
                  "width": "6"
                }
              ],
              "showHeader": true,
              "panelWidth": "9",
              "showOnly": "{{F_TYP}}=='1'"
            },
            {
              "headerText": {
                "text": "Surcharge"
              },
              "fields": [
                {
                  "label": "<hide>",
                  "inputType": "group",
                  "groupElements": [
                    {
                      "label": "Apply",
                      "inputType": "null"
                    },

                    {
                      "inputType": "select",
                      "required": true,
                      "visible": true,
                      "options": [
                        {
                          "value": "1",
                          "text": "%"
                        },
                        {
                          "value": "2",
                          "text": "$"
                        }
                      ],
                      "posParam": "SR_PER_C",
                      "editable": true,
                      "width": "4",
                      "selectType": "single",
                      "defaultValue": "1"
                    },

                    {
                      "label": "<hide>",
                      "inputType": "number",

                      "inputSuffix": "",
                      "selectType": "",
                      "required": true,
                      "validations": [
                        {
                          "exp": "^0*(?:[1-9][0-9]?|100)$",
                          "errorMsg": "Invalid Input!",
                          "name": "checkMinMax",
                          "showOnly": "{{SR_PER_C}}=='1'"
                        }
                      ],
                      "defaultValue": "100",
                      "placeholder": "",
                      "visible": true,
                      "posParam": "SR_AMT_C",
                      "editable": true,
                      "tooltip": "",
                      "width": "4",
                      "displayMode": "inline"
                    },
                    {
                      "label": "Whichever is higher",
                      "inputType": "null"
                    },
                    {
                      "label": "on Credit Card Tx Amount",
                      "inputType": "null"
                    }
                  ]
                },
                {
                  "label": "Display Label for Credit Card",
                  "inputType": "text",
                  "required": true,
                  "defaultValue": "",
                  "placeholder": "Please Enter Display Name",
                  "visible": true,
                  "posParam": "SR_DL_C",
                  "editable": true,
                  "width": "8",
                  "displayMode": "raw"
                },
                {
                  "label": "Disclose Fee to Customer",
                  "inputType": "switch",
                  "required": true,
                  "defaultValue": "0",
                  "visible": true,
                  "options": [
                    {
                      "value": "1",
                      "text": "Yes"
                    },
                    {
                      "value": "0",
                      "text": "No"
                    }
                  ],
                  "posParam": "SR_DF_CUS",
                  "editable": true,
                  "width": "6"
                },
                {
                  "label": "Apply Surcharge On Tip",
                  "inputType": "switch",
                  "required": true,
                  "defaultValue": "0",
                  "visible": true,
                  "options": [
                    {
                      "value": "1",
                      "text": "Yes"
                    },
                    {
                      "value": "0",
                      "text": "No"
                    }
                  ],
                  "posParam": "SR_AS_T",
                  "editable": true,
                  "width": "6"
                },
                {
                  "label": "Merchant Can Remove Fee",
                  "inputType": "switch",
                  "required": true,
                  "defaultValue": "0",
                  "placeholder": "Please Enter Suggestion 1",
                  "visible": true,
                  "options": [
                    {
                      "value": "1",
                      "text": "Yes"
                    },
                    {
                      "value": "0",
                      "text": "No"
                    }
                  ],
                  "posParam": "SR_MCR",
                  "editable": true,
                  "width": "6"
                },
                {
                  "label": "Password Protect Remove Option",
                  "inputType": "switch",
                  "required": true,
                  "defaultValue": "0",
                  "visible": true,
                  "options": [
                    {
                      "value": "1",
                      "text": "Yes"
                    },
                    {
                      "value": "0",
                      "text": "No"
                    }
                  ],
                  "posParam": "SR_PPR",
                  "editable": true,
                  "width": "6"
                },
                {
                  "label": "<hide>",
                  "inputType": "group",
                  "groupElements": [
                    {
                      "label": "Apply",
                      "inputType": "null"
                    },

                    {
                      "inputType": "select",
                      "required": true,
                      "visible": true,
                      "options": [
                        {
                          "value": "1",
                          "text": "%"
                        },
                        {
                          "value": "2",
                          "text": "$"
                        }
                      ],
                      "posParam": "SR_PER_D",
                      "editable": true,
                      "width": "4",
                      "selectType": "single",
                      "defaultValue": "1"
                    },

                    {
                      "label": "<hide>",
                      "inputType": "number",

                      "inputSuffix": "",
                      "selectType": "",
                      "required": true,
                      "validations": [
                        {
                          "exp": "^0*(?:[1-9][0-9]?|100)$",
                          "errorMsg": "Invalid Input!",
                          "name": "checkMinMax",
                          "showOnly": "{{SR_PER_D}}=='1'"
                        }
                      ],
                      "defaultValue": "100",
                      "placeholder": "",
                      "visible": true,
                      "posParam": "SR_AMT_D",
                      "editable": true,
                      "tooltip": "",
                      "width": "4",
                      "displayMode": "inline"
                    },
                    {
                      "label": "Whichever is higher",
                      "inputType": "null"
                    },
                    {
                      "label": "on Debit Card Tx Amount",
                      "inputType": "null"
                    }
                  ]
                },
                {
                  "label": "Display Label for Debit Card",
                  "inputType": "text",
                  "required": true,
                  "placeholder": "Please Enter Display Name",
                  "visible": true,
                  "posParam": "SR_DL_D",
                  "editable": true,
                  "width": "8"
                }
              ],
              "showHeader": true,
              "panelWidth": "9",
              "showOnly": "{{F_TYP}}=='2'"
            },
            {
              "headerText": {
                "text": "DTI"
              },
              "fields": [
                {
                  "label": "<hide>",
                  "inputType": "group",
                  "groupElements": [
                    {
                      "label": "Apply",
                      "inputType": "null"
                    },
                    {
                      "inputType": "select",
                      "required": true,
                      "visible": true,
                      "options": [
                        {
                          "value": "1",
                          "text": "%"
                        },
                        {
                          "value": "2",
                          "text": "$"
                        }
                      ],
                      "posParam": "D_PER_CR",
                      "editable": true,
                      "width": "4",
                      "selectType": "single",
                      "defaultValue": "1"
                    },
                    {
                      "label": "<hide>",
                      "inputType": "number",
                      "inputPrefix": "",
                      "inputSuffix": "",
                      "selectType": "",
                      "required": true,
                      "validations": [
                        {
                          "exp": "^0*(?:[1-9][0-9]?|100)$",
                          "errorMsg": "Invalid Input!",
                          "name": "checkMinMax",
                          "showOnly": "{{D_PER_CR}}=='1'"
                        }
                      ],
                      "defaultValue": "100",
                      "placeholder": "",
                      "visible": true,
                      "posParam": "D_AMT_CR",
                      "editable": true,
                      "tooltip": "",
                      "width": "4",
                      "displayMode": "inline"
                    },
                    {
                      "label": "for Credit and Debit Card",
                      "inputType": "null"
                    }
                  ]
                },
                {
                  "label": "Display Label for FEE",
                  "inputType": "text",
                  "required": true,
                  "defaultValue": "SVC",
                  "placeholder": "Please Enter Display Name",
                  "visible": true,
                  "posParam": "D_DL_F",
                  "editable": false,
                  "width": "8",
                  "displayMode": "raw"
                },
                {
                  "label": "Display Label for Discount",
                  "inputType": "text",
                  "required": true,
                  "defaultValue": "DISC",
                  "placeholder": "Please Enter Display Name",
                  "visible": true,
                  "posParam": "D_DL_D",
                  "editable": false,
                  "width": "8",
                  "displayMode": "raw"
                },
                {
                  "label": "Apply SVC on Debit card",
                  "inputType": "switch",
                  "required": true,
                  "defaultValue": "0",
                  "visible": true,
                  "posParam": "SVC_DBT",
                  "editable": true,
                  "width": "5",
                  "options": [
                    {
                      "value": "1",
                      "text": "Yes"
                    },
                    {
                      "value": "0",
                      "text": "No"
                    }
                  ]
                },
                {
                  "label": "Apply Fee On Tip",
                  "inputType": "switch",
                  "required": true,
                  "defaultValue": "0",
                  "visible": true,
                  "options": [
                    {
                      "value": "1",
                      "text": "Yes"
                    },
                    {
                      "value": "0",
                      "text": "No"
                    }
                  ],
                  "posParam": "D_AFT",
                  "editable": true,
                  "width": "5",
                  "displayMode": "raw"
                },
                {
                  "label": "<hide>",
                  "inputType": "group",
                  "groupElements": [
                    {
                      "label": "Apply Discount",
                      "inputType": "null"
                    },
                    {
                      "inputType": "select",
                      "required": true,
                      "visible": true,
                      "posParam": "D_PER_DDA",
                      "editable": true,
                      "width": "4",
                      "selectType": "single",
                      "defaultValue": "1",
                      "options": [
                        {
                          "value": "1",
                          "text": "%"
                        },
                        {
                          "value": "2",
                          "text": "$"
                        }
                      ]
                    },
                    {
                      "label": "<hide>",
                      "inputType": "number",
                      "inputPrefix": "",
                      "inputSuffix": "",
                      "selectType": "",
                      "required": true,
                      "validations": [
                        {
                          "exp": "^0*(?:[1-9][0-9]?|100)$",
                          "errorMsg": "Invalid Input!",
                          "name": "checkMinMax",
                          "showOnly": "{{D_PER_DDA}}=='1'"
                        }
                      ],
                      "defaultValue": "100",
                      "placeholder": "",
                      "visible": true,
                      "posParam": "D_AMT_DDA",
                      "editable": true,
                      "tooltip": "",
                      "width": "4",
                      "displayMode": "inline"
                    },
                    {
                      "label": "for Cash EBT",
                      "inputType": "null"
                    }
                  ]
                }
              ],
              "showHeader": true,
              "panelWidth": "9",
              "showOnly": "{{F_TYP}}=='3'"
            }
          ]
        }
      ]
    }

  }

  getTemplateJSON(id){
    // return this.httpClient.post(apiLinks.templateJsonUrl, data).pipe(
    //   map((res: any) => {
    //     return res;
    //   }),
    //   catchError(this.handleError.handleError)
    // );

    return this.httpClient.get(`${apiLinks.templateJsonUrl}/${id}`).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.handleError.handleError)
    );

  }

  getValueJSON(id){
    return this.httpClient.get(`${apiLinks.valueJsonUrl}/${id}`).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.handleError.handleError)
    )
  }

  saveTemplateJSON(data){
    return this.httpClient.post(apiLinks.valueJsonUrl, data).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.handleError.handleError)
    )
  }

  saveAsTemplate(data){
    return this.httpClient.post(apiLinks.saveAsTemplate, data).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.handleError.handleError)
    )
  }

  getLastFourBuild(data){
    return this.httpClient.post(apiLinks.latestBuilds, data).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.handleError.handleError)
    )
  }

  assignBuild(data){

    return this.httpClient.post(apiLinks.checkSum, data).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.handleError.handleError)
    )
  }

  getTemplateNames(data){
    return this.httpClient.post(apiLinks.templateNames, data).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.handleError.handleError)
    )
  }

  applyPosTemplate(id,data){
    return this.httpClient.post(`${apiLinks.applyTemplate}/${id}`, data).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.handleError.handleError)
    )
  }

  deleteTemplate(id){
    return this.httpClient.delete(`${apiLinks.deleteTemplateParam}/${id}`).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.handleError.handleError)
    )
  }

  getPinPadDeviceModels(model?){
    // return this.httpClient.get(`${apiLinks.pinpanTpnDevices}`).pipe(
    //   map((res: any) => {
    //     return res;
    //   }),
    //   catchError(this.handleError.handleError)
    // )

    let allModels=[
      {
        "identifier": "QD2",
        "manufacturer_from_device_model": {
          "name": "WizardPOS"
        }
      },
      {
        "identifier": "QD3",
        "manufacturer_from_device_model": {
          "name": "WizardPOS"
        }
      },
      {
        "identifier": "QD4",
        "manufacturer_from_device_model": {
          "name": "WizardPOS"
        }
      },
      {
        "identifier": "QD5",
        "manufacturer_from_device_model": {
          "name": "WizardPOS"
        }
      },
      {
        "identifier": "P1",
        "manufacturer_from_device_model": {
          "name": "Kozen"
        }
      },
      {
        "identifier": "P3",
        "manufacturer_from_device_model": {
          "name": "Kozen"
        }
      },
      {
        "identifier": "P5",
        "manufacturer_from_device_model": {
          "name": "Kozen"
        }
      }
    ];

    return model?_.find(allModels, ['identifier',model]):allModels;
  }

  savePinpadTpns(data){
    return this.httpClient.post(`${apiLinks.pinpadTpns}`,data).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.handleError.handleError)
    )
  }

  updatePinpadTpns(id,data){
    return this.httpClient.put(`${apiLinks.pinpadTpns}/${id}`,data).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.handleError.handleError)
    )
  }

  deletePinpadTpns(id){
    return this.httpClient.delete(`${apiLinks.pinpadTpns}/${id}`).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.handleError.handleError)
    )
  }

  getTpnById(id){
    return this.httpClient.get(`${apiLinks.deviceUrl}/${id}`).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.handleError.handleError)
    )
  }

  getPinpadTpns(id){
    return this.httpClient.get(`${apiLinks.pinpadTpns}/${id}`).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.handleError.handleError)
    )
  }

}
