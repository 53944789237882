import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "../../providers/auth.service";
import { Users } from "../model/user";
import { isEmpty } from 'lodash';

@Injectable({
  providedIn: "root",
})
export class ModuleGuard implements CanActivate {
  constructor(private authService: AuthService) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const currentUser: Users = this.authService.getUser;
    if (this.isUserFeatureExist(currentUser.roleFeatures, route.data.module, route.data.privilege)) {
      console.log("you are allowed user!");
      return true;
    }

    return false;
  }

  isUserFeatureExist(moduledata, moduleName, privilege) {
    let features = this.authService.getPrivileges(moduleName).features;
    let isModuleExists = moduledata.some((item) => {
      return item.module === moduleName;
    });
    if(isModuleExists) {
      if(typeof privilege == 'string'){
        return features.indexOf(privilege) > -1? true : false;
      }else{ 
        return !isEmpty(features.filter(element => privilege.includes(element))) 
      }
    }
    return isModuleExists;
  }
}
