<div class="card card-list-bg" *ngIf="isLoading">
    <div class="card-header list-header d-flex justify-content-between">
        <h5 class="loading"><span class="mdi mdi-download" aria-hidden></span>Processing your request</h5>
    </div>
    <div class="card-body">
        <div class="text-center m-5 p-5">
            <div>
                <img src="assets/gif/loader.gif" alt="IPOSPAYS LOADER" width="120" />
                <h5 class="mt-2">{{body}}......</h5>
                <ng-container *ngIf="timerData?.enableTimerContent">
                    <p class="mt-2 alert alert-warning" id="timerData">Downloading <b>{{timerData?.totalNoOfRows?.toLocaleString()}}</b> records,</p>
                </ng-container>
            </div>
        </div>
    </div>
</div>