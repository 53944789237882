import { Component, OnInit, Inject, ViewChild, ElementRef, NgZone } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { MessageService } from "primeng/api";
import { AuthService } from "./providers/auth.service";
import { ConnectionService } from 'ng-connection-service';
import { Subject } from 'rxjs';
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { environment } from "src/environments/environment";
const MINUTES_UNITL_AUTO_LOGOUT = environment.autoLogoutTime ;
const CHECK_INTERVALL = environment.checkInterval; 
const STORE_KEY = 'lastAction';

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  providers: [MessageService],
})
export class AppComponent implements OnInit {
  title = "nobleui-angular";
  isConnected = true;  
  noInternetConnection: boolean;
  timeoutId;
  userInactive: Subject<any> = new Subject();
  automaticLogOut: Subject<any> = new Subject();
  logOutFlag = false;
  idleState = 'Not started.';
  timedOut = false;
  logoutTimer;
  @ViewChild('logOutModal') logOutModal: ElementRef;
  modalReference: NgbModalRef;
  count = 30;  
  isPopupOpen = false ;
  reloadFlag = false;
  currentAppVersion = null;
  constructor(@Inject(DOCUMENT) private document: Document, private authService:AuthService ,private connectionService: ConnectionService, 
  private messageService: MessageService, private ngbModal:NgbModal, private ngZone: NgZone){

    this.check();
    this.initListener();
    this.initInterval();      
  }

  ngOnInit(): void {
    this.setDefaultTheme();
    this.checkConnection();
    window.onbeforeunload = () => {
      this.reloadFlag = true;
      this.reset();
      this.reloadFlag = false;
    }; 
    if(!environment.inspect){
      this.preventInspect();      
    }
  }

  showToaster(type, define, message, myToast) {
    console.log("show toast called");
    this.messageService.add({
      severity: type,
      summary: define,
      key : myToast,
      detail: message,
    });
  }

  preventInspect(){
    document.addEventListener('contextmenu', function(e) {
      e.preventDefault();
    });

    document.onkeydown = function(e) {
      if(e.ctrlKey && e.shiftKey && e.keyCode == 'I'.charCodeAt(0)) {
         return false;
      }
      if(e.ctrlKey && e.shiftKey && e.keyCode == 'C'.charCodeAt(0)) {
         return false;
      }
      if(e.ctrlKey && e.shiftKey && e.keyCode == 'J'.charCodeAt(0)) {
         return false;
      }
      if(e.ctrlKey && e.keyCode == 'U'.charCodeAt(0)) {
         return false;
      }
    }
  }

  setDefaultTheme() {

    let user=this.authService.getUser;
    if(user && ((user.themeColor == null)? "theme-cyan-light" : user.themeColor)){
      this.document.body.classList.remove(
        "theme-cyan-light",
        "theme-bluegrey-light"
      );
      this.document.body.classList.add(user.themeColor);
    }
    
  }
  checkConnection(){
    this.connectionService.monitor().subscribe(isConnected => {  
      this.isConnected = isConnected;  
      if (this.isConnected) {  
        this.noInternetConnection=false;  
        // console.log("Connection there");
        this.showToaster('success', 'Back Online', '', 'myToast');
      }  
      else {
        this.noInternetConnection=true; 
        // console.log("connection not present"); 
        this.showToaster('warn', 'warning', 'No Connection', 'myToast');
      }  
    })
  }

  continueSession() {
    console.log("continue session");
    this.lastAction = Date.now();
    this.modalReference.close();
    clearTimeout(this.logoutTimer);
    console.log(this.logoutTimer);
    this.isPopupOpen = false;
  }

  get lastAction() {
    return parseInt(localStorage.getItem(STORE_KEY));
  }
  set lastAction(value : number) {
    localStorage.setItem(STORE_KEY, value.toString());
  }

  reset() {
    if(this.isPopupOpen == false || (this.isPopupOpen == true && this.reloadFlag == true)){
      this.lastAction = Date.now();
    }
  }

  initListener() {
    this.ngZone.runOutsideAngular(() => {
      document.body.addEventListener('click', () => this.reset());
      document.body.addEventListener('mouseover',()=> this.reset());
      document.body.addEventListener('mouseout',() => this.reset());
      document.body.addEventListener('keydown',() => this.reset());
      document.body.addEventListener('keyup',() => this.reset());
      document.body.addEventListener('keypress',() => this.reset());
    });
  }

  initInterval() {
    this.ngZone.runOutsideAngular(() => {
      setInterval(() => {
        this.check();
      }, CHECK_INTERVALL);
    })
  }

  startCountdown(){
      var timeOut = setInterval(() => {
        if (this.count >= 0) {
          this.count -= 1;
        } else {
          clearInterval(timeOut);
        }
    }, 1000);
    this.count = 30;
  }

  check() {    
    const now = Date.now();
    const timeleftForWarning = this.lastAction + MINUTES_UNITL_AUTO_LOGOUT * 60 * 1000 - 30000;
    const diff = timeleftForWarning - now;
    const isTimeout = diff < 0;
    const timeleftForLogout = this.lastAction + MINUTES_UNITL_AUTO_LOGOUT * 60 * 1000 ; 
    const differenceForLogout = timeleftForLogout - now;
    const isTimeoutForLogout = differenceForLogout < 0;

    this.ngZone.run(() => {
      // console.log("this.currentAppVersion");
      // console.log(this.currentAppVersion);
      // if((isNull(localStorage.getItem("webVersion")) || this.currentAppVersion) && this.authService.getUser) {
      //   this.currentAppVersion = localStorage.getItem("webVersion");
      // } else if(!isNull(localStorage.getItem("webVersion")) && this.currentAppVersion != localStorage.getItem("webVersion")) {
      //   alert("Found new build release, kindly reload to proceed");
      //   this.currentAppVersion = localStorage.getItem("webVersion");
      //   window.location.reload();
      // }

      if (isTimeout && this.authService.getUser) {
        if(this.isPopupOpen == false){
          this.modalReference = this.ngbModal.open(this.logOutModal, { ariaLabelledBy: 'modal-basic-title', centered: true, backdrop: 'static', keyboard: false });
          this.startCountdown();
          this.isPopupOpen = true;
        }
        console.log(`User is inactive for ${MINUTES_UNITL_AUTO_LOGOUT - 30000} and will be logged out in 30 seconds.`);
      }
      if(isTimeoutForLogout && this.authService.getUser){
        this.authService.logout();
        this.modalReference.close();
        this.ngbModal.dismissAll();
        this.isPopupOpen = false;
      }

    });
  }

}
