import {
  ValidationErrors,
  AbstractControl,
  ValidatorFn,
  FormGroup,
  FormArray,
} from "@angular/forms";

export class CustomValidators {
  static PatternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        // if control is empty return no error
        return null;
      }
      const valid = regex.test(control.value);
      return valid ? null : error;
    };
  }

  static PassswordMatch(control: AbstractControl) {
    const password: string = control.get("password").value;
    const confirmPassword: string = control.get("cpassword").value;
    if (password !== confirmPassword) {
      control.get("cpassword").setErrors({ NoPassswordMatch: true });
    }
  }

  

  static minSelectedCheckboxes(): ValidatorFn {
    const validator: ValidatorFn = (formArray: FormArray) => {
      const selectedCount = formArray.controls
        .map((control) => control.value)
        .reduce((prev, next) => {
          return next ? prev + 1 : prev;
        }, 0);
      return selectedCount >= 1 ? null : { notSelected: true };
    };
    return validator;
  }
  static atLeastOneInputHasValue = () => {
    return (group: FormGroup) => {
      if (!Object.values(group.value).find(value => Boolean(value))) {
        return {
          atLeastOneRequired: {
            text: 'At least one control is required',
          }
        }
      }
      return null
    }
  }
}
