import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { AuthService } from "./auth.service";
import {ErrorHandlerService} from './error-handler.service';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class DownloadlogService {

  getMoreDownloadLogs = `${environment.steamApiUrl}/getMoreDownloadLogs/`;
  downloadLogDetails = `${environment.steamApiUrl}/getDownloadLogDetails/`;

  constructor(
    private handleError:ErrorHandlerService,
    private httpClient: HttpClient
  ) { }

  getAllDownloadLog(params) {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('sort', '{"field": "updatedAt", "order":"DESC"}')
    httpParams = httpParams.append('size', '20');
    Object.keys(params).forEach(function(key) {
      httpParams = httpParams.append(key, params[key]);
    })
    return this.httpClient.get(`${environment.steamApiUrl}/getAllDownloadLogs` , {params: httpParams}).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.handleError.handleError)
    );
  }

  getMoreDownloadLog(httpReqUrl, parameter, params) {
    let httpParams = new HttpParams();
    httpReqUrl = httpReqUrl+parameter.manufacturerId+"/"+parameter.deviceModelId+"/"+parameter.tpn;
    Object.keys(params).forEach(function(key) {
      httpParams = httpParams.append(key, params[key]);
    })
    return this.httpClient.get(httpReqUrl,{params:httpParams}).pipe(
      map((res: any) => {
        return res.data;
      }),
      catchError(this.handleError.handleError)
    );
  }

}
