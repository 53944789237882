import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { isEmpty, isUndefined } from 'lodash';
import { CustomValidators } from "../../../../core/custom-validator/custom-validators";
import { MessageService } from 'primeng/api';
import { CommonService } from "src/app/providers/common.service";
import { apiLinks } from '../../../../core/api-links/api-links';
import Swal from 'sweetalert2';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
export interface Fruit {
  name: string;
}
@Component({
  selector: 'app-add-processor',
  templateUrl: './add-processor.component.html',
  styleUrls: ['./add-processor.component.scss']
})
export class AddProcessorComponent implements OnInit {


  processorForm: FormGroup;
  searchProcessorList: any;
  isSpinnerTid:boolean;
  isSpinnerOwner:boolean;
  errMsgs: string;
  errMsgsOwner: string;
  isSelectedPro:boolean = false;
  processorLoading = false;
  disableMessage = true;
  isSaving:boolean = false;
  isEditHostInventory: boolean;
  editHostId: string;
  blockCode : string[];
  stateOptions: any=[{
    label: "Enable",
    value: "ENABLE"
  },
  {
    label: "Disable",
    value: "DISABLE"
  }

];
  isSpinnerNameCheck: boolean;

  constructor(
    private formBuilder: FormBuilder,
    // private merchantService: MerchantService,
    // private HostInventoryService: HostInventoryService,
    private messageService: MessageService,
    public commonService: CommonService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.processorForm = this.processorFormBuild();
    this.loadProcessor();
    this.editHostInventorySubscribe();
  }

  editHostInventorySubscribe(){
    this.route.queryParams.subscribe(async (param) => {
      if (!isUndefined(param.editProcessor) && param.editProcessor) {
        this.processorForm.patchValue({
          name: param.name,
          frequency: param.frequency,
          max: param.max,
          status: param.status,
          blockingCode: param.blockingCode,
          ownerId:param.OwnerId
        });
        this.blockCode = param.blockingCode;
        this.isEditHostInventory = true;
        this.editHostId = param.id;
        // this.hostInventoryForm.get(['key1']).setValidators([]);
        // this.hostInventoryForm.get(['key2']).setValidators([]);
      }
    });
  }
  
  processorFormBuild(): FormGroup {
    const alphaRegex = /^[A-Z0-9]*$/;
    const lengthNameRegex = /^.{3,8}$/;
    const nonZeroRegex = /^[1-9][0-9]*$/;
    const ownerRegex = /^[A-Z0-9]*$/;
    const ownerLengthRegex = /^.{8,8}$/;
    const lengthKey1Regex = /^.{32,32}$/;
    const lengthKey2Regex = /^.{32,32}$/;
    const numberRegex = /^[0-9]*$/;
    const lengthNearDemonRegex = /^.{1,8}$/;
    const lengthCashBox1Regex = /^.{1,12}$/;
    const lengthCashBox2Regex = /^.{1,12}$/;
    const lengthCashBox1DemonRegex = /^.{1,12}$/;
    const lengthCashBox2DemonRegex =  /^.{1,12}$/;
    const lengthNotesDispensed1Regex = /^.{1,12}$/;
    const lengthNotesDispensed2Regex = /^.{1,12}$/;

    return this.formBuilder.group({
      name: ["", [Validators.required,
                CustomValidators.PatternValidator(alphaRegex, { hasPattern: true }),
                CustomValidators.PatternValidator(lengthNameRegex, { hasLengthName: true })]],
      frequency: ["", [Validators.required,
                CustomValidators.PatternValidator(nonZeroRegex, { hasNonZero: true }),]],
      max: ["", Validators.required],
      status: ["",[Validators.required]],
      blockingCode:[[]],
      ownerId: ["",
                  [CustomValidators.PatternValidator(ownerRegex, { hasCorrectOwnerId: true }),
                    CustomValidators.PatternValidator(ownerLengthRegex, { hasOwnerLength: true })]]
      // key2: ["", [Validators.required,
      //   CustomValidators.PatternValidator(alphaRegex, { hasAlphaRegex: true }),
      //   CustomValidators.PatternValidator(lengthKey2Regex, { haskey2Regex: true })]],
      // nearestDenomination: ["", [Validators.required,
      // CustomValidators.PatternValidator(numberRegex, { hasNumber: true }),
      // CustomValidators.PatternValidator(lengthNearDemonRegex, { haslengthNearDemonRegex: true })]],
      // cashBox1Total: ["", [Validators.required,
      // CustomValidators.PatternValidator(numberRegex, { hasNumber: true }),
      // CustomValidators.PatternValidator(lengthCashBox1Regex, { haslengthCashBox1Regex: true })]],
      // cashBox2Total: ["", [Validators.required,
      // CustomValidators.PatternValidator(numberRegex, { hasNumber: true }),
      // CustomValidators.PatternValidator(lengthCashBox2Regex, { haslengthCashBox2Regex: true })]],
      // cashBox1Denomination: ["", [Validators.required,
      // CustomValidators.PatternValidator(numberRegex, { hasNumber: true }),
      // CustomValidators.PatternValidator(lengthCashBox1DemonRegex, { haslengthCashBox1DemonRegex: true })]],
      // cashBox2Denomination: ["", [Validators.required,
      // CustomValidators.PatternValidator(numberRegex, { hasNumber: true }),
      // CustomValidators.PatternValidator(lengthCashBox2DemonRegex, { haslengthCashBox2DemonRegex: true })]],
      // NotesDispensed1: ["", [Validators.required,
      // CustomValidators.PatternValidator(numberRegex, { hasNumber: true }),
      // CustomValidators.PatternValidator(lengthNotesDispensed1Regex, { haslengthNotesDispensed1Regex: true })]],
      // NotesDispensed2: ["", [Validators.required,
      // CustomValidators.PatternValidator(numberRegex, { hasNumber: true }),
      // CustomValidators.PatternValidator(lengthNotesDispensed2Regex, { haslengthNotesDispensed2Regex: true })]],
    })
  }

    // Processor List
    loadProcessor() {
      // this.processorLoading = true;
      // this.merchantService.getAllData(this.merchantService.getProcessorUrl).subscribe(data => {
      //   this.searchProcessorList = data;
      //   console.log("this.searchProcessorList", this.searchProcessorList);
      //   this.processorLoading = false;
      // });
    }

  nameCheck(event: any) {
    if (this.processorForm.get(['name']).valid) {
      this.isSpinnerNameCheck = true;
      let params = {
        name: this.processorForm.get(['name']).value
      }
      this.commonService.getCommonHttpClientParams(apiLinks.checkProcessor + params.name).subscribe(data => {
        if (isEmpty(data)) {
          this.errMsgs = '';
          this.isSpinnerNameCheck = false;
        } else {
          this.errMsgs = "No";
          this.isSpinnerNameCheck = false;
        }
      });
    }
  }

    onkeypressNear(target){
      if(target.value.length == 8) return false;
    }

    onkeypresscashBox1(target){
      if(target.value.length == 12) return false;
    }

    onkeypresscashBox1Total(target){
      if(target.value.length == 12) return false;
    }

    onkeypresscashBox2De(target){
      if(target.value.length == 12) return false;
    }

    onkeypresscashBox2To(target){
      if(target.value.length == 12) return false;
    }

    getPredefinedFields(){
      return {
        // encMasterKey: this.hostInventoryForm.get('key1').value + this.hostInventoryForm.get('key2').value,
        isSwitchhostInventory: true
      }
    }

    removeProperty(data) {
      // ['key1', 'key2'].forEach(e => delete data[e]);
      return data;
    }

    onFormSubmit() {
        const formData = {
          ...this.processorForm.value,
        };
        let value = this.removeProperty(formData);
        if(!this.isEditHostInventory)
          this.createHostInventory(formData);
        else
          this.editHostInventory({...formData,id:this.editHostId}) 
    }

    editHostInventory(formData){
      this.isSaving = true;
      this.commonService.postCommonHttpClient(apiLinks.editProcessor,formData).subscribe(
        (res) => {
          let successMsg = {
            message: res.message,
            status: "success",
          };
          this.isSaving = false;
          this.showAlert(successMsg, "success");
          this.processorForm.reset();
          this.router.navigate(['/processor-list/']);
        },
        (err) => {
          this.showAlert(err, "error");
          this.isSaving = false;
        }
      );
    }
    createHostInventory(formData) {
      this.isSaving = true;
      this.commonService.postCommonHttpClient(apiLinks.addProcessor,formData).subscribe(
        (res) => {
          let successMsg = {
            message: res.message,
            status: "success",
          };
          this.isSaving = false;
          this.showAlert(successMsg, "success");
          this.processorForm.reset();
          this.router.navigate(['/processor-list/']);
        },
        (err) => {
          this.showAlert(err, "error");
          this.isSaving = false;
        }
      );
    }

    tidFieldExistenceCheck(event) {
      // console.log(this.hostInventoryForm);
      // if(this.hostInventoryForm.get('tid').valid && this.hostInventoryForm.get('processorId').valid){
      //   this.isSpinnerTid = true;
      // let params = {
      //   tid: this.hostInventoryForm.get('tid').value,
      //   processorId: this.hostInventoryForm.get('processorId').value
      // }
      // console.log("tidFieldExistenceCheck", params);
      // this.HostInventoryService.getTid(this.HostInventoryService.getTidById, params).subscribe(data => {
      //   if (_.isEmpty(data)) {
      //     this.errMsgs = '';
      //     this.isSpinnerTid = false;
      //   }else {
      //     this.errMsgs = "No";
      //     this.existsWarningMsg("TID is already exists in this Processor.", 'tid');
      //     this.isSpinnerTid = false;
      //   }
      // })
      // }
    }

    ownerFieldExistenceCheck(event){
      // if(this.hostInventoryForm.get('ownerId').valid && this.hostInventoryForm.get('processorId').valid){
      //   this.isSpinnerOwner = true;
      // let params = {
      //   ownerId: this.hostInventoryForm.get('ownerId').value,
      //   processorId: this.hostInventoryForm.get('processorId').value
      // }
      // this.HostInventoryService.getOwner(this.HostInventoryService.getOwnerById, params).subscribe(data => {
      //   if (_.isEmpty(data)) {
      //     this.errMsgsOwner = '';
      //     this.isSpinnerOwner = false;
      //   }else {
      //     this.errMsgsOwner = "No";
      //     this.existsWarningMsg("Owner ID is already exists in this Processor.", 'ownerId');
      //     this.isSpinnerOwner = false;
      //   }
      // })
      // }
    }

    showAlert(data, alertType) {
      this.messageService.add({
        severity: alertType,
        summary: data.status,
        detail: data.message,
      });
    }

    existsWarningMsg(text, fromName) {
      Swal.fire({
        html: `<h5>${text}</h5>`,
        icon: "warning",
        showCancelButton: false,
        confirmButtonText: "Ok",
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          this.processorForm.get(fromName).reset();
        }else{
          this.processorForm.get(fromName).reset();
        }
      });
    }

    selectPro(event) {
      if(!isEmpty(event)){
        this.isSelectedPro = true;
        this.fromReset();
      }
    }

    fromReset(){
    let controlNames = ['tid', 'ownerId','encMasterKey','nearestDenomination','cashBox1Total','cashBox2Total','cashBox1Denomination',
    'cashBox2Denomination'];
    controlNames.map((value: string) => this.processorForm.get(value).reset());
    }

    resetProcessorForm(){
      this.processorForm.reset();
    }

    ngOnDestroy(){
      this.editHostId ='';
      this.isEditHostInventory = false;
    }

    onkeypress(target){
      if(target.value <= 0) return "";
    }


}
