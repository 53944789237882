import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse, HttpEventType, HttpHeaders, HttpParams } from "@angular/common/http";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { AuthService } from "./auth.service";
import { ErrorHandlerService } from "./error-handler.service";


@Injectable({
  providedIn: "root",
})
export class UserService {
  private loginUrl = `${environment.apiUrl}/user-login`;
  private forgotPasswordUrl = `${environment.apiUrl}/user-forgot-password`;
  private createUserUrl = `${environment.apiUrl}/users`;
  private resetPasswordUrl = `${environment.apiUrl}/user-verify`;
  private resendResetLinkUrl = `${environment.apiUrl}/user-reset-verify`;
  private getCurrentUserUrl = `${environment.apiUrl}/current-user-all`;
  private getAllUsers = `${environment.apiUrl}/users`;
  private updateUserByIdUrl = `${environment.apiUrl}/`;
  private deleteUserByIdUrl = `${environment.apiUrl}/`;
  private deleteAllUserForUserIdUrl = `${environment.apiUrl}/deleteUsers`;
  private getAllUserTypesUrl = `${environment.apiUrl}/user-types`;
  private getAllUserRolesUrl = `${environment.apiUrl}/user-type-roles`;
  private getAllUserEmailsUrl = `${environment.apiUrl}/user-emails`;
  public getThemeColorById = `${environment.apiUrl}/changetheme`;
  public userFavTheme: BehaviorSubject<any>;
  public getUserById = `${environment.apiUrl}/users/`;
  private updateUser = `${environment.apiUrl}/editUser`;
  public getAllUserTypeId = `${environment.apiUrl}/user-type-roles-byUserTypeId/`;

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
    private handleError: ErrorHandlerService
  ) {
    this.userFavTheme = new BehaviorSubject('theme-cyan-light');
  }

  login(data): Observable<any> {
    return this.httpClient.post(this.loginUrl, data).pipe(
      map((res: any) => {
        if (res.accessToken == "" || res.accessToken == null) {
         return null;
        }
        const UserRes = this.authService.setUser(res);
        return UserRes;
      }),
      catchError(this.handleError.handleError)
    );
  }

  forgotPassword(data) {
    return this.httpClient.post(this.forgotPasswordUrl, data).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.handleError.handleError)
    );
  }

  createUser(data) {
    return this.httpClient.post(this.createUserUrl, data).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.handleError.handleError)
    );
  }

  resetPassword(data) {
    return this.httpClient.post(this.resetPasswordUrl, data).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.handleError.handleError)
    );
  }

  resendResetLink(data) {
    return this.httpClient.post(this.resendResetLinkUrl, data).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.handleError.handleError)
    );
  }

  getCurrentUser() {
    return this.httpClient.get(this.getCurrentUserUrl).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.handleError.handleError)
    );
  }

  getAllUsersByUserId(isFilter) {

    let httpParams = new HttpParams();
    httpParams = httpParams.append("size","20");
    Object.keys(isFilter).forEach(function (key) {
      httpParams = httpParams.append(key, isFilter[key]);
    });
    return this.httpClient.get(`${this.getAllUsers}`, { params: httpParams }).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.handleError.handleError)
    );
  }

  getAllUserTypes() {
    return this.httpClient.get(this.getAllUserTypesUrl).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.handleError.handleError)
    );
  }

  getAllUserRoles() {
    return this.httpClient.get(this.getAllUserRolesUrl).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.handleError.handleError)
    );
  }

  getAllUserEmails() {
    return this.httpClient.get(this.getAllUserEmailsUrl).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.handleError.handleError)
    );
  }

  updateUserById(id, data) {
    return this.httpClient.put(`${this.updateUserByIdUrl}/${id}`, data).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.handleError.handleError)
    );
  }

  getTheme(httpReqUrl, parameter) {
    console.log(parameter);
    httpReqUrl = httpReqUrl+"/"+parameter.themeColor;
    return this.httpClient.get(httpReqUrl).pipe(
      map((res: any) => {
        return res.data;
      }),
      catchError(this.handleError.handleError)
    );
  }

  getUser(httpReqUrl, parameter) {
    console.log(parameter);
    httpReqUrl = httpReqUrl+"/"+parameter.id;
    return this.httpClient.get(httpReqUrl).pipe(
      map((res: any) => {
        return res.data;
      }),
      catchError(this.handleError.handleError)
    );
  }

  deleteUserById(data) {
    return this.httpClient.post(`${this.deleteAllUserForUserIdUrl}`, data).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.handleError.handleError)
    );
  }

  deleteAllUserForUserId() { }
  softDeleteSelectedUsers(data) {

    return this.httpClient.post(`${this.deleteAllUserForUserIdUrl}`, data).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.handleError.handleError)
    );
  }

  update(data) {
    return this.httpClient.post(this.updateUser, data).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.handleError.handleError)
    );
  }

  getAllUserRolesByUserType(httpReqUrl, parameter) {
    httpReqUrl = httpReqUrl + parameter.userTypeId;
     return this.httpClient.get(httpReqUrl).pipe(
       map((res: any) => {
         return res.data;
       }),
       catchError(this.handleError.handleError)
     );
 }

//   handleError(error) {
//     let errorMessage = {};
//     if (error.error instanceof ErrorEvent) {
//       errorMessage["message"] = error.error.message;
//     } else if (error.error.errors instanceof Array) {
//       errorMessage["status"] = "Error!";
//       errorMessage["message"] = error.error.errors[0].message;
//     } else if (error instanceof HttpErrorResponse) {
//       switch (error.status) {
//         case 504:      //login
//           errorMessage["status"] = error.status;
//           errorMessage["message"] = "Gateway timeout";
//           return throwError(errorMessage);
//         default:
//           errorMessage["status"] = error.status;
//           errorMessage["message"] = error.statusText;
//           return throwError(errorMessage);

//       }
//     } 
//     else {
//       errorMessage["status"] = error.status;
//       errorMessage["message"] = error.error.message
//         ? error.error.message
//         : error.message;
//     }
//     return throwError(errorMessage);
//   }
}
