<div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">{{'hostInventory.bulkStatusUpdate' | translate}}</h5>
    <h5>{{'hostInventory.countOfTid' | translate}} ({{totalTids}})</h5>
    <div class="d-flex">
        <button type="button" class="close" (click)="activeModal.close('by: close icon')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
</div>
<form action="" [formGroup]="bulkUpdateStatusForms" (submit)="forStatusUpdate();">
    <div class="modal-body">
        <div class="container">
            <div class="container" formGroupName="updateHostInventoryInfo">
                <div class="row ">
                    <div class="col-lg-12 col-sm-12 mx-auto">
                        <div class="mt-md-1 mb-3 pt-4">
                            <div class="form-row form-style col-md-12">
                                <div class="form-group form__div col-md-6 col-sm-11">
                                    <label for="Status">{{'htmlText.status' | translate}}</label>
                                    <ng-select class="disable-clear-all" [clearable]="false" [items]="statusList"
                                        bindLabel="name" bindValue="name" labelForId="Status" placeholder="Status"
                                        clearAllText="Clear" notFoundText="No Status found" formControlName="status">
                                    </ng-select>

                                    <!-- <ngb-alert [dismissible]="false" [type]="'danger'" class="text-right pt-3"
                                        *ngIf="bulkUpdateStatusForms.get(['updateHostInventoryInfo','status']).errors?.required">
                                        <i class="link-icon feather icon-alert-triangle">
                                        </i>
                                    </ngb-alert> -->

                                    <div class="error-message"
                                        *ngIf="(bulkUpdateStatusForms.get(['updateHostInventoryInfo','status']).touched)">
                                        <ngb-alert [dismissible]="false" [type]="'danger'" class="pt-md-5 alignPad"
                                            *ngIf="bulkUpdateStatusForms.get(['updateHostInventoryInfo','status']).invalid">
                                            <i class="link-icon feather icon-alert-triangle"></i>
                                            {{'errorMsg.invalidJobName' | translate}}
                                        </ngb-alert>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="col-md-12 d-flex justify-content-end">
            <!-- <button type="button" (click)="resetUploadForm()"
                class="btn col-md-2 col-sm-4 btn-danger btn-block shadow-sm button mt-md-2 mr-md-1">{{'hostInventory.Reset'
                | translate}}
            </button> -->
            <button class="btn btn-primary" type="submit" [disabled]="isSubmit || bulkUpdateStatusForms.get(['updateHostInventoryInfo','status']).invalid">
                <span *ngIf="isSaving"
                    class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>{{'hostInventory.saveForStatus' | translate}}
            </button>
        </div>
    </div>
</form>

