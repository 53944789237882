import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse, HttpEventType, HttpHeaders, HttpParams } from "@angular/common/http";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { AuthService } from "./auth.service";
import { ErrorHandlerService } from "./error-handler.service";


@Injectable({
  providedIn: 'root'
})
export class HostInventoryService {

  private createHostInventory = `${environment.apiUrl}/hostInventory`;
  public getTidById = `${environment.apiUrl}/getTid/`;
  public getOwnerById = `${environment.apiUrl}/getOwnerId/`;
  public getAll = `${environment.apiUrl}/hostInventory`;
  public getTidsById = `${environment.apiUrl}/getTids/`;
  public editHostInventory = `${environment.apiUrl}/editHostInventory`;
  public getTpnsIds = `${environment.apiUrl}/getTpnsIds/`;


  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
    private handleError: ErrorHandlerService
  ) { }

  createHostInven(data) {
    return this.httpClient.post(this.createHostInventory, data).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.handleError.handleError)
    );
  }

  editInventory(data) {
    return this.httpClient.post(this.editHostInventory, data).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.handleError.handleError)
    );
  }

  getTid(httpReqUrl, parameter) {
    httpReqUrl = httpReqUrl+"/"+parameter.tid+"/"+parameter.processorId;
    return this.httpClient.get(httpReqUrl).pipe(
      map((res: any) => {
        return res.data;
      }),
      catchError(this.handleError.handleError)
    );
  }

  getOwner(httpReqUrl, parameter) {
    httpReqUrl = httpReqUrl+"/"+parameter.ownerId+"/"+parameter.processorId;
    return this.httpClient.get(httpReqUrl).pipe(
      map((res: any) => {
        return res.data;
      }),
      catchError(this.handleError.handleError)
    );
  }

  getAllHostInv() {
    return this.httpClient.get(this.getAll).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.handleError.handleError)
    );
  }

  getTids(httpReqUrl, parameter) {
    httpReqUrl = httpReqUrl+"/"+parameter.isShowCurrentCustomerTids+"/"+parameter.processorId+"/"+parameter.tidOwner;
    return this.httpClient.get(httpReqUrl).pipe(
      map((res: any) => {
        return res.data;
      }),
      catchError(this.handleError.handleError)
    );
  }

  // getTpnsId(httpReqUrl, parameter) {
  //   httpReqUrl = httpReqUrl+"/"+parameter.tpnList;
  //   return this.httpClient.get(httpReqUrl).pipe(
  //     map((res: any) => {
  //       return res.data;
  //     }),
  //     catchError(this.handleError.handleError)
  //   );
  // }

  getTpnsId(data) {
    console.log("data >>>>>>>>>>>>>> ", data);
    return this.httpClient.post(this.getTpnsIds, data).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.handleError.handleError)
    );
  }

}
