import { Component, Input, OnInit } from '@angular/core';
import {isUndefined, isEmpty, get, map, pickBy, reduce, some} from 'lodash';
@Component({
  selector: 'app-downloader-loader',
  templateUrl: './downloader-loader.component.html',
  styleUrls: ['./downloader-loader.component.scss']
})
export class DownloaderLoaderComponent implements OnInit {

  constructor() { }
  @Input() body: any;
  @Input() isLoading= false;
  @Input() timerData :any;
  display = "00:00";
  intervalFunction;
  ngOnInit(): void {
    this.enableTimer(get(this.timerData, 'downloadTimer', 5) * 60, this.timerData);
    console.log("this.timerData", this.timerData);
  }

  enableTimer(duration, timerData) {
    var timer = duration, minutes, seconds;
    this.intervalFunction = setInterval(function () {
        minutes = parseInt(`${timer / 60}`, 10);
        seconds = parseInt(`${timer % 60}`, 10);

        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;
        this.display = minutes + ":" + seconds;
        document.getElementById('timerData').classList.add("d-block");
        // document.getElementById('timerData').innerHTML = `Downloading <b>${timerData.totalNoOfRows.toLocaleString()}</b> records, approximate download time <span class="p-1 mdi mdi-timer-sand mdi-spin font-weight-bold"> ${this.display}</span>`;
        document.getElementById('timerData').innerHTML = `Downloading records, approximate download time <span class="p-1 mdi mdi-timer-sand mdi-spin font-weight-bold"> ${this.display}</span>`;

        if (--timer < 0) {
            timer = duration;
        }
    }, 1000);
  }
  ngOnDestroy() {
    clearInterval(this.intervalFunction);
  }
}
