import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { Processor } from "../core/model/processor";
import { AuthService } from "./auth.service";
import { ErrorHandlerService } from './error-handler.service';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  private getCurrentCustomerUrl = `${environment.apiUrl}/customers`;
  private createCustomerUrl = `${environment.apiUrl}/iso`;
  private createSubIsoCustomerUrl = `${environment.apiUrl}/sub-iso`;
  public getProcessorUrl = `${environment.apiUrl}/processors`;
  getEmail = `${environment.apiUrl}/getEmail/`;
  getIsoIdentifier = `${environment.apiUrl}/getIsoIdentifier/`;
  customerById = `${environment.apiUrl}/getCustomer/`;
  getSideBarImage = `${environment.apiUrl}/getSidebarImage/`;
  getContractTemplate = `${environment.apiUrl}/getContract/`;
  getContractIso = `${environment.apiUrl}/getContractMaster/`;
  addContract = `${environment.apiUrl}/addContract/`;
  calculateISOMonthlyBillingSummary = `${environment.apiUrl}/calculateISOMonthlyBillingSummary`;
  getselectedInvoiceIso = `${environment.apiUrl}/getselectedInvoiceIso`;

  

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService
  ) { }

  getAllData(httpReqUrl): Observable<Processor[]> {
    return this.httpClient.get<Processor>(httpReqUrl).pipe(
      map((res: any) => {
        return res.data;
      }),
      catchError(this.handleError)
    );
  }

  getAllCustomersByCustomerId(params) {
    let httpParams = new HttpParams();
    Object.keys(params).forEach(function (key) {
      httpParams = httpParams.append(key, params[key]);
    });
    return this.httpClient.get(this.getCurrentCustomerUrl, {params: httpParams}).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.handleError)
    );
  }

  createIsoCustomer(data) {
    console.log("isocreate", data);
    return this.httpClient.post(this.createCustomerUrl, data).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.handleError)
    )
  }

  createSubIsoCustomer(data) {
    
    return this.httpClient.post(this.createSubIsoCustomerUrl, data).pipe(
      map((res: any) => {
        return res;
      }),
      catchError(this.handleError)
    )
  }

  handleError(error) {
    let errorMessage = {};
    console.log("error")
    console.log(error);
    console.log(error.error);
    if (error.error instanceof ErrorEvent) {
      errorMessage["message"] = error.error.message;
    } else if (error.error.errors instanceof Array) {
      return throwError({errors:error.error.errors});
    } else if(error.error.error.message.error){
      errorMessage["status"] = "Double Extensions";
      errorMessage["message"] = "File has multiple Extensions Please Change File Name"
    } else{
      errorMessage["status"] = error.status;
      errorMessage["message"] = error.error.message
        ? error.error.message
        : error.message;
    }
    return throwError(errorMessage);
  }

  get_Parameters(httpReqUrl, parameter) {

    if(_.isUndefined(parameter.isoIdentified)){
      httpReqUrl = httpReqUrl + parameter.email
    }    
    if(!_.isUndefined(parameter.isoIdentified)){
      httpReqUrl = httpReqUrl + parameter.isoIdentified
    }
      return this.httpClient.get(httpReqUrl).pipe(
      map((res: any) => {
        return res.data;
      }),
      catchError(this.handleError)
    );
  }

  getCustomerById(httpReqUrl, parameter) {
    //console.log("parameter",parameter);
    httpReqUrl = httpReqUrl + parameter.customerId;
    //console.log(httpReqUrl);
     return this.httpClient.get(httpReqUrl).pipe(
       map((res: any) => {
         return res.data;
       }),
       catchError(this.handleError)
     );
 }
}
