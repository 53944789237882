import { Injectable } from '@angular/core';
import { BehaviorSubject, observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StoreService {
  constructor() { }
  public refresh: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public transId: BehaviorSubject<any> = new BehaviorSubject<any>([]);

  public filteredData: BehaviorSubject<boolean> = new BehaviorSubject<any>([]);
  public updateFilteredData: BehaviorSubject<any> = new BehaviorSubject('');
  // public modelOpen: BehaviorSubject<any> = new BehaviorSubject<boolean>(false);

  public paginationInput: BehaviorSubject<any> = new BehaviorSubject<any>(0);
  public paginationOut: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  public closeBatch: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public merchantId: BehaviorSubject<any> = new BehaviorSubject<any>('');
  public refreshBatch: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public autoTpn: BehaviorSubject<any> = new BehaviorSubject<any>(false);

  public goToNext: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public bulkHostInventory: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public myTicketRefresh: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
}
